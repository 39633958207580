import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import light from './vuetify/theme';

Vue.use(Vuetify);

import pl from 'vuetify/lib/locale/pl';
import CustomIcon from '../components/layout/CustomIcon.vue';
export default new Vuetify({
  lang: {
    locales: { pl },
    current: 'pl',
  },
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    values: {
      custom: {
        component: CustomIcon,
      },
    },
  },
  theme: {
    themes: { light },
  },
});
