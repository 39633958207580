<template>
  <v-col :class="!isHorizontal ? 'd-flex flex-column' : 'pt-0 d-flex flex-wrap pb-0 pl-0'" :cols="cols" :md="md">
    <v-chip
      v-for="obj in arr"
      :key="obj[comparedArrayKey]"
      :color="compareResult(obj) ? 'black' : 'white'"
      :outlined="compareResult(obj)"
      :style="{
        opacity: compareResult(obj) ? '1' : '0.5',
      }"
      style="margin-right: 5px; margin-bottom: 5px"
      :small="small"
      :text-color="compareResult(obj) ? 'black' : 'grey'"
      :class="propClass ?? 'ml-3'"
      label
      @click="$emit('selectElement', obj)">
      <v-icon :color="hasIcon ? obj.color : 'black'" left>
        {{ obj.icon }}
      </v-icon>
      {{ propGetter(obj, displayKey ?? comparedArrayKey) }}
    </v-chip>
  </v-col>
</template>
<script>
export default {
  name: "JobsListFilterColumnSingleSelectChip",
  props: {
    arr: {},
    comparedValue: {default: null},
    propClass: { type: String },
    comparedArrayKey: {default: null},
    displayKey: {},
    hasIcon: { default: false, type: Boolean },
    cols: {},
    md: {},
    isHorizontal: { default: false },
    small: {type: Boolean, default: false}
  },
  emits: ["selectElement"],
  methods: {
    compareResult(obj) {
      if (obj.selected !== undefined) {
        return obj.selected;
      }
      return this.propGetter(obj, this.comparedArrayKey) === this.comparedValue;
    },
    propGetter(obj, k) {
      if (k === null) {
        return obj;
      }
      return obj[k];
    },
    getStatusIcon(t) {
      return this.taskStatuses.find((x) => x.officeName === t).icon;
    },
  },
};
</script>
<style>
@keyframes expandBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
    background-image: linear-gradient(90deg, silver 50%, transparent 50%),
      linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%),
      linear-gradient(0deg, silver 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
    background-image: linear-gradient(90deg, silver 50%, transparent 50%),
      linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%),
      linear-gradient(0deg, silver 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  }
}
</style>
