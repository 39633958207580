export default {
  setToken(state, payload) {
    state.token = payload;
  },
  setUserInfo(state, payload) {
    // console.log('Payload');
    // console.log(payload.data);
    state.userInfo = payload.data;
  },
  setUsers(state, payload) {
    state.users = payload;
  },
};
