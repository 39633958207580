<template>
  <v-container class="pa-0 ma-0 fill-height" fluid>
    <v-row class="align-start pa-0 ma-0 justify-space-around fill-height" no-gutters>
      <!--tasks-->
      <v-col class="d-flex fill-height flex-column fill-height" cols="5">
        <v-row no-gutters style="background-color: white">
          <v-col>
            <v-row no-gutters>
              <v-col class="d-flex flex-row align-center pa-3">
                <v-icon color="grey" size="24"> mdi-note-multiple</v-icon>
                <span class="pl-2">Zadania</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <v-icon
              color="grey"
              size="24"
              @click="scrollToElement(getChatTasks.length - 1, 'chat_task', $refs.taskList)">
              mdi-arrow-down
            </v-icon>
          </v-col>
        </v-row>
        <v-row no-gutters style="background-color: white">
          <v-col>
            <v-row no-gutters>
              <v-col class="d-flex flex-row align-center pa-1">
                <chat-filter-chip
                  v-model="getActiveFilter"
                  :filter="CHAT_TASK_FILTERS.ALL"
                  icon="mdi-note-multiple-outline">
                  Wszystkie zadania
                </chat-filter-chip>
                <v-spacer></v-spacer>
                <chat-filter-chip
                  v-model="getActiveFilter"
                  :filter="CHAT_TASK_FILTERS.MINE"
                  :icon-color="userInfo.color"
                  icon="mdi-face-agent">
                  Dla mnie
                </chat-filter-chip>
              </v-col>
            </v-row>
            <chat-tasks-filter-chips-row v-model="getActiveFilter" />
          </v-col>
        </v-row>
        <v-row ref="taskList" class="d-flex fill-height overflow-y-auto" no-gutters>
          <v-col class="d-flex flex-column flex-fill" cols="12" style="height: 0px">
            <v-row
              v-if="!getAreAllTaskFetched && !loadingChatTasks"
              v-intersect="{
                handler: onIntersect,
                options: {
                  threshold: 1.0,
                },
              }"
              no-gutters>
              <v-col class="d-flex flex-row justify-center">
                <v-progress-circular :size="30" color="primary" indeterminate />
              </v-col>
            </v-row>
            <v-row v-else-if="loadingChatTasks" no-gutters>
              <v-col class="d-flex flex-row justify-center">
                <v-progress-circular :size="30" color="red" indeterminate />
              </v-col>
            </v-row>
            <chat-task-item
              v-for="(chatTask, i) in getChatTasks"
              :key="chatTask.id"
              :ref="`chat_task-${i}`"
              :selected="computedSelectedTask === chatTask"
              :task="chatTask"
              class="ma-2 pa-1"
              @click.native="setSelectedTask(chatTask)">
            </chat-task-item>
          </v-col>
        </v-row>
        <v-row class="py-2" no-gutters style="background-color: white">
          <v-col>
            <v-sheet class="ma-2 mb-0 pa-1 pb-0" outlined rounded>
              <v-row no-gutters>
                <v-col class="d-flex flex-row align-center" cols="auto">
                  <chat-task-user-selector-chip
                    :assigned-user="newTaskAssignedUser"
                    top
                    @changeAssignedUser="(newAssigned) => (newTaskAssignedUser = newAssigned)" />
                </v-col>
              </v-row>
              <text-area-limited-length
                v-model="taskTitle"
                :loading="isAddNewTaskTextAreaLoading"
                :readonly="isAddNewTaskTextAreaLoading"
                :append-icon-click="postNewTask"
                :outlined="false"
                :text-area-max-length="10000"
                append-icon="mdi-play-box-outline"
                class="px-1 pt-1"
                label="Dodaj zadanie"
                prepend-icon="mdi-note-plus-outline"
                rows="1"
                @onEnter="postNewTask" />
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
      <!--task details-->
      <v-col
        class="d-flex fill-height flex-column"
        cols="5"
        style="border: 1px solid rgba(0, 0, 0, 0.12); border-radius: 2%">
        <v-row v-if="!computedSelectedTask" no-gutters>
          <v-col class="d-flex flex-row align-start pa-4" cols="auto">
            <v-icon color="grey" size="24"> mdi-note-search-outline</v-icon>
            <span class="pl-2">Wybierz zadanie</span>
          </v-col>
        </v-row>
        <div v-else class="d-flex fill-height flex-column">
          <!--task actions-->
          <v-row no-gutters style="flex: 1 1 auto">
            <v-col cols="12">
              <chat-task-details :task="computedSelectedTask"></chat-task-details>
            </v-col>
          </v-row>
          <!--        <v-divider></v-divider>-->
          <v-row class="px-2" no-gutters style="flex: 1 1 auto">
            <v-col>
              <v-row no-gutters>
                <v-col class="d-flex flex-row align-center pa-3">
                  <v-icon color="grey" size="24"> mdi-comment-text-multiple-outline</v-icon>
                  <span class="pl-2">Komentarze</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="computedSelectedTask.comments.length" cols="auto">
              <v-icon
                color="grey"
                size="24"
                @click="scrollToElement(computedSelectedTask.comments.length - 1, 'chat_comment', $refs.commentsList)">
                mdi-arrow-down
              </v-icon>
            </v-col>
          </v-row>

          <!--TODO extract comments list          -->
          <v-row ref="commentsList" class="d-flex fill-height overflow-y-auto px-2" no-gutters>
            <v-col cols="12" style="flex: 1 1 auto; height: 0px; align-content: flex-start">
              <span v-if="!computedSelectedTask.comments.length" class="pl-3">Brak komentarzy</span>
              <v-sheet
                v-for="(comment, i) in computedSelectedTask.comments"
                v-else
                :key="comment.id"
                :ref="`chat_comment-${i}`"
                class="mx-3 my-1">
                <v-row v-if="fadeOutNewMessages(comment)" class="fadeOut" no-gutters>
                  <v-col cols="12">
                    <span style="color: grey; font-size: 0.8em">Nowe komentarze</span>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    i === 0 ||
                    (i > 0 &&
                      formatDateTime(computedSelectedTask.comments[i - 1].createdInfo.createdAt) !==
                        formatDateTime(comment.createdInfo.createdAt))
                  "
                  no-gutters>
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <span style="font-size: 0.8em; color: grey">{{
                      formatDateTime(comment.createdInfo.createdAt)
                    }}</span>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row
                  v-if="
                    !isCommentMine(comment) &&
                    (i === 0 ||
                      (i > 0 &&
                        computedSelectedTask.comments[i - 1].createdInfo.createdBy.id !==
                          comment.createdInfo.createdBy.id))
                  "
                  no-gutters>
                  <v-col cols="auto">
                    <v-icon color="transparent" left small> mdi-circle</v-icon>
                  </v-col>
                  <v-col cols="auto">
                    <v-chip
                      :key="comment.createdInfo.createdBy.id"
                      color="transparent"
                      disabled
                      label
                      small
                      style="opacity: 1; margin: 0 -4px"
                      text-color="black">
                      {{ comment.createdInfo.createdBy.name }}
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row :class="isCommentMine(comment) ? 'd-flex flex-column justify-end align-end' : null" no-gutters>
                  <v-col v-if="!isCommentMine(comment)" cols="auto">
                    <v-icon
                      v-if="
                        i === computedSelectedTask.comments.length - 1 ||
                        (i !== computedSelectedTask.comments.length - 1 &&
                          computedSelectedTask.comments[i + 1].createdInfo.createdBy.id !==
                            comment.createdInfo.createdBy.id)
                      "
                      :color="comment.createdInfo.createdBy.color"
                      left
                      small>
                      mdi-circle
                    </v-icon>
                    <v-icon v-else color="transparent" left small> mdi-circle</v-icon>
                  </v-col>
                  <v-col cols="auto" style="max-width: 70%">
                    <v-sheet
                      :color="isCommentMine(comment) ? 'primary' : 'rgba(0,0,0,0.03)'"
                      class="pa-1"
                      outlined
                      rounded>
                      <span style="word-break: break-word">{{ comment.text }}</span>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>

          <v-row no-gutters style="flex: 0 1 auto">
            <v-col>
              <text-area-limited-length
                v-model="commentText"
                :append-icon-click="sendComment"
                :outlined="false"
                :text-area-max-length="10000"
                append-icon="mdi-email-fast-outline"
                class="pa-3 pb-0"
                label="Napisz komentarz"
                prepend-icon="mdi-comment-text"
                rows="1"
                @onEnter="sendComment" />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { CHAT_TASK_FILTERS, CHAT_TASK_STATUS, UPDATE_TYPE } from "../../constants";
import TextAreaLimitedLength from "../../components/job/TextAreaLimitedLength.vue";
import { formatDateTime } from "../../helper";
import ChatFilterChip from "../../components/chat/ChatFilterChip.vue";
import ChatTaskItem from "../../components/chat/ChatTaskItem.vue";
import ChatTaskDetails from "../../components/chat/ChatTaskDetails.vue";
import ChatTaskUserSelectorChip from "../../components/chat/ChatTaskUserSelectorChip.vue";
import ChatTasksFilterChipsRow from "./ChatTasksFilterChipsRow.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Chat",
  components: {
    ChatTasksFilterChipsRow,
    ChatTaskUserSelectorChip,
    ChatTaskDetails,
    ChatTaskItem,
    ChatFilterChip,
    TextAreaLimitedLength,
  },
  beforeRouteLeave(to, from, next) {
    if (this.addChatTaskEventCallback) {
      this.getStream.removeEventListener(UPDATE_TYPE.CHAT_TASK_ADD, this.addChatTaskEventCallback);
    }
    if (this.modifyChatTaskEventCallback) {
      this.getStream.removeEventListener(UPDATE_TYPE.CHAT_TASK_UPDATE, this.modifyChatTaskEventCallback);
    }
    if (this.addChatCommentEventCallback) {
      this.getStream.removeEventListener(UPDATE_TYPE.CHAT_TASK_ADD_COMMENT, this.addChatCommentEventCallback);
    }
    next();
  },
  beforeRouteUpdate: async (to, from, next) => {
    await this.fetchChatTasks(true);
  },
  data() {
    return {
      newTaskAssignedUser: null,
      tasks: [],
      currentTask: null,
      taskTitle: null,
      commentText: null,
      selectedTask: null,
      activeFilter: CHAT_TASK_FILTERS.ALL,
      loadingChatTasksProp: true,
      isIntersecting: false,
      addChatTaskEventCallback: null,
      modifyChatTaskEventCallback: null,
      addChatCommentEventCallback: null,
      isAddNewTaskTextAreaLoading: false,
      isAddNewCommentTextAreaLoading: false,
    };
  },
  mounted() {
    document.title = "Radmax - Chat";
    this.addChatTaskEventCallback = this.addTaskEvent();
    this.modifyChatTaskEventCallback = this.modifyTaskEvent();
    this.addChatCommentEventCallback = this.addCommentEvent();
    this.getStream.addEventListener(UPDATE_TYPE.CHAT_TASK_ADD, this.addChatTaskEventCallback);
    this.getStream.addEventListener(UPDATE_TYPE.CHAT_TASK_UPDATE, this.modifyChatTaskEventCallback);
    this.getStream.addEventListener(UPDATE_TYPE.CHAT_TASK_ADD_COMMENT, this.addChatCommentEventCallback);
  },
  computed: {
    loadingChatTasks: {
      get() {
        return this.loadingChatTasksProp;
      },
      set(v) {
        this.loadingChatTasksProp = v;
      },
    },
    CHAT_TASK_STATUS() {
      return CHAT_TASK_STATUS;
    },
    CHAT_TASK_FILTERS() {
      return CHAT_TASK_FILTERS;
    },
    ...mapGetters({ userInfo: "user/userInfo" }),
    ...mapGetters({ token: "user/getToken" }),
    ...mapGetters({ chatTask: "chat/getChatTasks" }),
    ...mapGetters({ repositoryTask: "chat/getChatTasks" }),
    ...mapGetters("chat", ["getAreAllTaskFetched"]),
    ...mapGetters("stream", ["getStream"]),
    getChatTasks: {
      get() {
        switch (this.activeFilter) {
          case CHAT_TASK_FILTERS.STATUS.UNCONFIRMED:
            return this.repositoryTask.filter((task) => task.status === CHAT_TASK_STATUS.UNCONFIRMED);
          case CHAT_TASK_FILTERS.STATUS.OPEN:
            return this.repositoryTask.filter((task) => task.status === CHAT_TASK_STATUS.OPEN);
          case CHAT_TASK_FILTERS.STATUS.COMPLETED:
            return this.repositoryTask.filter((task) => task.status === CHAT_TASK_STATUS.COMPLETED);
          case CHAT_TASK_FILTERS.STATUS.REJECTED:
            return this.repositoryTask.filter((task) => task.status === CHAT_TASK_STATUS.REJECTED);
          case CHAT_TASK_FILTERS.MINE:
            return this.repositoryTask.filter((task) => task.performBy && task.performBy.id === this.userInfo.id);
          default: //all
            return this.repositoryTask;
        }
      },
      set(v) {
        this.repositoryTask.add(v);
      },
    },
    computedSelectedTask: {
      get() {
        return this.selectedTask;
      },
      set(v) {
        this.selectedTask = v;
      },
    },
    getActiveFilter: {
      get() {
        return this.activeFilter;
      },
      set(v) {
        this.activeFilter = v;
      },
    },
    getTask: {
      get() {
        return this.tasks;
      },
      set(v) {
        this.tasks.add(v);
      },
    },
  },
  async created() {
    await this.fetchWorkers();
    await this.fetchChatTasks(true);
    this.scrollToElement(this.getChatTasks.length - 1, "chat_task", this.$refs.taskList);
    console.log(this.$el.querySelector("#" + "chat_task" + "-" + this.getChatTasks.length - 1));
    this.computedSelectedTask = this.getChatTasks.at(-1);
  },
  methods: {
    fadeOutNewMessages(comment) {
      return comment.id === this.selectedTask.hasUserRead.unreadCommentId;
    },
    addTaskEvent() {
      return (event) => {
        console.log(event.data);
        var audio = new Audio("notification_xylophone.mp3");
        window.playResult = audio.play();
        playResult.catch((e) => {
          window.playResultError = e;
        });
        this.addChatTask(JSON.parse(event.data));
        this.scrollToElement(this.getChatTasks.length - 1, "chat_task", this.$refs.taskList);
      };
    },
    modifyTaskEvent() {
      // console.log(event.data);
      return async (event) => {
        const task = JSON.parse(event.data);
        console.log(task);
        await this.updateChatTask({ task });
        if (this.computedSelectedTask && this.computedSelectedTask.id === task.id) {
          this.computedSelectedTask = this.$store.getters["chat/getChatTask"](task.id);
        }
      };
    },
    addCommentEvent() {
      // console.log(event.data);
      return async (event) => {
        const task = JSON.parse(event.data);
        console.log(task);
        this.clearTaskHelper(task.id);
        await this.updateChatTask({ task, recalculateStats: true });
        if (this.computedSelectedTask && this.computedSelectedTask.id === task.id) {
          this.computedSelectedTask = this.$store.getters["chat/getChatTask"](task.id);
        }
        if (
          this.computedSelectedTask &&
          this.computedSelectedTask.comments &&
          this.computedSelectedTask.comments.length
        ) {
          console.log("comments length");
          console.log(this.computedSelectedTask.comments.length - 1);
          this.scrollToElement(this.computedSelectedTask.comments.length - 1, "chat_comment", this.$refs.commentsList);
        }
      };
    },
    isCommentMine(comment) {
      return comment.createdInfo.createdBy.id === this.userInfo.id;
    },
    async onIntersect(entries, observer) {
      this.isIntersecting = entries[0].isIntersecting;
      if (this.isIntersecting) {
        const scrollIndexAfterFetch = this.getChatTasks.length;
        await this.fetchChatTasks();
        this.scrollToElement(this.getChatTasks.length - scrollIndexAfterFetch, "chat_task", this.$refs.taskList);
      }
    },
    formatDateTime,
    async postNewTask() {
      if (!this.taskTitle) {
        return;
      }
      if (this.taskTitle) {
        this.taskTitle = this.taskTitle.trim();
        if (!this.taskTitle) {
          return;
        }
      }
      const taskTitleToSend = `${this.taskTitle}`;
      this.taskTitle = null;
      this.isAddNewTaskTextAreaLoading = true;
      await this.$store.dispatch("chat/postChatTask", {
        title: taskTitleToSend,
        userId: this.newTaskAssignedUser ? this.newTaskAssignedUser.id : null,
      });
      this.isAddNewTaskTextAreaLoading = false;
    },
    async sendComment() {
      if (!this.commentText) {
        return;
      }
      if (this.commentText) {
        this.commentText = this.commentText.trim();
        if (!this.commentText) {
          return;
        }
      }
      const taskCommentToSend = `${this.commentText}`;
      this.commentText = null;
      this.isAddNewCommentTextAreaLoading = true;
      await this.$store.dispatch("chat/postChatTaskComment", {
        content: taskCommentToSend,
        taskId: this.computedSelectedTask.id,
      });
      this.isAddNewTaskTextAreaLoading = false;
    },
    scrollToElement(index, element, listRef) {
      this.$nextTick(() => {
        const ref = this.$refs[element + "-" + index];
        if (!ref) {
          return console.log("ELEMENT NOT FOUND");
        }
        const target = ref[0];
        if (!target) {
          return console.log("TARGET NOT FOUND");
        }
        this.$vuetify.goTo(target, { container: listRef, duration: 0 });
      });
      this.loadingChatTasks = false;
    },
    async setSelectedTask(task) {
      this.computedSelectedTask = task;
      if (task.hasUserRead.unreadCommentId || !task.hasUserRead.task) {
        await this.postTaskHasBeenRead(task.id);
        this.recalculateChatStats({ task: task, includeComments: true });
        this.saveTaskHasBeenRead({ task: task });
        setTimeout(() => {
          console.log("SAVE COMMENTS HAS BEEN READ.");
          this.saveTaskHasBeenRead({ task: task, readComments: true });
        }, "5000");
      }
      //TODO: fix scroll behaviour
      if (
        this.computedSelectedTask &&
        this.computedSelectedTask.comments &&
        this.computedSelectedTask.comments.length
      ) {
        this.scrollToElement(this.computedSelectedTask.comments.length - 1, "chat_comment", this.$refs.commentsList);
      }
    },
    ...mapActions("chat", [
      "addChatTask",
      "postTaskHasBeenRead",
      "updateChatTask",
      "saveTaskHasBeenRead",
      "recalculateChatStats",
      "clearTaskHelper"
    ]),
    async fetchWorkers() {
      try {
        this.$store.commit("layoutHelpers/addLoadingProcess", "fetchWorkers");
        await this.$store.dispatch("user/fetchUsers");
      } catch (error) {
        console.log(error);
      }
      this.$store.commit("layoutHelpers/removeLoadingProcess", "fetchWorkers");
    },
    async fetchChatTasks(initial = false) {
      try {
        if (initial) {
          this.$store.commit("layoutHelpers/addLoadingProcess", "fetchChatTasks");
        }
        this.loadingChatTasks = true;
        await this.$store.dispatch("chat/fetchChatTasks", { initial });
      } catch (error) {
        console.log(error);
      }
      if (initial) {
        return this.$store.commit("layoutHelpers/removeLoadingProcess", "fetchChatTasks");
      }
      this.loadingChatTasks = false;
    },
  },
};
</script>
<style scoped>
.fadeOut {
  -webkit-animation: fadeout 11s;
  -webkit-animation-fill-mode: forwards;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
</style>