<template>
  <v-row no-gutters>
    <v-col>
      <v-chip
        :color="!singleOrMultiSelect ? 'black' : 'white'"
        :outlined="!singleOrMultiSelect"
        :style="{
          opacity: !singleOrMultiSelect ? '1' : '0.5',
        }"
        small
        :text-color="!singleOrMultiSelect ? 'black' : 'grey'"
        class="pa-1"
        label
        @click="selectUnselectAllUsers(!areAllMembersSelected)">
        <v-icon color="black" small>
          {{ areAllMembersSelected ? "mdi-checkbox-multiple-marked-outline" : "mdi-checkbox-multiple-blank-outline" }}
        </v-icon>
      </v-chip>
    </v-col>
    <v-col>
      <v-chip
        :color="singleOrMultiSelect ? 'black' : 'white'"
        :outlined="singleOrMultiSelect"
        :style="{
          opacity: singleOrMultiSelect ? '1' : '0.5',
        }"
        small
        :text-color="singleOrMultiSelect ? 'black' : 'grey'"
        class="pa-1"
        label
        @click="selectFirst" >
        <v-icon color="black" small> mdi-checkbox-marked-circle-outline</v-icon>
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SingleMultiArraySelector",
  model: {
    prop: "singleOrMultiSelect",
    event: "changeSelectionMode",
  },
  props: {
    allMembers: {
      type: Array,
    },
    targetArray: {
      type: Array,
    },
    singleOrMultiSelect: {
      type: Boolean,
    },
  },
  emits: ["updateArray"],
  computed: {
    areAllMembersSelected() {
      console.log("all selected?");
      console.log(this.allMembers.every((uId) => this.targetArray.includes(uId)));
      return this.allMembers.every((uId) => this.targetArray.includes(uId));
    },
  },
  methods: {
    selectUnselectAllUsers(select = true) {
      this.$emit("changeSelectionMode", false);
      const newArray = select ? this.allMembers.map((x) => x) : [];
      this.$emit("updateArray", newArray);
    },
    selectFirst() {
      this.$emit("changeSelectionMode", true);
      this.$emit("updateArray", [this.allMembers[0]]);
    },
  },
};
</script>
