<template>
  <v-container>
    <div v-if="!usersAreLoaded" style="text-align: center">
      <v-progress-circular :size="200" :width="7" color="tertiary" indeterminate></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Użytkownicy</v-toolbar-title>
            <v-spacer></v-spacer>
            <add-user @refresh="loadUsers"></add-user>
          </v-toolbar>
          <v-list>
            <template v-for="(item, index) in users">
              <v-list-item :key="item.id">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip color="white" label text-color="black">
                      <v-icon :color="item.color" left> mdi-circle </v-icon>
                      {{ item.name }}
                    </v-chip>
                    <template>
                      <template v-if="item">
                        <user-chip :item="item" />
                      </template>
                    </template>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row>
                    <v-col>
                      <edit-user-button
                        icon="mdi-account-details"
                        label="Nazwa i kolor"
                        @click="expandChange(index, 'nameAndColor')"
                      />
                    </v-col>
                    <v-col>
                      <edit-user-button icon="mdi-lock" label="Hasło" @click="expandChange(index, 'password')" />
                    </v-col>
                    <v-col>
                      <edit-user-button icon="mdi-email" label="E-mail" @click="expandChange(index, 'email')" />
                    </v-col>
                    <v-col>
                      <edit-user-button icon="mdi-account-question" label="Rola" @click="expandChange(index, 'role')" />
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
              <v-sheet v-if="getChangeIndex === index && getChangeType === 'password'">
                <admin-change-password :user.sync="item" @refresh="loadUsers()"></admin-change-password>
              </v-sheet>
              <v-sheet v-if="getChangeIndex === index && getChangeType === 'role'" :key="item.role">
                <admin-change-role :user.sync="item" @refresh="loadUsers()"></admin-change-role>
              </v-sheet>
              <v-sheet v-if="getChangeIndex === index && getChangeType === 'email'" :key="item.email">
                <admin-change-email :user.sync="item" @refresh="loadUsers()"></admin-change-email>
              </v-sheet>
              <v-sheet v-if="getChangeIndex === index && getChangeType === 'nameAndColor'" :key="item.name">
                <admin-change-name-and-color :user.sync="item" @refresh="loadUsers()"></admin-change-name-and-color>
              </v-sheet>
              <v-divider v-if="index !== users.length - 1" :inset="true"></v-divider>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col cols="auto">
        <v-btn to="mobile-version" text left><v-icon>mdi-cellphone-arrow-down</v-icon>Aplikacja mobilna</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdminChangePassword from "../../components/admin/AdminChangePassword";
import AdminChangeRole from "../../components/admin/AdminChangeRole";
import AdminChangeEmail from "../../components/admin/AdminChangeEmail";
import { mapGetters } from "vuex";
import AddUser from "../../components/admin/AddUser.vue";
import AdminChangeNameAndColor from "../../components/admin/AdminChangeNameAndColor";
import { ROLE } from "../../constants";
import UserChip from "../../components/ui/UserChip.vue";
import EditUserButton from "../../components/admin/EditUserButton.vue";

export default {
  name: "UserList",
  components: {
    EditUserButton,
    UserChip,
    AdminChangeNameAndColor,
    AddUser,
    AdminChangeEmail,
    AdminChangeRole,
    AdminChangePassword,
  },
  data: () => ({
    usersAreLoaded: false,
    page: 1,
    limit: 5,
    changeIndex: 0,
    changeType: "nameAndColor",
  }),
  computed: {
    ROLE() {
      return ROLE;
    },
    ...mapGetters({ users: "users/users" }),
    getChangeIndex() {
      console.log(this.changeIndex);
      return this.changeIndex;
    },
    getChangeType() {
      console.log(this.changeType);
      return this.changeType;
    },
  },
  created() {
    this.loadUsers();
  },
  methods: {
    expandChange(i, t) {
      if (this.changeIndex === i && this.changeType === t) {
        return (this.changeIndex = null);
      }
      this.changeIndex = i;
      this.changeType = t;
    },
    async loadUsers() {
      this.usersAreLoaded = false;
      try {
        await this.$store.dispatch("users/loadUsers");
        await this.$store.dispatch("calendarTasks/fetchServiceTechnicians");
      } catch (error) {
        console.log(error);
      }
      this.usersAreLoaded = true;
    },
  },
};
</script>
