import { formatDate, formatDateTime } from '../../../helper';
import types from '../../../types.json';

export default {
  setJobs(state, payload) {
    console.log(payload);

    payload.forEach((x) => {
      x.lastModified = formatDateTime(x.lastModified);
      x.createdAt = formatDateTime(x.createdAt);
      x.cutOffDate = formatDate(x.cutOffDate);
    });
    console.log(payload);
    state.jobs = payload;
  },
  setJobTasks(state, payload) {
    console.log(payload);
    payload.forEach((j) => {
      if (j.tasks !== null) {
        if (j.tasks.length > 0) {
          j.tasks.forEach((t) => {
            t.uid = 't' + t.id;
            return (t.title = (t.startAt === null ? 'niezaplanowane' : formatDate(t.startAt)) + ' ' + types.taskTypes[t.type].name);
          });
        }
      }
      j.uid = 'u' + j.id;
      j.title = j.title + ' ' + (j.location !== null ? j.location.name : 'brak lokalizacji');
    });
    state.jobTasks = payload;
  },
};
