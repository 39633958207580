<template>
  <v-btn icon @click="print">
    <v-icon>mdi-printer-pos-play</v-icon>
  </v-btn>
</template>

<script>
import JsBarcode from "jsbarcode";

const printTemplate = `
<html>
<head>
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&display=swap" rel="stylesheet">
  <title>Drukowanie etykiety</title>
  <style>
    @media print, screen {
      @page {
     size: 4cm 2cm;
     size: landscape;
     margin: 0;
     display: flex;
  }
  body {
   margin: auto;
  }
      .printContent {
        margin: 0 !important;
        padding: 0 !important;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        page-break-after: always
      }
      .barcodeContainer, .textContent {
        flex: 1;
        width: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Reddit Mono", monospace;
        font-weight: bold;
        font-optical-sizing: auto;
        font-style: normal;
      }
      svg {
        height: auto;
        width: auto;
        margin: 0;
        padding: 0;
      }
    }
  </style>
</head>
<body>
  <div class="printContent">
    <div class="barcodeContainer"><!-- Barcode --></div>
    <div class="textContent"><!-- Text --></div>
  </div>
</body>
</html>
`;

export default {
  name: "WarehouseArticleEanPrintButton",
  props: {
    codes: {
      type: Array,
    },
  },
  methods: {
    generateBarcode(ean) {
      let svg = document.createElement("svg");
      JsBarcode(svg, ean, {
        format: "ITF",
        displayValue: false,
      });
      return svg.outerHTML;
    },
    print() {
      const printWindow = window.open("", "PRINT", "height=600,width=800");

      for (const code of this.codes) {
        const barcodeSvg = this.generateBarcode(code);
        let printContent = printTemplate
          .replace("<!-- Barcode -->", barcodeSvg)
          .replace("<!-- Text -->", code);

        printWindow.document.write(printContent);
      }

      // printWindow.document.write(printContent);
      printWindow.document.close(); // Ensure the document is fully loaded
      printWindow.focus(); // Necessary for IE >= 10

      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 1000);
    },
  },
};
</script>
