export default {
  setFacilities(state, payload) {
    state.facilities = payload;
  },
  setFacilitiesLoading(state, isLoading) {
    state.loadingFacilities = isLoading;
  },
  setArticleFacilitiesFilter(state, payload) {
    state.articleFacilitiesFilter = payload;
  },
};
