import { getUserRole } from "../../../helper";
import { ROLE } from "../../../constants";

export default {
  getCalendarSelectedUserIds(state) {
    return state.calendarSelectedUserIds;
  },
  getApiUserTasks(state) {
    return state.apiUserTasks;
  },
  getUserTasks(state) {
    return state.userTasks;
  },
  isUsersSet(state) {
    // console.log("Object.keys(state.users)");
    // console.log(Object.keys(state.users));
    return Object.keys(state.users).length === 0;
  },
  getServiceTechnician: (state) => (id) => {
    // if (this.isUsersSet(state) === false) return null;
    // console.log('keys')
    // console.log(Object.keys(state.users))
    return state.users[id];
  },
  getUsers(state) {
    // if (this.isUsersSet(state) === false) return [];
    if (Object.values(state.users).length === 0) {
      return [];
    }
    return Object.values(state.users);
  },
  getRadmax(state) {
    return Object.values(state.users).filter((x) => getUserRole(x.roles) !== ROLE.EXTERNAL);
  },
  getExternal(state) {
    return Object.values(state.users).filter((x) => getUserRole(x.roles) === ROLE.EXTERNAL);
  },
  getTargetUser(state) {
    return state.targetUser;
  },
  getTargetDate(state) {
    return state.targetDate;
  },
  isColLoading: (state) => (payload) => {
    if (state.loadingCols.length === 0) {
      return false;
    }
    return state.loadingCols.find((s) => s.userId == payload.userId && s.date === payload.date) !== undefined;
  },
  getColLoading(state) {
    return state.loadingCols;
  },
  getDraggingTask(state) {
    return state.draggingTask;
  },
  doesUserHasVacations: (state) => (payload) => {
    // console.log(state.userVacations[payload.date])
    return state.userVacations[payload.date] === undefined
      ? false
      : state.userVacations[payload.date].includes(payload.id);
  },
  getUserVacations(state) {
    return state.userVacations;
  },
  getGroupedTasksOutsideCalendar: (state) => {
    return state.tasksGroupedOutsideCalendar;
  },
  getUnplannedTasksOutsideCalendar(state) {
    return [
      ...state.unplannedTasksOutsideCalendar.assignedTaskWithoutDate,
      ...state.unplannedTasksOutsideCalendar.unassignedTaskWithDate,
      ...state.unplannedTasksOutsideCalendar.unassignedTaskWithoutDate,
    ];
  },
  getPlannedTasksOutsideCalendar(state) {
    return state.plannedTasksOutsideCalendar;
  },
  getAssignedTaskWithoutDate(state) {
    return state.unplannedTasksOutsideCalendar.assignedTaskWithoutDate;
  },
  getUnassignedTaskWithDate(state) {
    return state.unplannedTasksOutsideCalendar.unassignedTaskWithDate;
  },
  getUnassignedTaskWithoutDate(state) {
    return state.unplannedTasksOutsideCalendar.unassignedTaskWithoutDate;
  },
  getOverdueMoreThan7Days(state) {
    return state.unplannedTasksOutsideCalendar.overdueMoreThan7Days;
  },
  getOverdueLessThan7Days(state) {
    return state.unplannedTasksOutsideCalendar.overdueLessThan7Days;
  },
  unassignedFilters(state) {
    return state.unassignedFilters;
  },
};
