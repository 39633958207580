import Vue from 'vue';

export default {
  setUserTasks(state, payload) {
    state.userTasks = payload;
  },
  unsetUserTask(state, payload) {
    Object.keys(state.userTasks).forEach((x) => Vue.delete(state.userTasks[x], payload));
  },
  setUsers(state, payload) {
    state.users = payload;
  },
  setDraggingTask(state, payload) {
    console.log('dragging task');
    console.log(payload);
    state.draggingTask = payload;
  },
  setVacationsByDay(state, payload) {
    Vue.set(state.userVacations, payload.date, payload.data);
  },
};
