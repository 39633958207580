import { ROLE } from '../../../constants';
import { getUserRole, getUserRole as roleHelper } from '../../../helper';

export default {
  hasToken: (state) => {
    return !!state.token;
  },
  getToken: (state) => {
    return state.token;
  },
  userInfo: (state) => {
    return state.userInfo;
  },
  hasUserInfo: (state) => {
    return state.userInfo.email !== null;
  },
  isAdmin: (state) => {
    return state.userInfo.roles.includes('ROLE_ADMIN');
  },
  isServiceTechnician: (state) => {
    if (state.userInfo.roles.length === 0) return null;
    return [ROLE.SERVICE_TECHNICIAN, ROLE.EXTERNAL].includes(state.userInfo.roles[0]);
  },
  getUserRole: (state) => {
    return roleHelper(state.userInfo.roles);
  },
  getUsers(state) {
    return state.users;
  },
  getOfficeUsers(state) {
    return state.users.filter((x) => [ROLE.ADMIN, ROLE.OFFICE].includes(getUserRole(x.roles)));
  },
};
