<template>
  <v-menu :bottom="bottom" :top="top" offset-y>
    <template #activator="{ on, attrs }">
      <v-chip
        v-if="assignedUser"
        color="transparent"
        label
        class="mx-0"
        text-color="black"
        v-bind="attrs"
        v-on="on">
        <v-icon :color="assignedUser.color" left> mdi-face-agent</v-icon>
        {{ assignedUser.name }}
        <v-icon color="grey" right> mdi-menu-down-outline</v-icon>
      </v-chip>
      <v-chip
        v-else
        color="transparent"
        label
        class="mx-0"
        text-color="orange"
        v-bind="attrs"
        v-on="on">
        <v-icon color="orange" left> mdi-account-question</v-icon>
        Nieprzypisane
        <v-icon color="grey" right> mdi-menu-down-outline</v-icon>
      </v-chip>
    </template>
    <v-list class="pa-0" dense>
      <v-list-item class="px-1">
        <v-row no-gutters>
          <v-col>
            <span>Zmień wykonawcę</span>
          </v-col>
        </v-row>
      </v-list-item>
      <v-list-item
        v-for="(officeUser, index) in getOfficeUsers.filter(
          (current) => !(assignedUser && current.id === assignedUser.id)
        )"
        :key="index"
        class="px-1"
        @click="changeAssignedUser(officeUser)">
        <v-row no-gutters>
          <v-col>
            <v-chip class="ma-0" color="grey" label outlined style="width: 100%" text-color="black">
              <v-icon :color="officeUser.color" left> mdi-face-agent</v-icon>
              {{ officeUser.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-list-item>
      <v-list-item v-if="assignedUser" class="px-1" @click="changeAssignedUser(null)">
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col>
                <v-chip class="ma-0" color="grey" label outlined style="width: 100%" text-color="orange">
                  <v-icon color="orange" left> mdi-account-question</v-icon>
                  Nieprzypisane
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    assignedUser: {
      required: true,
    },
    bottom: {
      default: false,
      type: Boolean
    },
    top: {
      default: false,
      type: Boolean
    }
  },
  emits: ['changeAssignedUser'],
  computed: {
    ...mapGetters("user", ["getOfficeUsers"]),
  },
  methods: {
    async changeAssignedUser(newAssigned) {
      this.$emit('changeAssignedUser', newAssigned);
    },
  },
};
</script>
