export default {
  facilities: (state) => {
    return state.facilities;
  },
  getFacilityById: (state) => (facilityId) => {
    const facilityIdx = state.facilities.findIndex((value) => value.id === facilityId);
    if (facilityIdx === -1) {
      return null;
    }
    return state.facilities[facilityIdx];
  },
  facilitiesLoading: (state) => {
    return state.loadingFacilities;
  },
  facilitySubmitting: (state) => {
    return state.submittingFacility;
  },
  articleFacilitiesFilter: (state) => {
    return state.articleFacilitiesFilter;
  },
};
