import types from "../../types.json";

export default class CreateJobClass {
  taskType = 0;
  jobTitle = null;
  externalJobNumber = null;
  cutOffDate = null;
  isSLA = true;
  customer = null;
  location = null;
  signedUsers = null;
  startAtDate = null;
  taskDescription = null;
  warningOffset = null;

  constructor({
    taskType = null,
    jobTitle = null,
    externalJobNumber = null,
    cutOffDate = null,
    isSLA = null,
    customer = null,
    location = null,
    signedUsers = null,
    startAtDate = null,
    taskDescription = null,
    warningOffset = null,
  }) {
    // <-------
    this.taskType = taskType;
    this.jobTitle = jobTitle;
    this.externalJobNumber = externalJobNumber;
    this.cutOffDate = cutOffDate;
    this.isSLA = isSLA;
    this.customer = customer;
    this.location = location;
    this.signedUsers = signedUsers;
    this.startAtDate = startAtDate;
    this.taskDescription = taskDescription;
    this.warningOffset = warningOffset;
  }

  static createDefault() {
    // <-------
    return new CreateJobClass({
      taskType: Object.keys(types.taskTypes).at(0),
      jobTitle: null,
      externalJobNumber: null,
      cutOffDate: null,
      isSLA: true,
      customer: null,
      location: null,
      signedUsers: [],
      startAtDate: null,
      taskDescription: null,
      warningOffset: 7,
    });
  }

  static create(params) {
    // <-------
    return new CreateJobClass(params);
  }

  display() {
    console.log(this);
  }
}
