import { SETTLEMENT_STATUS } from "../../constants";

export default class JobFiltersClass {

    status =  null;
    taskType =  null;
    taskStatus =  null;
    searchText =  null;
    users =  null;
    settlementStatus = null


  constructor({
                status =  null,
                taskType =  null,
                taskStatus =  null,
                searchText =  null,
                users =  null,
                settlementStatus = null
  }) {
    // <-------
    this.status = status
    this.taskType = taskType
    this.taskStatus = taskStatus
    this.searchText = searchText
    this.users = users
    this.settlementStatus = settlementStatus
  }

  static createDefault() {
    // <-------
    return new JobFiltersClass({
        status: null,
        taskType: null,
        taskStatus: null,
        searchText: null,
        users: [],
        settlementStatus: null
    });
  }

  static create(params) {
    // <-------
    return new JobFiltersClass(params);
  }

  display() {
    console.log(this);
  }
}
