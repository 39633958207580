import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      articleDetails: null,
      loadingArticleDetails: true,
      submittingArticle: false,
      submittingArticleEAN: false,
      submittingArticleNameValidation: false,
      submittingArticleEANValidation: false,
    };
  },
  mutations,
  actions,
  getters,
};
