<template>
  <v-menu
    v-if="menuDetails"
    v-model="showMenu"
    transition="slide-x-transition"
    :position-x="x"
    :position-y="y"
    offset-y
    :close-on-content-click="false"
  >
    <v-card :style="{ width: menuCardWidth + 'px' }">
      <v-card-text>
        <v-row>
          <v-col style="max-width: 30px">
            <v-icon size="20"> mdi-map-marker </v-icon>
          </v-col>
          <v-col v-if="menuDetails.job !== undefined">
            {{ menuDetails.job.location !== null ? menuDetails.job.location.name : "Brak adresu" }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col style="max-width: 30px">
            <v-icon size="20">
              {{ returnIcon }}
            </v-icon>
          </v-col>
          <v-col>
            {{ taskTypes[menuDetails.type].name }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col style="max-width: 30px">
            <v-icon size="20"> mdi-text-box-search </v-icon>
          </v-col>
          <v-col>
            <p v-if="menuDetails.description === null">brak opisu</p>
            <p v-else style="color: black" v-html="menuDetails.description"></p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col style="max-width: 30px">
            <v-icon size="20"> mdi-timer-outline </v-icon>
          </v-col>
          <v-col>
            {{ menuDetails.job.cutOffDate === null ? "Brak daty granicznej" : cutOffDate }}
            <p v-if="menuDetails.job.cutOffDate !== null" :style="{ color: dayDiff < warningOffset ? 'red' : '' }">
              {{ " (pozostało " + dayDiff + " dni)" }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text style="padding-top: 0">
        <v-row :style="{ color: menuDetails.startAt === null ? 'red' : 'black' }">
          <v-col style="max-width: 30px">
            <v-icon size="20">
              {{ menuDetails.startAt === null ? "mdi-calendar-remove" : "mdi-calendar" }}
            </v-icon>
          </v-col>
          <v-col>
            {{ menuDetails.startAt === null ? "niezaplanowane" : startAt }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          text
          elevation="-1"
          style="min-width: 0; padding: 0 3px"
          @click.native="$router.push({ path: '/job/' + menuDetails.job.id })"
        >
          <v-icon left color="grey"> mdi-pencil </v-icon>
          Zlecenie
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";
import types from "../../types.json";
export default {
  name: "PopUpMenu",
  data() {
    return {
      taskTypes: types.taskTypes,
      dayDiff: {},
      cutOffDate: {},
      startAt: {},
      warningOffset: null,
      menuDetails: null,
      returnIcon: {},
      showMenu: {},
      x: {},
      y: {},
    };
  },
  computed: {
    ...mapGetters({ menuCardWidth: "layoutHelpers/getMenuPopUpWidth" }),
    ...mapGetters({ menuParams: "layoutHelpers/getMenuParams" }),
  },
  watch: {
    menuParams: {
      handler(a, b) {
        console.log("menu pop up");
        console.log(a);
        this.dayDiff = this.menuParams.dayDiff;
        this.cutOffDate = this.menuParams.cutOffDate;
        this.warningOffset = this.menuParams.warningOffset;
        this.startAt = this.menuParams.startAt;
        this.menuDetails = this.menuParams.menuDetails;
        this.returnIcon = this.menuParams.returnIcon;
        this.showMenu = this.menuParams.showMenu;
        this.x = this.menuParams.x;
        this.y = this.menuParams.y;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}
</style>
