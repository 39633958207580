export default {
  getJobs(state) {
    return state.jobs;
  },
  getTotalJobs(state) {
    return state.totalJobs;
  },
  getJob(state) {
    return state.job;
  },
  getNotes(state) {
    return state.notes;
  },
  getFiles(state) {
    return state.files;
  },
  getProtocols(state) {
    return state.protocols;
  },
  getFileNumber(state) {
    return state.fileNumber;
  },
  getUploadProgress(state) {
    return state.uploadProgress;
  },
};
