export default {
  setSubmittingArticleGroupDetails(state, isSubmitting) {
    state.submittingGroupDetails = isSubmitting;
  },
  setArticleGroupsDetails(state, payload) {
    state.articleGroupsDetails = payload;
  },
  setArticleGroupsDetailsLoading(state, isLoading) {
    state.loadingArticleGroupsDetails = isLoading;
  },
};
