<template>
  <div key="'Zlecenia'">
    <v-progress-circular
      v-if="jobs === null"
      :size="200"
      :width="7"
      color="tertiary"
      indeterminate></v-progress-circular>
    <template v-else>
      <p v-if="jobs.length === 0" class="d-flex justify-center">Brak zleceń w koszu</p>
      <v-data-table
        v-else
        v-model="computedItemsToDelete"
        :headers="headers"
        :items="jobs"
        :items-per-page="-1"
        hide-default-footer
        item-key="id"
        show-select>
        <template #body="{ items }">
          <tbody>
            <tr v-for="job in items" :key="job.id">
              <td>
                <v-checkbox v-model="computedItemsToDelete" :value="job" :input-value="job" ></v-checkbox>
              </td>
              <td @click="handleDoubleClick">
                <a
                  :href="$router.resolve({ name: 'jobView', params: { jobId: job.id } }).route.path"
                  style="color: black">
                  {{ job.title }}
                </a>
              </td>
              <td @click="handleDoubleClick(job.id)">{{ job.customer !== null ? job.customer.name : "Klient bez nazwy" }}</td>
              <td @click="handleDoubleClick(job.id)">{{ job.location !== null ? job.location.name : "Brak lokalizacji" }}</td>
              <td @click="handleDoubleClick(job.id)">{{ job.cutOffDate }}</td>
              <td @click="handleDoubleClick(job.id)">{{ formatDateTime(job.modifiedInfo.lastModified) }}</td>
              <td @click="handleDoubleClick(job.id)">{{ formatDateTime(job.createdInfo.createdAt) }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import types from "../../types.json";
import { formatDateTime } from "../../helper";
import DoubleClick from "../../store/class/DoubleClick";
import { binJobDataTableHeadersConst } from "../../constants";

export default {
  name: "JobBinTable",
  data() {
    return {
      doubleClick: DoubleClick.createDefault(),
      types,
      selected: [],
      headers: binJobDataTableHeadersConst,
    };
  },
  computed: {
    ...mapGetters({ jobs: "bin/getJobs" }),
    computedItemsToDelete: {
      get() {
        return this.itemsToDelete.deleteItems;
      },
      set(v) {
        if (this.tab !== 0) {
          return;
        }
        this.$emit("newValue", {
          deleteItems: v,
          type: "job",
        });
      },
    },
  },
  methods: {
    handleDoubleClick(value) {
      this.doubleClick.doubleClick(
        value,
        () => this.$router.push({ name: "jobView", params: { jobId: value } }),
        () => window.open("/job/" + value, "_blank")
      );
    },
    formatDateTime,
    ...mapActions({ fetchJobs: "bin/fetchBinJobs" }),
    goToJob(value) {
      this.$router.push({ name: "jobView", params: { jobId: value.id } });
    },
  },
  model: {
    prop: "itemsToDelete",
    event: "newValue",
  },
  props: {
    itemsToDelete: {},
    tab: {},
  },
  watch: {
    computedItemsToDelete(a) {
      if (a === null) {
        this.computedItemsToDelete = []
      }
    }
  },
  created() {
    this.fetchJobs();
    this.selected = [];
  },
};
</script>

<style scoped></style>
