<template>
  <v-container class="pt-0 pr-0" fluid>
    <v-row class="pb-3" no-gutters>
      <v-col class="d-flex flex-row align-end">
        <v-icon color="grey" size="24"> mdi-calendar-filter</v-icon>
        <span class="pl-2">Zadania</span>
      </v-col>
      <v-col v-if="getShowUnassignedFilters" cols="auto">
        <v-chip :color="'white'" :text-color="'black'" label @click="switchShowUnassignedFilters">
          <v-icon :color="filters.showUnplanned ? 'black' : 'grey'" left> mdi-calendar-collapse-horizontal</v-icon>
          ukryj filtry
        </v-chip>
      </v-col>
    </v-row>
    <v-tabs v-model="tab">
      <v-tab @click.native="filters.planningMode = CALENDAR_PLANNING_MODE.PLANNED">
        <v-icon left>
          mdi-calendar-check
        </v-icon>
        Zaplanowane
      </v-tab>
      <v-tab @click.native="filters.planningMode = CALENDAR_PLANNING_MODE.UNPLANNED">
        <v-icon left>
          mdi-calendar-question
        </v-icon>
        Niezaplanowane
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row class="d-flex flex-row" no-gutters>
          <v-col cols="auto">
            <v-sheet class="pa-1 mr-1" outlined rounded>
              <v-row no-gutters>
                <v-col>Podstawowe</v-col>
              </v-row>
              <unassigned-filters-result-limiter />
            </v-sheet>
          </v-col>

          <v-col cols="auto">
            <v-sheet class="pa-1 mr-1" outlined rounded>
              <v-row no-gutters>
                <v-col>Dodatkowe</v-col>
              </v-row>
              <unassigned-filters-extended />
            </v-sheet>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="d-flex flex-row" no-gutters>
          <v-col cols="auto">
            <v-sheet class="pa-1 mr-1" outlined rounded>
              <v-row no-gutters>
                <v-col>Podstawowe</v-col>
              </v-row>
              <unassigned-filters-result-limiter />
            </v-sheet>
          </v-col>

          <v-col cols="auto">
            <v-sheet class="pa-1 mr-1" outlined rounded>
              <v-row no-gutters>
                <v-col>Dodatkowe</v-col>
              </v-row>
              <unassigned-filters-extended />
            </v-sheet>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import UnassignedFiltersResultLimiter from "./UnassignedFiltersBasic.vue";
import { CALENDAR_PLANNING_MODE } from "../../../constants";
import UnassignedFiltersExtended from "./UnassignedFiltersExtended.vue";

export default {
  components: {
    UnassignedFiltersExtended,
    UnassignedFiltersResultLimiter,
  },
  data: () => ({
    CALENDAR_PLANNING_MODE: CALENDAR_PLANNING_MODE,
    tab: 1,
  }),
  emits: ['changePlanningMode'],
  computed: {
    ...mapGetters("layoutHelpers", ["getShowUnassignedFilters"]),
    filters: {
      get() {
        return this.$store.state.calendarTasks.unassignedFilters;
      },
      set(v) {
        this.$store.dispatch("calendarTasks/setUnassignedFilters", v);
      },
    },
  },
  methods: {
    ...mapActions("layoutHelpers", ["switchShowUnassignedFilters"]),
  },
  watch: {
    'filters.planningMode': {
      handler(a) {
        console.log('changed mode');
        console.log(a);
        this.$emit('changePlanningMode');
      }
    }
  }
};
</script>
<style scoped>
.theme--light.v-btn.v-btn--disabled {
  color: transparent !important;
}
</style>
