<template>
  <v-container>
    <v-row v-if="usersAreLoaded">
      <v-col>
        <template>
          <v-card>
            <v-toolbar flat color="primary" dark>
              <v-toolbar-title>Zarządzanie urlopami</v-toolbar-title>
            </v-toolbar>
            <v-row>
              <v-col class="shrink">
                <v-chip
                  v-for="(user, index) in users"
                  :key="user.id"
                  class="ma-2"
                  :outlined="activeVacationTab === index"
                  label
                  :color="index === activeVacationTab ? 'green' : 'transparent'"
                  @click="activeVacationTab = index"
                >
                  <v-icon left :color="user.color"> mdi-circle </v-icon>

                  {{ user.name }}
                </v-chip>
              </v-col>
              <v-col>
                <vacation-management :user="users[activeVacationTab]"></vacation-management>
              </v-col>
            </v-row>
          </v-card>
        </template>
        <!--      <vacation-management></vacation-management>-->
      </v-col>
      <v-col>
        <vacation-calendar></vacation-calendar>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VacationManagement from "../../components/vacation/VacationManagement";
import VacationCalendar from "../../components/vacation/VacationCalendar";
export default {
  components: { VacationCalendar, VacationManagement },
  data() {
    return {
      usersAreLoaded: false,

      activeVacationTab: 0,
    };
  },
  computed: {
    users() {
      return this.$store.getters["user/getUsers"];
    },
    indexesOfUsers() {
      return this.$store.getters["user/getUsers"];
    },
    activeTab: {
      get() {
        return this.activeVacationTab;
      },
      set(v) {
        this.activeVacationTab = v;
      },
    },
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      try {
        await this.$store.dispatch("user/fetchUsers");
      } catch (error) {
        console.log(error);
      }
      this.usersAreLoaded = true;
    },
    setActiveVacationTab(userId) {
      let a = this.users.findIndex((u) => u.id === userId);
      this.activeVacationTab = a === -1 ? 0 : a;
    },

    test(xd) {
      console.log(xd);
    },
  },
};
</script>
