export default {
  setTasks(state, payload) {
    state.tasks = payload;
  },

  addTask(state, payload) {
    state.tasks.push(payload);
  },

  deleteTask(state, payload) {
    var index = state.tasks.findIndex((element) => element.id === payload);
    state.tasks.splice(index, 1);
  },

  setTasksAreLoading(state, payload) {
    state.tasksAreLoading = payload;
  },
};
