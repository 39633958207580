<template>
  <v-container fluid>
    <v-data-table
      :footer-props="{ 'items-per-page-options': [50, 100, 250] }"
      :headers="selectedHeaders"
      :items="articles"
      :loading="articlesLoading"
      :options.sync="options"
      :server-items-length="totalArticles"
      class="elevation-1"
      item-key="id">
      <template #top>
        <v-toolbar dense flat>
          <v-toolbar-title>Magazyn - artykuły</v-toolbar-title>
          <v-spacer />
          <warehouse-create-article-dialog />
        </v-toolbar>
        <v-row class="pl-2" no-gutters>
          <v-col cols="3">
            <v-text-field
              v-model="searchTerm"
              :loading="articlesLoading && searchTerm"
              clearable
              label="Szukaj"
              prepend-icon="mdi-magnify"></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6">
            <v-row v-if="articleGroupsLoading">
              <v-spacer />
              <v-col>
                <v-progress-circular />
              </v-col>
              <v-spacer />
            </v-row>
            <warehouse-article-group-filter v-else class="pt-2" />
            <v-row v-if="facilitiesLoading">
              <v-spacer />
              <v-col>
                <v-progress-circular />
              </v-col>
              <v-spacer />
            </v-row>
            <warehouse-article-facility-filter v-else class="pt-2" />
          </v-col>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-row>
        <v-row no-gutters>
          <template v-if="articlesToPrint.length">
            <v-badge :content="articlesToPrint.length" :value="articlesToPrint.length" bordered overlap>
              <warehouse-article-ean-print-button
                :codes="
                  articlesToPrint.map((value) => String(value).padStart(6, '0'))
                "
                class="pl-1"></warehouse-article-ean-print-button>
            </v-badge>
<!--            <v-btn icon>-->
<!--              <v-icon>mdi-printer-pos-wrench</v-icon>-->
<!--            </v-btn>-->
            <v-btn color="error" icon @click="articlesToPrint = []">
              <v-icon>mdi-printer-pos-remove</v-icon>
            </v-btn>
          </template>
          <v-spacer />
          <v-col cols="auto">
            <v-menu :close-on-content-click="false" left offset-y>
              <template #activator="{ on, attrs }">
                <v-btn color="primary" text v-bind="attrs" v-on="on">
                  <v-icon left>mdi-table-cog</v-icon>
                  kolumny
                </v-btn>
              </template>
              <v-list class="pa-0" dense>
                <v-list-item
                  v-for="(iHeader, idxHeader) in headers.filter((x) => !['actions', 'name', 'warehouseArticleImages'].includes(x.value))"
                  :key="idxHeader"
                  class="px-1"
                  @click="selectHeader(iHeader)">
                  <v-row no-gutters>
                    <v-col>
                      <v-chip
                        :color="selectedHeaders?.some((v) => v === iHeader) ? 'grey' : 'transparent'"
                        :outlined="selectedHeaders?.some((v) => v === iHeader)"
                        class="ma-0"
                        label
                        style="width: 100%"
                        text-color="black">
                        {{ iHeader.text }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <template #item="{ item, index }">
        <tr :style="{ 'background-color': index % 2 ? '#FAFAFA' : 'white' }">
          <td class="pa-0">
            <v-checkbox
              :value="articlesToPrint.includes(item.id)"
              @change="selectToPrint(item.id)"
              dense
              class="pl-2"></v-checkbox>
          </td>
          <td @click="handleDoubleClick(item)" style="cursor: pointer" class="pl-0">
            <v-row no-gutters class="d-flex flex-row align-center justify-center">
            <v-img
              v-if="item.warehouseArticleImages?.some(i => i.isPrimary)"
              aspect-ratio="1"
              contain
              max-height="40"
              :src="WAREHOUSE_IMAGE_PATH+item.warehouseArticleImages.find(i => i.isPrimary)?.filename">
              <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-icon> mdi-image-plus </v-icon>
                </v-row>
              </template>
            </v-img>
            <v-icon v-else>mdi-image</v-icon>
            </v-row>
          </td>
          <td
            v-if="selectedHeaders?.some((v) => v.value === 'name')"
            :ref="`article_${item.id}_name`"
            style="cursor: pointer"
            @click="handleDoubleClick(item)">
            {{ item.name }}
          </td>
          <td v-if="selectedHeaders?.some((v) => v.value === 'id')">
            {{ String(item.id).padStart(6, "0") }}
          </td>
          <td
            v-if="selectedHeaders?.some((v) => v.value === 'originalProductNumber')"
            :ref="`article_${item.id}_originalProductNumber`"
            @click="openEditMenu($event, `article_${item.id}_originalProductNumber`, item)">
            {{ item.originalProductNumber === "" ? "-" : item.originalProductNumber }}
          </td>
          <td v-if="selectedHeaders?.some((v) => v.value === 'group')">
            {{ articleGroups.find((value) => value.id === item.group.id).name }}
          </td>
          <td v-if="selectedHeaders?.some((v) => v.value === 'supplier')" :ref="`article_${item.id}_supplier`">
            {{ item.supplier ? articleSuppliers.find((sup) => sup.id === item.supplier.id)?.name ?? "-" : "-" }}
          </td>
          <td v-if="selectedHeaders?.some((v) => v.value === 'price')">
            {{ item.price + " " + item.currency }}
          </td>
          <td v-if="selectedHeaders?.some((v) => v.value === 'totalQuantity')">
            {{
              item.warehouseArticleFacilities.reduce((accumulator, f) => accumulator + f.quantity, 0) +
              " " +
              warehouseTypes.articleUnit[item.unit].abbreviation
            }}
          </td>
          <template v-for="fh in facilityHeaders">
            <td
              v-if="selectedHeaders?.some((v) => v.value === fh.value)"
              :key="fh.id"
              :ref="`article_${item.id}_warehouseFacility-${fh.value}`"
              @click="openEditMenu($event, `article_${item.id}_warehouseFacility-${fh.value}`, item)">
              <v-chip
                :class="{
                  'font-weight-bold':
                    (item.warehouseArticleFacilities.find((fac) => fac.warehouseFacility.id === fh.value)?.quantity ??
                      0) <
                      item.warehouseArticleFacilities.find((fac) => fac.warehouseFacility.id === fh.value)
                        ?.min_quantity ?? 0,
                }"
                :style="{ cursor: 'grab' }"
                color="transparent"
                draggable
                label
                @dragstart="
                  startDrag($event, {
                    article: item,
                    facilityId: fh.value,
                  })
                "
                @drop="
                  endDrag($event, {
                    article: item,
                    facilityId: fh.value,
                  })
                "
                @dragover.prevent>
                {{
                  (item.warehouseArticleFacilities.find((fac) => fac.warehouseFacility.id === fh.value)?.quantity ??
                    0) +
                  " " +
                  warehouseTypes.articleUnit[item.unit].abbreviation
                }}
              </v-chip>
              <v-tooltip
                v-if="
                  (item.warehouseArticleFacilities.find((fac) => fac.warehouseFacility.id === fh.value)?.quantity ??
                    0) <
                    item.warehouseArticleFacilities.find((fac) => fac.warehouseFacility.id === fh.value)
                      ?.min_quantity ?? 0
                "
                bottom>
                <template #activator="{ on, attrs }">
                  <v-icon color="error" v-bind="attrs" v-on="on">mdi-alert-rhombus-outline</v-icon>
                </template>
                <span
                  >Brakująca ilość:
                  {{
                    (item.warehouseArticleFacilities.find((fac) => fac.warehouseFacility.id === fh.value)
                      ?.min_quantity ?? 0) -
                    (item.warehouseArticleFacilities.find((fac) => fac.warehouseFacility.id === fh.value)?.quantity ??
                      0)
                  }}
                </span>
              </v-tooltip>
            </td>
          </template>
<!--          <td-->
<!--            v-if="selectedHeaders?.some((v) => v.value === 'actions')"-->
<!--            class="d-flex flex-row justify-center align-center">-->
<!--            <a-->
<!--              :href="$router.resolve({ name: 'warehouseArticleDetails', params: { articleId: item.id } }).route.path"-->
<!--              style="color: black">-->
<!--              <v-icon> mdi-dots-horizontal</v-icon>-->
<!--            </a>-->
<!--          </td>-->
        </tr>
      </template>
    </v-data-table>

    <v-menu
      v-if="editingProperty"
      v-model="showEditingMenu"
      :close-on-content-click="false"
      :left="getWindowWidth / 2 < x"
      :position-x="x"
      :position-y="y"
      :right="getWindowWidth / 2 >= x"
      transition="slide-x-transition">
      <v-card v-if="editingProperty === 'transfer'">
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="auto">
              <v-text-field
                v-model="
                  draggedData.to.article.warehouseArticleFacilities.find(
                    (wAF) => wAF.warehouseFacility.id === draggedData.from.facilityId
                  ).quantity
                "
                :label="facilities.find((f) => f.id === draggedData.from.facilityId).name"
                dense
                disabled
                type="number">
              </v-text-field>
              <v-text-field
                v-model="draggedData.quantity"
                :rules="[rules.isQuantityNonPositive()]"
                label="Ilość"
                prepend-icon="mdi-arrow-down-bold-circle-outline"
                type="number">
              </v-text-field>
              <v-text-field
                v-model="
                  draggedData.to.article.warehouseArticleFacilities.find(
                    (wAF) => wAF.warehouseFacility.id === draggedData.to.facilityId
                  ).quantity
                "
                :label="facilities.find((f) => f.id === draggedData.to.facilityId).name"
                dense
                disabled
                type="number">
                <template #append-outer>
                  <v-btn
                    :loading="articleStockSubmitting"
                    :disabled="
                      !!rules.isQuantityNonPositive()(draggedData.quantity).length ||
                      draggedData.to.article.warehouseArticleFacilities.find(
                        (wAF) => wAF.warehouseFacility.id === draggedData.from.facilityId
                      ).quantity -
                        draggedData.quantity <
                        0
                    "
                    icon
                    @click="submitArticleTransfer()">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card v-else-if="editingProperty" :min-width="menuWidth">
        <!--        <v-card-title>{{ editedArticle }}</v-card-title>-->
        <v-card-text class="py-0">
          <template v-if="editingProperty.includes('warehouseFacility')">
            <v-row no-gutters>
              <warehouse-stock-operation-chip
                v-for="type in Object.values(WAREHOUSE_STOCK_OPERATION).filter(
                  (x) => ![WAREHOUSE_STOCK_OPERATION.TRANSFER].includes(x)
                )"
                :key="type"
                :operation-type="type"
                :stock-operation="stockOperation"
                @update:stockOperation="stockOperation = $event" />
            </v-row>
            <v-text-field
              v-if="stockOperation === WAREHOUSE_STOCK_OPERATION.CORRECTION"
              :label="`Inwentaryzacja -  ${
                facilities[
                  facilities.findIndex((wAF) => wAF.id === parseInt(editingProperty.split('warehouseFacility-').at(-1)))
                ]?.name
              }`"
              :rules="[rules.isQuantityNegative()]"
              :value="getQuantity()"
              type="number"
              @input="setQuantity">
              <template #append-outer>
                <v-btn
                  :loading="articleStockSubmitting"
                  :disabled="!!rules.isQuantityNegative()(getQuantity()).length"
                  icon
                  @click="submitArticleStockUpdate()">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-text-field
              v-else-if="stockOperation === WAREHOUSE_STOCK_OPERATION.CONSUMPTION"
              :label="`Rozchód - ${
                facilities[
                  facilities.findIndex((wAF) => wAF.id === parseInt(editingProperty.split('warehouseFacility-').at(-1)))
                ]?.name
              }`"
              :rules="[rules.isQuantityNonPositive()]"
              :value="getQuantity()"
              prefix="-"
              type="number"
              @input="setQuantity">
              <template #append-outer>
                <v-btn
                  :loading="articleStockSubmitting"
                  :disabled="!!rules.isQuantityNonPositive()(getQuantity()).length"
                  icon
                  @click="submitArticleStockUpdate()">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </template>
            </v-text-field>

            <template v-else-if="stockOperation === WAREHOUSE_STOCK_OPERATION.REPLENISHMENT">
              <v-text-field
                :label="`Uzupełnienie - ${
                  facilities[
                    facilities.findIndex(
                      (wAF) => wAF.id === parseInt(editingProperty.split('warehouseFacility-').at(-1))
                    )
                  ]?.name
                }`"
                :rules="[rules.isQuantityNonPositive()]"
                :value="getQuantity()"
                prefix="+"
                type="number"
                @input="setQuantity">
                <template #append-outer>
                  <v-btn
                    :loading="articleStockSubmitting"
                    :disabled="
                      !!rules.isQuantityNonPositive()(getQuantity()).length ||
                      !!rules.isPriceNegative()(editedArticle.price).length
                    "
                    icon
                    @click="submitArticleStockUpdate()">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <custom-combobox-single
                v-if="stockOperation === WAREHOUSE_STOCK_OPERATION.REPLENISHMENT"
                v-model="supplierHelper"
                :disabled="articleSuppliersLoading"
                :item-text="'name'"
                :items="articleSuppliers"
                :loading="articleSuppliersLoading"
                dense
                label="Dostawca"
                @trueValue="(n) => setSupplierObjOrName(n)" />
              <v-text-field
                v-if="stockOperation === WAREHOUSE_STOCK_OPERATION.REPLENISHMENT"
                v-model="editedArticle.price"
                :rules="[rules.isPriceNegative()]"
                label="Cena zakupu"
                suffix="PLN"
                type="number">
              </v-text-field>
            </template>
          </template>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import warehouseTypes from "../../warehouseTypes.json";
import WarehouseArticleGroupFilter from "../../components/warehouse/group/WarehouseArticleGroupFilter.vue";
import WarehouseCreateArticleDialog from "../../components/warehouse/article/WarehouseCreateArticleDialog.vue";
import CustomComboboxSingle from "../../components/layout/CustomComboboxSingle.vue";
import warehouse from "../../store/modules/warehouse";
import { UPDATE_TYPE, WAREHOUSE_IMAGE_PATH, WAREHOUSE_STOCK_OPERATION } from "../../constants";
import WarehouseStockOperationChip from "../../components/warehouse/WarehouseStockOperationChip.vue";
import WarehouseArticleFacilityFilter from "../../components/warehouse/group/WarehouseArticleFacilityFilter.vue";
import DoubleClick from "../../store/class/DoubleClick";
import WarehouseArticleEanPrintButton from "../../components/warehouse/articleEAN/WarehouseArticleEanPrintButton.vue";

export default {
  name: "WarehouseArticleListView",
  components: {
    WarehouseArticleEanPrintButton,
    WarehouseArticleFacilityFilter,
    WarehouseStockOperationChip,
    CustomComboboxSingle,
    WarehouseCreateArticleDialog,
    WarehouseArticleGroupFilter,
  },
  beforeRouteLeave(to, from, next) {
    this.removeListeners();
    next();
  },
  data() {
    return {
      WAREHOUSE_IMAGE_PATH,
      warehouseTypes,
      selectedHeaders: [],
      facilityHeaders: [],
      editedArticle: { name: null },
      supplierHelper: null,
      options: {
        itemsPerPage: 50,
        page: 1,
        sortBy: ["name"],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      rules: {
        isPriceNegative() {
          return (v) => !v || (v >= 0 && v && v.toString().match(/^\d+(\.\d{1,2})?$/) !== null) || "Niepoprana cena";
        },
        isQuantityNonPositive() {
          return (v) => v > 0 || "Niepoprana wartość";
        },
        isQuantityNegative() {
          return (v) => v >= 0 || "Niepoprana wartość";
        },
      },
      headers: [
        {
          text: "",
          align: "start",
          value: "selected",
          sortable: false,
        },
        {
          text: "",
          align: "start",
          value: "warehouseArticleImages",
          sortable: false,
        },
        {
          text: "Nazwa",
          align: "start",
          value: "name",
        },
        {
          text: "Kod systemowy",
          align: "start",
          value: "id",
        },
        // {
        //   text: "Kod producenta",
        //   align: "start",
        //   value: "originalProductNumber",
        // },
        // {
        //   text: "Grupa",
        //   value: "group",
        //   sortable: false,
        // },
        // {
        //   text: "Dostawca",
        //   align: "start",
        //   value: "supplier",
        // },
        // {
        //   text: "Cena zakupu",
        //   value: "price",
        // },
        {
          text: "Ilość całkowita",
          value: "totalQuantity",
        },
      ],
      showEditingMenu: false,
      x: 0,
      y: 0,
      menuWidth: null,
      editingProperty: null,
      formErrors: {
        name: [],
      },

      isDefaultStockSupplier: true,
      stockOperation: WAREHOUSE_STOCK_OPERATION.REPLENISHMENT,
      draggedData: {
        from: {
          article: null,
          facilityId: null,
        },
        to: {
          article: null,
          facilityId: null,
        },
        quantity: 0,
      },
      doubleClick: DoubleClick.createDefault(),
      articlesToPrint: [],
      updateWarehouseArticleCallback: null,
      updateWarehouseArticleStockCallback: null,
      createWarehouseArticleCallback: null,
      addWarehouseArticleSupplierCallback: null,
      addWarehouseArticleGroupCallback: null,
    };
  },
  computed: {
    WAREHOUSE_STOCK_OPERATION() {
      return WAREHOUSE_STOCK_OPERATION;
    },
    warehouse() {
      return warehouse;
    },
    ...mapGetters("warehouse/facilities", ["facilities", "facilitiesLoading", "articleFacilitiesFilter"]),
    ...mapGetters("warehouse/articles", ["articles", "articlesLoading", "totalArticles"]),
    ...mapGetters("warehouse/groups", ["articleGroups", "articleGroupsLoading", "articleGroupFilter"]),
    ...mapGetters("warehouse/stocks", ["articleStocksLoading", "getArticleStock", "articleStockSubmitting"]),
    ...mapGetters("warehouse/suppliers", ["articleSuppliers", "articleSuppliersLoading"]),
    ...mapGetters("warehouse/articleDetails", ["articleNameValidationSubmitting"]),
    ...mapGetters({ getWindowWidth: "layoutHelpers/getWindowWidth" }),
    ...mapGetters("stream", ["getStream"]),
    tableOptions: {
      get() {
        return this.$store.getters["warehouse/articles/tableOptions"];
      },
      set(v) {
        this.$store.dispatch("warehouse/articles/setTableOptions", { options: v });
      },
    },
    searchTerm: {
      get() {
        return this.$store.getters["warehouse/articles/searchTerm"];
      },
      set(v) {
        this.$store.dispatch("warehouse/articles/setSearchTerm", v);
      },
    },
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.tableOptions = {
          sortBy: sortBy[0],
          sortDesc: sortDesc[0],
          page: page,
          itemsPerPage: itemsPerPage,
        };
        this.fetchArticles();
      },
      deep: true,
    },
    "editedArticle.name"(val) {
      if (val && this.editingProperty === "name")
        this.postArticleNameValidation({ name: val }).then((valid) => {
          this.$set(this.formErrors, "name", valid ? [] : ["Nazwa artykułu jest już zajęta"]);
        });
      this.$set(this.formErrors, "name", []);
    },
    searchTerm() {
      this.options.page = 1;
      this.fetchArticles();
    },
    articleGroupFilter() {
      this.options.page = 1;
      this.fetchArticles();
    },
    articleFacilitiesFilter() {
      this.options.page = 1;
      this.fetchArticles();
    },
  },
  mounted() {
    console.log("MOUNTED");

    this.setListeners();
  },
  async created() {
    await Promise.all([this.fetchFacilities(),
      this.fetchArticleGroups(),
      this.fetchArticleSuppliers()]);
    console.debug("facility", this.facilities);
    this.facilityHeaders = this.facilities.map((fac) => {
      return {
        text: fac.name,
        value: fac.id,
        sortable: false,
      };
    });
    this.headers = [...this.headers, ...this.facilityHeaders];
    this.selectedHeaders = [...this.headers];
  },
  methods: {
    ...mapActions("warehouse/facilities", ["fetchFacilities", "setArticleFacilitiesFilter"]),
    ...mapActions("warehouse/groups", ["fetchArticleGroups", "addArticleGroup"]),
    ...mapActions("warehouse/stocks", [
      "fetchArticleStock",
      "updateArticleStock",
      "postArticleStockCorrection",
      "postArticleStockReplenish",
      "postArticleStockConsumption",
      "postArticleStockTransfer",
    ]),
    ...mapActions("warehouse/suppliers", ["fetchArticleSuppliers", "addNewSupplier"]),
    ...mapActions("warehouse/articleDetails", [
      "postArticleNameValidation",
      "updateArticle",
    ]),
    ...mapActions("warehouse/articles", ["fetchArticles", "addArticle"]),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    getQuantity() {
      return this.editedArticle.warehouseArticleFacilities[
        this.editedArticle.warehouseArticleFacilities.findIndex(
          (wAF) => wAF.warehouseFacility.id === parseInt(this.editingProperty.split("warehouseFacility-").at(-1))
        )
      ].quantity;
    },
    selectToPrint(articleId) {
      if (this.articlesToPrint.includes(articleId)) {
        this.articlesToPrint = this.articlesToPrint.filter((value) => value !== articleId);
        // this.$delete(this.articlesToPrint)
        return;
      }

      this.articlesToPrint.push(articleId);
    },
    handleDoubleClick(value) {
      this.doubleClick.doubleClick(
        value.id,
        () => this.$router.push({ name: "warehouseArticleDetails", params: { articleId: '' +value.id } }),
        () => window.open("/warehouse/article/" + value.id, "_blank")
      );
    },
    setQuantity(value) {
      console.log(value);
      const index = this.editedArticle.warehouseArticleFacilities.findIndex(
        (wAF) => wAF.warehouseFacility.id === parseInt(this.editingProperty.split("warehouseFacility-").at(-1))
      );
      if (index !== -1) {
        this.$set(this.editedArticle.warehouseArticleFacilities[index], "quantity", parseFloat(value));
      }
    },
    selectHeader(h) {
      const arr = this.selectedHeaders;
      console.log(...arr.map((x) => x.value));
      const i = arr.findIndex((v) => v.value === h.value);
      if (i !== -1) {
        arr.splice(i, 1);
      } else {
        const originalIndex = this.headers.findIndex((v) => v.value === h.value);
        let insertAt = arr.findIndex((item) => this.headers.indexOf(item) > originalIndex);
        // If not found, insert at the end
        insertAt = insertAt === -1 ? arr.length : insertAt;
        console.log(originalIndex);
        arr.splice(insertAt, 0, h);
      }
      console.log(...arr.map((x) => x.value));
      this.selectedHeaders = arr;
    },
    submitArticleStockUpdate() {
      const { id, ...facilityUpdateData } =
        this.editedArticle.warehouseArticleFacilities[
          this.editedArticle.warehouseArticleFacilities.findIndex(
            (wAF) => wAF.warehouseFacility.id === parseInt(this.editingProperty.split("warehouseFacility-").at(-1))
          )
        ];

      switch (this.stockOperation) {
        case WAREHOUSE_STOCK_OPERATION.REPLENISHMENT:
          this.postArticleStockReplenish({
            articleId: this.editedArticle.id,
            facilityId: facilityUpdateData.warehouseFacility.id,
            quantity: facilityUpdateData.quantity,
            supplier: this.editedArticle.supplier,
            price: this.editedArticle.price,
          });
          break;
        case WAREHOUSE_STOCK_OPERATION.CONSUMPTION:
          this.postArticleStockConsumption({
            articleId: this.editedArticle.id,
            facilityId: facilityUpdateData.warehouseFacility.id,
            quantity: facilityUpdateData.quantity,
          });
          break;
        case WAREHOUSE_STOCK_OPERATION.CORRECTION:
          this.postArticleStockCorrection({
            articleId: this.editedArticle.id,
            facilityId: facilityUpdateData.warehouseFacility.id,
            quantity: facilityUpdateData.quantity,
          });
          break;
        default:
          break;
      }
    },
    submitArticleTransfer() {
      this.postArticleStockTransfer({
        articleId: this.draggedData.to.article.id,
        sourceFacilityId: this.draggedData.from.facilityId,
        destinationFacilityID: this.draggedData.to.facilityId,
        quantity: this.draggedData.quantity,
      });
    },
    openEditMenu(e, i, o) {
      this.showEditingMenu = false;
      e.preventDefault();

      console.log(i);
      console.log(o);
      console.log(this.$refs[i]);
      let pos;
      if (i.includes("warehouseFacility")) {
        pos = this.$refs[i][0].getBoundingClientRect();
      } else {
        pos = this.$refs[i].getBoundingClientRect();
      }
      this.x = pos.left;
      this.y = pos.top;
      console.log(this.x);
      console.log(this.y);
      this.menuWidth = pos.right - pos.left;

      this.editedArticle = structuredClone(o);
      this.supplierHelper = this.editedArticle.supplier
        ? this.articleSuppliers.find((sup) => sup.id === this.editedArticle.supplier.id) ?? null
        : null;
      this.editingProperty = i.split("_").at(-1);
      //create warehouseArticleFacility for editing article if not exists
      if (this.editingProperty.includes("warehouseFacility")) {
        const warehouseFacilityId = parseInt(this.editingProperty.split("warehouseFacility-").at(-1));
        const warehouse =
          this.editedArticle.warehouseArticleFacilities.find(
            (wAF) => wAF.warehouseFacility.id === warehouseFacilityId
          ) ?? null;
        if (!warehouse) {
          const newWarehouseArticleFacility = {
            id: null,
            warehouseFacility: { id: warehouseFacilityId },
            quantity: 0,
          };
          this.editedArticle.warehouseArticleFacilities.push(newWarehouseArticleFacility);
        }
      }
      console.log(this.editingProperty);
      console.log(this.editedArticle.warehouseArticleFacilities);
      console.log(
        this.editedArticle.warehouseArticleFacilities.findIndex(
          (wAF) => wAF.warehouseFacility.id === parseInt(this.editingProperty.split("warehouseFacility-").at(-1))
        )
      );

      this.$nextTick(() => {
        this.showEditingMenu = true;
      });
    },
    openTransferMenu() {
      this.showEditingMenu = false;
      let article = this.draggedData.to.article;
      const refId = `article_${article.id}_warehouseFacility-${this.draggedData.to.facilityId}`;
      console.log(refId);
      console.log(article);
      console.log(this.$refs[refId]);
      let pos = this.$refs[refId][0].getBoundingClientRect();
      this.x = pos.left;
      this.y = pos.top;
      this.menuWidth = null;

      this.editingProperty = "transfer";
      //create warehouseArticleFacility for target article if not exists
      const warehouseFacilityId = this.draggedData.to.facilityId;
      if (article.warehouseArticleFacilities.length) {
        const warehouse =
          article.warehouseArticleFacilities.find((wAF) => wAF.warehouseFacility.id === warehouseFacilityId) ?? null;
        if (!warehouse) {
          const newWarehouseArticleFacility = {
            id: null,
            warehouseFacility: { id: warehouseFacilityId },
            quantity: 0,
          };
          article.warehouseArticleFacilities.push(newWarehouseArticleFacility);
        }
      }

      this.$nextTick(() => {
        this.showEditingMenu = true;
      });
    },
    async setSupplierObjOrName(v) {
      this.editedArticle.supplier = v;
    },
    startDrag(e, { article, facilityId }) {
      this.showEditingMenu = false;
      this.draggedData.from = { article: article, facilityId: facilityId };
    },
    async endDrag(e, { article, facilityId }) {
      console.log(e);
      this.draggedData.to = { article: article, facilityId: facilityId };
      console.log(this.draggedData);

      if (
        this.draggedData.from.article.id !== this.draggedData.to.article.id ||
        this.draggedData.from.facilityId === this.draggedData.to.facilityId
      ) {
        console.log("cancel");
        return;
      }
      const foundFacility = this.draggedData.from.article.warehouseArticleFacilities.find(
        (wAF) => wAF.warehouseFacility.id === this.draggedData.from.facilityId
      );
      if (!foundFacility || foundFacility.quantity === 0) {
        await this.notifyError("Transfer niemożliwy. Niewystarczająca ilość.");
        console.log("0 quantity");
        return;
      }
      this.openTransferMenu();

      // console.log(article);
      // console.log(articleGroup);
      e.preventDefault();
    },
    setListeners() {
      this.createWarehouseArticleCallback = this.createWarehouseArticleEvent();
      this.getStream.addEventListener(UPDATE_TYPE.WAREHOUSE_ARTICLE_CREATE, this.createWarehouseArticleCallback);
      this.updateWarehouseArticleCallback = this.updateWarehouseArticleEvent();
      this.getStream.addEventListener(UPDATE_TYPE.WAREHOUSE_ARTICLE_UPDATE, this.updateWarehouseArticleCallback);
      this.updateWarehouseArticleStockCallback = this.updateWarehouseArticleStockEvent();
      this.getStream.addEventListener(
        UPDATE_TYPE.WAREHOUSE_ARTICLE_STOCK_UPDATE,
        this.updateWarehouseArticleStockCallback
      );
      this.addWarehouseArticleSupplierCallback = this.addWarehouseArticleSupplierEvent();
      this.getStream.addEventListener(UPDATE_TYPE.WAREHOUSE_SUPPLIER_CREATE, this.addWarehouseArticleSupplierCallback);
      this.addWarehouseArticleGroupCallback = this.addWarehouseArticleGroupEvent();
      this.getStream.addEventListener(UPDATE_TYPE.WAREHOUSE_ARTICLE_GROUP_CREATE, this.addWarehouseArticleGroupCallback);
    },
    removeListeners() {
      console.log("remove listeners");
      this.getStream.removeEventListener(UPDATE_TYPE.WAREHOUSE_ARTICLE_CREATE, this.createWarehouseArticleCallback);
      this.getStream.removeEventListener(UPDATE_TYPE.WAREHOUSE_ARTICLE_UPDATE, this.updateWarehouseArticleCallback);
      this.getStream.removeEventListener(
        UPDATE_TYPE.WAREHOUSE_ARTICLE_STOCK_UPDATE,
        this.updateWarehouseArticleStockCallback
      );
      this.getStream.removeEventListener(
        UPDATE_TYPE.WAREHOUSE_SUPPLIER_CREATE,
        this.addWarehouseArticleSupplierCallback
      );
      this.getStream.removeEventListener(
        UPDATE_TYPE.WAREHOUSE_ARTICLE_GROUP_CREATE,
        this.addWarehouseArticleGroupCallback
      );
    },
    addWarehouseArticleSupplierEvent() {
      return (event) => {
        console.debug(JSON.parse(event.data), "NEW -SUPPIER-  ADD");
        this.addNewSupplier({ supplierData: JSON.parse(event.data) });
      };
    },
    addWarehouseArticleGroupEvent() {
      return (event) => {
        console.debug(JSON.parse(event.data), "NEW -Group-  ADD");
        this.addArticleGroup({ newGroup: JSON.parse(event.data) });
      };
    },
    createWarehouseArticleEvent() {
      return async (event) => {
        console.debug(JSON.parse(event.data), "NEW -ARTICLE-  CREATE");
        await this.addArticle({ article: JSON.parse(event.data) });
      };
    },
    updateWarehouseArticleEvent() {
      return async (event) => {
        console.debug(JSON.parse(event.data), "NEW -ARTICLE-  UPDATE");
        await this.updateArticle({ article: JSON.parse(event.data) });
      };
    },
    updateWarehouseArticleStockEvent() {
      return async (event) => {
        console.debug(JSON.parse(event.data), "NEW -ARTICLE STOCK-  UPDATE");
        this.showEditingMenu = false;
        await this.updateArticleStock({ article: JSON.parse(event.data) });
      };
    },
  },
};
</script>
