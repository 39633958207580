import axios from 'axios';
import { compareDesc } from 'date-fns';

export default {
  async fetchTasks({ commit, rootGetters, dispatch }) {
    commit('setTasksAreLoading', true);
    const response = await axios.get(`task/fetchTasks`, {
      params: {
        jobId: rootGetters['jobs/getJob'].id,
      },
    });
    commit('setTasks', response.data.tasks);
    dispatch('sortTasksByDate');
    commit('setTasksAreLoading', false);

    // console.log(response.data)
    // console.log("response.data")
  },
  async addTask(context, payload) {
    const response = await axios.post(`job/${payload.jobId}/addTask`, {
      ...payload.taskData,
      // title: payload.taskData.title,
      // type: payload.taskData.type,
      // dateStart: payload.taskData.dateStart
    });

    // context.commit('addTask', response.data.task)
  },

  sortTasksByDate({ commit, getters }) {
    const sortedTasks = getters.getTasks.sort((a, b) => {
      if (!a.startAt && !b.startAt) {
        return 0;
      }
      if (!a.startAt) {
        return 1;
      }
      if (!b.startAt) {
        return -1;
      }
      return compareDesc(new Date(a.startAt), new Date(b.startAt));
    });
    commit('setTasks', sortedTasks);
  },

  async editTask({ commit, getters, dispatch }, payload) {
    const response = await axios.post(`task/${payload.taskId}/edit`, {
      ...payload.taskData,
    });
    dispatch('fetchTasks');
  },

  async changeTaskStatus(context, { task, status }) {
    const response = await axios.post(`task/${task.id}/changeStatus`, {
      status,
    });
  },

  async updateCalendarPost(context, payload) {
    const response = await axios.post(`calendar/userTask/${payload.id}/schedule`, {
      taskNewDate: payload.taskNewDate,
      taskNewPriority: payload.taskNewPriority,
      taskNewUser: payload.taskNewUser,
    });
  },

  async scheduleTask(context, payload) {
    const response = await axios.post(`calendar/task/${payload.taskId}/schedule`, {
      id: payload.id,
      taskNewDate: payload.taskNewDate,
      taskNewPriority: payload.taskNewPriority,
      taskNewUser: payload.taskNewUser,
    });
  },

  async resolveTaskAssignment(context, payload) {
    const response = await axios.post(`task/resolveTaskAssignment`, {
      id: payload.id,
    });
  },
};
