<template>
  <v-dialog v-model="dialog" max-width="50vw" persistent>
    <template #activator="{ on, attrs }">
      <v-btn icon text v-bind="attrs" v-on="on">
        <v-icon> mdi-image-edit</v-icon>
      </v-btn>
    </template>
    <v-card
      :class="{ 'blue lighten-4': dragover }"
      @drop.prevent="onDrop"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false">
      <v-card-title>
        <span class="text-h5">Edytuj</span>
        <v-spacer />
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="d-flex flex-column align-center">
        <v-row align="center" class="d-flex flex-column" dense justify="center">
          <v-btn :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" icon x-large @click="chooseFiles">
            <v-icon color="primary"> mdi-cloud-upload</v-icon>
          </v-btn>
          <p>Wybierz pliki lub upuść je tutaj.</p>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col>
            <v-file-input
              v-show="false"
              id="fileUpload"
              v-model="fileInputFilterComputed"
              :disabled="fileConversion"
              :rules="[fileInputRules.checkTotalFileSize(filesSize), fileInputRules.checkTotalFileNumber(filesNumber)]"
              accept="image/png, image/jpeg"
              chips
              clearable
              counter
              label="Wybierz zdjęcia do przesłania"
              multiple
              show-size
              small-chips
              truncate-length="33"></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="file.length">Do przesłania</v-card-text>
      <v-card-text>
        <v-row>
          <v-col v-for="(i, idx) in file" :key="idx" class="d-flex child-flex" cols="4">
            <v-container style="position: relative">
              <v-img :src="imagesData[i]" aspect-ratio="1" class="grey lighten-2">
                <v-row
                  class="d-flex flex-row fill-height align-content-space-between"
                  no-gutters
                  style="position: absolute; z-index: 10; width: 100%">
                  <v-col class="d-flex flex-column justify-space-between flex-nowrap">
                    <v-row class="d-flex flex-row flex-grow-0 flex-shrink-1" no-gutters>
                      <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" @click="removeFile(file[idx].name)" v-on="on">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </template>
                          <span>Usuń</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row v-if="uploadingFilesProgress[idx]" align="center" class="fill-height ma-0" justify="center">
                      <v-progress-circular :value="uploadingFilesProgress[idx]" color="grey lighten-5">
                        {{ uploadingFilesProgress[idx] }}%
                      </v-progress-circular>
                    </v-row>
                    <v-row v-if="file[idx]" class="d-flex flex-row flex-grow-0 flex-shrink-1 flex-nowrap" no-gutters>
                      <v-col class="d-flex flex-column flex-grow-0 flex-shrink-1 pl-1 text-truncate" cols="auto">
                        <span class="text-truncate" style="background-color: rgba(255, 255, 255, 0.6)">{{
                          file[idx].name.substring(file[idx].name.indexOf("-") + 1, file[idx].name.lastIndexOf("."))
                        }}</span>
                      </v-col>
                      <v-col class="d-flex flex-column flex-grow-0 flex-shrink-1 pr-1" cols="auto">
                        <span style="background-color: rgba(255, 255, 255, 0.6)"
                          >.{{ file[idx].name.split(".").at(-1) }}</span
                        >
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col class="d-flex flex-column align-end pa-1" cols="auto">
                        <span style="background-color: rgba(255, 255, 255, 0.6)">{{
                          humanFileSize(file[idx].size)
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-img>
            </v-container>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="article.warehouseArticleImages.length">Przesłane</v-card-text>
      <v-card-text>
        <v-row>
          <v-col v-for="ai in article.warehouseArticleImages" :key="ai.id" class="d-flex child-flex" cols="4">
            <v-img :src="WAREHOUSE_IMAGE_PATH + ai.filename" aspect-ratio="1" class="grey lighten-2">
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                </v-row>
              </template>
              <v-row
                class="d-flex flex-row fill-height align-content-space-between"
                no-gutters
                style="position: absolute; z-index: 10; width: 100%">
                <v-col class="d-flex flex-column justify-space-between flex-nowrap">
                  <v-row class="d-flex flex-row flex-grow-0 flex-shrink-1" no-gutters>
                    <v-col cols="auto">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :color="ai.isPrimary ? 'yellow' : 'primary'"
                            :disabled="!!settingAsPrimaryImage"
                            :loading="settingAsPrimaryImage === ai.id"
                            elevation="24"
                            icon
                            v-bind="attrs"
                            @click="ai.isPrimary ? null : setPrimaryImage({ fileId: ai.id })"
                            v-on="on">
                            <v-icon>{{ ai.isPrimary ? "mdi-star-circle" : "mdi-star-circle-outline" }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ ai.isPrimary ? "Zdjęcie główne" : "Ustaw jako zdjęcie główne" }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :disabled="!!deletingFile"
                            :loading="deletingFile === ai.id"
                            color="error"
                            elevation="24"
                            icon
                            v-bind="attrs"
                            @click="deleteFile({ fileId: ai.id })"
                            v-on="on">
                            <v-icon> mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Usuń z serwera</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex flex-row flex-grow-0 flex-shrink-1 flex-nowrap" no-gutters>
                    <v-col class="d-flex flex-column flex-grow-0 flex-shrink-1 pl-1 text-truncate" cols="auto">
                      <span class="text-truncate" style="background-color: rgba(255, 255, 255, 0.6)">{{
                        ai.filename.substring(ai.filename.indexOf("-") + 1, ai.filename.lastIndexOf("."))
                      }}</span>
                    </v-col>
                    <v-col class="d-flex flex-column flex-grow-0 flex-shrink-1 pr-1" cols="auto">
                      <span style="background-color: rgba(255, 255, 255, 0.6)"
                        >.{{ ai.filename.split(".").at(-1) }}</span
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex flex-column align-end pa-1" cols="auto">
                      <v-icon color="primary">mdi-cloud</v-icon>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { WAREHOUSE_IMAGE_PATH } from "../../../constants";
import { humanFileSize } from "../../../helper";

export default {
  data: () => ({
    WAREHOUSE_IMAGE_PATH,
    fileConversion: false,
    imagesData: [],
    dialog: false,
    validFileTypes: ["image/jpeg", "image/png", "image/webp"],
    textAreaMaxLength: 10000,
    filesNumber: 25,
    filesSize: 100000000,
    loadingUpload: false,
    fileInputRules: {
      checkTotalFileSize(maxSize) {
        return (v) => !v || v.reduce((partialSum, a) => partialSum + a.size, 0) < maxSize || "JPEG, PNG poniżej 100MB";
      },
      checkTotalFileNumber(maxLength) {
        return (v) => !v || v.length <= maxLength || "Limit 5 plików";
      },
    },
    file: [],
    dragover: false,
  }),
  computed: {
    ...mapGetters({
      userRole: "user/getUserRole",
    }),
    ...mapGetters("warehouse/articleDetails", ["article", "articleDetailsLoading"]),
    ...mapGetters("warehouse/articleImages", [
      "uploadingFilesProgress",
      "uploadingFiles",
      "uploadingFilesControllers",
      "deletingFile",
      "settingAsPrimaryImage",
    ]),
    fileInputFilterComputed: {
      get: function () {
        return this.file;
      },
      set: function (newValue) {
        console.log(newValue);
        const invalidTypes = [];
        for (const file of newValue) {
          if (!this.validFileType(file)) {
            invalidTypes.push(file);
          } else {
            this.file.push(file);
          }
        }
        console.log(invalidTypes);
        if (invalidTypes.length) {
          this.notifyError(
            "Niepoprawny rodzaj pliku: \n" +
              invalidTypes.reduce((previousValue, currentValue) => previousValue + (currentValue.name + ",\n "), "")
          );
        }
        this.uploadImages();
      },
    },
  },
  watch: {
    file(newFiles, oldFiles) {
      console.log(oldFiles);
      console.log(newFiles);
      this.imagesData = [];
      newFiles.forEach((f) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imagesData.push(e.target.result);
        };
        reader.readAsDataURL(f);
      });
    },
  },
  methods: {
    humanFileSize,
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    ...mapActions("warehouse/articleImages", ["uploadFile", "deleteFile", "setPrimaryImage"]),
    validFileType(file) {
      return this.validFileTypes.includes(file.type);
    },
    async uploadImages() {
      this.loadingUpload = true;
      try {
        this.file
          .filter((value) => !this.uploadingFiles.includes(value))
          .forEach((value) =>
            this.$store
              .dispatch("warehouse/articleImages/uploadFile", {
                articleId: this.article.id,
                articleImage: value,
              })
              .then((value1) => {
                const index = this.file.indexOf(value);
                console.log(this.file, index);
                if (index > -1) this.file.splice(index, 1);
              })
          );
        this.fileConversion = false;
        this.loadingUpload = false;
      } catch (error) {
        if (!window.navigator.onLine) {
          this.notifyError("Połączenie przerwane.");
        }
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    onDrop(e) {
      console.log(e);
      console.log(e.dataTransfer.files);
      this.dragover = false;
      const invalidTypes = [];
      for (const file of e.dataTransfer.files) {
        if (!this.validFileType(file)) {
          invalidTypes.push(file);
        } else {
          this.file.push(file);
        }
      }
      console.log(invalidTypes);
      if (invalidTypes.length) {
        this.notifyError(
          "Niepoprawny rodzaj pliku: \n" +
            invalidTypes.reduce((previousValue, currentValue) => previousValue + (currentValue.name + ",\n "), "")
        );
      }
      this.uploadImages();
    },
    removeFile(fileName) {
      const index = this.file.findIndex((file) => file.name === fileName);
      this.abortFileUpload(this.file[index]);

      if (index > -1) this.file.splice(index, 1);
    },
    abortFileUpload(file) {
      const idx = this.uploadingFiles.indexOf(file);
      this.uploadingFilesControllers[idx].abort();
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
  },
};
</script>
