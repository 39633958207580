import { formatDateTime } from '../../../helper';

export default {
  setVacationsForUser(state, payload) {
    state.vacations[payload.userId] = payload.data;
  },

  setVacationsForCalendar(state, payload) {
    state.vacationsForCalendar = payload.data;
  },

  addVacationForUser(state, payload) {
    if (payload.data.dates[1] === undefined) {
      payload.data.dates[1] = payload.data.dates[0];
    }
    const requestDate = formatDateTime(new Date());
    let data = {
      id: payload.newVacationId,
      dateStart: payload.data.dates[0],
      dateFinish: payload.data.dates[1],
      requestDate,
      type: payload.data.type,
    };

    var index = state.vacations[payload.userId].map((x, index) => {
      const secondDate = new Date(x['dateStart']);
      if (secondDate > new Date(payload.data.dates[0])) {
        return index;
      }
    });

    const firstIndex = index.find((element) => element !== undefined);

    if (firstIndex === undefined) {
      state.vacations[payload.userId].push(data);
    } else {
      state.vacations[payload.userId].splice(firstIndex, 0, data);
    }
  },

  deleteVacationForUser(state, payload) {
    var index = state.vacations[payload.userId]
      .map((x) => {
        return x.id;
      })
      .indexOf(payload.vacationId);
    state.vacations[payload.userId].splice(index, 1);
  },

  addVacationToCalendar(state, payload) {
    if (payload.data.dates[1] === undefined) {
      payload.data.dates[1] = payload.data.dates[0];
    }
    let data = {
      id: payload.newVacationId,
      dateStart: payload.data.dates[0],
      dateFinish: payload.data.dates[1],
      type: payload.data.type,
      user: payload.user,
    };
    // console.log(data)
    state.vacationsForCalendar.push(data);

    console.log(state.vacations[payload.userId]);
    // console.log(payload.userId)
  },

  deleteVacationInCalendar(state, payload) {
    var index = state.vacationsForCalendar
      .map((x) => {
        return x.id;
      })
      .indexOf(payload.vacationId);
    state.vacationsForCalendar.splice(index, 1);
  },
};
