export default {
  getUserTasks(state) {
    return state.userTasks;
  },
  isUsersSet(state) {
    console.log('Object.keys(state.users)');
    console.log(Object.keys(state.users));
    return Object.keys(state.users).length === 0;
  },
  getServiceTechnician: (state) => (id) => {
    // if (this.isUsersSet(state) === false) return null;
    // console.log('keys')
    // console.log(Object.keys(state.users))
    return state.users[id];
  },
  getUsers(state) {
    // if (this.isUsersSet(state) === false) return [];
    console.log('values');
    console.log(Object.values(state.users));
    return Object.values(state.users);
  },
  doesUserHasVacations: (state) => (payload) => {
    console.log(state.userVacations);
    return state.userVacations[payload.date] === undefined
      ? false
      : state.userVacations[payload.date].includes(payload.id);
  },
  getUserVacations(state) {
    return state.userVacations;
  },
};
