export default {
  mobileReleases: (state) => {
    return state.mobileReleases;
  },
  loadingMobileReleases: (state) => {
    return state.loadingMobileReleases;
  },
  submittingMobileReleaseReleaseId: (state) => {
    return state.submittingMobileReleaseReleaseId;
  },
  submittingMobileReleaseRollbackId: (state) => {
    return state.submittingMobileReleaseRollbackId;
  },
  submittingMobileReleaseDeleteId: (state) => {
    return state.submittingMobileReleaseDeleteId;
  },
  uploadingApk: (state) => {
    return state.uploadingApk;
  },
  uploadProgress: (state) => {
    return state.uploadProgress;
  },
};
