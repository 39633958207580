export default {
  setMobileReleases(state, mobileReleases) {
    state.mobileReleases = mobileReleases;
  },
  setLoadingMobileReleases(state, isloading) {
    state.loadingMobileReleases = isloading;
  },
  submittingMobileReleaseReleaseId(state, apkId) {
    state.submittingMobileReleaseReleaseId = apkId;
  },
  submittingMobileReleaseRollbackId(state, apkId) {
    state.submittingMobileReleaseRollbackId = apkId;
  },
  submittingMobileReleaseDeleteId(state, apkId) {
    state.submittingMobileReleaseDeleteId = apkId;
  },
  setUploadingApk(state, data) {
    state.uploadingApk = data;
  },
  setUploadProgress(state, progress) {
    state.uploadProgress = progress
  }
};
