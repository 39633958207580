<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template #activator="{ on, attrs }">
      <v-btn color="primary" small dark v-bind="attrs" v-on="on">
        <v-icon left> mdi-plus </v-icon>
        Dodaj notatkę
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Dodaj notatkę</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <p
                v-if="fileConversion"
                class="mx-4"
                style="color: black"
                v-html="note.content"></p>
              <text-editor
                v-else
                v-model="note.content"
                :text-limit="textAreaMaxLength"
                :placeholder="'Opis notatki'">
              </text-editor>
            </v-col>
            <v-col v-if="userRole !== 1" cols="12">
              <v-checkbox v-model="note.isPrivate" :disabled="fileConversion" label="Prywatne"></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                v-model="file"
                :disabled="fileConversion"
                :rules="[
                  fileInputRules.checkTotalFileSize(filesSize),
                  fileInputRules.checkTotalFileNumber(filesNumber),
                ]"
                accept="image/png, image/jpeg, application/pdf, video/mp4, video/, video/quicktime, video/x-msvideo, .xlsx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                chips
                clearable
                counter
                label="Wybierz plik"
                multiple
                show-size
                small-chips
                truncate-length="33"></v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-progress-linear v-if="uploadProgress == -1" color="red" height="25">
        <strong>ZERWANO POŁĄCZENIE</strong>
      </v-progress-linear>
      <v-progress-linear v-else-if="uploadProgress !== null" v-model="uploadProgress" height="25">
        <strong>{{ uploadProgress + "%" }}</strong>
      </v-progress-linear>

      <v-card-actions v-if="uploadProgress == -1">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="refreshAll"> Odśwież </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelAddingNote"> Anuluj </v-btn>
        <v-btn :disabled="validateNote() || loadingUpload" color="blue darken-1" text @click="addNote()">
          Dodaj notatkę
        </v-btn>
      </v-card-actions>
      <v-card style="display: flex; flex-direction: row">
        <v-progress-circular
          v-if="uploadProgress === 100 && fileConversion === true"
          style="margin: 10px"
          indeterminate></v-progress-circular>
        <v-card-subtitle v-if="uploadProgress === 100 && fileConversion === true"
          >Pliki przesłane jesteśmy w trakcie ich konwertowania</v-card-subtitle
        >
      </v-card>
    </v-card>
  </v-dialog>
  <!--  </v-row>-->
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import TextAreaLimitedLength from "./TextAreaLimitedLength.vue";
import Integer from "vuelidate/lib/validators/integer";
import TextEditor from "../textEditor/TextEditor.vue";
import Vue from "vue";

export default {
  components: { TextEditor, TextAreaLimitedLength },
  props: {
    jobId: { type: Integer, default: "" },
  },
  data: () => ({
    fileConversion: false,
    dialog: false,
    textAreaMaxLength: 10000,
    filesNumber: 25,
    filesSize: 100000000,
    loadingUpload: false,
    note: {
      content: null,
      isPrivate: false,
    },
    fileInputRules: {
      checkTotalFileSize(maxSize) {
        return (v) =>
          !v ||
          v.reduce((partialSum, a) => partialSum + a.size, 0) < maxSize ||
          "PDF, JPEG, PNG, MP4, word, excel poniżej 100MB";
      },
      checkTotalFileNumber(maxLength) {
        return (v) => !v || v.length <= maxLength || "Limit 5 plików";
      },
    },
    file: [],
  }),
  computed: {
    ...mapGetters({
      uploadProgress: "jobs/getUploadProgress",
      userRole: "user/getUserRole",
    }),
  },
  methods: {
    ...mapMutations({ resetProgress: "jobs/setUploadProgress" }),
    refreshAll() {
      location.reload();
    },
    validateNote() {
      console.log(this.note.content);
      if (this.file.reduce((partialSum, a) => partialSum + a.size, 0) > this.filesSize) {
        return true;
      }
      if (this.file.length > this.filesNumber) {
        return true;
      }
      if (this.note.content && (this.note.content.length > this.textAreaMaxLength)) {
        return true;
      }
      return this.note.content === null && this.file.length === 0;
    },
    async addNote() {
      this.loadingUpload = true;
      const newNote = await this.$store.dispatch("jobs/addNote", {
        jobId: this.jobId,
        note: this.note,
      });
      console.log(newNote);
      try {
        if (this.file.length) {
          this.fileConversion = true;
          await this.$store.dispatch("jobs/addFile", {
            noteId: newNote,
            converted: false,
            file: this.file,
          });
          this.file = [];
        }
        this.fileConversion = false;
        this.$emit("refresh");
        this.resetProgress(null);
        this.dialog = false;
        this.loadingUpload = false;
      } catch (error) {
        if (!window.navigator.onLine) {
          this.resetProgress(-1);
        }
      }
      this.resetValues();
    },
    cancelAddingNote() {
      this.resetValues();
      this.dialog = false;
    },
    resetValues(title = true, content = true, isPrivate = true) {
      if (content) {
        Vue.set(this.note, "content", null);
      }
      if (isPrivate) {
        this.note.isPrivate = false;
      }
    },
  },
};
</script>
