<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <template #activator="{ on, attrs }">
      <v-btn :disabled="articleGroup.articlesInGroupStats > 0" v-bind="attrs" icon v-on="on">
        <v-icon small> mdi-delete</v-icon>
      </v-btn>
    </template>
    <v-card class="d-flex flex-column justify-space-between">
      <v-card-title>
        <span class="text-h5">Usuwanie magazynu</span>
      </v-card-title>
      <v-card-text>
        <span>Historia artykułów związana z grupą będzie niekompletna.</span>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-btn text @click="dialog = false">
          <v-icon left> mdi-cancel</v-icon>
          Anuluj
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="groupDetailsSubmitting" color="error" @click="deleteArticleGroup">
          <v-icon left> mdi-home-minus-outline</v-icon>
          Usuń groupę
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import warehouseTypes from "../../../warehouseTypes.json";
import { WAREHOUSE_FACILITY_TYPE } from "../../../constants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "WarehouseDeleteArticleGroupDialog",
  props: {
    articleGroup: {
      type: Object
    }
  },
  data: () => ({
    dialog: false,
    warehouseTypes,
  }),
  computed: {
    ...mapGetters({
      articleGroupsDetails: "warehouse/groupsSettings/articleGroupsDetails",
      groupDetailsSubmitting: "warehouse/groupsSettings/groupDetailsSubmitting",
    }),
  },
  methods: {
    ...mapActions("warehouse/groupsSettings", ["postDeleteArticleGroup"]),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    async deleteArticleGroup() {
      try {
        await this.postDeleteArticleGroup({ groupData: this.articleGroup });
        await this.notifyInfo("Usunięto grupę.");
      } catch (e) {
        await this.notifyError(e);
        console.log(e);
      }
      this.dialog = false;
    },
  },
};
</script>
