<template>
  <v-card-text>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="auto" md="5">
            <form-row-with-icon icon="mdi-account-box">
              <create-new-user-field-name ref="nameRef" v-model="userData.name" label="Nazwa" type="name">
              </create-new-user-field-name>
            </form-row-with-icon>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" md="auto">
            <form-row-with-icon :color="userData.color" icon="mdi-circle" text="Kolor">
              <v-color-picker v-model="userData.color" canvas-height="50" hide-inputs></v-color-picker>
            </form-row-with-icon>
          </v-col>
          <v-spacer></v-spacer>
          <v-col align-self="center" cols="auto" md="2">
            <v-btn block :disabled="buttonDisabled()" color="primary" @click="handleSubmit"> Zmień </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card-text>
</template>

<script>
import { mapActions } from "vuex";
import CreateUserClass from "../../store/class/CreateUserClass";
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import CreateNewUserFieldName from "./ValidatedTextField.vue";

export default {
  components: { CreateNewUserFieldName, FormRowWithIcon },
  props: ["user"],
  data: () => ({
    userData: null,
  }),
  methods: {
    ...mapActions({ editUser: "users/editUser" }),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    async handleSubmit() {
      await this.editUser({ userId: this.user.id, userData: this.userData });
      await this.notifyInfo("Zaktualizowano użytkownika");
      this.$emit("refresh");
    },
    buttonDisabled() {
      let nErrors = [];
      if (this.$refs.nameRef) {
        nErrors = [...this.$refs.nameRef.getNameError()];
      }
      return nErrors.length > 0;
    },
  },
  created() {
    this.userData = CreateUserClass.create({
      name: this.user.name,
      color: this.user.color,
    });
  },
};
</script>
