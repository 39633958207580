export default {
  facilitiesDetails: (state) => {
    return state.facilitiesDetails;
  },
  getFacilityDetailsById: (state) => (facilityId) => {
    const facilityIdx = state.facilitiesDetails.findIndex((value) => value.id === facilityId);
    if (facilityIdx === -1) {
      return null;
    }
    return state.facilitiesDetails[facilityIdx];
  },
  facilitiesDetailsLoading: (state) => {
    return state.loadingFacilitiesDetails;
  },
  facilityDetailsSubmitting: (state) => {
    return state.submittingFacilityDetails;
  },
};
