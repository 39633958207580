<template>
  <v-row class="d-flex justify-start" no-gutters>
    <v-col cols="auto">
      <v-menu v-model="menu" min-width="0" nudge-left="300">
        <template #activator="{ on, attrs }">
          <form-row-with-icon :icon="icon" style="margin-top: 10px" :text="labelText">
            <v-row class="d-flex flex-row align-end" no-gutters>
              <v-col class="pr-3" cols="auto" v-bind="attrs" v-on="on">
                <v-chip :text-color="'black'" class="pl-2 pr-4" color="primary" label outlined>
                  {{ computedDate }}
                </v-chip>
              </v-col>
              <v-spacer></v-spacer>
              <v-divider v-show="date" vertical></v-divider>
              <v-spacer></v-spacer>
              <v-col v-show="date" class="pl-3" cols="auto">
                <v-btn color="grey" small text @click="setNoDate">{{ emptyText }}</v-btn>
              </v-col>
            </v-row>
          </form-row-with-icon>
        </template>
        <v-date-picker v-model="pickerData" :first-day-of-week="1" show-adjacent-months></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import { formatDate } from "../../helper";

export default {
  name: "TextFieldExtendedDatePicker",
  components: { FormRowWithIcon },
  model: {
    prop: "date",
    event: "updateDate",
  },
  props: {
    date: {},
    labelText: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    emptyText: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    computedDate: {
      get() {
        return !this.date ? this.emptyText : this.date;
      },
      set(v) {
        this.$emit("updateDate", v);
      },
    },
    pickerData: {
      get() {
        return !this.date ? formatDate(new Date()) : this.date;
      },
      set(v) {
        this.$emit("updateDate", v);
      },
    },
  },
  methods: {
    setNoDate(v) {
      this.$emit("updateDate", null);
    },
  },
};
</script>
<style scoped>
@keyframes selectFiltersAnimation {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}

/*.theme--light.v-calendar-daily .v-calendar-daily__intervals-body .v-calendar-daily__interval-text {*/
/*  color: transparent;*/
/*}*/
</style>