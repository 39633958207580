<template>
  <v-container>
    <v-card>
      <v-card-title
        ><span>Wersja aplikacji mobilnej</span>
        <v-spacer />
        <v-btn
          :color="selectedType === MOBILE_VERSION_TYPE.TECHNICIAN ? 'primary' : null"
          left
          @click="selectType(MOBILE_VERSION_TYPE.TECHNICIAN)">
          <v-icon left>mdi-cellphone</v-icon>
          Serwisanaci
        </v-btn>
        <div class="px-2"></div>
        <v-btn
          :color="selectedType === MOBILE_VERSION_TYPE.SCANNER ? 'primary' : null"
          left
          @click="selectType(MOBILE_VERSION_TYPE.SCANNER)">
          <v-icon left>mdi-barcode-scan</v-icon>
          SKANER
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-file-input
          v-show="false"
          id="fileUpload"
          v-model="fileInputFilterComputed"
          accept="application/vnd.android.package-archive"></v-file-input>
        <v-progress-circular v-if="loadingMobileReleases"></v-progress-circular>
        <div v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Status</th>
                  <th class="text-left">Version</th>
                  <th class="text-left">Build</th>
                  <th class="text-left">Created at</th>
                  <th class="text-left">Modified at</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!file">
                  <td colspan="6" style="text-align: center" @click="chooseFiles">
                    <v-icon left>mdi-upload</v-icon>
                    Upload .apk
                  </td>
                </tr>
                <tr v-else>
                  <td colspan="6" style="text-align: center">
                    Load {{ file.name }} {{ humanFileSize(file.size) }}
                    <v-progress-circular :value="uploadProgress" color="primary">
                      {{ uploadProgress }}%
                    </v-progress-circular>
                    <v-btn text @click="abortFileUpload">
                      <v-icon>mdi-cancel</v-icon>
                      cancel
                    </v-btn>
                  </td>
                </tr>
                <tr
                  v-for="item in selectedMobileReleases.filter((v) => v.status === MOBILE_VERSION_STATUS.UNRELEASED)"
                  :key="item.name">
                  <td>{{ item.status }}</td>
                  <td>{{ item.version }}</td>
                  <td>{{ item.versionBuild }}</td>
                  <td>{{ item.createdInfo.createdAt }}</td>
                  <td>{{ item.modifiedInfo.lastModified ?? '-' }}</td>
                  <td style="text-align: end">
                    <v-btn :loading="submittingMobileReleaseDeleteId === item.id" text @click="deleteApk(item.id)">
                      <v-icon left>mdi-delete</v-icon>
                      delete
                    </v-btn>
                    <v-btn text :loading="submittingMobileReleaseReleaseId === item.id" @click="releaseApk(item.id)">
                      <v-icon left>mdi-play-box</v-icon>
                      release
                    </v-btn>
                  </td>
                </tr>
                <tr
                  v-for="item in selectedMobileReleases.filter((v) => v.status === MOBILE_VERSION_STATUS.CURRENT)"
                  :key="item.name">
                  <td>{{ item.status }}</td>
                  <td>{{ item.version }}</td>
                  <td>{{ item.versionBuild }}</td>
                  <td>{{ item.createdInfo.createdAt }}</td>
                  <td>{{ item.modifiedInfo.lastModified ?? '-' }}</td>
                  <td style="text-align: end">
                    <v-btn :href="`/uploads/files/android/builds/${item.filename}`" text @click="copyURL(`/uploads/files/android/builds/${item.filename}`)"><v-icon>mdi-link</v-icon></v-btn>
                    <v-btn :loading="submittingMobileReleaseRollbackId === item.id" text @click="rollbackApk(item.id)">
                      <v-icon left>mdi-arrow-u-left-top</v-icon>
                      rollback
                    </v-btn>
                  </td>
                </tr>
                <tr
                  v-for="item in selectedMobileReleases.filter((v) => v.status === MOBILE_VERSION_STATUS.BACKUP)"
                  :key="item.name">
                  <td>{{ item.status }}</td>
                  <td>{{ item.version }}</td>
                  <td>{{ item.versionBuild }}</td>
                  <td>{{ item.createdInfo.createdAt }}</td>
                  <td>{{ item.modifiedInfo.lastModified ?? '-' }}</td>
                  <td style="text-align: end"></td>
                </tr>
                <tr
                  v-for="item in selectedMobileReleases.filter((v) => v.status === MOBILE_VERSION_STATUS.ARCHIVE)"
                  :key="item.name">
                  <td>{{ item.status }}</td>
                  <td>{{ item.version }}</td>
                  <td>{{ item.versionBuild }}</td>
                  <td>{{ item.createdInfo.createdAt }}</td>
                  <td>{{ item.modifiedInfo.lastModified ?? '-' }}</td>
                  <td style="text-align: end"></td>
                </tr>
                <tr
                  v-for="item in selectedMobileReleases.filter((v) => v.status === MOBILE_VERSION_STATUS.TEST)"
                  :key="item.name">
                  <td>{{ item.status }}</td>
                  <td>{{ item.version }}</td>
                  <td>{{ item.versionBuild }}</td>
                  <td>{{ item.createdInfo.createdAt }}</td>
                  <td>{{ item.modifiedInfo.lastModified ?? '-' }}</td>
                  <td style="text-align: end"></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { MOBILE_VERSION_STATUS, MOBILE_VERSION_TYPE } from "../../constants";
import { humanFileSize } from "../../helper";

export default {
  data() {
    return {
      selectedType: MOBILE_VERSION_TYPE.TECHNICIAN,
      file: null,
      validFileTypes: ["application/vnd.android.package-archive"],
    };
  },
  computed: {
    MOBILE_VERSION_TYPE() {
      return MOBILE_VERSION_TYPE;
    },
    MOBILE_VERSION_STATUS() {
      return MOBILE_VERSION_STATUS;
    },
    ...mapGetters("mobileVersion", [
      "loadingMobileReleases",
      "mobileReleases",
      "uploadProgress",
      "uploadingApk",
      "submittingMobileReleaseDeleteId",
      "submittingMobileReleaseReleaseId",
      "submittingMobileReleaseRollbackId"
    ]),
    selectedMobileReleases() {
      return this.mobileReleases.filter((v) => v.type === this.selectedType);
    },
    fileInputFilterComputed: {
      get: function () {
        return this.file;
      },
      set: function (newValue) {
        console.log(newValue);
        const invalidTypes = [];
        if (newValue && !this.validFileType(newValue)) {
          invalidTypes.push(newValue);
        } else {
          this.file = newValue;
        }
        console.log(invalidTypes);
        if (invalidTypes.length) {
          this.notifyError("Niepoprawny rodzaj pliku");
        } else {
          this.upload();
        }
      },
    },
  },
  methods: {
    humanFileSize,
    ...mapActions("mobileVersion", ["fetchMobileReleases", "uploadApk", "deleteApk", "releaseApk", "rollbackApk"]),
    ...mapActions("messages", ["notifyError", "notifyInfo"]),
    selectType(type) {
      this.selectedType = type;
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    validFileType(file) {
      this.abortFileUpload();
      return this.validFileTypes.includes(file.type);
    },
    async upload() {
      try {
        this.uploadApk({ file: this.file }).then((value1) => {
          this.file = null;
        });
      } catch (error) {
        if (!window.navigator.onLine) {
          await this.notifyError("Połączenie przerwane.");
        }
      }
    },
    abortFileUpload() {
      this.uploadingApk?.controller.abort();
      this.file = null;
    },
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.notifyInfo('Skopiowano do schowka')
      } catch($e) {
        console.error('Cannot copy to clipboard')
      }
    }
  },
  created() {
    this.fetchMobileReleases();
  },
};
</script>
