<template>
  <v-row :style="{ cursor: draggingTask !== null ? 'grabbing' : '' }" class="d-flex flex-nowrap" no-gutters>
    <calendar-same-assignment-dialog
      v-if="sameAssigmentHandle"
      :my-list="myList"
      :new-user="newUser"
      :old-user="oldUser"
      :same-assigment-handle="sameAssigmentHandle"
      @cancelAssignment="cancelAssignment"
      @resolveAssignment="resolveAssignment" />
    <v-col
      v-for="(list, k, index) in myList === undefined ? users.length : myList"
      :key="k + '' + date"
      :class="{ border_loader: isColLoading(list.id, date) }"
      :style="{
        border: $store.getters['calendarTasks/doesUserHasVacations']({ date: date, id: list.id })
          ? '2px dashed #CCCCCC'
          : '',
        width: width,
        'min-width': getCardWidthPX,
        'background-color': index % 2 || (index === undefined && k % 2) ? 'transparent' : 'rgba(0,0,0,0.03)',
      }"
      class="pa-0"
      cols="auto"
      style="display: flex; flex-flow: column; height: 100%">
      <v-row
        v-if="serviceTechnicians.find((u) => u.id === list.id) !== undefined"
        no-gutters
        style="height: 64px; flex: 0 1 auto">
        <v-col class="pa-5 pl-3">
          <v-icon :color="serviceTechnicians.find((u) => u.id === list.id).color" size="24">mdi-circle</v-icon>
          {{ serviceTechnicians.find((u) => u.id === list.id).name }}
        </v-col>
      </v-row>
      <v-card
        v-if="
          list.tasks !== undefined &&
          list.tasks.length === 0 &&
          (!(list.id == newUser && targetDate === date) || draggingTask === null)
        "
        color="transparent"
        elevation="0"
        style="flex: 0 1 auto">
        <v-card-title
          style="
            padding: 0;
            margin: 16px;
            -webkit-user-select: none; /* Safari */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */
          ">
          {{ emptyOrVacations(list.id) }}
        </v-card-title>
      </v-card>
      <!--      ok{{selectedTask !== null ? selectedTask.id : 'brak'}}<br>{{selectedTask !== null ? otherAssignment(index) : ' nie ma'}}-->
      <draggable
        v-if="isDragAndDropActive"
        :id="list.id + '_' + date"
        :key="'' + date + ' ' + list.id"
        v-model="list.tasks"
        :delay="isMobile ? 400 : 50"
        :disabled="getColLoading.length > 0 || (hoveredTask && hoveredTask.task.status === 'completed')"
        :group="{ name: 'group', pull: !cancel, put: !cancel }"
        :move="checkMove"
        :style="{
          'background-color': columnBackground(list),
        }"
        class="list-group"
        force-fallback="true"
        style="flex: 1 1 auto"
        @end="(event) => endDrag(null, event)"
        @start="(event) => startDrag(list.id, event)">
        <v-card
          v-for="(element, i) in list.tasks"
          v-if="!loading && list.tasks.length > 0"
          :key="i"
          :ref="'' + element.id + '_' + date"
          :class="{
            myCard: draggingTask !== null && draggingTask.id === element.id && element.task.status !== 'completed',
          }"
          :color="taskCardBackground(index, element)"
          :elevation="element.task.status === 'completed' ? 0 : 3"
          :style="{ cursor: element.task.status === 'completed' ? 'default' : 'grab' }"
          outlined
          style="
            margin: 0 10px 5px;
            -webkit-user-select: none; /* Safari */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */
          "
          @click="show($event, '' + element.id + '_' + date, element)"
          @mouseleave="setSelectedTask(null)"
          @mouseover="setSelectedTask(element)">
<!--          {{element.priority + ' ' + element.id}}-->
          <calendar-task-card :task="element.task"> </calendar-task-card>
        </v-card>
      </draggable>
    </v-col>
  </v-row>
  <!--  $router.push({path: '/job/' + element.task.job.id})-->
</template>

<script>
import draggable from "vuedraggable";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { formatDate } from "../../helper";
import types from "../../types.json";
import CalendarTaskCard from "./CalendarTaskCard.vue";
import CalendarSameAssignmentDialog from "./CalendarSameAssignmentDialog.vue";

export default {
  display: "Drag",
  order: 0,
  components: {
    CalendarSameAssignmentDialog,
    CalendarTaskCard,
    draggable,
  },
  props: {
    date: {},
    users: {},
    componentIndex: {},
    height: {},
    loading: {},
    cancel: {},
    width: {},
  },
  data() {
    return {
      taskStatuses: types.taskStatusesFinal,
      showMenu: false,
      oldUser: null,
      taskNewPriority: null,
      hoveredTask: null,
      sameAssigmentHandle: false,
      preSameAssigmentHandle: false,
      tasks: null,
      oldIndex: null,
      delay: 300,
      timer: null,
      clickedTask1: null,
      clickedTask2: null,
    };
  },
  watch: {
    isDragAndDropActive(a) {
      if (!a) {
        this.setDraggingTask(null);
        this.taskNewPriority = null;
        this.sameAssigmentHandle = false;
      }
    },
  },
  computed: {
    myList: {
      get() {
        const newObj = { ...this.dayTasks[this.date] };
        this.serviceTechnicians.map((u) => u.id).filter((x) => !this.getCalendarSelectedUserIds.includes(x)).forEach(key => delete newObj[key]);
        return newObj;
      },
      set(value) {
        // this.$store.dispatch()
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    ...mapGetters({
      dayTasks: "calendarTasks/getUserTasks",
      targetDate: "calendarTasks/getTargetDate",
      newUser: "calendarTasks/getTargetUser",
      draggingTask: "calendarTasks/getDraggingTask",
      getColLoading: "calendarTasks/getColLoading",
      menuCardWidth: "layoutHelpers/getMenuPopUpWidth",
      serviceTechnicians: "calendarTasks/getUsers",
    }),
    ...mapGetters("calendarTasks", ["getCalendarSelectedUserIds"]),
    ...mapGetters("layoutHelpers", ["getCardWidthPX", "isDragAndDropActive"]),
  },
  methods: {
    columnBackground(list) {
      const draggingTaskTemp = this.draggingTask;
      if (this.date == this.targetDate && this.newUser == list.id && draggingTaskTemp !== null) {
        if (
          list.tasks.find(
            (t) =>
              t.id !== draggingTaskTemp.id && t.task.id === draggingTaskTemp.task.id && draggingTaskTemp.priority !== -1
          )
        ) {
          return "orange";
        }
      } else {
        return "transparent";
      }
    },
    dayDiff(d) {
      const date1 = new Date();
      const date2 = new Date(d);
      const diffTime = date2 - date1;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    taskCardBackground(index, element) {
      const otherAssigmentWhileDragging = this.otherAssignment(index, this.draggingTask);
      if (
        this.draggingTask !== null &&
        otherAssigmentWhileDragging !== false &&
        otherAssigmentWhileDragging.id == element.id
      ) {
        return "blue accent-2";
      }
      const otherAssigmentWhileHover = this.otherAssignment(index, this.hoveredTask);
      if (
        this.hoveredTask !== null &&
        otherAssigmentWhileHover !== false &&
        otherAssigmentWhileHover.id == element.id
      ) {
        return "blue accent-2";
      }
      if (this.taskStatuses[element.task.status]) {
        var status = this.taskStatuses[element.task.status];
        return status["backgroundColor"];
      }
    },
    emptyOrVacations(id) {
      return this.$store.getters["calendarTasks/doesUserHasVacations"]({ date: this.date, id: id }) ? "Urlop" : "Brak";
    },
    show(e, i, o) {
      var icon;
      if (types.taskTypes[o.task.type]) {
        icon = types.taskTypes[o.task.type]["icon"];
      } else {
        icon = "mdi-comment-question-outline";
      }
      let pos = this.$refs[i][0].$el.getBoundingClientRect();
      e.preventDefault();
      this.setMenuPopUp({ showMenu: false });

      const menuParams = {
        dayDiff: this.dayDiff(o.task.job.cutOffDate),
        cutOffDate: formatDate(o.task.job.cutOffDate),
        warningOffset: o.task.job.warningOffset,
        startAt: formatDate(o.task.startAt),
        menuDetails: o.task,
        returnIcon: icon,
        showMenu: true,
        x: pos.right > 1000 ? pos.left - this.menuCardWidth : pos.right,
        y: pos.top,
      };

      if (this.clickedTask1 !== null && this.clickedTask2 === null) {
        this.clickedTask2 = o.task.id;
      }
      if (this.clickedTask1 === null) {
        this.clickedTask1 = o.task.id;
      }

      if (this.clickedTask1 !== null && this.clickedTask2 === null) {
        var self = this;
        this.timer = setTimeout(
          function () {
            self.clickedTask1 = null;
          },
          this.delay,
          this.$nextTick(() => {
            this.setMenuPopUp(menuParams);
          })
        );
      } else {
        if (this.clickedTask1 === this.clickedTask2) {
          clearTimeout(this.timer);
          window.open("/job/" + o.task.job.id, "_blank");
        }
        this.clickedTask1 = null;
        this.clickedTask2 = null;
      }
    },
    ...mapMutations({
      setTargetDate: "calendarTasks/setTargetDate",
      setNewUser: "calendarTasks/setTargetUser",
      setDraggingTask: "calendarTasks/setDraggingTask",
    }),
    ...mapActions({
      addLoadingCols: "calendarTasks/addLoadingCols",
      removeLoadingCols: "calendarTasks/removeLoadingCols",
      setMenuPopUp: "layoutHelpers/setMenuParams",
      postUserTaskAssignment: "tasks/resolveTaskAssignment",
    }),
    ...mapActions('tasks', ['scheduleTask']),
    isColLoading(uId, date) {
      const payload = {
        userId: uId,
        date: date,
      };
      return this.$store.getters["calendarTasks/isColLoading"](payload);
      // return this.loadingCols.includes(id)
    },
    otherAssignment(i, ut) {
      if (this.myList === undefined || ut === null) {
        return false;
      }
      let a = Object.values(this.myList).map((x) =>
        x.tasks.filter((y) => {
          return y.task.id == ut.task.id;
        })
      );
      // console.log(a)
      return a[i].length !== 0 ? a[i][0] : false;
    },
    //TODO: extract useful in vuex
    setSelectedTask(t) {
      this.hoveredTask = t;
    },
    async cancelAssignment() {
      await this.addLoadingCols({ userId: this.oldUser, date: this.date });
      await this.addLoadingCols({ userId: Number(this.newUser), date: this.targetDate });
      await this.removeLoadingCols({ userId: this.oldUser, date: this.date });
      await this.removeLoadingCols({ userId: Number(this.newUser), date: this.targetDate });
      this.setDraggingTask(null);
      this.taskNewPriority = null;
      this.sameAssigmentHandle = false;
    },
    async resolveAssignment() {
      await this.addLoadingCols({ userId: this.oldUser, date: this.date });
      await this.addLoadingCols({ userId: this.newUser, date: this.date });
      await this.postUserTaskAssignment({ id: this.draggingTask.id });
      this.sameAssigmentHandle = false;
      this.taskNewPriority = null;
      this.setTargetDate(null);
      this.setDraggingTask(null);
      await this.removeLoadingCols({ userId: this.oldUser, date: this.date });
      await this.removeLoadingCols({ userId: this.newUser, date: this.date });
    },
    async startDrag(u, e) {
      this.oldUser = u.toString();
    },
    async endDrag(dt, e) {
      console.log("data table end drag");
      if (this.draggingTask === null) {
        this.$emit("setCancel", false);
        return false;
      }
      if (this.cancel) {
        const oldTasks = this.dayTasks[this.date][this.oldUser].tasks.splice(
          0,
          this.dayTasks[this.date][this.oldUser].tasks.length
        );
        oldTasks.splice(this.oldIndex, 0, this.draggingTask);
        this.$set(this.dayTasks[this.date][this.oldUser], "tasks", oldTasks);
        if (this.dayTasks[this.targetDate] !== undefined)
          this.$delete(this.dayTasks[this.targetDate][this.newUser].tasks, this.taskNewPriority);
        this.setDraggingTask(null);
        this.taskNewPriority = null;
        this.setTargetDate(null);
        this.$emit("setCancel", false);
        return false;
      }
      if (this.preSameAssigmentHandle) {
        this.sameAssigmentHandle = true;
        return;
      }
      // console.log('2')
      const payload = {
        id: this.draggingTask.id,
        taskNewDate: this.targetDate,
        taskNewPriority: this.taskNewPriority + 1,
        taskNewUser: this.newUser,
      };
      //unassign user and date
      if (this.targetDate === "" && this.newUser === "") {
        await this.addLoadingCols({ userId: this.oldUser, date: this.date });
        await this.updateCalendarPost(payload);
        this.$emit("refresh");
        await this.removeLoadingCols({ userId: this.oldUser, date: this.date });
        this.setNewUser(null);
        this.oldUser = null;
      } else if (
        this.draggingTask.priority != this.taskNewPriority + 1 ||
        this.newUser != this.oldUser ||
        this.targetDate != formatDate(this.draggingTask.task.startAt)
      ) {
        await this.addLoadingCols({ userId: this.oldUser, date: this.date });
        await this.addLoadingCols({ userId: Number(this.newUser), date: this.targetDate });
        await this.updateCalendarPost(payload);
        await this.removeLoadingCols({ userId: this.oldUser, date: this.date });
        await this.removeLoadingCols({ userId: Number(this.newUser), date: this.targetDate });
        this.setNewUser(null);
        this.oldUser = null;
      }
      this.setDraggingTask(dt);
      this.taskNewPriority = null;
      this.setTargetDate(null);
    },
    checkMove: function (e) {
      if (e.draggedContext.element.task.status === "completed") {
        return false;
      }
      this.showMenu = false;
      let info = e.to.id.split("_");
      this.setTargetDate(info[1]);
      this.setNewUser(info[0]);
      this.taskNewPriority = e.draggedContext.futureIndex;
      this.setDraggingTask(e.draggedContext.element);
      if (this.oldIndex === null) {
        this.oldIndex = this.myList[this.oldUser].tasks.findIndex((t) => t.task.id === this.draggingTask.task.id);
      }
      if (this.cancel) {
        return false;
      }
      this.preSameAssigmentHandle =
        this.date == this.targetDate &&
        this.newUser != this.oldUser &&
        this.myList[this.newUser].tasks.find((t) => t.task.id === this.draggingTask.task.id);
    },
    async updateCalendarPost(payload) {
      // console.log(payload)
      try {
        await this.$store.dispatch("tasks/updateCalendarPost", payload);
      } catch (error) {
        console.log("problem z wysyłaniem");
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}

.v-card__text {
  padding-top: 0 !important;
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}

.column {
  transition: height 0.25s ease-in-out, width 0.15s linear, background-color 0.15s linear;
}

.myCard {
  animation: expandBox 0.25s;
}

@keyframes createBox {
  from {
    transform: scale(0.2);
  }
  to {
    transform: scale(1);
  }
}

@keyframes expandBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.list-group:hover {
  /*background-color: lightgrey;*/
}

.border_loader {
  position: relative;
  z-index: 0;
  border-radius: 10px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #2daae2;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
</style>