<template>
  <div style="position: relative">
    <draggable
      id="_"
      :group="{ name: 'group', pull: false, put: true }"
      class="list-group"
      drag-class="chosen"
      force-fallback="true"
      ghost-class="ghost"
      style="
        height: 86px;
        background-color: transparent;
        z-index: 2;
        border-color: lightgray;
        border-style: dashed;
        border-width: 2px 2px 3px 3px;
        border-radius: 10px;
      ">
    </draggable>
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
      <div style="position: relative">
        <v-row class="d-flex align-center justify-center" no-gutters>
          <v-col> Odplanuj </v-col>
        </v-row>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-col>
            <v-icon>mdi-cursor-pointer</v-icon>
          </v-col>
          <v-col>
            <v-icon>mdi-menu-right-outline</v-icon>
          </v-col>
          <v-col>
            <v-icon>mdi-calendar-sync</v-icon>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
import { mapGetters } from "vuex";
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";

export default {
  components: { Draggable },
  computed: {
    ...mapGetters({ draggingTask: "calendarTasks/getDraggingTask" }),
  },
};
</script>
<style scoped></style>
