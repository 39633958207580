import Vue from 'vue';
import Router from 'vue-router';
import LoginPage from './views/auth/LoginPage';
import Forgot from './views/auth/Forgot.vue';

import UserEdit from './views/user/UserEdit';
import NewPassword from './views/auth/NewPassword.vue';
import NewEmail from './views/auth/NewEmail.vue';
import store from './vuex';
import UserList from './views/admin/Admin';
import Jobs from './components/calendar/Calendar.vue';
import JobView from './views/office/JobView';
import JobsList from './views/office/JobsList';
import Dashboard from './views/mobile/Dashboard';
import Vacation from './views/office/Vacation';
import AccessDenied from './views/global/AccessDenied.vue';
import { ROLE, UPDATE_TYPE } from './constants';
import NotFound from './views/global/NotFound.vue';
import WeekView from './views/office/WeekView';
import Bin from './views/global/Bin.vue';
import CustomersView from './views/office/CustomersView.vue';
import Chat from './views/chat/Chat.vue';
import WarehouseArticleListView from './views/warehouse/WarehouseArticleListView.vue';
import WarehouseArticleDetailsView from './views/warehouse/WarehouseArticleDetailsView.vue';
import WarehouseSettingsView from './views/warehouse/WarehouseSettingsView.vue';
import MobileVersionView from './views/mobile-version/MobileVersionView.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    /*DON'T REQUIRE AUTH*/
    { path: '/login', name: 'login', component: LoginPage, meta: { auth: false } },
    { path: '/forgot', name: 'forgot', component: Forgot, meta: { auth: false } },
    { path: '/accessDenied', name: 'accessDenied', component: AccessDenied, meta: { auth: false } },
    { path: '/notFound', name: 'notFound', component: NotFound, meta: { auth: false } },
    { path: '/newPassword', name: 'newPassword', component: NewPassword, meta: { auth: false } },

    /*REQUIRE AUTH*/
    { path: '/changeEmail', name: 'changeEmail', component: NewEmail, meta: { auth: true } },
    { path: '/user', name: 'user', component: UserEdit, meta: { auth: true } },
    { path: '/chat', name: 'chat', component: Chat, meta: { auth: true } },

    /*REQUIRE OFFICE ROLE*/
    { path: '/', name: 'home', component: Jobs, meta: { auth: true, roles: [ROLE.OFFICE, ROLE.ADMIN] } },
    { path: '/jobs', name: 'jobList', component: JobsList, meta: { auth: true, roles: [ROLE.OFFICE, ROLE.ADMIN] } },
    {
      path: '/job/:jobId',
      name: 'jobView',
      component: JobView,
      props: true,
      meta: { auth: true, roles: [ROLE.OFFICE, ROLE.ADMIN] },
    },
    {
      path: '/vacation',
      name: 'vacation',
      component: Vacation,
      meta: { auth: true, roles: [ROLE.OFFICE, ROLE.ADMIN] },
    },
    { path: '/week', name: 'week', component: WeekView, meta: { auth: true, roles: [ROLE.OFFICE, ROLE.ADMIN] } },
    { path: '/bin', name: 'bin', component: Bin, meta: { auth: true, roles: [ROLE.OFFICE, ROLE.ADMIN] } },
    {
      path: '/customers',
      name: 'customers',
      component: CustomersView,
      meta: { auth: true, roles: [ROLE.OFFICE, ROLE.ADMIN] },
    },
    {
      path: '/warehouse',
      name: 'warehouseArticleList',
      component: WarehouseArticleListView,
      meta: { auth: true, roles: [ROLE.OFFICE, ROLE.ADMIN] },
    },
    {
      path: '/warehouse/article/:articleId',
      name: 'warehouseArticleDetails',
      component: WarehouseArticleDetailsView,
      props: true,
      meta: { auth: true, roles: [ROLE.OFFICE, ROLE.ADMIN] },
    },
    {
      path: '/warehouse/settings',
      name: 'warehouseSettings',
      component: WarehouseSettingsView,
      meta: { auth: true, roles: [ROLE.OFFICE, ROLE.ADMIN] },
    },

    /*REQUIRE SERVICE TECHNICIAN ROLE*/
    {
      path: '/dashboard',
      name: 'mobileDashboard',
      component: Dashboard,
      meta: { auth: true, roles: [ROLE.SERVICE_TECHNICIAN, ROLE.EXTERNAL, ROLE.ADMIN] },
    },

    /*REQUIRE 🄰🄳🄼🄸🄽*/
    { path: '/admin', name: 'admin', component: UserList, meta: { auth: true, roles: [ROLE.ADMIN] } },
    {
      path: '/mobile-version',
      name: 'mobileVersion',
      component: MobileVersionView,
      meta: { auth: true, roles: [ROLE.ADMIN] },
    },
  ],
});
// SSE global handler
const newTaskAddedEvent = async function (event) {
  const task = JSON.parse(event.data);
  await store.dispatch('chat/recalculateChatStats', { task: task, includeComments: true, add: true });
  await store.dispatch('messages/notifyChatInfo', {
    user: task.createdInfo.createdBy,
    task: task,
    updateType: UPDATE_TYPE.CHAT_TASK_ADD,
  });
};

const newCommentAddedEvent = async function (event) {
  const task = JSON.parse(event.data);
  await store.dispatch('chat/clearTaskHelper', task.id);
  await store.dispatch('chat/addCommentToChatStats');
  await store.dispatch('messages/notifyChatInfo', {
    user: task.comments.at(-1).createdInfo.createdBy,
    task: task,
    updateType: UPDATE_TYPE.CHAT_TASK_ADD_COMMENT,
  });
};

router.beforeEach(async (to, from, next) => {
  // remove SSE handler
  if (store.getters['stream/getStream']) {
    store.getters['stream/getStream'].removeEventListener(UPDATE_TYPE.CHAT_TASK_ADD, newTaskAddedEvent);
    store.getters['stream/getStream'].removeEventListener(UPDATE_TYPE.CHAT_TASK_ADD_COMMENT, newCommentAddedEvent);
  }
  const token = localStorage.getItem('token');

  if (token !== undefined) {
    await store.dispatch('user/setToken', token);
  } else {
    await store.dispatch('user/setToken', null);
  }

  const hasToken = store.getters['user/hasToken'];
  if (hasToken && !store.getters['user/hasUserInfo']) {
    await store.dispatch('user/loadUserInfo');
  }

  const requireAuth = to.matched.some((record) => {
    return record.meta.auth;
  });

  const isTechnician = [ROLE.SERVICE_TECHNICIAN, ROLE.EXTERNAL].includes(store.getters['user/getUserRole']);
  const destination = to.name;
  if (requireAuth) {
    const redirect = { redirect: to.path };

    if (destination !== 'login' && !hasToken) {
      next({ name: 'login', params: redirect });
    } else if (hasToken) {
      // add SSE handler
      console.log('CONNECT');
      store.getters['stream/getStream'].addEventListener(UPDATE_TYPE.CHAT_TASK_ADD, newTaskAddedEvent);
      store.getters['stream/getStream'].addEventListener(UPDATE_TYPE.CHAT_TASK_ADD_COMMENT, newCommentAddedEvent);

      if (destination === null) {
        if (isTechnician) next({ name: 'mobileDashboard' });
        if (!isTechnician) next({ name: 'home' });
      } else {
        if (to.matched.some((record) => record.meta.roles === undefined)) {
          next();
        } else {
          //user role = path role = pass
          if (
            to.matched.some(
              (record) =>
                record.meta.roles !== undefined && record.meta.roles.includes(store.getters['user/getUserRole'])
            )
          ) {
            next();
          } else {
            if (isTechnician) next({ name: 'mobileDashboard' });
            if (!isTechnician) next({ name: 'home' });
          }
        }
      }
    }
  } else if (destination === 'login' && hasToken) {
    if (isTechnician) next({ name: 'mobileDashboard' });
    if (!isTechnician) next({ name: 'home' });
  } else {
    if (destination === null) {
      if (hasToken) {
        next({ name: 'notFound' });
      } else {
        next({ name: 'login' });
      }
    } else {
      next();
    }
  }
});

export default router;
