<template>
  <v-textarea
    v-model="componentText"
    :append-icon="componentText ? appendIcon : null"
    :counter="componentText ? textAreaMaxLength : null"
    :disabled="disabled"
    :prepend-icon="prependIcon"
    :flat="isFlat"
    :label="label"
    :outlined="outlined"
    :rows="rows"
    :rules="[textInputRules.validateTextLength(textAreaMaxLength)]"
    auto-grow
    :loading="loading"
    :readonly="readonly"
    row-height="25"
    @click:append="appendIconClick"
    @keydown.enter.prevent="handleEnterPress"></v-textarea>
</template>
<script>
import DoubleClick from "../../store/class/DoubleClick";

export default {
  name: "TextAreaLimitedLength",
  model: {
    prop: "parentValue",
    event: "newValue",
  },
  props: {
    textAreaMaxLength: {},
    disabled: { default: false },
    label: {},
    rows: {},
    appendIconClick: { type: Function, default: () => null },
    appendIcon: { type: String, default: null },
    prependIcon: { type: String, default: null },
    parentValue: { type: String, default: "" },
    outlined: { type: Boolean, default: true },
    isFlat: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  emits: ["newValue", "onEnter"],
  data: () => ({
    textInputRules: {
      validateTextLength(maxLength) {
        return (v) => !v || v.length <= maxLength || "Przekroczono limit znaków (" + maxLength + ")";
      },
    },
    isEnterReleased: DoubleClick.createDefault(),
  }),
  computed: {
    componentText: {
      get() {
        return this.parentValue;
      },
      set(v) {
        this.$emit("newValue", v);
      },
    },
  },
  methods: {
    handleEnterPress(value) {
      this.isEnterReleased.doubleClick(
        value.id,
        () => this.$emit("onEnter"),
        () => null
      );
    },
  },
};
</script>
