<template>
  <td :colspan="headers.length" style="padding-left: 60px">
    <v-data-table :headers="jobHeaders" :items="item.jobs" hide-default-footer item-key="id" :items-per-page="-1">
      <template #body="{ items }">
        <tbody>
          <tr v-for="job in items" :key="job.id" @click="handleDoubleClick(job)">
            <td>
              <a
                style="color: black"
                :href="$router.resolve({ name: 'jobView', params: { jobId: job.id } }).route.path">
                {{ job.title }}
              </a>
            </td>
            <td>{{ formatDate(job.cutOffDate) }}</td>
            <td>{{ formatDateTime(job.lastModified) }}</td>
            <td>{{ formatDateTime(job.createdAt) }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </td>
</template>
<script>
import { formatDate, formatDateTime } from "../../helper";
import DoubleClick from "../../store/class/DoubleClick";
import types from '../../types.json';

export default {
  name: "CustomerLocationsJobsTable",
  props: {
    item: {},
    headers: {},
  },
  data: () => ({
    types,
    doubleClick: DoubleClick.createDefault(),
    jobHeaders: [
      {
        text: "Tytuł",
        align: "start",
        sortable: false,
      },
      {
        text: "Data graniczna",
      },
      {
        text: "Ostatnio modyfikowano",
      },
      {
        text: "Utworzono",
      },
    ],
  }),
  methods: {
    formatDateTime,
    formatDate,
    handleDoubleClick(value) {
      this.doubleClick.doubleClick(
        value.id,
        () => this.$router.push({ name: "jobView", params: { jobId: value.id } }),
        () => window.open("/job/" + value.id, "_blank")
      );
    },
  },
};
</script>
