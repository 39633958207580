export default {
  setSubmittingArticleGroup(state, isSubmitting) {
    state.submittingGroup = isSubmitting;
  },
  setArticleGroups(state, payload) {
    state.articleGroups = payload;
  },
  setArticleGroupFilter(state, payload) {
    state.articleGroupFilter = payload;
  },
  setArticleGroupsLoading(state, isLoading) {
    state.loadingArticleGroups = isLoading;
  },
};
