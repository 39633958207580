<template>
  <v-chip
    :color="toggleData ? 'grey' : 'transparent'"
    :outlined="toggleData"
    :style="{ opacity: !toggleData ? '0.5' : '1' }"
    class="me-2"
    label
    style="margin-right: 5px; margin-bottom: 5px"
    text-color="black"
    @click="$emit('newValue', !toggleData)"
  >
    <v-icon left>
      {{ toggleData ? "mdi-checkbox-marked-outline" : "mdi-checkbox-blank-outline" }}
    </v-icon>
    {{ toggleData ? "Tak" : "Nie" }}
  </v-chip>
</template>
<script>
export default {
  name: "ChipSwitch",
  model: {
    prop: "toggleData",
    event: "newValue",
  },
  props: {
    toggleData: {},
  },
  emits: ["newValue"],
};
</script>
