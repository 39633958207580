export default {
  setJobs(state, payload) {
    // console.log(payload)
    state.jobs = payload;
  },
  setTotalJobs(state, payload) {
    state.totalJobs = payload;
  },
  setJob(state, payload) {
    state.job = payload;
  },
  addJob(state, payload) {
    state.jobs.unshift(payload);
    // state.jobs.push(payload)
  },
  editJob(state, payload) {
    console.log(payload);
    state.job = payload;
    // var index = state.tasks.findIndex((element) => element === payload)
    // console.log(index);
    // state.tasks.splice(index, 1);
    // state.jobs.unshift(payload)
    // state.jobs.push(payload)
  },
  setNotes(state, payload) {
    state.notes = payload;
  },
  setProtocols(state, payload) {
    state.protocols = payload;
  },
  setFiles(state, payload) {
    state.files = payload;
  },
  setFileNumber(state, payload) {
    state.fileNumber = payload;
  },
  addNote(state, payload) {
    state.notes.unshift(payload);
  },
  setUploadProgress(state, payload) {
    state.uploadProgress = payload;
  },
};
