<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end align-center" cols="1">
          <v-btn color="grey darken-2" fab small text @click="fetchOffset(-1)">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" xs="8">
          <v-menu v-model="menu" :nudge-right="40" min-width="auto" offset-y transition="scale-transition">
            <template #activator="{ on, attrs }">
              <v-col style="padding: 0" cols="auto">
                <v-text-field
                  v-model="computedDate"
                  dense
                  :prepend-icon="isMobile ? null : 'mdi-calendar'"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                  <template #append>
                    <v-btn
                      v-if="!isMobile"
                      :color="isToday() ? 'black' : 'grey'"
                      :outlined="isToday()"
                      small
                      text
                      @click="$emit('today')"
                    >
                      Dzisiaj
                    </v-btn>
                    <v-btn
                      v-else
                      :color="isToday() ? 'black' : 'grey'"
                      :outlined="isToday()"
                      small
                      text
                      @click="$emit('today')"
                    >
                      <v-icon> mdi-calendar-today </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </template>
            <v-date-picker
              v-model="date"
              :first-day-of-week="1"
              show-adjacent-months
              @input="$emit('fetchGivenDay', date)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex justify-start align-center" cols="1">
          <v-btn color="grey darken-2" fab small text @click="fetchOffset(1)">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { dateWithDayOfWeek, formatDate } from "../../helper";

export default {
  name: "CalendarDatePicker",
  model: {
    prop: "passDate",
    event: "dateNewValue",
  },
  props: {
    passDate: {},
    firstDate: {},
  },
  emits: ["today", "fetchGivenDay"],
  data() {
    return {
      menu: false,
      date: null,
    };
  },
  computed: {
    computedDate: {
      set(v) {
        this.date = v;
        this.$emit("dateNewValue", this.date);
      },
      get() {
        return dateWithDayOfWeek(this.passDate);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  created() {
    this.date = this.passDate;
  },
  methods: {
    fetchOffset(offset) {
      this.$emit("fetchGivenDay", formatDate(new Date(Date.parse(this.passDate)).addDays(offset)));
    },
    isToday() {
      return (
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10) === this.passDate
      );
    },
  },
};
</script>
<style scoped></style>
