import axios from 'axios';

export default {
  async postNewSupplier({ commit, getters, rootGetters }, { supplierData }) {
    console.debug(supplierData, 'payload');
    commit('setSubmittingSupplier', true);
    try {
      const response = await axios.post(`warehouse/supplier/add`, { ...supplierData });
      console.debug(response.data, 'response.data');
      supplierData.id = response.data.id;
      commit('setArticleSuppliers', [...getters.articleSuppliers, supplierData]);
      commit(
        'warehouse/suppliersSettings/setArticleSuppliersDetails',
        [...rootGetters['warehouse/suppliersSettings/articleSuppliersDetails'], supplierData],
        {
          root: true,
        }
      );
    } catch (e) {
      throw e;
    } finally {
      commit('setSubmittingSupplier', false);
    }
  },
  async fetchArticleSuppliers({ commit }, payload) {
    commit('setArticleSuppliersLoading', true);
    const response = await axios.get(`warehouse/supplier/all`);
    console.debug(response.data, 'response.data');
    commit('setArticleSuppliers', response.data);
    commit('setArticleSuppliersLoading', false);
  },
  addNewSupplier({ commit, getters, rootGetters }, { supplierData }) {
    commit('setArticleSuppliers', [...getters.articleSuppliers, supplierData])
  }
};
