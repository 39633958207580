import axios from 'axios';
import Vue from 'vue';
import { dates, formatDate } from '../../../helper';

export default {
  async fetchChatTasks({ commit, getters }, { initial }) {
    if (initial) {
      commit('setCurrentOldestTaskDate', formatDate(new Date()));
    }
    const duration = -1;
    const startDate = formatDate(new Date(getters.getCurrentOldestTaskDate).addDays(duration));
    const endDate = getters.getCurrentOldestTaskDate;
    const response = await axios.get(`/chat/tasks`, { params: { startDate: startDate, endDate: endDate } });
    if (response.data.isLast) {
      commit('setAreAllTaskFetched', true);
    }
    if (response.data.chatTasks.length) {
      const currentOldestTaskDateFromRequest = formatDate(response.data.chatTasks[0].createdInfo.createdAt);
      if (dates.compare(new Date(currentOldestTaskDateFromRequest), startDate) === 1) {
        commit('setCurrentOldestTaskDate', formatDate(new Date(startDate).addDays(-1)));
      } else {
        commit('setCurrentOldestTaskDate', formatDate(new Date(currentOldestTaskDateFromRequest).addDays(-1)));
      }
    } else {
      commit('setCurrentOldestTaskDate', formatDate(new Date(startDate).addDays(-1)));
    }

    const mergedTasks = [...response.data.chatTasks, ...getters.getChatTasks];

    if (initial) {
      return commit('setChatTasks', response.data.chatTasks);
    }

    commit('setChatTasks', mergedTasks);
  },
  async postChatTask(context, { title, userId }) {
    try {
      const response = await axios.post(`/chat/tasks`, {
        title: title,
        userId: userId,
      });
    } catch (error) {
      console.log(error);
    }
  },
  async postTaskHasBeenRead(context, taskId) {
    try {
      const response = await axios.post(`/chat/task/${taskId}/hasBeenRead`, {});
    } catch (error) {
      console.log(error);
    }
  },
  async postChatTaskComment(context, payload) {
    try {
      const response = await axios.post(`/chat/task/${payload.taskId}/comment`, {
        content: payload.content,
      });
    } catch (error) {
      console.log(error);
    }
  },
  async addChatTask({ commit, dispatch }, task) {
    await dispatch('recalculateChatStats', { task: task, includeComments: true, add: true });
    commit('addChatTask', task);
  },
  async updateChatTask({ commit, getters, dispatch }, { task, recalculateStats = false }) {
    const storeTaskIndex = getters.getChatTasks.findIndex((t) => t.id === task.id);
    if (storeTaskIndex !== -1) {
      Vue.set(getters.getChatTasks[storeTaskIndex], 'comments', task.comments);
      Vue.set(getters.getChatTasks, storeTaskIndex, task);
    }
    if (recalculateStats) {
      dispatch('recalculateChatStats', { task, includeComments: true, add: true });
      dispatch('clearTaskHelper', task.id);
    }
  },
  async changeTaskAssignedUser(context, payload) {
    try {
      const response = await axios.post(`/chat/task/${payload.taskId}/performBy`, {
        assignedUserId: payload.assignedUserId,
      });
    } catch (error) {
      console.log(error);
    }
  },
  async changeTaskStatus(context, payload) {
    try {
      const response = await axios.post(`/chat/task/${payload.taskId}/status`, {
        status: payload.status,
      });
    } catch (error) {
      console.log(error);
    }
  },
  async fetchChatStats({ commit }, _) {
    try {
      const response = await axios.get(`/chat/stats`);
      commit('setChatStats', {
        unreadTasks: response.data.unreadTasks,
        unreadModifications: response.data.unreadModifications,
        unreadComments: response.data.unreadComments,
      });
    } catch (error) {
      console.log(error);
    }
  },
  addCommentToChatStats({ getters, commit }) {
    const unreadStats = { ...getters.getChatStats };
    unreadStats.unreadComments = unreadStats.unreadComments + 1;
    commit('setChatStats', unreadStats);
  },
  recalculateChatStats({ getters, commit, dispatch }, { task, includeComments = false, add = false }) {
    const unreadStats = { ...getters.getChatStats };
    const isBeingProceeded = getters.getRecalculateHelper.findIndex((tId) => tId === task.id);
    if (!task.hasUserRead.task) {
      unreadStats.unreadTasks = unreadStats.unreadTasks - (add ? -1 : 1);
    }
    if (!task.hasUserRead.modification) {
      unreadStats.unreadModifications = unreadStats.unreadModifications - (add ? -1 : 1);
    }
    if (includeComments && task.hasUserRead.unreadCommentId) {
      if (isBeingProceeded === -1) {
        unreadStats.unreadComments =
          unreadStats.unreadComments - (add ? -1 : getters.countUnreadTasksComments(task.id));
        const newState = getters.getRecalculateHelper.filter((tId) => tId !== task.id);
        commit('setRecalculateHelper', newState);
      }

      commit('setRecalculateHelper', [...getters.getRecalculateHelper, task.id]);
    } else {
      const newState = getters.getRecalculateHelper.filter((tId) => tId !== task.id);
      commit('setRecalculateHelper', newState);
    }
    commit('setChatStats', unreadStats);
  },
  clearTaskHelper({ commit, getters, dispatch }, taskId) {
    const newState = getters.getRecalculateHelper.filter((tId) => tId !== taskId);
    commit('setRecalculateHelper', newState);
  },
  saveTaskHasBeenRead({ commit, getters, dispatch }, { task, readComments = false }) {
    console.log('TASK---------');
    console.log(readComments);

    const storeTaskIndex = getters.getChatTasks.findIndex((t) => t.id === task.id);
    if (storeTaskIndex === -1) {
      return console.log('NOT FOUND');
    }
    const storeTaskHasBeenRead = storeTaskIndex.hasUserRead;
    const storeTask = getters.getChatTasks[storeTaskIndex];
    const hasBeenRead = {
      task: true,
      modification: true,
      unreadCommentId: storeTask.hasUserRead.unreadCommentId,
    };

    // comments
    if (readComments) {
      hasBeenRead.unreadCommentId = null;
    }

    Vue.set(getters.getChatTasks[storeTaskIndex], 'hasUserRead', { ...storeTaskHasBeenRead, ...hasBeenRead });
  },
};
