<template>
  <div>
    <form-row-with-icon icon="mdi-text-short" text="Typ zadania">
      <v-chip
        v-for="(tt, k_tt, i) in types.taskTypes"
        :key="k_tt"
        :autofocus="true"
        :color="jobData.taskType === k_tt ? 'black' : 'white'"
        :outlined="jobData.taskType === k_tt"
        :tabindex="0"
        :text-color="jobData.taskType === k_tt ? 'black' : 'grey'"
        class="me-2"
        label
        @click="jobData.taskType = k_tt"
        @focus="focus.taskType = i">
        <v-icon :color="jobData.taskType === k_tt ? 'black' : 'grey'" left>
          {{ tt.icon }}
        </v-icon>
        {{ tt.name }}
      </v-chip>
    </form-row-with-icon>
    <form-row-with-icon icon="mdi-text">
      <v-text-field
        v-model="jobData.jobTitle"
        :error="!!!jobData.jobTitle"
        :rules="[(value) => !!value || 'Tytuł nie może być pusty.']"
        clearable
        label="Tytuł"></v-text-field>
    </form-row-with-icon>
    <form-row-with-icon icon="mdi-text-recognition">
      <v-text-field
        v-model="jobData.externalJobNumber"
        clearable
        label="Numer zewnętrzny zlecenia"
        placeholder="Brak"></v-text-field>
    </form-row-with-icon>
    <v-row>
      <v-col cols="12">
        <v-menu
          v-model="menuCutOffDate"
          :close-on-content-click="false"
          min-width="auto"
          offset-y
          transition="scale-transition">
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="jobData.cutOffDate"
              :error="!!!jobData.cutOffDate"
              :rules="[(value) => !!value || 'Data graniczna nie może być pusta.']"
              label="Data graniczna"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              @keydown.space.prevent="menuCutOffDate = true"></v-text-field>
          </template>
          <v-date-picker
            v-model="jobData.cutOffDate"
            :first-day-of-week="1"
            no-title
            show-adjacent-months
            @input="menuCutOffDate = false">
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col style="margin: 0; padding-left: 30px">
        <p style="margin: 0; padding: 0; font-size: 12px">Podlega SLA</p>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col style="max-width: 30px">
        <v-icon :color="jobData.isSLA ? 'red' : 'orange'" size="20"> mdi-alert-outline </v-icon>
      </v-col>
      <v-col>
        <v-chip
          :autofocus="true"
          :color="jobData.isSLA ? 'grey' : 'transparent'"
          :outlined="jobData.isSLA"
          :style="{ opacity: !jobData.isSLA ? '0.5' : '1' }"
          class="me-2"
          label
          style="margin-right: 5px; margin-bottom: 5px"
          text-color="black"
          @click="jobData.isSLA = !jobData.isSLA"
          @keydown.space.prevent="jobData.isSLA = !jobData.isSLA">
          <v-icon left>
            {{ jobData.isSLA ? "mdi-checkbox-marked-outline" : "mdi-checkbox-blank-outline" }}
          </v-icon>
          {{ jobData.isSLA ? "Tak" : "Nie" }}
        </v-chip>
      </v-col>
    </v-row>
    <form-row-with-icon icon="mdi-alert" text="Dni przed ostrzeżeniem">
      <v-chip
        v-for="(deadLineValue, i) in deadLineValues"
        :key="deadLineValue"
        :color="deadLineValue === jobData.warningOffset ? 'black' : 'white'"
        :outlined="deadLineValue === jobData.warningOffset"
        :tabindex="0"
        :text-color="deadLineValue === jobData.warningOffset ? 'black' : 'grey'"
        class="me-2"
        label
        @click="jobData.warningOffset = deadLineValue">
        {{ deadLineValue + (deadLineValue === jobData.warningOffset ? " dni" : "") }}
      </v-chip>
    </form-row-with-icon>
  </div>
</template>
<script>
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import types from "../../types.json";

export default {
  name: "AddJobJobDetails",
  components: { FormRowWithIcon },
  model: {
    prop: "jobData",
  },
  props: {
    focus: {},
    jobData: {},
  },
  data: () => ({
    deadLineValues: [1, 2, 3, 5, 7, 14, 21],
    menuCutOffDate: false,
    types,
  }),
  computed: {
    selectedTaskType: {
      get() {
        return this.jobData.taskType;
      },
      set(v) {
        this.jobData.taskType = v;
      },
    },
  },
  watch: {
    selectedTaskType(a, b) {
      const taskType = types.taskTypes[a];

      const contains = Object.values(this.types.taskTypes).some((element) => {
        if (this.jobData.jobTitle === element.name + " ") {
          return true;
        }
        return false;
      });
      if ((this.jobData.jobTitle === null || this.jobData.jobTitle === "" || contains) && taskType !== null) {
        this.jobData.jobTitle = taskType.name + " ";
      }
    },
  },
};
</script>