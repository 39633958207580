<template>
  <v-card-text>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" md="5">
            <form-row-with-icon icon="mdi-lock-question">
              <create-new-user-field-name ref="passwordRef" v-model="userData.password" label="Hasło" type="password" />
            </form-row-with-icon>
          </v-col>
          <v-col cols="12" md="5">
            <form-row-with-icon icon="mdi-lock-question">
              <create-new-user-field-name
                ref="confirmPasswordRef"
                v-model="userData.confirmPassword"
                :repeat="userData.password"
                label="Powtórz hasło"
                type="confirmPassword"
              />
            </form-row-with-icon>
          </v-col>
          <v-col align-self="center" cols="12" md="2">
            <v-btn :disabled="buttonDisabled()" block color="primary" @click="handleSubmit"> Zmień </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card-text>
</template>
<script>
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import CreateNewUserFieldName from "./ValidatedTextField.vue";
import { mapActions } from "vuex";
import CreateUserClass from "../../store/class/CreateUserClass";
import SnackbarMessageClass from "../../store/class/SnackbarMessageClass";

export default {
  components: { CreateNewUserFieldName, FormRowWithIcon },
  props: ["user"],
  data: () => ({
    userData: null,
    error: "",
    message: "",
  }),
  methods: {
    ...mapActions({ editUser: "users/editUser" }),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    async handleSubmit() {
      const ok = await this.editUser({ userId: this.user.id, userData: this.userData });
      this.$emit("refresh");
      if (ok) {
        await this.notifyInfo("Hasło zostało zmienione");
        this.$emit("refresh");
      } else {
        await this.notifyError("Nie można zmienić hasła");
      }
    },
    buttonDisabled() {
      let nErrors = ["unchecked"];
      if (this.$refs.passwordRef && this.$refs.confirmPasswordRef) {
        nErrors = [...this.$refs.passwordRef.getNameError(), ...this.$refs.confirmPasswordRef.getNameError()];
      }
      console.log(nErrors);
      return nErrors.length > 0;
    },
  },
  created() {
    this.userData = CreateUserClass.create({});
  },
};
</script>
