import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './axios';
import store from './vuex';
import vuetify from './plugins/vuetify';
import { validationMixin } from 'vuelidate';

Vue.config.productionTip = false;

new Vue({
  vuetify,
  mixins: [validationMixin],
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
