<template>
  <v-card class="elevation-12">
    <v-toolbar dark color="orange">
      <v-toolbar-title>Resetowanie hasła</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form v-if="!error && !message">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                :error-messages="passwordErrors"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                label="Nowe hasło"
                required
                @click:append="show = !show"
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="confirmPassword"
                :error-messages="confirmPasswordErrors"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                label="Powtórz hasło"
                required
                @click:append="show1 = !show1"
                @input="$v.confirmPassword.$touch()"
                @blur="$v.confirmPassword.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="7">
              <v-btn class="mr-4" :disabled="buttonDisabled" @click="handleSubmit"> Zresetuj hasło </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-sheet
        v-if="message"
        color="success"
        elevation="4"
        rounded
        style="padding: 20px; margin-right: 30px; margin-left: 30px; margin-bottom: 10px"
        >{{ message }}
      </v-sheet>
      <v-sheet
        v-if="error"
        color="error"
        elevation="4"
        rounded
        style="padding: 20px; margin-right: 30px; margin-left: 30px; margin-bottom: 10px"
        >{{ error }}
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { sameAs, minLength, required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "NewPassword",
  mixins: [validationMixin],
  validations: {
    password: {
      required,
      minLength: minLength(8),
      containsUppercase: function (value) {
        return /[A-Z]/.test(value);
      },
      containsLowercase: function (value) {
        return /[a-z]/.test(value);
      },
      containsNumber: function (value) {
        return /[0-9]/.test(value);
      },
      containsSpecial: function (value) {
        return /[#?!@$%^&*-]/.test(value);
      },
    },
    confirmPassword: {
      required,
      sameAs: sameAs("password"),
    },
  },
  data: () => ({
    show: false,
    show1: false,
    password: "",
    confirmPassword: "",
    hash: "",
    error: "",
    message: "",
  }),
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Hasło wymagane");
      const passwordRules = ["Wymagania: "];
      !this.$v.password.containsLowercase && passwordRules.push("mała litera,");
      !this.$v.password.containsNumber && passwordRules.push("cyfrę,");
      !this.$v.password.containsUppercase && passwordRules.push("wielka litera,");
      !this.$v.password.containsSpecial && passwordRules.push("znak specjalny,");
      !this.$v.password.minLength && passwordRules.push("co najmniej 8 znaków");
      passwordRules.length > 1 && errors.push(passwordRules.join(" "));
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.required && errors.push("Powtórz hasło!");
      !this.$v.confirmPassword.sameAs && errors.push("Hasła nie są identyczne!");
      return errors;
    },
    buttonDisabled() {
      const nErrors = this.passwordErrors.length + this.confirmPasswordErrors.length;
      return !!nErrors || !!!this.password.length;
    },
  },
  async created() {
    try {
      this.hash = this.$route.query.hash;
      const response = await axios.get("user/resetPassword/checkHash", { params: { hash: this.hash } });
    } catch (e) {
      if (e.response.data.message === "badHash") {
        this.error = "Wstęp wzbroniony";
      } else if (e.response.data.message === "outOfTime") {
        this.error = "Link wygasł.";
      } else {
        this.error = e;
      }
    }
  },
  methods: {
    clearPassword() {
      this.password = "";
    },
    async handleSubmit() {
      try {
        const response = await axios.post(
          "user/resetPassword/changePassword",
          JSON.stringify({
            hash: this.hash,
            password: this.password,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        this.message = "Hasło zostało zmienione.";
      } catch (e) {
        console.log(e);
        this.clearPassword();
        this.error = e;
      }
    },
  },
};
</script>
