import axios from 'axios';
import store from './vuex';
import router from './router';

axios.defaults.baseURL = '/api/';

axios.interceptors.request.use(
  async (config) => {
    const myToken = localStorage.getItem('token');
    // console.log(myToken)
    if (myToken === undefined) {
      await store.dispatch('user/setToken', null);
    } else if (myToken) {
      config.headers.Authorization = 'Bearer ' + myToken;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      if (error.response.data.message === 'Invalid credentials.') {
        console.log('error.response');
        console.log(error.response);
        localStorage.removeItem('token');
        await store.dispatch('user/setToken', null);
      } else {
        console.log('logout');
        await store.dispatch('user/logout');
        await router.replace({ name: 'login' }).catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        });
      }
    }

    return Promise.reject(error);
  }
);
