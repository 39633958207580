<template>
  <v-row no-gutters>
    <v-col>
      <v-row v-if="articleSuppliersDetailsLoading" no-gutters>
        <v-spacer />
        <v-col>
          <v-progress-circular></v-progress-circular>
        </v-col>
        <v-spacer />
      </v-row>
      <template v-else>
        <v-row no-gutters>
          <v-col>
            <v-data-table
              :headers="articleSuppliersHeaders"
              :items="articleSuppliersDetails"
              :items-per-page="-1"
              hide-default-footer
              class="elevation-1"
              item-key="id">
              <template #top>
                <v-row no-gutters>
                  <v-spacer></v-spacer>
                  <v-col class="pr-3" cols="auto">
                    <warehouse-create-supplier-dialog />
                  </v-col>
                </v-row>
              </template>
              <template #item.actions="{ item }">
                <warehouse-edit-supplier-dialog :supplier="item" />
                <v-tooltip :disabled="item.supplierArticlesStats === 0" bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      <warehouse-delete-supplier-dialog :supplier="item" />
                    </span>
                  </template>
                  <span>Nie można usunąć z powodu przypisanych artykułów.</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WarehouseCreateSupplierDialog from "./WarehouseCreateSupplierDialog.vue";
import WarehouseEditSupplierDialog from "./WarehouseEditSupplierDialog.vue";
import WarehouseDeleteSupplierDialog from "./WarehouseDeleteSupplierDialog.vue";

export default {
  name: "WarehouseSuppliersManagement",
  components: {
    WarehouseDeleteSupplierDialog,
    WarehouseEditSupplierDialog,
    WarehouseCreateSupplierDialog,
  },
  data: () => ({
    articleSuppliersHeaders: [
      {
        text: "Dostawca",
        value: "name",
        sortable: false,
      },
      {
        text: "Liczba artykułów",
        value: "supplierArticlesStats",
        sortable: false,
      },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      articleSuppliersDetails: "warehouse/suppliersSettings/articleSuppliersDetails",
      articleSuppliersDetailsLoading: "warehouse/suppliersSettings/articleSuppliersDetailsLoading",
    }),
  },
  async created() {
    await this.fetchArticleSuppliersDetails();
  },
  methods: {
    ...mapActions("warehouse/suppliersSettings", ["fetchArticleSuppliersDetails"]),
  },
};
</script>
