import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
import { formatDate } from '../../../helper';

export default {
  namespaced: true,
  state() {
    return {
      tasks: [],
      currentOldestTaskDate: formatDate(new Date()),
      areAllTaskFetched: false,
      taskComments: [],
      chatStats: {
        unreadTasks: 0,
        unreadModifications: 0,
        unreadComments: 0,
      },
      recalculateHelper: [],
    };
  },
  mutations,
  actions,
  getters,
};
