<template>
  <v-dialog v-model="dialog" :fullscreen="isMobile" max-width="850px" persistent>
    <template #activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon left> mdi-plus </v-icon>
        zlecenie
      </v-btn>
    </template>
    <v-card class="d-flex flex-column justify-space-between" min-height="650">
      <v-card-title>
        <span class="text-h5">Tworzenie zlecenia</span>
      </v-card-title>
      <v-card-text style="padding: 0">
        <v-card v-if="dialog" class="d-flex flex-column justify-space-between" elevation="0">
          <v-stepper v-model="stepper" elevation="0" non-linear>
            <v-stepper-header :style="{ 'box-shadow': '0 1px grey' }">
              <v-stepper-step :complete="stepIndex > 1" :tabindex="-1" editable step="1"> Klient </v-stepper-step>
              <v-spacer></v-spacer>
              <v-stepper-step
                :complete="stepIndex > 2"
                :rules="[() => !validateJob().length || stepIndex !== 3]"
                :tabindex="-1"
                editable
                step="2"
              >
                Zlecenie
                <small v-show="validateJob().length && stepIndex !== 2" style="color: red">Uzupełnij</small>
              </v-stepper-step>
              <v-spacer></v-spacer>
              <v-stepper-step
                :complete="stepIndex > 3"
                :rules="[() => !validateTask()]"
                :tabindex="-1"
                editable
                step="3"
              >
                Planowanie
                <small v-show="validateTask() && stepIndex !== 3" style="color: red">Popraw</small>
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <form-row-with-icon icon="mdi-store">
                  <custom-combobox-single
                    v-model="jobCustomerInput"
                    :autofocus="true"
                    :item-text="'name'"
                    :items="customers"
                    :disabled="getCustomersLoading"
                    :loading="getCustomersLoading"
                    :label="'Klient'"
                    :tabindex="0"
                    @trueValue="(n) => setJobCustomer(n)"
                  ></custom-combobox-single>
                </form-row-with-icon>

                <form-row-with-icon
                  v-show="
                    jobData.customer !== null || (typeof jobData.customer === 'string' && jobData.customer.length > 0)
                  "
                  icon="mdi-map-marker"
                >
                  <custom-combobox-single
                    v-model="jobLocationInput"
                    :item-text="'name'"
                    :items="customerLocations"
                    :disabled="getCustomerLocationsLoading"
                    :loading="getCustomerLocationsLoading"
                    :label="'Lokalizacja'"
                    @trueValue="(n) => setJobCustomerLocation(n)"
                  ></custom-combobox-single>
                </form-row-with-icon>
              </v-stepper-content>

              <v-stepper-content step="2">
                <add-job-job-details :focus="focus" :job-data="jobData"> </add-job-job-details>
              </v-stepper-content>

              <v-stepper-content step="3">
                <form-row-with-icon v-if="radmaxWorkers" icon="mdi-account-group" text="Przypisane osoby">
                  <v-chip
                    v-for="(item, i) in radmaxWorkers"
                    :key="item.id"
                    :autofocus="true"
                    :color="jobData.signedUsers.find((v) => v.id === item.id) !== undefined ? 'grey' : 'transparent'"
                    :outlined="jobData.signedUsers.find((v) => v.id === item.id) !== undefined"
                    :style="{ opacity: jobData.signedUsers.find((v) => v.id === item.id) === undefined ? '0.5' : '1' }"
                    :tabindex="0"
                    class="me-2"
                    label
                    style="margin-right: 5px; margin-bottom: 5px"
                    text-color="black"
                    @click="handleSelectedWorkers(item)"
                    @keydown.space.prevent="handleSelectedWorkers(item)"
                  >
                    <v-icon :color="item.color" left> mdi-circle </v-icon>
                    {{ item.name }}
                  </v-chip>
                </form-row-with-icon>
                <form-row-with-icon v-if="externalWorkers" icon="mdi-account-supervisor-outline">
                  <v-chip
                    v-for="(item, i) in externalWorkers"
                    :key="item.id"
                    :autofocus="true"
                    :color="jobData.signedUsers.find((v) => v.id === item.id) !== undefined ? 'grey' : 'transparent'"
                    :outlined="jobData.signedUsers.find((v) => v.id === item.id) !== undefined"
                    :style="{ opacity: jobData.signedUsers.find((v) => v.id === item.id) === undefined ? '0.5' : '1' }"
                    :tabindex="0"
                    class="me-2"
                    label
                    style="margin-right: 5px; margin-bottom: 5px"
                    text-color="black"
                    @click="handleSelectedWorkers(item)"
                    @keydown.space.prevent="handleSelectedWorkers(item)"
                  >
                    <v-icon :color="item.color" left> mdi-circle </v-icon>
                    {{ item.name }}
                  </v-chip>
                </form-row-with-icon>
                <v-row>
                  <v-col>
                    <v-menu
                      v-model="menuStartAtDate"
                      :close-on-content-click="false"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="jobData.startAtDate"
                          clearable
                          label="Data rozpoczęcia"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @keydown.s.space.prevent="menuStartAtDate = true"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="jobData.startAtDate"
                        :first-day-of-week="1"
                        no-title
                        show-adjacent-months
                        @input="menuStartAtDate = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <form-row-with-icon icon="mdi-text-long" text="Opis zadania">
                  <text-editor v-model="jobData.taskDescription" :text-limit="textAreaMaxLength"></text-editor>
                </form-row-with-icon>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-btn text @click="dialog = false">
          <v-icon left> mdi-cancel </v-icon>
          {{ isMobile ? null : "Anuluj" }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-show="stepIndex > 1" text @click="stepIndex -= 1">
          <v-icon left> mdi-arrow-left-bold </v-icon>
          {{ isMobile ? null : "Cofnij" }}
        </v-btn>
        <v-btn :disabled="!!validation().length" color="primary" @click="addJob">
          <v-icon left> mdi-note-plus </v-icon>
          {{ isMobile ? "Utwórz" : "Utwórz zlecenie" }}
        </v-btn>
        <v-btn v-show="stepIndex < 3" color="primary" @click="stepIndex += 1">
          <v-icon left> mdi-arrow-right-bold </v-icon>
          {{ isMobile ? null : "Dalej" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomComboboxSingle from "../layout/CustomComboboxSingle";
import { mapActions, mapGetters } from "vuex";
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import CreateJobClass from "../../store/class/CreateJobClass";
import types from "../../types.json";
import TextEditor from "../textEditor/TextEditor.vue";
import AddJobJobDetails from "./AddJobJobDetails.vue";

export default {
  components: { AddJobJobDetails, TextEditor, FormRowWithIcon, CustomComboboxSingle },
  data: () => ({
    dialog: false,
    stepIndex: 1,
    customersAreLoaded: false,
    focus: {
      taskType: 0,
    },
    selectTab: false,
    menuStartAtDate: false,
    jobCustomerInput: null,
    jobLocationInput: "",
    jobData: CreateJobClass.createDefault(),
    textAreaMaxLength: 10000,
  }),
  methods: {
    ...mapActions("layoutHelpers", ["setCalendarViewRefreshing"]),
    ...mapActions('customerLocations', ['fetchCustomerLocations', 'resetCustomerLocations']),
    reset() {
      this.focus.taskType = 0;
      this.jobLocationInput = "";
      this.jobCustomerInput = null;
      this.stepIndex = 1;
    },
    validation() {
      const err = this.validateJob();
      if (this.validateTask()) {
        err.push("Przekroczono limit opisu zadania");
      }
      return err;
    },
    validateJob() {
      const err = [];
      if (this.jobData.jobTitle === null || this.jobData.jobTitle === "") {
        err.push("Tytuł nie powinien być pusty");
      }
      if (this.jobData.cutOffDate === null || this.jobData.cutOffDate === "") {
        err.push("Wybierz datę graniczną");
      }
      return err;
    },
    validateTask() {
      if (!this.jobData.taskDescription) {
        return false;
      }
      return this.jobData.taskDescription && this.jobData.taskDescription.length > this.textAreaMaxLength;
    },
    async setJobCustomer(v) {
      this.jobLocationInput = null;
      if (v && typeof v === "object") {
        await this.fetchCustomerLocations({ customerId: v.id});
        this.jobLocationInput = null;
      } else {
        this.resetCustomerLocations();
      }
      this.jobData.location = null;
      this.jobData.customer = v;
    },
    setJobCustomerLocation(v) {
      this.jobData.location = v;
    },
    handleSelectedWorkers(item) {
      const i = this.jobData.signedUsers.findIndex((v) => v.id === item.id);
      if (i !== -1) {
        return this.jobData.signedUsers.splice(i, 1);
      }
      return this.jobData.signedUsers.push(item);
    },
    async fetchWorkers() {
      try {
        this.$store.commit("layoutHelpers/addLoadingProcess", "fetchWorkers");
        await this.$store.dispatch("calendarTasks/fetchServiceTechnicians");
      } catch (error) {
        console.log(error);
      }
      this.workersAreLoaded = true;
      this.$store.commit("layoutHelpers/removeLoadingProcess", "fetchWorkers");
    },
    async fetchCustomers() {
      try {
        await this.$store.dispatch("customers/fetchCustomers");
      } catch (e) {
        console.log(e);
      }
      this.customersAreLoaded = true;
    },
    async addJob() {
      await this.$store.dispatch("jobs/addJob", this.jobData);

      this.reset();
      this.resetValues();
      this.dialog = false;
      // this.reset()
      this.jobData = CreateJobClass.createDefault();
      await this.setCalendarViewRefreshing(true);
      await this.$emit("refresh");
    },
    resetValues(customer = true, location = true) {
      if (customer) {
        this.jobCustomerInput = "";
      }
      if (location) {
        this.jobLocationInput = "";
        this.resetCustomerLocations();
      }
    },
  },
  computed: {
    ...mapGetters({
      workers: "calendarTasks/getUsers",
      radmaxWorkers: "calendarTasks/getRadmax",
      externalWorkers: "calendarTasks/getExternal",
      customerLocations: "customerLocations/getCustomerLocations",
      customers: "customers/getCustomers",
    }),
    ...mapGetters('customerLocations', ['getCustomerLocationsLoading']),
    ...mapGetters('customers', ['getCustomersLoading']),
    stepper: {
      get() {
        return this.stepIndex;
      },
      set(v) {
        this.stepIndex = Number(v);
      },
    },
    jobCustomerOutput: {
      get() {
        return this.jobData.customer;
      },
      set(v) {
        this.jobData.customer = v;
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {
    dialog(v) {
      this.fetchCustomers();
    },
    async jobCustomerOutput(val) {
      console.log(val);
      this.resetValues(false, true);
      this.setJobCustomerLocation(null);
      if (val !== null && typeof val !== "string") {
        await this.fetchCustomerLocations({ customerId: val.id });
      }
    },
  },
  async created() {
    await this.fetchCustomers();
    if (!this.$store.getters["calendarTasks/getUsers"].length) {
      await this.fetchWorkers();
    }
    window.addEventListener("keydown", (e) => {
      if ((e.key === " " || e.key === "Spacebar") && (this.focus.taskType !== null)) {
        this.selectedTaskType = this.focus.taskType;
        this.focus.taskType = null;
        return;
      }
      this.focus.taskType = null;
    });
  },
  beforeDestroy() {
    window.removeEventListener("keydown", (e) => {
      if (e.key === " " || e.key === "Spacebar") {
        this.selectTab = true;
        return;
      }
      this.selectTab = false;
    });
  },
};
</script>
