<template>
  <v-dialog v-model="dialog" max-width="580px" persistent>
    <template #activator="{ on, attrs }">
      <v-btn color="grey" depressed elevation="0" outlined right v-bind="attrs" x-small v-on="on">
        <v-icon left small> mdi-download</v-icon>
        <slot></slot>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Wybierz pliki do pobrania</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-chip
              :color="isActive ? 'grey' : 'transparent'"
              :outlined="isActive"
              class="me-2"
              label
              style="margin-right: 5px; margin-bottom: 5px"
              text-color="black"
              @click="switchAllNoneButton()">
              <v-icon left>
                {{ isActive ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
              </v-icon>
              {{ isActive ? "Odznacz wszystkie" : "Zaznacz wszystkie" }}
            </v-chip>
          </v-row>
          <v-row align="center" class="overflow-auto" justify="center">
            <ul style="width: 100%; padding: 0">
              <li
                v-for="f in files"
                :key="f.filename"
                :style="[
                  selectedFiles.includes(f.filename)
                    ? { border: 'lightgrey 1px solid !important', 'border-radius': '3px !important' }
                    : {},
                ]"
                style="padding: 0; margin: 10px">
                <v-checkbox
                  :id="f.filename"
                  v-model="selectedFiles"
                  :value="f.filename"
                  class="checkbox"
                  hide-details
                  style="margin: 8px">
                  <template #label>
                    <label :for="f.filename" style="display: block; position: relative; cursor: pointer">
                      <v-chip
                        v-if="f.filetype === 'document'"
                        color="transparent"
                        style="width: 150px; height: 150px"
                        text-color="black">
                        <v-spacer id="file">
                          <v-icon black left> mdi-file</v-icon>
                          {{ normFilename(f.filename) }}
                        </v-spacer>
                      </v-chip>
                      <v-chip
                        v-if="f.filetype === 'image'"
                        class="flex-grow-1"
                        color="transparent"
                        style="width: 150px; height: 150px"
                        text-color="black">
                        <v-img id="file" :src="'/uploads/files/' + f.filename" contain height="150px" width="150px" />
                      </v-chip>
                      <v-chip
                        v-if="f.filetype === 'video'"
                        class="flex-grow-1"
                        color="transparent"
                        style="width: 150px; height: 150px"
                        text-color="black">
                        <video id="file" height="120px" width="130px">
                          <source :src="'/uploads/files/' + f.filename" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <v-icon style="position: absolute; top: 50px; left: 55px" x-large>mdi-movie-open</v-icon>
                      </v-chip>
                    </label>
                  </template>
                </v-checkbox>
              </li>
            </ul>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-col class="text-right">
          <v-btn color="blue darken-1" style="margin-right: 4px" text @click="cancelDownloading"> Anuluj</v-btn>
          <v-btn :disabled="selectedFiles.length < 1" color="primary" elevation="2" @click.prevent="downloadZip">
            <v-icon left> mdi-download</v-icon>
            pobierz
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--  </v-row>-->
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import Integer from "vuelidate/lib/validators/integer";

export default {
  props: {
    files: {
      type: Array,
      default() {
        return [];
      },
    },
    jobId: Integer,
    separator: String,
  },
  data: () => ({
    selectedFiles: [],
    dialog: false,
    loadingDownload: false,
  }),
  computed: {
    ...mapGetters({
      uploadProgress: "jobs/getUploadProgress",
      userRole: "user/getUserRole",
    }),
    isActive() {
      console.log(this.selectedFiles.length);
      console.log(this.files.length);

      return this.selectedFiles.length === this.files.length;
    },
  },
  created() {
    this.checkAll();
  },
  methods: {
    switchAllNoneButton() {
      if (this.isActive) {
        return this.uncheckAll();
      }
      this.checkAll();
    },
    ...mapMutations({ resetProgress: "jobs/setUploadProgress" }),
    refreshAll() {
      location.reload();
    },
    checkAll() {
      this.selectedFiles = [...this.files.map((f) => f.filename)];
    },
    uncheckAll() {
      this.selectedFiles = [];
    },
    cancelDownloading() {
      this.selectedFiles = [];
      this.dialog = false;
    },
    async downloadZip() {
      if (this.loadingDownload) {
        return;
      }
      this.loadingDownload = true;
      // for(let file in this.selectedFiles){
      //   console.log(file)
      // }
      const response = await axios.get("job/downloadNoteFiles?files=" + this.selectedFiles + "&job=" + this.jobId);
      if (response.data) {
        this.fileAccessToken = response.data.token;
        window.open("/api/download?token=" + this.fileAccessToken, "_blank");
      }
      this.loadingDownload = false;
      this.dialog = false;
    },
    normFilename(fn) {
      const r = fn.toString().split("-");
      r.shift();
      return r.join("-");
    },
  },
};
</script>
<style scoped>
ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

input[type="checkbox"] {
  display: none;
}

label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

#file {
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked + label {
  border: lightgrey 1px solid;
  border-radius: 3px;
}

:checked + label #file {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
}
</style>
