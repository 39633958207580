<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Ustawienia magazynu</v-toolbar-title>
        <template #extension>
          <v-tabs v-model="tab" background-color="white">
            <v-tab v-for="item in items" :key="item.tab">
              {{ item.tab }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <warehouse-facilities-management />
        </v-tab-item>
        <v-tab-item>
          <warehouse-suppliers-management />
        </v-tab-item>
        <v-tab-item>
          <warehouse-article-groups-management/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import WarehouseFacilitiesManagement from "../../components/warehouse/facility/WarehouseFacilitiesManagement.vue";
import WarehouseSuppliersManagement from "../../components/warehouse/supplier/WarehouseSuppliersManagement.vue";
import WarehouseArticleGroupsManagement from "../../components/warehouse/group/WarehouseArticleGroupsManagement.vue";

export default {
  name: "WarehouseSettingsView",
  components: { WarehouseArticleGroupsManagement, WarehouseSuppliersManagement, WarehouseFacilitiesManagement },
  data: () => ({
    tab: null,
    items: [{ tab: "Magazyny" }, { tab: "Dostawcy" }, { tab: "Grupy" }],
  }),
};
</script>