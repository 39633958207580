<template>
  <v-container>
    <v-data-table
      :footer-props="{ 'items-per-page-options': [50, 100, 250] }"
      :headers="headers"
      :item-class="itemRowClass"
      :items="jobs"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalJobs"
      class="elevation-1"
      item-key="name">
      <template #body="{ items }">
        <tbody>
          <tr v-for="job in items" :key="job.id" @click="handleDoubleClick(job)">
            <td>
              <a
                style="color: black"
                :href="$router.resolve({ name: 'jobView', params: { jobId: job.id } }).route.path">
                {{ job.title }}
              </a>
            </td>
            <td>{{job.customer !== null ? job.customer.name : 'Klient bez nazwy' }}</td>
            <td>{{ job.location !== null ? job.location.name : 'Brak lokalizacji' }}</td>
            <td>{{ job.cutOffDate }}</td>
            <td>{{ formatDateTime(job.modifiedInfo.lastModified) }}</td>
            <td>{{ formatDateTime(job.createdInfo.createdAt) }}</td>
          </tr>
        </tbody>
      </template>
      <template #top>
        <v-toolbar dense flat>
          <v-toolbar-title>Lista zleceń</v-toolbar-title>

          <v-spacer />

          <add-job @refresh="fetchJobs(true)" />
        </v-toolbar>
        <v-row no-gutters>
          <v-col cols="12" md="3">
            <v-text-field v-model="jobFilters.searchText" clearable dense label="Szukaj" prepend-icon="mdi-magnify" />
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-container fluid>
          <v-row>
            <v-col md="12">
              <v-row>
                <!--TODO: extract-->

                <v-col md="3">
                  <jobs-list-filter-column title="Zlecenia">
                    <jobs-list-filter-column-single-select-chip
                      :arr="statuses"
                      :cols="12"
                      :md="'auto'"
                      :compared-value="statuses[jobFilters.status]"
                      :display-key="'name'"
                      @selectElement="(n) => setFilters(getKeyByValue(statuses, n), 'status')" />
                    <jobs-list-filter-column-single-select-chip
                      :arr="getSettlementStatuses()"
                      :cols="12"
                      :md="'auto'"
                      has-icon
                      :compared-value="jobFilters.settlementStatus"
                      :compared-array-key="'key'"
                      :display-key="'name'"
                      @selectElement="(n) => setFilters(n.key, 'settlementStatus')" />
                  </jobs-list-filter-column>
                </v-col>

                <!--TODO: extract-->

                <v-col md="5">
                  <jobs-list-filter-column title="Zadania">
                    <jobs-list-filter-column-single-select-chip
                      :arr="taskStatuses"
                      :cols="12"
                      :md="6"
                      has-icon
                      :compared-value="taskStatuses[jobFilters.taskStatus]"
                      :display-key="'name'"
                      @selectElement="(n) => setFilters(getKeyByValue(taskStatuses, n), 'taskStatus')" />
                    <v-col class="d-flex flex-column justify-start" cols="12" md="6">
                      <v-row no-gutters>
                        <jobs-list-filter-column-single-select-chip
                          :arr="taskTypes"
                          :md="'auto'"
                          :compared-value="taskTypes[jobFilters.taskType]"
                          :display-key="'name'"
                          @selectElement="(n) => setFilters(getKeyByValue(taskTypes, n), 'taskType')" />
                      </v-row>
                    </v-col>
                  </jobs-list-filter-column>
                </v-col>

                <!--TODO: extract-->
                <v-col md="3">
                  <jobs-list-filter-column title="Użytkownicy">
                    <v-col class="d-flex flex-column" cols="6" md="auto">
                      <form-row-with-icon
                        :toggleable="true"
                        :toggleable-value="isRadmax"
                        text="Radmax"
                        @toggled="selectTeam(isRadmax, radmaxWorkers)">
                        <jobs-list-filter-column-single-select-chip
                          :arr="radmaxWorkers.map((x) => ({ ...x, icon: 'mdi-circle', selected: findUser(x) }))"
                          :md="'auto'"
                          :prop-class="'me-2'"
                          has-icon
                          :compared-value="'selected'"
                          :compared-array-key="null"
                          display-key="name"
                          @selectElement="(n) => handleSelectedWorkers(n)" />
                      </form-row-with-icon>
                    </v-col>
                    <v-col class="d-flex flex-column" cols="6" md="auto">
                      <form-row-with-icon
                        :toggleable="true"
                        :toggleable-value="isExternal"
                        text="Podwykonawcy"
                        @toggled="selectTeam(isExternal, externalWorkers)">
                        <jobs-list-filter-column-single-select-chip
                          :arr="externalWorkers.map((x) => ({ ...x, icon: 'mdi-circle', selected: findUser(x) }))"
                          :md="'auto'"
                          :prop-class="'me-2'"
                          has-icon
                          small
                          :compared-value="'selected'"
                          :compared-array-key="null"
                          display-key="name"
                          @selectElement="(n) => handleSelectedWorkers(n)" />
                      </form-row-with-icon>
                    </v-col>
                  </jobs-list-filter-column>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import AddJob from "../../components/job/AddJob";
import { mapGetters } from "vuex";
import types from "../../types.json";
import FormRowWithIcon from "../../components/ui/FormRowWithIcon.vue";

import {
  arraysEqual,
  formatDateTime,
  getKeyByValue
} from "../../helper";
import Vue from "vue";
import JobsListFilterColumn from "../../components/job/JobsListFilterColumn.vue";
import JobsListFilterColumnSingleSelectChip from "../../components/job/JobsListFilterColumnSingleSelectChip.vue";
import JobFiltersClass from "../../store/class/JobFiltersClass";
import DoubleClick from "../../store/class/DoubleClick";
import { jobsListDataTableConst } from "../../constants";

export default {
  components: { JobsListFilterColumnSingleSelectChip, JobsListFilterColumn, FormRowWithIcon, AddJob },

  data() {
    return {
      types,
      taskTypes: types.taskTypes,

      jobsAreLoaded: true,
      statuses: types.jobStatuses,

      recentlyAdded: null,
      taskStatuses: types.taskStatusesFinal,

      jobFilters: JobFiltersClass.createDefault(),

      loading: true,

      options: {
        itemsPerPage: 50,
        page: 1,
        sortBy: ["lastModified"],
        sortDesc: [true],
        groupBy: ["lastModified"],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      addedLastly: false,
      delay: 300,
      timer: null,
      clickedJob1: null,
      clickedJob2: null,
      doubleClick: DoubleClick.createDefault(),
    };
  },
  computed: {
    headers() {
      return jobsListDataTableConst;
    },
    isExternal() {
      return this.externalWorkers.map((x) => x.id).every((r) => this.jobFilters.users.includes(r));
    },
    isRadmax() {
      return this.radmaxWorkers.map((x) => x.id).every((r) => this.jobFilters.users.includes(r));
    },
    ...mapGetters({
      radmaxWorkers: "calendarTasks/getRadmax",
      externalWorkers: "calendarTasks/getExternal",
      jobs: "jobs/getJobs",
      totalJobs: "jobs/getTotalJobs",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      async handler(newVal, oldVal) {
        console.log(newVal);
        console.log(oldVal);

        this.setJobFiltersViaQuery(newVal);

        this.options.itemsPerPage = Number(newVal.limit ?? 50); //default
        this.options.page = Number(newVal.page ?? 1); //default

        console.log(this.jobFilters.users);
        console.log(this.options);
        await this.updateQueryString();
      },
    },
    "jobFilters.searchText": {
      handler(v) {
        this.fetchJobsDebounced();
        this.updateQueryString();
      },
      immediate: false,
    },
    jobs(a, b) {
      this.recentlyAdded = Math.max.apply(
        null,
        this.$store.getters["jobs/getJobs"].map((x) => x.id)
      );
    },
    options: {
      immediate: false,
      async handler(n, o) {
        console.log('options');
        console.log(n);
        console.log(o);
        try {
          if (typeof o === "object" || typeof n === "object") {
            if (n.page != o.page || n.itemsPerPage != o.itemsPerPage) {
              return this.updateQueryString();
            }
          }
        } catch (err) {
          console.log(err);
        }
        await this.fetchJobs();
      },
      deep: true,
    },
  },
  created() {
    console.log("CREATED");
    console.log(this.$route.query);
  },
  methods: {
    getKeyByValue,
    formatDateTime,
    setJobFiltersViaQuery(query) {
      this.jobFilters.status = query.status ?? undefined;
      this.jobFilters.taskStatus = query.taskStatus ?? undefined;
      this.jobFilters.jobType = query.jobType ?? undefined;
      this.jobFilters.taskType = query.taskType ?? undefined;
      this.jobFilters.searchText = query.search ?? undefined;
      this.jobFilters.settlementStatus = query.settlementStatus ?? undefined;

      if (query.users !== undefined) {
        this.jobFilters.users = query.users.split(",").map(Number);
      } else if (query.team === "all") {
        console.log("radmax");
      } else if (query.team === "radmax") {
        this.jobFilters.users = this.radmaxWorkers.map((x) => x.id);
      } else if (query.team === "external") {
        this.jobFilters.users = this.externalWorkers.map((x) => x.id);
      } else if (query.team === null || query.users === null) {
        if (this.jobFilters.users.length === 0) {
          this.jobFilters.users = [];
        }
      } else {
        this.jobFilters.users = [];
      }
    },
    setFilters(val, jobFiltersProp) {
      console.log(val);
      if (this.jobFilters[jobFiltersProp] === val) {
        this.jobFilters[jobFiltersProp] = null;
      } else {
        this.jobFilters[jobFiltersProp] = val;
      }
      this.updateQueryString();
    },
    async fetchJobs(fetch_default = false) {
      if (fetch_default) {
        this.addedLastly = true;
      }

      this.loading = true;

      // console.log(this.jobFilters.users);

      const selectedUsers = this.jobFilters.users;
      let filtersCopy = structuredClone(this.jobFilters);
      filtersCopy.users = selectedUsers;

      console.log("job options");
      console.log(this.options);

      try {
        const params = {
          options: this.options,
          jobFilters: this.jobFilters,
        };
        await this.$store.dispatch("jobs/fetchJobs", params);
        console.log('PARAMS');
        console.log(params);
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    fetchJobsDebounced() {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.fetchJobs();
      }, 500);
    },
    findUser(item) {
      if (this.jobFilters.users.length > 0) {
        return this.jobFilters.users.find((v) => v === item.id) !== undefined;
      }
      return false;
    },
    formatDate(date = new Date()) {
      return [date.getFullYear(), this.padTo2Digits(date.getMonth() + 1), this.padTo2Digits(date.getDate())].join("-");
    },
    getStatusIcon(t) {
      return this.taskStatuses.find((x) => x.officeName === t).icon;
    },
    getStatusIconColor(t) {
      return this.taskStatuses.find((x) => x.officeName === t).color;
    },
    getSettlementStatuses() {
      return Object.entries(this.types.jobSettlementStatus).map(([key, value]) => {
        return { key, ...value };
      });
    },
    handleDoubleClick(value) {
      this.doubleClick.doubleClick(
        value.id,
        () => this.$router.push({ name: "jobView", params: { jobId: value.id } }),
        () => window.open("/job/" + value.id, "_blank")
      );
    },
    handleSelectedWorkers(item) {
      console.log("handle");
      console.log(this.jobFilters.users);
      const i = this.jobFilters.users.findIndex((v) => v === item.id);
      if (i !== -1) {
        this.jobFilters.users.splice(i, 1);
      } else {
        this.jobFilters.users.push(item.id);
      }
      this.updateQueryString();
    },
    itemRowClass: function (item) {
      return item.id === this.recentlyAdded && this.addedLastly ? "style-1" : "style-2";
    },

    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    selectTeam(isTeam, teamToSet) {
      const tempWorkers = new Set(this.jobFilters.users);
      const a = isTeam;
      teamToSet.forEach((x) => {
        if (!a) {
          tempWorkers.add(x.id);
        } else {
          tempWorkers.delete(x.id);
        }
      });
      Vue.set(this.jobFilters, "users", Array.from(tempWorkers));
      this.updateQueryString();
    },
    async updateQueryString() {
      //mutate query
      const query = (({ users, searchText, ...o }) => o)(this.jobFilters);
      query.search = this.jobFilters.searchText;
      if (this.jobFilters.users.length === 0) {
        query.team = "all";
      } else if (
        arraysEqual(
          this.jobFilters.users,
          this.radmaxWorkers.map((x) => x.id)
        )
      ) {
        query.team = "radmax";
      } else if (
        arraysEqual(
          this.jobFilters.users,
          this.externalWorkers.map((x) => x.id)
        )
      ) {
        query.team = "external";
      } else {
        query.users = this.jobFilters.users.join(",");
      }

      query.limit = Number(this.options.itemsPerPage);
      query.page = Number(this.options.page);

      //update store
      this.fetchJobsDebounced();

      //redirect
      await this.$router
        .replace({
          name: "jobList",
          query,
        })
        .catch((err) => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          // console.log(err)
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes("Avoided redundant navigation to current location")
          ) {
            // But print any other errors to the console
            console.log(err);
          }
        });
    },
  },
};
</script>

<style>
.style-1 {
  animation: expandBox 0.3s, border-dance 1s 10 linear;
  /*background-image: linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%);*/
  /*background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;*/
  /*background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;*/
  /*background-position: left top, right bottom, left bottom, right top;*/
  cursor: pointer;
}

@keyframes expandBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
    background-image: linear-gradient(90deg, silver 50%, transparent 50%),
      linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%),
      linear-gradient(0deg, silver 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
    background-image: linear-gradient(90deg, silver 50%, transparent 50%),
      linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%),
      linear-gradient(0deg, silver 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  }
}

.style-2 {
  cursor: pointer;
}
</style>
