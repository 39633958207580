import axios from 'axios';
import { formatDate, formatDateTime } from '../../../helper';
import types from '../../../types.json';

export default {
  async fetchJobs(context, payload) {
    const response = await axios.get(`job/fetchJobs`, {
      params: {
        options: payload.options,
        jobFilters: payload.jobFilters,
      },
    });
    console.log(response.data);

    let jobs = [];
    response.data.jobs.forEach((item) => {
      item.cutOffDate = formatDate(item.cutOffDate);
      item.lastModified = formatDateTime(item.lastModified);
      item.createdAt = formatDateTime(item.createdAt);
      jobs.push(item);
    });
    // context.commit('setJobs', response.data.jobs);
    context.commit('setJobs', jobs);
    context.commit('setTotalJobs', response.data.totalJobs);
  },
  async fetchJob(context, payload) {
    const response = await axios.get(`job/fetchJob`, { params: { jobId: payload.jobId } });

    context.commit('setJob', response.data.job);
  },

  async addJob(context, payload) {
    const o = structuredClone(payload);
    console.log(o);
    // o.jobType = o.jobType.name;
    // if (o.signedUsers.length > 0) {
    o.signedUsers = o.signedUsers.map((x) => x.id);
    // }
    await axios.post(`job/addJobWithTask`, o);
  },

  async editJob(context, payload) {
    console.log(payload);
    await axios.post(`job/editJob`, {
      jobData: payload.jobData,
    });
  },
  async fetchNotes(context, payload) {
    const response = await axios.get(`job/fetchNotes`, { params: { jobId: payload.jobId } });

    context.commit('setFileNumber', response.data.fileNumber);
    context.commit(
      'setNotes',
      response.data.notes.sort((a, b) => b.id - a.id)
    );
  },
  async fetchProtocols(context, payload) {
    const response = await axios.get(`protocol/getProtocols`, { params: { jobId: payload.jobId } });
    context.commit('setProtocols', response.data);
  },
  async fetchJobFiles(context, payload) {
    const response = await axios.get(`job/getJobFiles`, { params: { jobId: payload.jobId } });
    context.commit('setFiles',response.data);
  },
  async addNote(context, payload) {
    const response = await axios.post(`job/addNote`, {
      jobId: payload.jobId,
      title: payload.note.title,
      content: payload.note.content,
      isPrivate: payload.note.isPrivate,
    });

    context.commit('addNote', response.data.note);
    return response.data.note.id;
  },
  async addProtocol(context, payload) {
    let formData = new FormData();
    formData.append('file', payload.file);
    formData.append('jobId', payload.jobId);
    formData.append('protocolDetails', JSON.stringify(payload.protocolDetails));
    const response = await axios.post(`protocol/addProtocol`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        context.commit('setUploadProgress', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)));
      }.bind(this),
    });
    console.log(formData);
    // context.commit('addNote', response.data.note);
    // return response.data.note.id;
  },
  async deleteFile(context, payload) {
    await axios.post(`job/deleteFile`, { fileName: payload.fileName });
  },
  async deleteProtocol(context, payload) {
    await axios.post(`protocol/delete`, { fileName: payload.fileName });
  },
  async checkFileAudio(context, payload) {
    return await axios.post(`job/checkFileAudio`, { fileName: payload.fileName });
  },
  async muteFile(context, payload) {
    await axios.post(`job/muteFile`, { fileName: payload.fileName });
  },
  async addFile(context, payload) {
    let formData = new FormData();
    payload.file.forEach((e, i) => {
      formData.append('file' + i, e);
    });
    // formData.append('fileNumber', payload.file.length);
    formData.append('noteId', payload.noteId);
    formData.append('converted', payload.converted);
    console.log(payload.converted);
    const response = await axios.post(`job/uploadFile`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        context.commit('setUploadProgress', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)));
      }.bind(this),
    });
  },
};
