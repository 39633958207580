<template>
  <v-row no-gutters>
    <v-col cols="auto">
      <slot></slot>
    </v-col>
    <v-col cols="auto" style="margin-top: -10px; margin-left: -10px">
      <v-row v-if="getChatStats.unreadTasks" no-gutters>
        <v-col cols="auto">
          <v-sheet color="secondary" elevation="2" style="border-radius: 15px; padding: 2px 5px">
            <v-row no-gutters>
              <v-col class="d-flex flex-row" cols="auto">
                <v-icon color="white" dense small>mdi-note-plus-outline</v-icon>
                <span style="font-size: 15px; margin-top: -3px; margin-bottom: -5px; color: white">{{
                  getChatStats.unreadTasks
                }}</span>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row v-if="getChatStats.unreadComments" no-gutters>
        <v-col cols="auto">
          <v-sheet color="secondary" elevation="2" style="border-radius: 15px; padding: 2px 5px; margin-top: 4px">
            <v-row no-gutters>
              <v-col class="d-flex flex-row" cols="auto">
                <v-icon color="white" dense small>mdi-comment-plus-outline</v-icon>
                <span style="font-size: 15px; margin-top: -3px; margin-bottom: -5px; color: white">{{
                  getChatStats.unreadComments
                }}</span>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavChatButtonBadge",
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
    }),
    ...mapGetters("chat", ["getChatStats"]),
  },
};
</script>
