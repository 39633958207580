import axios from 'axios';

export default {
  async fetchFacilities({ commit }, payload) {
    commit('setFacilitiesLoading', true);

    const response = await axios.get(`warehouse/facility/all`);
    console.debug(response.data, 'response.data');
    commit('setFacilities', response.data);
    commit('setFacilitiesLoading', false);
  },
  setArticleFacilitiesFilter({ commit }, payload) {
    commit('setArticleFacilitiesFilter', payload);
  },
};
