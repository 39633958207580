<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <template #activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon left> mdi-plus</v-icon>
        grupa
      </v-btn>
    </template>
    <v-card class="d-flex flex-column justify-space-between">
      <v-card-title>
        <span class="text-h5">Nowa grupa</span>
      </v-card-title>
      <v-card-text>
        <v-card v-if="dialog" class="d-flex flex-column justify-space-between" elevation="0">
          <v-row class="align-center justify-start" no-gutters>
            <v-col>
              <v-text-field
                v-model="newGroup.name"
                :rules="[rules.isNameEmpty(), rules.checkIfArticleGroupExists(articleGroupsDetails)]"
                label="Nazwa grupy"></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-btn text @click="dialog = false">
          <v-icon left> mdi-cancel</v-icon>
          Anuluj
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="isButtonDisabled"
          :loading="groupDetailsSubmitting"
          color="primary"
          @click="createNewArticleGroup">
          <v-icon left> mdi-home-plus-outline</v-icon>
          Utwórz grupę
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import warehouseTypes from "../../../warehouseTypes.json";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "WarehouseCreateArticleGroupDialog",
  computed: {
    ...mapGetters({
      articleGroupsDetails: "warehouse/groupsSettings/articleGroupsDetails",
      groupDetailsSubmitting: "warehouse/groupsSettings/groupDetailsSubmitting",
    }),
    isButtonDisabled() {
      const nameErrors = [
        this.rules.isNameEmpty()(this.newGroup.name),
        this.rules.checkIfArticleGroupExists(this.articleGroupsDetails)(this.newGroup.name),
      ].filter((error) => typeof error === "string");
      return nameErrors.length > 0;
    },
  },
  data: () => ({
    dialog: false,
    newGroup: {
      name: null,
    },
    rules: {
      isNameEmpty() {
        return (v) => !!v || "Nazwa nie może być pusta";
      },
      checkIfArticleGroupExists(articleGroups) {
        return (v) => !v || !articleGroups.map((v) => v.name).includes(v) || "Grupa już istnieje";
      },
    },
    warehouseTypes,
  }),
  methods: {
    ...mapActions("warehouse/groupsSettings", ["postNewArticleGroup"]),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    async createNewArticleGroup() {
      try {
        await this.postNewArticleGroup({ articleGroupData: this.newGroup });
        await this.notifyInfo("Utworzono nową grupę");
      } catch (e) {
        await this.notifyError(e);
        console.log(e);
      }
      this.dialog = false;
      this.newGroup = {
        name: null,
      };
    },
  },
};
</script>
