<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <template #activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon small> mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card v-if="dialog" class="d-flex flex-column justify-space-between">
      <v-card-title>
        <span class="text-h5">Edycja dostawcy</span>
      </v-card-title>
      <v-card-text>
        <v-card class="d-flex flex-column justify-space-between" elevation="0">
          <v-row class="align-center justify-start" no-gutters>
            <v-col>
              <v-text-field
                v-model="editedSupplier.name"
                :rules="[rules.isNameEmpty(), rules.checkIfSupplierExists(suppliers)]"
                label="Nazwa dostawcy"></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-btn text @click="dialog = false">
          <v-icon left> mdi-cancel</v-icon>
          Anuluj
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="isButtonDisabled" :loading="supplierSubmitting" color="primary" @click="editFacility">
          <v-icon left> mdi-content-save</v-icon>
          Zapisz
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "WarehouseEditSupplierDialog",
  props: {
    supplier: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    dialog: false,
    editedSupplier: null,
    rules: {
      isNameEmpty() {
        return (v) => !!v || "Nazwa nie może być pusta";
      },
      checkIfSupplierExists(suppliers) {
        return (v) => !v || !suppliers.map((v) => v.name).includes(v) || "Dostawca już istnieje";
      },
    },
  }),
  computed: {
    ...mapGetters({
      suppliers: "warehouse/suppliersSettings/articleSuppliersDetails",
      supplierSubmitting: "warehouse/suppliersSettings/supplierDetailsSubmitting",
    }),
    isButtonDisabled() {
      const nameErrors = [
        this.rules.isNameEmpty()(this.editedSupplier.name),
        this.rules.checkIfSupplierExists(this.suppliers)(this.editedSupplier.name),
      ].filter((error) => typeof error === "string");
      return nameErrors.length > 0;
    },
  },
  watch: {
    dialog(v) {
      if (v) {
        this.editedSupplier = structuredClone(this.supplier);
      }
    },
  },
  methods: {
    ...mapActions("warehouse/suppliersSettings", ["postEditedSupplier"]),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    async editFacility() {
      try {
        await this.postEditedSupplier({ supplierData: this.editedSupplier });
        await this.notifyInfo("Zapisano zmiany.");
      } catch (e) {
        await this.notifyError(e);
        console.log(e);
      }
      this.dialog = false;
    },
  },
};
</script>
