export default {
  articleGroupsDetails: (state) => {
    return state.articleGroupsDetails;
  },
  groupDetailsSubmitting: (state) => {
    return state.submittingGroupDetails;
  },
  articleGroupsDetailsLoading: (state) => {
    return state.loadingArticleGroupsDetails;
  },
};
