<template>
  <v-row no-gutters>
    <v-col class="d-flex flex-row align-center pa-1">
      <chat-filter-chip
        v-model="activeFilter"
        :filter="CHAT_TASK_FILTERS.STATUS.UNCONFIRMED"
        class="mx-1"
        icon="mdi-help-rhombus"
        icon-color="orange">
        Oczekujące
      </chat-filter-chip>
      <chat-filter-chip
        v-model="activeFilter"
        :filter="CHAT_TASK_FILTERS.STATUS.OPEN"
        class="mx-1"
        icon="mdi-play-box-outline"
        icon-color="blue">
        Otwarte
      </chat-filter-chip>
      <chat-filter-chip
        v-model="activeFilter"
        :filter="CHAT_TASK_FILTERS.STATUS.COMPLETED"
        class="mx-1"
        icon="mdi-check-circle"
        icon-color="green">
        Zakończone
      </chat-filter-chip>
      <chat-filter-chip
        v-model="activeFilter"
        :filter="CHAT_TASK_FILTERS.STATUS.REJECTED"
        class="mx-1"
        icon="mdi-minus-circle"
        icon-color="red">
        Odrzucone
      </chat-filter-chip>
    </v-col>
  </v-row>
</template>
<script>
import ChatFilterChip from "../../components/chat/ChatFilterChip.vue";
import { CHAT_TASK_FILTERS } from "../../constants";

export default {
  name: "ChatTasksFilterChipsRow",
  components: { ChatFilterChip },
  model: {
    prop: "getActiveFilter",
    event: "activateFilter",
  },
  props: {
    getActiveFilter: {},
  },
  computed: {
    CHAT_TASK_FILTERS() {
      return CHAT_TASK_FILTERS;
    },
    activeFilter: {
      get() {
        return this.getActiveFilter;
      },
      set(v) {
        this.$emit("activateFilter", v);
      },
    },
  },
};
</script>
<style scoped>
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
</style>