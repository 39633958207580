<template>
  <v-row no-gutters style="height: 100%">
    <v-col cols="12" style="height: 100%">
      <!--      user filter bar-->
      <v-row class="fixed-bar" no-gutters>
        <v-col>
          <v-row class="d-flex justify-space-between mb-6" no-gutters>
            <v-col cols="auto" style="padding: 0 13px">
              <calendar-technician-filter
                :single-select="true"
                v-if="currentUser !== null"
                :selected-ids="[currentUser.id]"
                :technicians="serviceTechnicians"
                @selectUser="(id) => selectUser(id)"
              >
                >
              </calendar-technician-filter>
            </v-col>
          </v-row>

          <v-row no-gutters style="margin: 10px 0 5px">
            <v-col v-for="(list, k, i) in userTasks" :key="k" style="padding: 0; margin: 0 10px">
              <v-row no-gutters>
                <v-col>
                  <h3>{{ k }}</h3>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--      first row-->
      <v-row ref="firstDate" no-gutters style="display: block">
        <v-col style="padding: 10px 0 0">
          <calendar-date-picker
            v-model="date"
            @fetchGivenDay="(pickerDate) => fetchGivenDay(pickerDate)"
            @today="today(0)"
          ></calendar-date-picker>

          <week-table :date="date" :users="selectedUser" :week-days="getWeekDays()" style="height: 100%">
          </week-table>
        </v-col>
      </v-row>
    </v-col>

    <PopUpMenu></PopUpMenu>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Vue from "vue";
import draggable from "vuedraggable";
import PopUpMenu from "../../components/job/PopUpMenu";
import AddJob from "../../components/job/AddJob";
import { formatDate } from "../../helper";
import CalendarDatePicker from "../../components/ui/CalendarDatePicker.vue";
import CalendarTechnicianFilter from "../../components/job/CalendarTechnicianFilter.vue";
import WeekTable from "../../components/job/WeekTable.vue";


export default {
  components: { WeekTable, CalendarTechnicianFilter, CalendarDatePicker, AddJob, PopUpMenu, draggable },
  data: () => ({
    windowHeight: window.innerHeight,
    weekday: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
    currentUser: null,
    users: null,
    menu: false,
    date: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    dataTableLoading: [false, false],
    groupedOthers: null,
    groupedOthersKeys: [],
    taskNewPriority: null,
    oldUser: null,
    otherTasksLoading: true,
    hideUsers: false,
    h0: 61,
    showUnsignedColumn: false,
  }),
  computed: {
    cGroupedOthersKeys: {
      set(newValue) {
        this.groupedOthersKeys = newValue;
      },
      get() {
        return this.groupedOthersKeys;
      },
    },
    ...mapGetters({
      menuCardWidth: "layoutHelpers/getMenuPopUpWidth",
      userTasks: "weekCalendar/getUserTasks",
      serviceTechnicians: "weekCalendar/getUsers",
      getServiceTechnician: "weekCalendar/getServiceTechnician",
    }),
    selectedUser: {
      set(newValue) {
        this.currentUser = newValue;
      },
      get() {
        return this.currentUser;
      },
    },
    firstDate: {
      set(newValue) {
        this.date = newValue;
      },
      get() {
        return this.date + " " + this.weekday[new Date(this.date).getDay()];
      },
    },
  },
  methods: {
    getWeekDays() {
      return Object.keys(this.userTasks);
    },
    async reload() {
      await this.loadWeekTasks({ date: this.date, user: this.selectedUser.id });
    },
    async loadWeekTasks(payload) {
      // this.$store.commit('layoutHelpers/addLoadingProcess', 'loadWeekTasks');
      // try {
      //   await this.$store.dispatch('weekCalendar/fetchUserVacations', payload);
      // } catch (error) {
      //   console.log(error);
      // }
      try {
        await this.$store.dispatch("weekCalendar/fetchWeekUserTasks", payload);
        // this.$store.commit('layoutHelpers/removeLoadingProcess', 'loadWeekTasks');
      } catch (error) {
        console.log(error);
      }
    },
    dayDiff(d) {
      const date1 = new Date();
      const date2 = new Date(d);
      const diffTime = date2 - date1;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    ...mapActions({ setMenuPopUp: "layoutHelpers/setMenuParams" }),
    setDataTableLoading(v, k) {
      Vue.set(this.dataTableLoading, k, v);
    },
    selectUser(id) {
      this.currentUser = this.getServiceTechnician(id);
    },
    async loadServiceTechnicianList(payload) {
      try {
        await this.$store.dispatch("weekCalendar/fetchServiceTechnicians", payload);
      } catch (error) {
        console.log(error);
      }
    },
    async loadCalendarUserTasks(payload) {
      // this.$store.commit('layoutHelpers/addLoadingProcess', 'loadWeekTasks');
      // try {
      //   await this.$store.dispatch('calendarTasks/fetchUserVacations', payload);
      // } catch (error) {
      //   console.log(error);
      // }
      try {
        await this.$store.dispatch("weekCalendar/fetchWeekUserTasks", payload);
        // this.$store.commit('layoutHelpers/removeLoadingProcess', 'loadWeekTasks');
      } catch (error) {
        console.log(error);
      }
    },
    async fetchGivenDay(date) {
      this.date = date;
      await this.loadCalendarUserTasks({
        date: formatDate(date),
        user: this.currentUser.id,
      });
      this.menu = false;
    },
    async today(i) {
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10);
      await this.fetchGivenDay(this.date, i, i);
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
  watch: {
    async selectedUser(a, b) {
      console.log(a);
      await this.loadWeekTasks({ date: this.date, user: this.selectedUser.id });
    },
  },
  async created() {
    await this.loadServiceTechnicianList();
    this.selectedUser = this.serviceTechnicians[0];
    const date1 = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10);
    this.date = date1;
    await this.loadWeekTasks({ date: date1, user: this.selectedUser.id });
  },
  mounted() {
    this.users = this.serviceTechnicians;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
</script>

<style scoped>
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 64px;
  z-index: 2;
  background-color: white;
  margin: 0;
  width: 100%;
  max-width: none;
  padding: 0;
  overflow: hidden;
}

.theme--light.v-chip:not(.v-chip--active).selectFiltersStyle {
  background-image: linear-gradient(90deg, silver 50%, transparent 50%),
    linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%),
    linear-gradient(0deg, silver 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: selectFiltersAnimation 1s infinite linear;
}

@keyframes selectFiltersAnimation {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}

/*.theme--light.v-calendar-daily .v-calendar-daily__intervals-body .v-calendar-daily__interval-text {*/
/*  color: transparent;*/
/*}*/
</style>
