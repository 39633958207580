import axios from 'axios';
import store from '../../../vuex';
import { EventSourcePolyfill } from 'event-source-polyfill';

export default {
  async logout(context) {
    // await axios.get("user/logout");
    localStorage.removeItem('token');
    await context.commit('setToken', null);
    const payload = {};
    payload.data = {
      email: null,
      name: null,
      roles: [],
    };
    await context.commit('setUserInfo', payload);
  },

  setToken(context, payload) {
    context.commit('setToken', payload);
  },

  async login(context, user) {
    console.log(store.getters['user/hasToken']);
    const response = await axios
      .post('login_check', {
        username: user.username,
        password: user.password,
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });

    if (response.data.token) {
      const token = response.data.token;
      localStorage.setItem('token', token);
      await context.commit('setToken', token);
      console.log('token po logowaniu');
      console.log();
      console.log(context.getters['hasToken']);
      await context.dispatch('loadUserInfo');
      return response.data;
    } else {
      context.commit('setToken', null);
      localStorage.setItem('token', null);
    }

    return response;
  },

  async loadUserInfo({ commit, getters, dispatch }) {
    try {
      const responseGetUserInfo = await axios.get('user/getUserInfo');
      // console.log(responseGetUserInfo)
      const userInfo = {
        email: responseGetUserInfo.data.email,
        name: responseGetUserInfo.data.name,
        roles: responseGetUserInfo.data.roles,
      };
      console.log(userInfo);
      await commit('setUserInfo', responseGetUserInfo);
      await dispatch('stream/openStream', { token: getters.getToken, userId: getters.userInfo.id }, { root: true });
      await dispatch('chat/fetchChatStats', {}, { root: true });
    } catch (e) {
      console.log(e);
    }
  },

  async fetchUsers(context) {
    const response = await axios.get('user/fetchUsers');
    // console.log('response');
    // console.log(response.data);
    if (response.status === 200) {
      context.commit('setUsers', response.data);
    }
  },
};
