import axios from 'axios';
import { compareDesc } from 'date-fns';

export default {
  async fetchArticleChanges({ commit }, { articleId }) {
    commit('setArticleChangesLoading', true);

    const response = await axios.get(`warehouse/article/${articleId}/changes`);
    console.debug(response.data, 'response.data');
    commit(
      'setArticleChanges',
      response.data.sort((a, b) => compareDesc(new Date(a.createdInfo.createdAt), new Date(b.createdInfo.createdAt)))
    );
    commit('setArticleChangesLoading', false);
  },
  addArticleChange({ commit, getters }, { articleAuditLog }) {
    commit(
      'setArticleChanges',
      [...getters.articleChanges, articleAuditLog].sort((a, b) =>
        compareDesc(new Date(a.createdInfo.createdAt), new Date(b.createdInfo.createdAt))
      )
    );
  },
};
