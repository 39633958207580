import { FiltersBasicGroup, ROLE } from './constants';
import types from './types.json';
import { differenceInDays, isAfter, isBefore, parseISO, startOfDay } from 'date-fns';

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

const flattenUserTasks = (userTasks) => {
  return Object.values(userTasks).flatMap((date) => Object.values(date).flatMap((user) => user.tasks));
};

function adjustPrioritiesAfterRemoval(tasks, removedTaskPriority) {
  return tasks.map((task) => {
    if (task.priority > removedTaskPriority) {
      return { ...task, priority: task.priority - 1 };
    }
    return task;
  });
}

function adjustPrioritiesAfterInsertion(tasks, insertedTaskPriority) {
  return tasks.map((task) => {
    if (task.priority >= insertedTaskPriority) {
      return { ...task, priority: task.priority + 1 };
    }
    return task;
  });
}

function classifyTask(task) {
  // Check if a task is overdue
  const now = startOfDay(new Date());
  let taskCutOffDate = parseISO(task.job.cutOffDate);
  const diffInDays = differenceInDays(taskCutOffDate, now);

  if (diffInDays >= -7 && diffInDays < 0) {
    return FiltersBasicGroup.OVERDUE_LESS_7;
  } else if (diffInDays < -7) {
    return FiltersBasicGroup.OVERDUE_MORE_7;
  }

  if (!task.startAt && task.userTasks.length === 0) {
    return FiltersBasicGroup.NU_ND;
  } else if (task.startAt && task.userTasks.length === 0) {
    return FiltersBasicGroup.NU_D;
  } else if (!task.startAt && task.userTasks.length > 0) {
    return FiltersBasicGroup.U_ND;
  }

  return null;
}

function isTaskMeetingFiltersCriteria({ task, filters, group = null }) {
  console.log('isTaskMeetingFiltersCriteria');
  console.log(filters);
  console.log(group);
  console.log(
    [FiltersBasicGroup.U_ND, FiltersBasicGroup.OVERDUE_LESS_7, FiltersBasicGroup.OVERDUE_MORE_7].includes(group)
  );
  const taskAssignedUsersIds = task.userTasks.map((ut) => ut.user.id);
  if (
    !group ||
    FiltersBasicGroup.U_ND === group ||
    ([FiltersBasicGroup.OVERDUE_LESS_7, FiltersBasicGroup.OVERDUE_MORE_7].includes(group) &&
      taskAssignedUsersIds.length)
  ) {
    const containsUser = filters.users.some((uId) => taskAssignedUsersIds.includes(uId));
    console.log(taskAssignedUsersIds);
    console.log(filters.users);
    console.log(containsUser);
    if (!containsUser) {
      return false;
    }
  }

  const containsTaskType = filters.taskTypes.some((tt) => task.type === tt);
  console.log(task.type);
  console.log(filters.taskTypes);
  console.log(containsTaskType);
  if (!containsTaskType) {
    return false;
  }

  console.log(filters.realizationEndDate);
  console.log(filters.realizationStartDate);
  console.log(task.startAt);

  if (
    filters.realizationEndDate &&
    task.startAt &&
    (!group || ![FiltersBasicGroup.U_ND, FiltersBasicGroup.NU_ND].includes(group))
  ) {
    const isMeetingRealizationEndDateCriteria = isAfter(new Date(filters.realizationEndDate), new Date(task.startAt));
    console.log(filters.realizationEndDate);
    console.log(task.startAt);
    console.log(isMeetingRealizationEndDateCriteria);

    if (!isMeetingRealizationEndDateCriteria) {
      return false;
    }
  }
  if (
    filters.realizationStartDate &&
    task.startAt &&
    (!group || ![FiltersBasicGroup.U_ND, FiltersBasicGroup.NU_ND].includes(group))
  ) {
    const isMeetingRealizationStartDateCriteria = isBefore(
      new Date(filters.realizationStartDate),
      new Date(task.startAt)
    );
    console.log(filters.realizationStartDate);
    console.log(task.startAt);
    console.log(isMeetingRealizationStartDateCriteria);
    if (!isMeetingRealizationStartDateCriteria) {
      return false;
    }
  }
  return true;
}

function formatDate(date) {
  let d = new Date(Date.parse(date));
  return [d.getFullYear(), padTo2Digits(d.getMonth() + 1), padTo2Digits(d.getDate())].join('-');
}

function formatDateTime(dt) {
  const t = new Date(dt)
    .toLocaleString('pl', {
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
    .split(', ');
  return t[1] + ' ' + t[0];
}

function dateWithDayOfWeek(d) {
  const weekdays = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
  return d + ' ' + weekdays[new Date(d).getDay()];
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const dates = {
  convert: function (d) {
    // Converts the date in d to a date-object. The input can be:
    //   a date object: returned without modification
    //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
    //   a number     : Interpreted as number of milliseconds
    //                  since 1 Jan 1970 (a timestamp)
    //   a string     : Any format supported by the javascript engine, like
    //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
    //  an object     : Interpreted as an object with year, month and date
    //                  attributes.  **NOTE** month is 0-11.
    return d.constructor === Date
      ? d
      : d.constructor === Array
      ? new Date(d[0], d[1], d[2])
      : d.constructor === Number
      ? new Date(d)
      : d.constructor === String
      ? new Date(d)
      : typeof d === 'object'
      ? new Date(d.year, d.month, d.date)
      : NaN;
  },
  compare: function (a, b) {
    // Compare two dates (could be of any type supported by the convert
    // function above) and returns:
    //  -1 : if a < b
    //   0 : if a = b
    //   1 : if a > b
    // NaN : if a or b is an illegal date
    // NOTE: The code inside isFinite does an assignment (=).
    return isFinite((a = this.convert(a).valueOf())) && isFinite((b = this.convert(b).valueOf()))
      ? (a > b) - (a < b)
      : NaN;
  },
  inRange: function (d, start, end) {
    // Checks if date in d is between dates in start and end.
    // Returns a boolean or NaN:
    //    true  : if d is between start and end (inclusive)
    //    false : if d is before start or after end
    //    NaN   : if one or more of the dates is illegal.
    // NOTE: The code inside isFinite does an assignment (=).
    return isFinite((d = this.convert(d).valueOf())) &&
      isFinite((start = this.convert(start).valueOf())) &&
      isFinite((end = this.convert(end).valueOf()))
      ? start <= d && d <= end
      : NaN;
  },
};

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    const x1 = formatDate(new Date(x[key]));
    (rv[x1] = rv[x1] || []).push({ task: x });
    return rv;
  }, {});
};

function getUserRole(roles) {
  if (roles.length === 0) return null;
  switch (roles[0]) {
    case ROLE.ADMIN:
      return ROLE.ADMIN;
    case ROLE.OFFICE:
      return ROLE.OFFICE;
    case ROLE.SERVICE_TECHNICIAN:
      return ROLE.SERVICE_TECHNICIAN;
    case ROLE.EXTERNAL:
      return ROLE.EXTERNAL;
    default:
      // console.log('role error');
      return null;
  }
}

function getUserRoleProps(role) {
  const result = types.userRole[role];
  if (result === undefined) {
    return {};
  }
  return result;
}

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

function getTaskTypeIcon(taskTypeName) {
  if (types.taskTypes[taskTypeName]) {
    return types.taskTypes[taskTypeName].icon;
  }
}

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.
  a.sort();
  b.sort();

  for (var i = 0; i < a.length; ++i) {
    // console.log(a[i] !== b[i])
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function hasSubArray(master, sub) {
  // console.log('master');
  // console.log(master);
  // console.log(sub);
  return sub.sort().every(
    (
      (i) => (v) =>
        (i = master.sort().indexOf(v, i) + 1)
    )(0)
  );
}

function cleanString(string) {
  if (!string) {
    return '';
  }
  return string
    .replace(/[\r\n]+/g, '')
    .replace(/\s+/g, ' ')
    .trim();
}

function deepMerge(obj1, obj2, shouldMerge = true) {
  let result = { ...obj1 };

  Object.keys(obj2).forEach(key => {
    if (shouldMerge && obj1.hasOwnProperty(key) && isObject(obj1[key]) && isObject(obj2[key])) {
      result[key] = deepMerge(obj1[key], obj2[key], shouldMerge);
    } else {
      result[key] = obj2[key];
    }
  });

  return result;
}

function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

function isValidBarcode(value) {
  // We only allow correct length barcodes
  if (!value.match(/^(\d{8}|\d{12,14})$/)) {
    return false;
  }

  const paddedValue = value.padStart(14, '0');

  let result = 0;
  for (let i = 0; i < paddedValue.length - 1; i += 1) {
    result += parseInt(paddedValue.charAt(i), 10) * ((i % 2 === 0) ? 3 : 1);
  }

  return ((10 - (result % 10)) % 10) === parseInt(paddedValue.charAt(13), 10);
}



function humanFileSize(bytes, si=true, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export {
  formatDate,
  formatDateTime,
  dates,
  groupBy,
  flattenUserTasks,
  classifyTask,
  adjustPrioritiesAfterRemoval,
  adjustPrioritiesAfterInsertion,
  dateWithDayOfWeek,
  getUserRole,
  getUserRoleProps,
  arraysEqual,
  hasSubArray,
  getKeyByValue,
  isTaskMeetingFiltersCriteria,
  cleanString,
  deepMerge,
  isValidBarcode,
  humanFileSize
};
