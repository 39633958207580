<template>
  <v-container class="pa-0 ma-0 fill-height" fluid>
    <v-row class="align-start pa-0 ma-0 overflow-auto overflow-x-hidden justify-center fill-height" no-gutters>
      <!--planning-->
      <v-col
        :cols="isMobile ? 12 : null"
        :style="{
          'max-width':
            'calc(100% - ' +
            getUnassignedFiltersWidthPX +
            ' - ' +
            getCardWidthPX * getShowUnassignedPlannedColumn +
            ' - ' +
            unassignedMargin +
            'px)',
        }"
        class="align-start pa-0 ma-0 fill-height overflow-x-auto"
        style="width: auto">
        <v-container
          class="align-start pa-0 ma-0 overflow-y-hidden"
          fluid
          style="display: flex; flex-flow: column; height: 100%">
          <!--toolbar-->
          <calendar-toolbar-desktop
            :cancel="cancel"
            :is-mobile="isMobile"
            :selected-ids="selectedUsers"
            :service-technicians="serviceTechnicians"
            style="flex: 0 1 auto; position: sticky; left: 0"
            @selectUser="(id) => selectUser(id)" />
          <v-divider style="width: 100%; position: sticky; left: 0"></v-divider>
          <!--picker-->
          <v-row no-gutters style="flex: 0 1 auto; position: sticky; left: 0; align-self: stretch">
            <v-col cols="auto">
              <calendar-date-picker
                v-model="date[0]"
                @fetchGivenDay="(pickerDate) => fetchGivenDay(pickerDate, 0)"
                @today="today(0)"></calendar-date-picker>
            </v-col>
            <v-spacer></v-spacer>
            <v-col :style="{ width: dragColumnWidth, 'min-width': getCardWidthPX }" cols="auto">
              <calendar-unassign-task-drop-area></calendar-unassign-task-drop-area>
            </v-col>
          </v-row>

          <!--draggable-->

          <calendar-draggable-table
            v-if="selectedUsers.length !== 0"
            :cancel="cancel"
            :class="dataTableLoading[0] ? 'border_loader' : null"
            :component-index="0"
            :date="date[0]"
            :loading="dataTableLoading[0]"
            :users="selectedUsers"
            :width="dragColumnWidth"
            style="flex: 1 1 auto"
            @load="(n) => setDataTableLoading(n, 0)"
            @setCancel="(n) => setCancel(n)"></calendar-draggable-table>
        </v-container>
      </v-col>

      <v-divider vertical></v-divider>
      <!--unassigned-->
      <v-col
        :class="otherTasksLoading ? 'border_loader' : null"
        :style="{ 'min-width': getCardWidthPX, 'margin-left': isMobile ? 0 : unassignedMargin + 'px' }"
        class="d-flex flex-column fill-height align-content-start flex-shrink-1"
        cols="auto">
        <v-row class="d-flex flex-row fill-height" no-gutters>
          <v-divider vertical></v-divider>
          <v-col :cols="isMobile ? 12 : 'auto'" style="display: flex; flex-flow: column; height: 100%">
            <!--filters preview TODO-->
            <v-sheet outlined rounded>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <v-col v-if="!getShowUnassignedFilters" cols="auto">
                      <v-chip :color="'white'" :text-color="'black'" label @click="switchShowUnassignedFilters">
                        <v-icon :color="unassignedFilters.showUnplanned ? 'black' : 'grey'" left>
                          mdi-calendar-expand-horizontal
                        </v-icon>
                        pokaż filtry
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
            <!--right column-->
            <v-row
              v-if="unassignedFilters.planningMode === CALENDAR_PLANNING_MODE.PLANNED"
              no-gutters
              style="flex: 1 1 auto; flex-wrap: nowrap">
              <!--planned-->
              <v-col class="d-flex flex-column flex-grow-1 flex-shrink-1" cols="auto">
                <v-row class="d-flex flex-grow-0 flex-shrink-1 pa-2 pl-0" no-gutters>
                  <v-col class="d-flex flex-row align-end">
                    <v-icon color="grey" size="24">mdi-calendar-check</v-icon>
                    <span class="pl-2">Zaplanowane</span>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!--drag-->
                <calendar-planned-tasks-drag-column
                  :cancel="cancel"
                  :date="date[0]"
                  :filters="unassignedFilters"
                  :other-tasks-loading="otherTasksLoading"
                  :style="isMobile ? '' : 'overflow-y: auto;'"
                  :users="users"
                  :width="dragColumnWidth"
                  style="flex: 1 1 auto; height: 0px; align-content: flex-start"
                  @loadTasksOutsideCalendar="loadTasksOutsideCalendar"
                  @setCancel="(n) => setCancel(n)" />
              </v-col>
            </v-row>
            <v-row v-else no-gutters style="flex: 1 1 auto; flex-wrap: nowrap">
              <!--unplanned-->
              <v-col class="d-flex flex-column flex-grow-1 flex-shrink-1" cols="auto">
                <v-row class="d-flex flex-grow-0 flex-shrink-1 pa-2 pl-0" no-gutters>
                  <v-col class="d-flex flex-row align-end">
                    <v-icon color="grey" size="24"> mdi-calendar-question</v-icon>
                    <span class="pl-2">Niezaplanowane</span>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!--drag-->
                <calendar-unsigned-tasks-drag-column
                  :cancel="cancel"
                  :date="date[0]"
                  :filters="unassignedFilters"
                  :other-tasks-loading="otherTasksLoading"
                  :style="isMobile ? '' : 'overflow-y: auto;'"
                  :users="users"
                  :width="dragColumnWidth"
                  style="flex: 1 1 auto; height: 0px; align-content: flex-start"
                  @loadTasksOutsideCalendar="loadTasksOutsideCalendar"
                  @setCancel="(n) => setCancel(n)" />
              </v-col>
            </v-row>
          </v-col>

          <!--filters-->
          <v-col v-if="!isMobile" v-show="getShowUnassignedFilters" ref="unassignedFilters" cols="auto">
            <unassigned-filters @changePlanningMode="loadTasksOutsideCalendar"></unassigned-filters>
          </v-col>
          <!--mobile-->
          <v-dialog v-else v-model="getShowUnassignedFilters">
            <v-card>
              <unassigned-filters @changePlanningMode="loadTasksOutsideCalendar"></unassigned-filters>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>
    <PopUpMenu></PopUpMenu>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CalendarDraggableTable from "../job/CalendarDraggableTable.vue";
import Vue from "vue";
import PopUpMenu from "../job/PopUpMenu.vue";
import UnassignedFilters from "./filters/UnassignedFilters.vue";
import { arraysEqual, formatDate } from "../../helper";
import CalendarUnsignedTasksDragColumn from "../job/CalendarUnsignedTasksDragColumn.vue";
import CalendarDatePicker from "../ui/CalendarDatePicker.vue";
import CalendarToolbarDesktop from "../job/CalendarToolbarDesktop.vue";
import CalendarUnassignTaskDropArea from "./CalendarUnassignTaskDropArea.vue";
import UnassignedFiltersClass from "../../store/class/UnassignedFiltersClass";
import { CALENDAR_PLANNING_MODE, UPDATE_TYPE } from "../../constants";
import CalendarPlannedTasksDragColumn from "../job/CalendarPlannedTasksDragFilteredColumn.vue";

export default {
  components: {
    CalendarPlannedTasksDragColumn,
    CalendarUnassignTaskDropArea,
    CalendarToolbarDesktop,
    CalendarDatePicker,
    CalendarUnsignedTasksDragColumn,
    UnassignedFilters,
    PopUpMenu,
    CalendarDraggableTable,
  },
  beforeRouteLeave(to, from, next) {
    this.removeListeners()
    next();
  },
  data: () => ({
    users: null,
    menu: [false, false],
    date: [],
    dataTableLoading: [false, false],
    otherTasksLoading: true,
    cancel: false,
    sheet: false,
    unassignedMargin: 32,

    updateUnassignedTaskEventCallback: null,
    removeUnassignedTaskEventCallback: null,

    updatePlanedTaskEventCallback: null,
    removePlanedTaskEventCallback: null,

    addUserTaskEventCallback: null,
    updateUserTaskEventCallback: null,
    removeUserTaskEventCallback: null,

  }),
  computed: {
    CALENDAR_PLANNING_MODE() {
      return CALENDAR_PLANNING_MODE;
    },
    ...mapGetters({
      draggingTask: "calendarTasks/getDraggingTask",
      radmaxWorkers: "calendarTasks/getRadmax",
      externalWorkers: "calendarTasks/getExternal",
      serviceTechnicians: "calendarTasks/getUsers",
    }),
    ...mapGetters("stream", ["getStream"]),
    ...mapGetters("calendarTasks", ["getCalendarSelectedUserIds"]),
    ...mapGetters("layoutHelpers", [
      "isCurrentViewRefreshing",
      "getWindowHeight",
      "getWindowWidth",
      "getUnassignedFiltersWidth",
      "getUnassignedFiltersWidthPX",
      "getCardWidthPX",
      "getCardWidth",
      "getShowUnassignedFilters",
      "getShowUnassignedPlannedColumn",
    ]),
    unassignedFilters: {
      get() {
        return this.$store.state.calendarTasks.unassignedFilters;
      },
      set(v) {
        this.$store.dispatch("calendarTasks/setUnassignedFilters", v);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    selectedUsers: {
      set(newValue) {
        this.setCalendarSelectedUserIds({ userIds: newValue });
      },
      get() {
        return this.getCalendarSelectedUserIds;
      },
    },
    dragColumnWidth() {
      return this.mediaQueryClass();
    },
  },
  watch: {
    isCurrentViewRefreshing(a, b) {
      if (a) {
        this.reload();
        this.setCalendarViewRefreshing(false);
      }
    },
    "$route.query": {
      immediate: true,
      async handler() {
        if (!this.$store.getters["calendarTasks/getUsers"].length) {
          await this.loadServiceTechnicianList();
          console.log(this.radmaxWorkers.map((x) => x.id));
          this.unassignedFilters = UnassignedFiltersClass.createDefault(this.radmaxWorkers.map((x) => x.id));
        }
        if (this.$route.query.team === "radmax") {
          this.selectedUsers = this.radmaxWorkers.map((x) => x.id);
        } else if (this.$route.query.team === "external") {
          this.selectedUsers = this.externalWorkers.map((x) => x.id);
        } else if (this.$route.query.team === "all") {
          this.selectedUsers = this.serviceTechnicians.map((x) => x.id);
        } else if (this.$route.query.users !== undefined) {
          this.selectedUsers = this.$route.query.users.split(",").map(Number);
        } else {
          this.selectedUsers = this.serviceTechnicians.map((x) => x.id);
          this.updateQueryString();
        }
        console.log(this.selectedUsers);
      },
    },
  },
  mounted() {
    if (!this.isMobile) {
      this.setUnassignedFiltersWidth(this.$refs.unassignedFilters.offsetWidth);
    }

    console.log('MOUNTED');

    this.setListeners();
  },
  async created() {
    this.date = [formatDate(new Date()), null];
    await this.loadCalendarTasks({ date: this.date[0] });

    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.cancel = true;
      }
    });
    if (this.$store.getters["calendarTasks/getUsers"].length) {
      await this.loadServiceTechnicianList();
      this.unassignedFilters = UnassignedFiltersClass.createDefault(this.radmaxWorkers.map((x) => x.id));
    }

    console.log('CREATED');
    console.log(this.getStream._listeners);

  },
  methods: {
    ...mapActions("layoutHelpers", [
      "setUnassignedFiltersWidth",
      "switchShowUnassignedFilters",
      "setCalendarViewRefreshing",
      "setDragAndDropActive"
    ]),
    ...mapActions("calendarTasks", [
      "setCalendarSelectedUserIds",
      "updateApiUserTask",
      "removeApiUserTask",
      "addApiUserTask",
      "addUnassignedTask",
      "removeUnassignedTask",
      "addPlannedTask",
      "removePlannedTask",
    ]),
    mediaQueryClass() {
      const filter_width = this.getUnassignedFiltersWidth;
      if (this.isMobile) {
        return this.getCardWidthPX;
      }
      console.log("window.innerWidth");
      const containerPadding = 24;
      const innerWidth = this.getWindowWidth - containerPadding;
      console.log(innerWidth);
      return (
        (innerWidth - filter_width - this.unassignedMargin) /
          (this.selectedUsers.length + this.getShowUnassignedPlannedColumn) +
        "px"
      );
    },
    async reload() {
      this.setDataTableLoading(true, 0);
      // this.setDataTableLoading(true, 1);
      await this.loadTasksOutsideCalendar();
      await this.loadCalendarTasks({ date: this.date[0], userIds: this.selectedUsers.join() });
      this.setDataTableLoading(false, 0);
      // this.setDataTableLoading(false, 1);
    },
    async loadCalendarTasks(payload) {
      try {
        await this.$store.dispatch("calendarTasks/fetchUserVacations", payload);
      } catch (error) {
        console.error(error);
      }
      try {
        await this.$store.dispatch("calendarTasks/fetchCalendarUserTasks", payload);
      } catch (error) {
        console.error(error);
      }
    },
    setDataTableLoading(v, k) {
      Vue.set(this.dataTableLoading, k, v);
    },
    async selectUser(id) {
      console.log("SELECT USER");
      if (Array.isArray(id)) {
        this.selectedUsers = id;
      } else if (this.selectedUsers.includes(id)) {
        this.selectedUsers.splice(this.selectedUsers.indexOf(id), 1);
      } else {
        this.selectedUsers = [...this.selectedUsers, id];
        this.setDataTableLoading(0, true);

        await this.fetchGivenDay(this.date[0], 0);
        this.setDataTableLoading(0, false);
      }
      this.updateQueryString();
    },
    async loadServiceTechnicianList(payload) {
      this.$store.commit("layoutHelpers/addLoadingProcess", "loadServiceTechnicianList");
      try {
        await this.$store.dispatch("calendarTasks/fetchServiceTechnicians", payload);
      } catch (error) {
        console.log(error);
      }
      this.$store.commit("layoutHelpers/removeLoadingProcess", "loadServiceTechnicianList");
    },
    async loadCalendarUserTasks(payload) {
      try {
        await this.$store.dispatch("calendarTasks/fetchUserVacations", payload);
      } catch (error) {}
      try {
        await this.$store.dispatch("calendarTasks/fetchCalendarUserTasks", payload);
      } catch (error) {}
    },
    async fetchGivenDay(date, nLoader) {
      Vue.set(this.date, nLoader, date);
      Vue.set(this.dataTableLoading, nLoader, true);
      await this.loadCalendarUserTasks({
        date: formatDate(date),
      });
      Vue.set(this.dataTableLoading, nLoader, false);
    },
    async today(i) {
      Vue.set(this.date, i, new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10));
      await this.fetchGivenDay(this.date[i], i);
    },
    async loadTasksOutsideCalendar() {
      this.otherTasksLoading = true;
      try {
        await this.$store.dispatch("calendarTasks/fetchTasksOutsideCalendar", {
          ...this.unassignedFilters,
          users: this.unassignedFilters.users.join(","),
          taskTypes: this.unassignedFilters.taskTypes.join(","),
          taskStatuses: this.unassignedFilters.taskStatuses.join(","),
        });
      } catch (error) {
        console.log(error);
      }
      this.otherTasksLoading = false;
    },
    setCancel(v) {
      this.cancel = v;
    },
    updateQueryString() {
      const query = {};
      if (
        arraysEqual(
          this.selectedUsers,
          this.serviceTechnicians.map((x) => x.id)
        )
      ) {
        query.team = "all";
      } else if (
        arraysEqual(
          this.selectedUsers,
          this.radmaxWorkers.map((x) => x.id)
        )
      ) {
        query.team = "radmax";
      } else if (
        arraysEqual(
          this.selectedUsers,
          this.externalWorkers.map((x) => x.id)
        )
      ) {
        query.team = "external";
      } else if (this.selectedUsers.length > 0) {
        query.users = this.selectedUsers.join(",");
      } else {
        return;
      }
      this.$router
        .replace({
          name: "home",
          query: query,
        })
        .catch((err) => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes("Avoided redundant navigation to current location")
          ) {
            // But print any other errors to the console
            console.log(err);
          }
        });
    },
    // TODO WRITE WRAPPER FOR CALENDAR
    setListeners() {
      this.addUserTaskEventCallback = this.addUserTaskEvent();
      this.updateUserTaskEventCallback = this.updateUserTaskEvent();
      this.removeUserTaskEventCallback = this.removeUserTaskEvent();
      this.updateUnassignedTaskEventCallback = this.updateUnassignedTaskEvent();
      this.removeUnassignedTaskEventCallback = this.removeUnassignedTaskEvent();
      this.updatePlannedTaskEventCallback = this.updatePlannedTaskEvent();
      this.removePlannedTaskEventCallback = this.removePlannedTaskEvent();

      this.getStream.addEventListener(UPDATE_TYPE.JOB_USER_TASK_ADD, this.addUserTaskEventCallback);
      this.getStream.addEventListener(UPDATE_TYPE.JOB_USER_TASK_UPDATE, this.updateUserTaskEventCallback);
      this.getStream.addEventListener(UPDATE_TYPE.JOB_USER_TASK_REMOVE, this.removeUserTaskEventCallback);
      this.getStream.addEventListener(UPDATE_TYPE.JOB_TASK_UNASSIGNED_UPDATE, this.updateUnassignedTaskEventCallback);
      this.getStream.addEventListener(UPDATE_TYPE.JOB_TASK_UNASSIGNED_REMOVE, this.removeUnassignedTaskEventCallback);
      this.getStream.addEventListener(UPDATE_TYPE.JOB_TASK_PLANNED_UPDATE, this.updatePlannedTaskEventCallback);
      this.getStream.addEventListener(UPDATE_TYPE.JOB_TASK_PLANNED_REMOVE, this.removePlannedTaskEventCallback);
    },
    removeListeners() {
      console.log('remove listeners');
      this.getStream.removeEventListener(UPDATE_TYPE.JOB_USER_TASK_ADD, this.addUserTaskEventCallback);
      this.getStream.removeEventListener(UPDATE_TYPE.JOB_USER_TASK_UPDATE, this.updateUserTaskEventCallback);
      this.getStream.removeEventListener(UPDATE_TYPE.JOB_USER_TASK_REMOVE, this.removeUserTaskEventCallback);
      this.getStream.removeEventListener(UPDATE_TYPE.JOB_TASK_UNASSIGNED_UPDATE, this.updateUnassignedTaskEventCallback);
      this.getStream.removeEventListener(UPDATE_TYPE.JOB_TASK_UNASSIGNED_REMOVE, this.removeUnassignedTaskEventCallback);
      this.getStream.removeEventListener(UPDATE_TYPE.JOB_TASK_PLANNED_UPDATE, this.updatePlannedTaskEventCallback);
      this.getStream.removeEventListener(UPDATE_TYPE.JOB_TASK_PLANNED_REMOVE, this.removePlannedTaskEventCallback);
    },
    updatePlannedTaskEvent() {
      return async (event) => {
        console.log("NEW -PLANNED- TASK ADD");
        console.log(JSON.parse(event.data));
        await this.addPlannedTask({ task: JSON.parse(event.data) });
      };
    },
    removePlannedTaskEvent() {
      return async (event) => {
        console.log("NEW -PLANNED- TASK REMOVE");
        console.log(JSON.parse(event.data));
        await this.removePlannedTask({ task: JSON.parse(event.data) });
      };
    },
    removeUnassignedTaskEvent() {
      return async (event) => {
        console.log("NEW -UNASSIGNED- TASK REMOVE");
        console.log(JSON.parse(event.data));
        await this.removeUnassignedTask({ task: JSON.parse(event.data) });
      };
    },
    updateUnassignedTaskEvent() {
      return async (event) => {
        console.log("NEW -UNASSIGNED- TASK ADD");
        console.log(JSON.parse(event.data));
        await this.addUnassignedTask({ task: JSON.parse(event.data) });
      };
    },
    addUserTaskEvent() {
      return async (event) => {
        console.log("NEW -USERTASK- ADD");
        console.log(JSON.parse(event.data));
        await this.setDragAndDropActive({active: false})
        await this.addApiUserTask({ userTask: JSON.parse(event.data) });
        await this.setDragAndDropActive({active: true})
      };
    },
    updateUserTaskEvent() {
      return async (event) => {
        console.log("NEW -USERTASK- UPDATE");
        console.log(JSON.parse(event.data));
        await this.setDragAndDropActive({active: false})
        await this.updateApiUserTask({ userTask: JSON.parse(event.data) });
        await this.setDragAndDropActive({active: true})
      };
    },
    removeUserTaskEvent() {
      return async (event) => {
        console.log("NEW -USERTASK- REMOVE");
        console.log(JSON.parse(event.data));
        await this.setDragAndDropActive({active: false})
        await this.removeApiUserTask({ userTask: JSON.parse(event.data) });
        await this.setDragAndDropActive({active: true})
      };
    },
  },
};

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
</script>

<style scoped>
.desktop_column {
  position: fixed;
  right: 0;
  z-index: 1;
}

.theme--light.v-chip:not(.v-chip--active).selectFiltersStyle {
  background-image: linear-gradient(90deg, silver 50%, transparent 50%),
    linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%),
    linear-gradient(0deg, silver 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: selectFiltersAnimation 1s infinite linear;
}

@keyframes selectFiltersAnimation {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}

.border_loader {
  position: relative;
  z-index: 0;
  border-radius: 10px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #2daae2;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
</style>