<template>
  <v-col style="display: flex; flex-direction: row">
    <v-btn
      v-if="fileCanBeDeleted === false && muteControl !== file.filename && file.filetype === 'video'"
      depressed
      small
      style="margin: 5px 5px 0 0"
      :disabled="check !== true || muteLoading !== false"
      @click="prepareMute(file.filename)">
      <v-icon v-if="check !== true && muteLoading === false"> mdi-volume-variant-off </v-icon>
      <v-progress-circular v-if="muteLoading === true" indeterminate size="15"></v-progress-circular>
      <v-icon v-if="check === true && muteLoading === false"> mdi-volume-high </v-icon>
    </v-btn>
    <v-btn v-if="muteControl === file.filename" depressed small style="margin: 5px 5px 0 0" @click="cancelMute">
      <v-icon> mdi-cancel </v-icon>
    </v-btn>
    <v-btn
      v-if="muteControl === file.filename"
      depressed
      small
      style="margin: 5px 5px 0 0"
      @click="muteFile(file.filename)">
      <v-icon> mdi-check </v-icon>
    </v-btn>
    <v-btn
      v-if="fileCanBeMuted === false && fileControl !== file.filename && muteLoading === false"
      depressed
      small
      style="margin: 5px 5px 0 0"
      @click="prepareFileToDelete(file.filename)">
      <v-icon> mdi-delete </v-icon>
    </v-btn>
    <v-btn v-if="fileControl === file.filename" depressed small style="margin: 5px 5px 0 0" @click="cancelDelete">
      <v-icon> mdi-cancel </v-icon>
    </v-btn>
    <v-btn v-if="fileControl === file.filename" depressed small style="margin: 5px 5px 0 0" @click="deleteFile">
      <v-icon> mdi-check </v-icon>
    </v-btn>
  </v-col>
</template>

<script>
export default {
  name: "JobNoteDeleteToolbar",
  props: {
    file: {
      type: Object,
      required: true,
    },
    isProtocol: {
      type: Boolean,
      required: false
    }
  },
  data: () => ({
    muteLoading: false,
    check: false,
    fileCanBeDeleted: false,
    fileCanBeMuted: false,
    fileControl: null,
    muteControl: null,
  }),
  mounted() {
    if (this.file.filename && this.file.filetype === "video") {
      this.checkFileAudio(this.file.filename);
    }
  },
  methods: {
    cancelDelete() {
      this.fileControl = null;
      this.fileCanBeDeleted = false;
    },
    async deleteFile() {
      if (this.isProtocol) {
        await this.$store.dispatch("jobs/deleteProtocol", {
          fileName: this.fileControl,
        });
      } else {
        await this.$store.dispatch("jobs/deleteFile", {
          fileName: this.fileControl,
        });
      }
      await this.$emit("fetch");
      this.fileControl = null;
      this.fileCanBeDeleted = false;
    },
    cancelMute() {
      this.muteControl = null;
      this.fileCanBeMuted = false;
    },
    async checkFileAudio(file) {
      let response;
      response = await this.$store.dispatch("jobs/checkFileAudio", {
        fileName: file,
      });
      this.check = response.data;
    },
    async muteFile(file) {
      this.fileCanBeMuted = false;
      this.muteControl = null;
      this.muteLoading = true;
      await this.$store
        .dispatch("jobs/muteFile", {
          fileName: file,
        })
      await this.$emit("fetch");
      await this.checkFileAudio(this.file.filename);
      this.muteLoading = false;
    },
    prepareFileToDelete(file) {
      this.fileControl = file;
      this.fileCanBeDeleted = true;
    },
    prepareMute(file) {
      this.muteControl = file;
      this.fileCanBeMuted = true;
    },
  },
};
</script>

<style scoped></style>
