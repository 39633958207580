<template>
  <border-active-v-sheet
    :is-active="selected"
    :is-unread="!task.hasUserRead.task"
    outlined
    @click.native="setSelectedTask">
    <v-row no-gutters>
      <v-col>
        <chat-task-assigned-chip :perform-by="task.performBy" />
      </v-col>
      <v-col>
        <v-row no-gutters class="d-flex flex-row justify-end">
          <v-spacer></v-spacer>
          <v-col v-if="!hideCreatedInfo" cols="auto">
            <created-modified-row
              :date="task.createdInfo.createdAt"
              :is-modified="false"
              :user="task.createdInfo.createdBy" />
            <created-modified-row
              v-if="task.modifiedInfo.lastModified"
              :date="task.modifiedInfo.lastModified"
              :is-modified="true"
              :user="task.modifiedInfo.modifiedBy" />
          </v-col>
          <chat-task-status-chip :disabled="true" :outlined="false" no-text :status="task.status" />
        </v-row>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-row flex-nowrap align-end" no-gutters>
      <v-col class="px-2 pt-3">
        <span style="word-break: break-word">{{ task.title }}</span>
      </v-col>
      <!--      <v-spacer></v-spacer>-->
      <v-col v-if="task.comments.length" cols="auto">
        {{ task.comments.length }}
        <v-icon :color="task.hasUserRead.unreadCommentId && !isChatTaskIsBeingReadComp ? 'green' : 'grey'">
          mdi-comment-text-multiple-outline
        </v-icon>
      </v-col>
    </v-row>
  </border-active-v-sheet>
</template>

<script>
import ChatTaskAssignedChip from "./ChatTaskAssignedChip.vue";
import CreatedModifiedRow from "../layout/CreatedModifiedRow.vue";
import BorderActiveVSheet from "../calendar/filters/BorderActiveVSheet.vue";
import ChatTaskStatusChip from "./ChatTaskStatusChip.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ChatTaskStatusChip,
    BorderActiveVSheet,
    CreatedModifiedRow,
    ChatTaskAssignedChip,
  },
  props: {
    hideCreatedInfo: {
      type: Boolean,
      default: false,
    },
    task: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("chat", ["isChatTaskIsBeingRead"]),
    isChatTaskIsBeingReadComp() {
      return this.isChatTaskIsBeingRead(this.task.id);
    }
  },
  methods: {
    setSelectedTask() {
      this.$emit("selected-task", this.task);
    },
  },
};
</script>