export default {
  setMenuParams(context, payload) {
    context.commit('setMenuParams', payload);
  },
  setCalendarViewRefreshing(context, payload) {
    context.commit('setCurrentViewRefreshing', payload);
  },
  setWindowHeight(context, payload) {
    context.commit('setWindowHeight', payload);
  },
  setWindowWidth(context, payload) {
    context.commit('setWindowWidth', payload);
  },
  setUnassignedFiltersWidth(context, payload) {
    context.commit('setUnassignedFiltersWidth', payload);
  },
  setShowUnassignedFilters(context, payload) {
    context.commit('setShowUnassignedFilters', payload);
  },
  switchShowUnassignedFilters(context) {
    context.commit('switchShowUnassignedFilters');
  },
  setShowUnassignedPlannedColumn(context, payload) {
    context.commit('setShowUnassignedPlannedColumn', payload);
  },
  setShowUnassignedUnplannedColumn(context, payload) {
    context.commit('setShowUnassignedUnplannedColumn', payload);
  },
  setDragAndDropActive({ commit }, { active }) {
    commit('setDragAndDropActive', active);
  },
};
