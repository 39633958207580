<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <template #activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon left> mdi-plus </v-icon>
        Dodaj Użytkownika
      </v-btn>
    </template>

    <v-card class="d-flex flex-column justify-space-between" min-height="650">
      <v-card-title>
        <span class="text-h5">Nowy użytkownik</span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <form-row-with-icon icon="mdi-account-box">
            <create-new-user-field-name ref="nameRef" v-model="requestData.name" label="Nazwa" type="name" />
          </form-row-with-icon>

          <form-row-with-icon :color="requestData.color" icon="mdi-circle" text="Kolor">
            <v-color-picker v-model="requestData.color" canvas-height="50" hide-inputs></v-color-picker>
          </form-row-with-icon>

          <form-row-with-icon icon="mdi-at">
            <create-new-user-field-name ref="emailRef" v-model="requestData.email" label="Email" type="email" />
          </form-row-with-icon>

          <form-row-with-icon icon="mdi-lock-question">
            <create-new-user-field-name
              ref="passwordRef"
              v-model="requestData.password"
              label="Hasło"
              type="password"
            />
          </form-row-with-icon>

          <form-row-with-icon icon="mdi-lock-question">
            <create-new-user-field-name
              ref="confirmPasswordRef"
              v-model="requestData.confirmPassword"
              label="Powtórz hasło"
              :repeat="requestData.password"
              type="confirmPassword"
            />
          </form-row-with-icon>

          <form-row-with-icon icon="mdi-account-question" text="Typ konta">
            <user-role-selector v-model="requestData.role"></user-role-selector>
          </form-row-with-icon>

          <form-row-with-icon icon="mdi-table-eye" text="Widoczny w planowaniu">
            <chip-switch v-model="requestData.planVisibility" />
          </form-row-with-icon>

          <form-row-with-icon icon="mdi-table-key" text="Przeplanowywanie zadań">
            <chip-switch v-model="requestData.canReplan" />
          </form-row-with-icon>
        </v-form>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-btn text @click="dialog = false">
          <v-icon left> mdi-cancel </v-icon>
          Anuluj
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="buttonDisabled()" color="primary" @click="handleSubmit">
          <v-icon left> mdi-account-plus </v-icon>
          Utwórz
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserRoleSelector from "../layout/UserRoleSelector.vue";
import { mapActions } from "vuex";
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import ChipSwitch from "./ChipSwitch.vue";
import CreateUserClass from "../../store/class/CreateUserClass";
import CreateNewUserFieldName from "./ValidatedTextField.vue";

export default {
  name: "AddUser",
  components: { CreateNewUserFieldName, ChipSwitch, FormRowWithIcon, UserRoleSelector },
  data: () => ({
    dialog: false,
    requestData: CreateUserClass.createDefault(),
  }),
  computed: {
    showLoading() {
      return "loading";
    },
  },
  methods: {
    buttonDisabled() {
      let nErrors = ["unchecked"];
      if (this.$refs.nameRef && this.$refs.emailRef && this.$refs.passwordRef && this.$refs.confirmPasswordRef) {
        nErrors = [
          ...this.$refs.nameRef.getNameError(),
          ...this.$refs.emailRef.getNameError(),
          ...this.$refs.passwordRef.getNameError(),
          ...this.$refs.confirmPasswordRef.getNameError(),
        ];
      }
      return nErrors.length > 0;
    },
    ...mapActions({ createNewUser: "users/createNewUser" }),

    async handleSubmit() {
      await this.createNewUser(this.requestData);
      this.$emit("refresh");
      this.dialog = false;
      this.requestData = CreateUserClass.createDefault();
    },
  },
};
</script>

<style>
.v-color-picker__dot {
  display: none !important;
}
</style>
