<template>
  <div>
    <v-navigation-drawer v-if="isRoleAdminOrOffice" v-model="drawer" app :color="uiColor">
      <nav-button path="/chat" icon="mdi-forum-outline" label="Czat" />
      <v-divider class="mx-16" />
      <nav-button path="/" icon="mdi-calendar-cursor" label="Planowanie" />
      <nav-button path="/?team=radmax" icon="mdi-account-group" label="Pracownicy Radmax" child />
      <nav-button path="/?team=external" icon="mdi-account-supervisor-outline" label="Pracownicy zew." child />
      <nav-button path="/week" icon="mdi-calendar-end" label="Podgląd tygodnia" />
      <v-divider />
      <nav-button path="/warehouse" icon="mdi-warehouse" label="Magazyn" />
      <nav-button path="/warehouse/settings" icon="mdi-table-cog" label="Ustawienia magazynu" child />
      <v-divider />
      <nav-button path="/jobs" icon="mdi-file-tree" label="Zlecenia" />
      <nav-button
        path="/jobs?status=open&taskType=workshop&taskStatus=open"
        icon="mdi-garage-alert-variant"
        label="Na warsztacie"
        child />
      <nav-button
        path="/jobs?status=open&taskType=overview&taskStatus=open"
        icon="mdi-shopping-search"
        label="Przeglądy"
        child />
      <nav-button
        path="/jobs?status=open&taskType=parts_order&taskStatus=open"
        icon="mdi-robot-industrial"
        label="Oczekujące na części"
        child />
      <nav-button
        path="/jobs?status=open&taskType=modernization&taskStatus=open"
        icon="mdi-puzzle-plus-outline"
        label="Modernizacje"
        child />
      <nav-button
        path="/jobs?status=open&taskType=defect&taskStatus=open"
        icon="mdi-thermometer-alert"
        label="Usterki"
        child />
      <nav-button
        path="/jobs?status=open&taskType=installation&taskStatus=open"
        icon="mdi-wrench"
        label="Montaż"
        child />
      <nav-button
        path="/jobs?status=open&taskType=overview_zero&taskStatus=open"
        icon="mdi-select-search"
        label="Przeglądy zerowe"
        child />
      <v-divider class="mx-16" />
      <nav-button path="/jobs?team=radmax" icon="mdi-account-group" label="Pracownicy Radmax" child />
      <nav-button path="/jobs?team=external" icon="mdi-account-supervisor-outline" label="Pracownicy zew." child />
      <v-divider v-if="isAdmin" />
      <nav-button v-if="isAdmin" path="/customers" icon="mdi-card-account-details-outline" label="Klienci" />
      <v-divider />
      <nav-button path="/vacation" icon="mdi-account-clock" label="Urlopy" />
      <v-divider v-if="isAdmin" />
      <nav-button v-if="isAdmin" path="/bin" icon="mdi-delete" label="Kosz" />
      <v-divider v-if="isAdmin" />
      <nav-button v-if="isAdmin" path="/admin" icon="mdi-cog" label="Administracja" />
      <v-divider />
      <nav-button path="/user" :icon="userIcon" label="Moje konto" />
      <v-divider />
      <nav-button icon="mdi-logout" right label="Wyloguj" @click="logout" />
    </v-navigation-drawer>
    <v-app-bar app flat :color="uiColor">
      <v-container v-if="isRoleAdminOrOffice" fluid>
        <v-row>
          <nav-button icon="mdi-menu" @click="toggleDrawer" />
          <nav-button path="/" icon="mdi-calendar-cursor" label="Planowanie" hide-label-on-mobile />
          <nav-chat-button-badge>
            <nav-button path="/chat" icon="mdi-forum-outline" label="Czat" hide-label-on-mobile />
          </nav-chat-button-badge>
          <v-spacer />
          <v-icon class="mx-3" :color="getStream.readyState ? 'green' : 'red'" v-if="['home', 'chat', 'warehouseArticleList', 'warehouseArticleDetails'].includes($route.name)">
            {{ getStream.readyState ?'mdi-lan-connect' : 'mdi-lan-disconnect'}}
          </v-icon>
          <add-job v-if="['home'].includes($route.name)"></add-job>
          <nav-button path="/user" :icon="userIcon" :label="userName" class="mx-3 d-md-flex d-none" />
          <nav-button icon="mdi-logout" right label="Wyloguj" hide-label-on-mobile @click="logout" />
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row class="pr-3">
          <v-spacer />
          <nav-button path="/login" icon="mdi-login" label="Zaloguj" />
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavButton from "./NavButton";
import { ROLE } from "../../constants";
import AddJob from "../job/AddJob.vue";
import NavChatButtonBadge from "./NavChatButtonBadge.vue";

export default {
  name: "NavOffice",
  components: { NavChatButtonBadge, AddJob, NavButton },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
      await this.$router.replace({ name: "login" });
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      userRole: "user/getUserRole",
      isAdmin: "user/isAdmin",
    }),
    ...mapGetters("chat", ["getChatStats"]),
    ...mapGetters("stream", ["getStream"]),
    isRoleAdminOrOffice() {
      return [ROLE.ADMIN, ROLE.OFFICE].includes(this.userRole);
    },
    userName() {
      return `${this.userInfo.name}`;
    },
    userIcon() {
      if (this.userRole === ROLE.ADMIN) {
        return "mdi-shield-account";
      }
      return "mdi-account";
    },
    uiColor() {
      return "light-blue lighten-5";
    },
  },
};
</script>