import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      token: null,
      userInfo: {
        email: null,
        name: null,
        roles: [],
        color: null,
        id: null,
      },
      users: [],
    };
  },
  mutations,
  actions,
  getters,
};
