<template>
  <v-card elevation="1" style="margin-top: 10px">
    <v-card-text style="padding-bottom: 5px">
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <created-modified-row
            :date="task.createdInfo.createdAt"
            :is-modified="false"
            :user="task.createdInfo.createdBy" />
          <created-modified-row :date="task.modifiedInfo.lastModified" :user="task.modifiedInfo.modifiedBy" />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :disabled="!isEditing"
            min-width="auto"
            offset-y
            transition="scale-transition">
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="getDate"
                :append-icon="isEditing ? 'mdi-close-circle-outline' : null"
                :prepend-icon="getDate === 'niezaplanowane' ? 'mdi-calendar-remove' : 'mdi-calendar'"
                :solo="!isEditing"
                flat
                hide-details
                readonly
                style="font-size: 1.8em"
                v-bind="attrs"
                v-on="on"
                @click:append="setDate = null"></v-text-field>
            </template>
            <v-date-picker
              v-model="setDate"
              :first-day-of-week="1"
              show-adjacent-months
              @input="menu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-space-between" cols="12" md="6">
          <v-row class="d-flex justify-end" no-gutters>
            <v-col class="d-flex flex-column" cols="auto">
              <job-task-status-selector-chip
                :current-task-status="isEditing ? editingTask.status : task.status"
                :disabled="isEditing"
                bottom
                @changeStatus="(newTaskStatus) => saveTaskStatus(newTaskStatus)">
              </job-task-status-selector-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <template v-if="isEditing">
        <form-row-with-icon class="mt-2" icon="mdi-account-group">
          <v-chip
            v-for="item in radmaxWorkers"
            :key="item.id"
            :color="selectedWorkersListEditing.find((v) => v.id === item.id) !== undefined ? 'grey' : 'transparent'"
            :outlined="selectedWorkersListEditing.find((v) => v.id === item.id) !== undefined"
            :style="{ opacity: selectedWorkersListEditing.find((v) => v.id === item.id) === undefined ? '0.5' : '1' }"
            class="ma-1"
            label
            text-color="black"
            @click="handleSelectedWorkers(item)">
            <v-icon :color="item.color" left> mdi-circle</v-icon>
            {{ item.name }}
          </v-chip>
        </form-row-with-icon>
        <form-row-with-icon class="mb-2" icon="mdi-account-supervisor-outline">
          <v-chip
            v-for="item in externalWorkers"
            :key="item.id"
            :color="selectedWorkersListEditing.find((v) => v.id === item.id) !== undefined ? 'grey' : 'transparent'"
            :outlined="selectedWorkersListEditing.find((v) => v.id === item.id) !== undefined"
            :style="{ opacity: selectedWorkersListEditing.find((v) => v.id === item.id) === undefined ? '0.5' : '1' }"
            class="ma-1"
            label
            text-color="black"
            @click="handleSelectedWorkers(item)">
            <v-icon :color="item.color" left> mdi-circle</v-icon>
            {{ item.name }}
          </v-chip>
        </form-row-with-icon>
      </template>

      <template v-else>
        <form-row-with-icon v-if="assignedUsers.length === 0" icon="mdi-account-group">
          <p class="mx-4">brak przypisanych osób</p>
        </form-row-with-icon>
        <template v-else>
          <form-row-with-icon v-if="intersect(assignedUsers, radmaxWorkers).length" icon="mdi-account-group">
            <v-chip
              v-for="item in intersect(assignedUsers, radmaxWorkers)"
              :key="item.id"
              color="grey"
              outlined
              class="ma-1"
              label
              text-color="black">
              <v-icon :color="item.color" left> mdi-circle</v-icon>
              {{ item.name }}
            </v-chip>
          </form-row-with-icon>
          <form-row-with-icon
            v-if="intersect(assignedUsers, externalWorkers).length"
            icon="mdi-account-supervisor-outline">
            <v-chip
              v-for="item in intersect(assignedUsers, externalWorkers)"
              :key="item.id"
              color="grey"
              outlined
              class="ma-1"
              label
              text-color="black">
              <v-icon :color="item.color" left> mdi-circle</v-icon>
              {{ item.name }}
            </v-chip>
          </form-row-with-icon>
        </template>
      </template>

      <v-row no-gutters>
        <v-col style="max-width: 30px">
          <v-icon size="20"> mdi-text-short</v-icon>
        </v-col>
        <v-col>
          <template v-if="isEditing">
            <v-chip
              v-for="(tt, k_tt) in types.taskTypes"
              :key="'status' + task.id + k_tt"
              :color="editingTask.type === k_tt ? 'grey' : 'transparent'"
              :outlined="editingTask.type === k_tt"
              :style="{ opacity: editingTask.type !== k_tt ? '0.5' : '1' }"
              class="ml-3"
              label
              style="margin-right: 5px; margin-bottom: 5px"
              text-color="black"
              @click="editingTask.type = k_tt">
              <v-icon color="black" left>
                {{ tt.icon }}
              </v-icon>
              {{ tt.name }}
            </v-chip>
          </template>
          <v-chip
            v-else
            class="ml-3"
            color="transparent"
            label
            style="margin-right: 5px; margin-bottom: 5px"
            text-color="black">
            <v-icon color="black" left>
              {{ getJobTaskIcon(task.type) }}
            </v-icon>
            {{ getJobTaskName(task.type) }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col style="max-width: 30px">
          <v-icon size="20"> mdi-text-box-search</v-icon>
        </v-col>
        <v-col>
          <template v-if="!isEditing">
            <p v-if="task.description === null" class="mx-3">brak opisu</p>
            <p
              v-else
              class="mx-4"
              style="color: black"
              v-html="task.description"></p>
          </template>
          <text-editor v-else v-model="editingTask.description" :text-limit="textAreaMaxLength"></text-editor>
        </v-col>
      </v-row>

      <v-row v-if="canEdit">
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end align-center">
          <v-btn v-show="!isEditing" depressed small @click="startEditing">
            <v-icon> mdi-pencil</v-icon>
          </v-btn>
          <v-btn v-show="isEditing" depressed small style="margin-right: 5px" @click="cancelEditing">
            <v-icon left> mdi-cancel</v-icon>
            Anuluj
          </v-btn>
          <template v-if="userRole === ROLE.ADMIN">
            <v-btn
              v-if="taskCanBeDeleted === false"
              v-show="isEditing"
              depressed
              small
              style="margin-right: 5px"
              @click="prepareTaskToDelete">
              <v-icon left> mdi-delete</v-icon>
              Usuń
            </v-btn>
            <v-btn v-else v-show="isEditing" depressed small style="margin-right: 5px" @click="saveDeleteTask">
              <v-icon left> mdi-check</v-icon>
              Potwierdź usunięcie
            </v-btn>
          </template>
          <v-btn
            v-if="taskCanBeDeleted === false"
            v-show="isEditing"
            :disabled="isEditing && validateTask()"
            depressed
            small
            style="margin-right: 5px"
            @click="saveTask">
            <v-icon left> mdi-content-save</v-icon>
            Zapisz
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import types from "../../types.json";
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import TextEditor from "../textEditor/TextEditor.vue";
import { ROLE } from "../../constants";
import {formatDate} from "../../helper";
import CreatedModifiedRow from "../layout/CreatedModifiedRow.vue";
import JobTaskStatusSelectorChip from "./JobTaskStatusSelectorChip.vue";
import { format } from "date-fns";
import { pl } from 'date-fns/locale';

export default {
  components: { JobTaskStatusSelectorChip, CreatedModifiedRow, TextEditor, FormRowWithIcon },
  props: {task: {}, job: {}, canEdit: {}},
  data() {
    return {
      types,
      isEditing: false,
      selectedWorkersListEditing: [],
      taskStatuses: types.taskStatusesFinal,
      menu: false,
      taskCanBeDeleted: false,
      currentTaskData: null,
      editingTask: {},
      textAreaMaxLength: 10000,
    };
  },
  methods: {
    ...mapActions("tasks", ["changeTaskStatus", "editTask", "sortTasksByDate"]),
    ...mapActions("bin", ["deleteTask"]),
    getJobTaskIcon(t) {
      if (types.taskTypes[t]) {
        return types.taskTypes[t].icon;
      }
    },
    getJobTaskName(t) {
      if (types.taskTypes[t]) {
        return types.taskTypes[t].name;
      }
    },
    getStatusIcon(t) {
      if (this.taskStatuses[t]) {
        return this.taskStatuses[t].icon;
      }
    },
    getStatusIconColor(t) {
      if (this.taskStatuses[t]) {
        return this.taskStatuses[t].color;
      }
    },
    handleSelectedWorkers(item) {
      if (!this.isEditing) {
        return;
      }
      const i = this.selectedWorkersListEditing.findIndex((v) => v.id === item.id);
      if (i !== -1) {
        return this.selectedWorkersListEditing.splice(i, 1);
      }
      return this.selectedWorkersListEditing.push(item);
    },
    normalize(user) {
      const { color, id, name } = user;
      return { color, id, name };
    },
    formatDate,
    intersect(a, b) {
      const normalizedA = a.map(this.normalize);
      const normalizedB = b.map(this.normalize);
      const setB = new Set(normalizedB.map((user) => user.id));
      return normalizedA.filter((user) => setB.has(user.id));
    },
    cancelEditing() {
      this.editingTask = structuredClone(this.task);
      this.selectedWorkersListEditing = [...this.assignedUsers];
      this.taskCanBeDeleted = false;
      this.isEditing = !this.isEditing;
    },
    startEditing() {
      this.editingTask = structuredClone(this.task);
      this.selectedWorkersListEditing = [...this.assignedUsers];
      this.isEditing = true;
    },
    async saveTaskStatus(newStatus) {
      await this.changeTaskStatus({
        status: newStatus,
        task: this.task,
      });
      this.editingTask.status = newStatus;
    },
    async saveTask() {
      this.isEditing = false;
      this.editingTask["userTasks"] = this.selectedWorkersListEditing;
      this.editingTask.description = this.editingTask.description === "" ? null : this.editingTask.description;
      await this.editTask({
        taskId: this.task.id,
        taskData: this.editingTask,
      });
      console.log(this.editingTask.type);
    },
    prepareTaskToDelete() {
      this.taskCanBeDeleted = true;
    },
    async saveDeleteTask() {
      await this.deleteTask({
        taskId: [this.task.id],
        operation: "move",
      });
    },
    validateTask() {
      if (!this.editingTask.description) {
        return false;
      }
      return this.editingTask.description && this.editingTask.description.length > this.textAreaMaxLength;
    },
  },
  computed: {
    ROLE() {
      return ROLE;
    },
    ...mapGetters({
      userRole: "user/getUserRole",
      radmaxWorkers: "calendarTasks/getRadmax",
      externalWorkers: "calendarTasks/getExternal",
    }),
    getDate: {
      get() {
        if (!this.isEditing) {
          return this.task.startAt ? format(new Date(this.task.startAt), 'EEEE, dd.MM.yyyy', { locale: pl }) : "niezaplanowane";
        }
        return this.editingTask.startAt ?  format(new Date(this.editingTask.startAt), 'EEEE, dd.MM.yyyy', { locale: pl }) : "niezaplanowane";
      },
      set(v) {
        this.editingTask.startAt = v;
      },
    },
    setDate: {
      get() {
        return this.editingTask.startAt ? format(new Date(this.editingTask.startAt), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd');
      },
      set(v) {
        this.editingTask.startAt = v;
      },
    },
    assignedUsers() {
      return this.task.userTasks.map((ut) => ut.user);
    },
  },

};
</script>