export default {
  groupSubmitting: (state) => {
    return state.submittingGroup;
  },
  articleGroups: (state) => {
    return state.articleGroups;
  },
  getArticleGroupById: (state) => (groupId) => {
    const groupIdx = state.articleGroups.findIndex((value) => value.id === groupId);
    if (groupIdx === -1) {
      return null;
    }
    return state.articleGroups[groupIdx];
  },
  articleGroupsLoading: (state) => {
    return state.loadingArticleGroups;
  },
  articleGroupFilter: (state) => {
    return state.articleGroupFilter;
  },
};
