<template>
  <v-dialog v-model="showDialog" max-width="50%" persistent>
    <v-card class="d-flex flex-column justify-space-between">
      <v-card-title>
        <span class="text-h5">Edycja klienta</span>
      </v-card-title>
      <v-card-text>
        <form-row-with-icon v-if="[DIALOG.EDIT_CUSTOMER, DIALOG.EDIT_LOCATION].includes(editType)" icon="mdi-store">
          <v-text-field
            v-model="editedCustomer.customerName"
            :disabled="editType === DIALOG.EDIT_LOCATION"
            clearable
            label="Nazwa klienta"
          ></v-text-field>
        </form-row-with-icon>

        <form-row-with-icon v-if="editType === DIALOG.EDIT_LOCATION" icon="mdi-map-marker">
          <v-text-field v-model="editedCustomer.locationName" clearable label="Lokalizacja klienta"></v-text-field>
        </form-row-with-icon>
        <p v-else-if="editType === DIALOG.DELETE_CUSTOMER">
          Czy na pewno chcesz usunąc klienta
          {{ editedCustomer.customerName }}
          wraz z wszystkimi jego lokalizacjami?
        </p>
        <p v-else-if="editType === DIALOG.DELETE_LOCATION">
          Czy na pewno chcesz usunąc lokalizację {{ editedCustomer.locationName }}
        </p>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-btn text @click="$emit('refresh')">
          <v-icon left> mdi-cancel </v-icon>
          Anuluj
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="handleSubmit()">
          <v-icon left>
            {{ [DIALOG.EDIT_CUSTOMER, DIALOG.EDIT_LOCATION].includes(editType) ? "mdi-content-save" : "mdi-delete" }}
          </v-icon>
          {{ [DIALOG.EDIT_CUSTOMER, DIALOG.EDIT_LOCATION].includes(editType) ? "Zapisz" : "Usuń" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CustomComboboxSingle from "../layout/CustomComboboxSingle.vue";
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import { DIALOG } from "../../constants";
import { mapActions } from "vuex";

export default {
  name: "CustomerEditDialog",
  components: { FormRowWithIcon },
  props: {
    editedCustomer: {},
    editType: {},
  },
  emits: ["refresh"],
  data: () => ({
    showDialog: false,
  }),
  computed: {
    DIALOG() {
      return DIALOG;
    },
  },
  methods: {
    ...mapActions({ editCustomer: "customers/editCustomer" }),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    async handleSubmit() {
      let message = "";
      let error = "";
      let response;
      response = await this.editCustomer({ customerData: this.editedCustomer, action: this.editType });

      switch (this.editType) {
        case DIALOG.EDIT_CUSTOMER:
          message = "Zaktualizowano nazwę klienta";
          error = "Nie można zmienić nazwy klienta";
          break;
        case DIALOG.DELETE_CUSTOMER:
          message = "Usunięto klienta";
          error = "Nie można usunąć klienta";
          break;
        case DIALOG.EDIT_LOCATION:
          message = "Zaktualizowano lokalizację";
          error = "Nie można zmienić lokalizacji";
          break;
        case DIALOG.DELETE_LOCATION:
          message = "Usunięto lokalizację";
          error = "Nie można usunąć lokalizacji";
          break;
      }
      if (response) {
        await this.notifyInfo(message);
        this.$emit("refresh");
        this.showDialog = false;
      } else {
        await this.notifyError(error);
      }
    },
  },
  watch: {
    editType(n, o) {
      this.showDialog = n !== null;
    },
  },
};
</script>
