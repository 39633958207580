<template>
  <v-card-text>
    <v-row>
      <v-col style="max-width: 30px">
        <v-icon size="20"> mdi-text-recognition </v-icon>
      </v-col>
      <v-col>
        {{ job.externalJobNumber !== null ? job.externalJobNumber : "Brak numeru zewnętrznego zlecenia" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col style="max-width: 30px">
        <v-icon size="20"> mdi-map-marker </v-icon>
      </v-col>
      <v-col>
        {{ job.location !== null ? job.location.name : "Brak adresu" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col style="max-width: 30px">
        <v-icon size="20"> mdi-text-box-search </v-icon>
      </v-col>
      <v-col>
        {{ job.title }}
      </v-col>
    </v-row>
    <v-row>
      <v-col style="max-width: 30px">
        <v-icon size="20"> mdi-timer-outline </v-icon>
      </v-col>
      <v-col>
        {{ job.cutOffDate === null ? "Brak daty granicznej" : formatDate(job.cutOffDate) }}
      </v-col>
    </v-row>
    <v-row>
      <v-col style="max-width: 30px">
        <v-icon size="20"> {{ types.jobSettlementStatus[job.settlementStatus.status].icon }} </v-icon>
      </v-col>
      <v-col cols="auto">
        {{ types.jobSettlementStatus[job.settlementStatus.status].name }}
      </v-col>
      <v-col cols="auto">
        <v-chip
          color="transparent"
          label
          small
          style="margin-right: 5px; margin-bottom: 5px"
          text-color="black">
          <v-icon
            small
            :color="job.settlementStatus.modifiedBy !== null ? job.settlementStatus.modifiedBy.color : 'grey'"
            left>
            {{ job.settlementStatus.modifiedBy !== null ? 'mdi-circle' : "mdi-robot" }}
          </v-icon>
          {{ job.settlementStatus.modifiedBy !== null ? job.settlementStatus.modifiedBy.name : "System" }}
        </v-chip>
        {{ formatDateTime(job.settlementStatus.modifiedAt) }}
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col style="max-width: 30px">
        <v-icon size="20"> mdi-arrow-right-circle </v-icon>
      </v-col>
      <v-col>
        {{ types.jobStatuses[job.status].name }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-col cols="auto" >
        <created-modified-row :date="job.createdInfo.createdAt" :user="job.createdInfo.createdBy" :is-modified='false' />
        <created-modified-row :date="job.modifiedInfo.lastModified" :user="job.modifiedInfo.modifiedBy" />
      </v-col>
    </v-row>
  </v-card-text>
</template>
<script>
import { formatDate, formatDateTime } from "../../helper";
import types from "../../types.json";
import CreatedModifiedRow from "../layout/CreatedModifiedRow.vue";

export default {
  name: "JobCardDetails",
  components: { CreatedModifiedRow },
  props: {
    job: {},
  },
  data: () => ({
    types,
  }),
  methods: { formatDateTime, formatDate },
};
</script>
