<template>
  <v-row no-gutters>
    <v-col>
      <v-row v-if="facilitiesDetailsLoading" no-gutters>
        <v-spacer />
        <v-col>
          <v-progress-circular></v-progress-circular>
        </v-col>
        <v-spacer />
      </v-row>
      <template v-else>
        <v-row no-gutters>
          <v-col>
            <v-data-table
              :headers="facilitiesHeaders"
              :items="facilitiesDetails"
              :items-per-page="-1"
              class="elevation-1"
              hide-default-footer
              item-key="id">
              <template #top>
                <v-row no-gutters>
                  <v-spacer></v-spacer>
                  <v-col class="pr-3" cols="auto">
                    <warehouse-create-facility-dialog />
                  </v-col>
                </v-row>
              </template>
              <template #item.type="{ item }">
                {{ warehouseTypes.facilityType[item.type].name }}
              </template>
              <template #item.actions="{ item }">
                <warehouse-edit-facility-dialog :facility="item" />
                <v-tooltip :disabled="item.articlesInFacility === 0" bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      <warehouse-delete-facility-dialog :facility="item" />
                    </span>
                  </template>
                  <span>Nie można usunąć z powodu przypisanych artykułów.</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import WarehouseCreateFacilityDialog from "./WarehouseCreateFacilityDialog.vue";
import { mapActions, mapGetters } from "vuex";
import warehouseTypes from "../../../warehouseTypes.json";
import WarehouseEditFacilityDialog from "./WarehouseEditFacilityDialog.vue";
import WarehouseDeleteFacilityDialog from "./WarehouseDeleteFacilityDialog.vue";

export default {
  name: "WarehouseFacilitiesManagement",
  components: {
    WarehouseDeleteFacilityDialog,
    WarehouseEditFacilityDialog,
    WarehouseCreateFacilityDialog,
  },
  computed: {
    ...mapGetters({
      facilitiesDetails: "warehouse/facilitiesSettings/facilitiesDetails",
      facilitiesDetailsLoading: "warehouse/facilitiesSettings/facilitiesDetailsLoading",
    }),
  },
  async created() {
    await this.fetchFacilitiesStats();
  },
  data: () => ({
    facilitiesHeaders: [
      {
        text: "Magazyn",
        value: "name",
        sortable: false,
      },
      {
        text: "Typ",
        value: "type",
        sortable: false,
      },
      {
        text: "Liczba artykułów",
        value: "articlesInFacility",
        sortable: false,
      },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    warehouseTypes,
  }),
  methods: {
    ...mapActions("warehouse/facilitiesSettings", ["fetchFacilitiesStats"]),
  },
};
</script>
