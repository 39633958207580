import axios from 'axios';

export default {
  async fetchCustomerLocations({ commit }, { customerId }) {
    commit('setCustomerLocationsLoading', { isLoading: true });

    const response = await axios.get(`customer/${customerId}/locations`);
    commit('setCustomerLocations', response.data);
    commit('setCustomerLocationsLoading', { isLoading: false });
  },
  resetCustomerLocations({ commit }, _) {
    commit('setCustomerLocationsLoading', { isLoading: true });

    commit('setCustomerLocations', []);
    commit('setCustomerLocationsLoading', { isLoading: false });
  },
  setCustomerLocationsLoading({ commit }, { isLoading }) {
    commit('setCustomerLocationsLoading', { isLoading });
  },
};
