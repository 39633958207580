<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          v-model="newArticle.name"
          :autofocus="isEditionActive"
          :class="{ 'no-bottomline': !isEditionActive }"
          :error-messages="formErrors.name"
          :flat="!isEditionActive"
          :hide-details="!isEditionActive"
          :loading="articleNameValidationSubmitting"
          :readonly="!isEditionActive"
          :rules="[rules.isNameEmpty()]"
          label="Nazwa artykułu"
          @blur="cleanName"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          v-model="newArticle.originalProductNumber"
          :class="{ 'no-bottomline': !isEditionActive }"
          :flat="!isEditionActive"
          :hide-details="!isEditionActive"
          :readonly="!isEditionActive"
          label="Kod producenta"
          @blur="cleanOriginalProductNumber"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols>
        <v-text-field
          v-model="newArticle.quantityPerPackage"
          :class="{ 'no-bottomline': !isEditionActive }"
          :flat="!isEditionActive"
          :hide-details="!isEditionActive"
          :readonly="!isEditionActive"
          :rules="[rules.isQuantityPerPackageNotPositive()]"
          label="Ilość w opakowaniu"
          type="number"
          @blur="setDefaultQuantityPerPackage"
          @focus="removeDefaultQuantityPerPackage">
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="newArticle.unit"
          :class="{ 'no-bottomline': !isEditionActive }"
          :flat="!isEditionActive"
          :hide-details="!isEditionActive"
          :items="availableUnits"
          :readonly="!isEditionActive"
          item-text="name"
          item-value="key"
          label="Jednostka"></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols>
        <v-text-field
          v-model="newArticle.price"
          :class="{ 'no-bottomline': !isEditionActive }"
          :flat="!isEditionActive"
          :hide-details="!isEditionActive"
          :readonly="!isEditionActive"
          :rules="[rules.isPriceNegative()]"
          label="Cena zakupu"
          type="number"
          @blur="setDefaultPrice"
          @focus="removeDefaultPrice">
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="newArticle.currency"
          :class="{ 'no-bottomline': !isEditionActive }"
          :flat="!isEditionActive"
          :hide-details="!isEditionActive"
          :items="['PLN']"
          :readonly="!isEditionActive"
          label="Waluta"
          return-object></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols>
        <v-text-field
          v-model="newArticle.markup"
          :class="{ 'no-bottomline': !isEditionActive }"
          :flat="!isEditionActive"
          :hide-details="!isEditionActive"
          :readonly="!isEditionActive"
          :rules="[rules.isMarkupNegative()]"
          label="Narzut"
          suffix="%"
          type="number"
          @blur="setDefaultMarkup"
          @focus="removeDefaultMarkup">
        </v-text-field>
      </v-col>
      <v-col cols>
        <v-text-field
          class="pl-2"
          v-model="finalPrice"
          :class="{ 'no-bottomline': !isEditionActive }"
          readonly
          :flat="!isEditionActive"
          label="Cena finalna"
          messages="CZ * (1 + Narzut)"
          suffix="PLN"
          type="number">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols>
        <v-text-field
          v-model="newArticle.VATRate"
          :class="{ 'no-bottomline': !isEditionActive }"
          :flat="!isEditionActive"
          :hide-details="!isEditionActive"
          :readonly="!isEditionActive"
          :rules="[rules.isVATRateValid()]"
          label="Stawka VAT"
          suffix="%"
          type="number"
          @blur="setDefaultVARRate"
          @focus="removeDefaultVARRate">
        </v-text-field>
      </v-col>
    </v-row>
    <custom-combobox-single
      v-model="supplierHelper"
      :class="{ 'no-bottomline': !isEditionActive }"
      :disabled="articleSuppliersLoading"
      :hide-details="!isEditionActive"
      :item-text="'name'"
      :items="articleSuppliers"
      :loading="articleSuppliersLoading"
      :readonly="!isEditionActive"
      label="Dostawca"
      @trueValue="(n) => setSupplierObjOrName(n)" />
    <custom-combobox-single
      v-model="groupHelper"
      :disabled="articleGroupsLoading"
      :class="{ 'no-bottomline': !isEditionActive }"
      :item-text="'name'"
      :rules="[rules.isGroupEmpty()]"
      :items="articleGroups"
      :loading="articleGroupsLoading"
      :readonly="!isEditionActive"
      label="Grupa"
      @trueValue="(n) => setGroupObjOrName(n)" />
  </div>
</template>

<script>
import FormRowWithIcon from "../../ui/FormRowWithIcon.vue";
import warehouseTypes from "../../../warehouseTypes.json";
import { mapActions, mapGetters } from "vuex";
import { cleanString } from "../../../helper";
import CustomComboboxSingle from "../../layout/CustomComboboxSingle.vue";

const defaultArticle = {
  name: null,
  originalProductNumber: null,
  price: 0,
  currency: "PLN",
  quantityPerPackage: 1.0,
  unit: "piece",
  markup: 0,
  VATRate: 23,
  supplier: null,
  group: {
    id: null,
  },
  note: null,
};
export default {
  name: "WarehouseArticleForm",
  components: { CustomComboboxSingle, FormRowWithIcon },
  mounted() {
    this.emitInterface();
  },
  created() {
    this.newArticle = structuredClone(this.article)
    if (this.newArticle.supplier) {
      let articleSupplier = this.articleSuppliers.find((sup) => sup.id === this.newArticle.supplier.id);
      this.setSupplierObjOrName(articleSupplier);
      this.supplierHelper = articleSupplier;
    }
    if (this.newArticle.group) {
      let articleGroup = this.articleGroups.find((sup) => sup.id === this.newArticle.group.id);
      this.setGroupObjOrName(articleGroup);
      this.groupHelper = articleGroup;
    }
  },
  emits: ["newArticleData"],
  computed: {
    finalPrice() {
      return (Number(this.newArticle?.price ?? 0) * (1 + Number(this.newArticle?.markup ?? 0) * 0.01)).toFixed(2)
    },
    availableUnits() {
      return Object.entries(this.warehouseTypes.articleUnit).map(([key, value]) => {
        return {
          key: key,
          ...value, // This spreads the properties of the current value object
        };
      });
    },
    ...mapGetters("warehouse/facilities", [
      "facilities",
    ]),
    ...mapGetters("warehouse/articleDetails", [
      "articleNameValidationSubmitting",
    ]),
    ...mapGetters("warehouse/suppliers", [
      "articleSuppliers",
      "articleSuppliersLoading",
    ]),
    ...mapGetters("warehouse/groups", [
      "articleGroups",
      "articleGroupsLoading",
    ]),
    isButtonDisabled() {
      if (this.articleNameValidationSubmitting) {
        return true;
      }
      const mergedArray = Object.values(this.formErrors).reduce((acc, currentArray) => {
        return acc.concat(currentArray);
      }, []);
      const nameErrors = [this.rules.isNameEmpty()(this.newArticle.name)].filter((error) => typeof error === "string");
      return nameErrors.length > 0 || mergedArray.length > 0;
    },
  },
  props: {
    article: {
      default: () => ({ defaultArticle }),
    },
    isEditionActive: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    newArticle: null,
    supplierHelper: null,
    groupHelper: null,
    formErrors: {
      name: [],
    },
    rules: {
      isNameEmpty() {
        return (v) => !!cleanString(v) || "Nazwa nie może być pusta";
      },
      isPriceNegative() {
        return (v) => !v || (v >= 0 && v && v.toString().match(/^\d+(\.\d{1,2})?$/) !== null) || "Niepoprana cena";
      },
      isQuantityPerPackageNotPositive() {
        return (v) => (v > 0 && v && v.toString().match(/^\d+(\.\d{1,2})?$/) !== null) || "Niepoprana ilość";
      },
      isMarkupNegative() {
        return (v) => v >= 0 || "Niepoprana wartość";
      },
      isVATRateValid() {
        return (v) => (v >= 0 && v <= 100) || "Niepoprana wartość";
      },
      isGroupEmpty() {
        return (v) => !!v || "Grupa nie może być pusta";
      }
    },
    warehouseTypes
  }),
  watch: {
    "newArticle.name"(val) {
      if (val && val !== this.article.name)
        this.postArticleNameValidation({ name: val }).then((valid) => {
          this.$set(this.formErrors, "name", valid ? [] : ["Nazwa artykułu jest już zajęta"]);
        });
      this.$set(this.formErrors, "name", []);
    },
    newArticle(v) {
      this.$emit('newArticleData', v);
    },
    article() {
      this.createEditableArticle()
    }
  },
  methods: {
    ...mapActions("warehouse/articleDetails", ["postArticleNameValidation"]),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    cleanName() {
      this.$set(this.newArticle, "name", cleanString(this.newArticle.name));
    },
    cleanOriginalProductNumber() {
      this.$set(this.newArticle, "originalProductNumber", cleanString(this.newArticle.originalProductNumber));
    },
    setDefaultPrice() {
      if (!!!this.newArticle.price) {
        this.newArticle.price = 0;
      }
    },
    removeDefaultPrice() {
      if (this.newArticle.price === 0) {
        this.$set(this.newArticle, "price", null);
      }
    },
    setDefaultMarkup() {
      if (!!!this.newArticle.markup) {
        this.newArticle.markup = 0;
      }
    },
    removeDefaultMarkup() {
      if (this.newArticle.markup === 0) {
        this.$set(this.newArticle, "markup", null);
      }
    },
    setDefaultQuantityPerPackage() {
      if (!!!this.newArticle.quantityPerPackage) {
        this.newArticle.quantityPerPackage = 1;
      }
    },
    removeDefaultQuantityPerPackage() {
      if (this.newArticle.quantityPerPackage === 1) {
        this.$set(this.newArticle, "quantityPerPackage", null);
      }
    },
    setDefaultVARRate() {
      if (!!!this.newArticle.VARRate) {
        this.newArticle.VARRate = 23;
      }
    },
    removeDefaultVARRate() {
      if (this.newArticle.VARRate === 23) {
        this.$set(this.newArticle, "VARRate", null);
      }
    },
    async setSupplierObjOrName(v) {
      this.newArticle.supplier = v;
    },
    async setGroupObjOrName(v) {
      this.newArticle.group = v;
    },
    emitInterface() {
      this.$emit("interface", {
        cancelEditing: () => this.createEditableArticle()
      });
    },
    createEditableArticle() {
      this.newArticle = structuredClone(this.article)
      if (this.newArticle.supplier) {
        let articleSupplier = this.articleSuppliers.find((sup) => sup.id === this.newArticle.supplier.id);
        this.setSupplierObjOrName(articleSupplier);
        this.supplierHelper = articleSupplier;
      }
      if (this.newArticle.group) {
        let articleGroup = this.articleGroups.find((sup) => sup.id === this.newArticle.group.id);
        this.setGroupObjOrName(articleGroup);
        this.groupHelper = articleGroup;
      }
    }
  },
};
</script>

<style scoped>
>>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot,
>>> .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0;
}

.no-bottomline >>> .v-input__slot::before {
  border-style: none !important;
}

.no-bottomline >>> .v-input__slot::after {
  border-style: none !important;
}
</style>
