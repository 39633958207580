import axios from 'axios';

export default {
  async fetchArticleGroups({ commit }, payload) {
    commit('setArticleGroupsLoading', true);

    const response = await axios.get(`warehouse/articleGroup/all`);
    console.debug(response.data, 'response.data');
    commit('setArticleGroups', response.data);
    commit('setArticleGroupsLoading', false);
  },
  setArticleGroupFilter({ commit }, payload) {
    commit('setArticleGroupFilter', payload);
  },

  addArticleGroup({ commit, getters }, { newGroup }) {
    commit('setArticleGroups', [newGroup, ...getters.articleGroups]);
  },
};
