export default {
  getCustomers(state) {
    return state.customers;
  },
  getCustomerLocationsJobs(state) {
    return state.customerLocationsJobs;
  },
  getCustomersLoading(state) {
    return state.customersLoading;
  },
};
