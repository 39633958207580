<template>
  <p>Brak dostępu</p>
</template>

<script>
export default {
  name: "AccessDenied",
};
</script>

<style scoped></style>
