import axios from 'axios';

export default {
  async fetchWorkers(context, payload) {
    context.commit('setWorkersAreLoading', true);
    const response = await axios.get(`worker/fetchWorkers`);

    console.log(response.data.users);
    console.log('response.data.users');
    context.commit('setWorkers', response.data.users);
    context.commit('setWorkersAreLoading', false);
  },
};
