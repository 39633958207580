<template>
  <v-chip
    :color="isSelected ? 'black' : 'white'"
    :outlined="isSelected"
    :style="{ opacity: isSelected ? '1' : '0.5' }"
    :text-color="isSelected ? 'black' : 'grey'"
    class="pa-1"
    label
    small
    @click="selectOperation"
  >
    <v-icon :color="config.iconColor" small>{{ config.icon }}</v-icon>
    {{ config.label }}
  </v-chip>
</template>

<script>
export default {
  name: "WarehouseStockOperationChip",
  props: {
    stockOperation: {
      type: String,
      required: true,
    },
    operationType: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSelected() {
      return this.stockOperation === this.operationType;
    },
    config() {
      const configs = {
        replenishment: {
          icon: this.isSelected ? 'mdi-plus-box' : 'mdi-plus-box-outline',
          label: 'Uzupełnienie',
        },
        consumption: {
          icon: this.isSelected ? 'mdi-minus-box' : 'mdi-minus-box-outline',
          label: 'Rozchód',
        },
        correction: {
          icon: this.isSelected ? 'mdi-pencil-box' : 'mdi-pencil-box-outline',
          label: 'Inwentaryzacja',
        },
      };
      return configs[this.operationType] || {};
    },
  },
  methods: {
    selectOperation() {
      this.$emit('update:stockOperation', this.operationType);
    },
  },
};
</script>

