import axios from 'axios';
import { getUserRole } from '../../../../helper';

export default {
  async loadUsers(context) {
    const response = await axios.get('/admin/user/listOfUsers');

    if (response.status !== 200) {
      throw new Error(response || 'Failed to fetch!');
    }

    const users = [];
    for (const key in response.data) {
      const user = { ...response.data[key], role: getUserRole(response.data[key].roles) };
      users.push(user);
    }
    context.commit('setUsers', users);
  },

  async createNewUser(context, userData) {
    try {
      await axios.post('admin/user/createUser', userData);
    } catch (e) {
      console.log(e);
    }
  },

  async editUser(context, { userId, userData } = {}) {
    try {
      const response = await axios.post('admin/user/edit/' + userId, userData);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};
