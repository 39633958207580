<template>
  <v-card class="elevation-12">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Email confirmation</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-sheet
        v-if="message"
        color="success"
        elevation="4"
        rounded
        style="padding: 20px; margin-right: 30px; margin-left: 30px; margin-bottom: 10px"
        >{{ message }}
      </v-sheet>
      <v-sheet
        v-if="error"
        color="error"
        elevation="4"
        rounded
        style="padding: 20px; margin-right: 30px; margin-left: 30px; margin-bottom: 10px"
        >{{ error }}
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "NewEmail",
  data: () => ({
    error: "",
    message: "",
  }),
  async created() {
    try {
      this.hash = this.$route.query.hash;
      const response = await axios.get("http://localhost/api/user/changeEmail/checkHash", {
        params: { hash: this.hash },
      });
      this.message = "E-mail zmieniony";
    } catch (e) {
      if (e.response.data.message === "badHash") {
        this.error = "WSTĘP WZBRONIONY";
      } else if (e.response.data.message === "outOfTime") {
        this.error = "Zaminiono hasło. Link wygasł.";
      } else {
        this.error = e;
      }
    }
  },
};
</script>
