import Vue from 'vue';

export default {
  setSubmittingSupplier(state, isSubmitting) {
    state.submittingSupplier = isSubmitting;
  },
  setArticleSuppliers(state, payload) {
    state.articleSuppliers = payload;
  },
  setArticleSuppliersLoading(state, isLoading) {
    state.articleSuppliersLoading = isLoading;
  },
};
