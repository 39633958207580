<template>
  <v-container v-if="jobIsLoaded">
    <v-row no-gutters style="margin-top: 10px">
      <v-col md="6" sm="12">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title v-if="!jobIsEdited">
              <v-row no-gutters>
                <v-col style="max-width: 30px">
                  <v-icon size="25"></v-icon>
                </v-col>
                <v-col class="text-h3">
                  {{ job.customer !== null ? job.customer.name : "Klient bez nazwy" }}
                </v-col>
              </v-row>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-row v-if="!job.isDeleted">
              <v-spacer></v-spacer>
              <v-col class="d-flex justify-end align-center">
                <v-btn v-show="!jobIsEdited" depressed small @click="editJobDetails">
                  <v-icon> mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-show="jobIsEdited" depressed small style="margin-right: 5px" @click="jobIsEdited = false">
                  <v-icon left> mdi-cancel</v-icon>
                  Anuluj
                </v-btn>
                <template v-if="userRole === ROLE.ADMIN">
                  <v-btn
                    v-if="jobCanBeDeleted === false"
                    v-show="jobIsEdited"
                    depressed
                    small
                    style="margin-right: 5px"
                    @click="jobCanBeDeleted = true">
                    <v-icon left> mdi-delete</v-icon>
                    Usuń
                  </v-btn>
                  <v-btn v-else v-show="jobIsEdited" depressed small style="margin-right: 5px" @click="deleteJob()">
                    <v-icon left> mdi-check</v-icon>
                    Potwierdź usunięcie
                  </v-btn>
                </template>
                <v-btn
                  v-if="jobCanBeDeleted === false"
                  v-show="jobIsEdited"
                  depressed
                  small
                  style="margin-right: 5px"
                  @click="saveJob">
                  <v-icon left> mdi-content-save</v-icon>
                  Zapisz
                </v-btn>
              </v-col>
            </v-row>
            <p v-else>W koszu</p>
          </v-toolbar>
          <!--          details-->
          <job-card-details v-if="!jobIsEdited" :job="job" />
          <!--          edit-->
          <v-card-text v-else style="width: 70%">
            <v-row class="align-center justify-start" no-gutters>
              <v-col style="max-width: 30px">
                <v-icon size="20"> mdi-store</v-icon>
              </v-col>
              <v-col>
                <custom-combobox-single
                  v-model="jobCustomerInput"
                  :loading="getCustomersLoading"
                  :disabled="getCustomersLoading"
                  :item-text="'name'"
                  :items="customers"
                  :label="'Klient'"
                  @trueValue="(n) => setJobCustomer(n)"></custom-combobox-single>
              </v-col>
            </v-row>

            <v-row v-if="jobCustomerOutput" class="align-center justify-start" no-gutters>
              <v-col style="max-width: 30px">
                <v-icon size="20"> mdi-map-marker</v-icon>
              </v-col>
              <v-col>
                <custom-combobox-single
                  v-model="jobLocationInput"
                  :item-text="'name'"
                  :loading="getCustomerLocationsLoading"
                  :disabled="getCustomerLocationsLoading"
                  :items="customerLocations"
                  :label="'Lokalizacja'"
                  @trueValue="(n) => setJobCustomerLocation(n)"></custom-combobox-single>
              </v-col>
            </v-row>
            <v-row class="align-center justify-start" no-gutters>
              <v-col style="max-width: 30px">
                <v-icon size="20"> mdi-text</v-icon>
              </v-col>
              <v-col>
                <v-text-field v-model="jobTitle" clearable label="Tytuł"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-center justify-start" no-gutters>
              <v-col style="max-width: 30px">
                <v-icon size="20"> mdi-text-recognition</v-icon>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="externalJobNumber"
                  clearable
                  label="Numer zewnętrzny zlecenia"
                  placeholder="Brak"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-menu
                  v-model="menuCutOffDate"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="cutOffDate"
                      label="Data graniczna"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @keydown.space.prevent="menuCutOffDate = true"></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="cutOffDate"
                    :first-day-of-week="1"
                    no-title
                    show-adjacent-months
                    @input="menuCutOffDate = false">
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col style="margin: 0; padding-left: 30px">
                <p style="margin: 0; padding: 0; font-size: 12px">Podlega SLA</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col style="max-width: 30px">
                <v-icon :color="isSLA ? 'red' : 'orange'" size="20"> mdi-alert-outline</v-icon>
              </v-col>
              <v-col>
                <v-chip
                  :key="'sla'"
                  :autofocus="true"
                  :color="isSLA ? 'grey' : 'transparent'"
                  :outlined="isSLA"
                  :style="{ opacity: !isSLA ? '0.5' : '1' }"
                  class="me-2"
                  label
                  style="margin-right: 5px; margin-bottom: 5px"
                  text-color="black"
                  @click="isSLA = !isSLA"
                  @keydown.space.prevent="isSLA = !isSLA">
                  <v-icon left>
                    {{ isSLA ? "mdi-checkbox-marked-outline" : "mdi-checkbox-blank-outline" }}
                  </v-icon>
                  {{ isSLA ? "Tak" : "Nie" }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col style="margin: 0; padding-left: 30px">
                <p style="margin: 0; padding: 0; font-size: 12px">Dni przed ostrzeżeniem</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col style="max-width: 30px">
                <v-icon size="20"> mdi-alert</v-icon>
              </v-col>
              <v-col>
                <v-chip
                  v-for="deadLineValue in deadLineValues"
                  :key="deadLineValue"
                  :color="deadLineValue === deadLineOffset ? 'black' : 'white'"
                  :outlined="deadLineValue === deadLineOffset"
                  :tabindex="0"
                  :text-color="deadLineValue === deadLineOffset ? 'black' : 'grey'"
                  class="me-2"
                  label
                  @click="deadLineOffset = deadLineValue">
                  {{ deadLineValue + (deadLineValue === deadLineOffset ? " dni" : "") }}
                </v-chip>
              </v-col>
            </v-row>
            <form-row-with-icon icon="mdi-trending-up" text="Status rozliczenia">
              <v-chip
                v-for="(jss, k) in types.jobSettlementStatus"
                :key="jss.name"
                :color="k === jobSettlementStatus ? 'black' : 'white'"
                :outlined="k === jobSettlementStatus"
                :tabindex="0"
                :text-color="k === jobSettlementStatus ? 'black' : 'grey'"
                class="me-2"
                label
                @click="jobSettlementStatus = k">
                <v-icon left>
                  {{ types.jobSettlementStatus[k].icon }}
                </v-icon>
                {{ jss.name }}
              </v-chip>
            </form-row-with-icon>
            <form-row-with-icon icon="mdi-arrow-right-circle" text="Status zlecenia">
              <v-chip
                v-for="(js, k) in types.jobStatuses"
                :key="js.name"
                :color="jobStatus === k ? 'black' : 'white'"
                :outlined="jobStatus === k"
                :tabindex="0"
                :text-color="jobStatus === k ? 'black' : 'grey'"
                class="me-2"
                label
                @click="jobStatus = k">
                {{ js.name }}
              </v-chip>
            </form-row-with-icon>
          </v-card-text>
        </v-card>
        <job-tasks-list></job-tasks-list>
      </v-col>

      <v-spacer></v-spacer>

      <v-col md="6" sm="12">
        <template>
          <v-card :style="isMobile ? 'margin-top: 20px' : 'margin-left: 20px'">
            <v-row no-gutters>
              <v-col>
                <v-tabs v-model="tab" grow>
                  <v-tab v-for="item in ['Notatki', 'Protokoły']" :key="item">
                    {{ item }}
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="pa-2" cols="auto">
                <div v-if="tab === 0">
                  <add-note v-if="!job.isDeleted" :job-id="jobId" @refresh="fetchNotes()"></add-note>
                  <div style="height: 5px"></div>
                  <download-note-files v-if="files.length" :files="files" :job-id="jobId"
                    >pobierz z wszystkich
                  </download-note-files>
                </div>
                <div v-if="tab === 1">
                  <add-protocol v-if="!job.isDeleted" :job-id="jobId" @refresh="fetchProtocols()"></add-protocol>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <list-of-notes :job-id="jobId" @refresh="fetchNotes()"></list-of-notes>
              </v-tab-item>
              <v-tab-item>
                <list-of-protocols :job-id="jobId" @refresh="fetchProtocols()"></list-of-protocols>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddNote from "../../components/job/AddNote";
import ListOfNotes from "../../components/job/ListOfNotes";
import { formatDate, formatDateTime } from "../../helper";
import CustomComboboxSingle from "../../components/layout/CustomComboboxSingle";
import { ROLE, UPDATE_TYPE } from "../../constants";
import JobCardDetails from "../../components/job/JobCardDetails.vue";
import FormRowWithIcon from "../../components/ui/FormRowWithIcon.vue";

import types from "../../types.json";
import ListOfProtocols from "../../components/job/ListOfProtocols.vue";
import AddProtocol from "../../components/job/AddProtocol.vue";
import DownloadNoteFiles from "../../components/job/DownloadNoteFiles.vue";
import JobTasksList from "./JobTasksList.vue";

export default {
  name: "JobView",
  components: {
    JobTasksList,
    DownloadNoteFiles,
    AddProtocol,
    ListOfProtocols,
    FormRowWithIcon,
    JobCardDetails,
    ListOfNotes,
    AddNote,
    CustomComboboxSingle,
  },
  beforeRouteLeave(to, from, next) {
    this.removeListeners()
    next();
  },
  props: ["jobId"],
  data: () => ({
    types,
    jobIsLoaded: false,
    workersAreLoaded: false,
    jobCanBeDeleted: false,

    events: [],
    input: null,

    jobIsEdited: false,

    jobCustomerInput: "",
    jobCustomerOutput: null,

    jobLocationInput: "",
    jobLocationOutput: null,

    jobTitle: "",
    externalJobNumber: "",
    menuCutOffDate: false,
    cutOffDate: null,
    isSLA: true,
    deadLineOffset: 7,
    deadLineValues: [1, 2, 3, 5, 7, 14, 21],
    jobStatus: "",
    jobSettlementStatus: "",

    tab: 1,

    updateUnassignedTaskEventCallback: null,
    updatePlanedTaskEventCallback: null,
  }),
  computed: {
    ROLE() {
      return ROLE;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    ...mapGetters({
      userRole: "user/getUserRole",
      customerLocations: "customerLocations/getCustomerLocations",
      customers: "customers/getCustomers",
      job: "jobs/getJob",
      tasks: "tasks/getTasks",
      workers: "workers/getWorkers",
      notes: "jobs/getNotes",
      files: "jobs/getFiles",
    }),
    ...mapGetters('customerLocations', ['getCustomerLocationsLoading']),
    ...mapGetters('customers', ['getCustomersLoading']),
    ...mapGetters("stream", ["getStream"]),
  },
  async created() {
    await this.$store.dispatch("calendarTasks/fetchServiceTechnicians");
    await this.fetchJob();
    await this.fetchWorkers();
    await this.fetchJobFiles();
  },
  mounted() {
    this.setListeners();
  },
  methods: {
    ...mapActions('customerLocations', ['fetchCustomerLocations', 'resetCustomerLocations']),
    ...mapActions('customers', ['fetchCustomers']),
    ...mapActions('tasks', ['fetchTasks']),
    async editJobDetails() {
      console.log('editJobDetails');
      this.jobTitle = this.job.title;
      this.externalJobNumber = this.job.externalJobNumber;
      this.cutOffDate = formatDate(this.job.cutOffDate);
      this.isSLA = this.job.sla;
      this.deadLineOffset = this.job.warningOffset;
      this.jobStatus = this.job.status;
      this.jobSettlementStatus = this.job.settlementStatus.status;
      await this.fetchCustomers();
      // await this.fetchCustomerLocations();
      const foundCustomer = this.job.customer ? this.customers.find((c) => c.id === this.job.customer.id) : null;
      this.jobCustomerInput = foundCustomer ?? "";
      if (this.jobCustomerInput) {
        await this.fetchCustomerLocations({ customerId: this.jobCustomerInput.id });
        this.jobLocationInput = this.customerLocations.find(
          (v) => v && this.job.location && v.name === this.job.location.name
        );
        if (this.jobLocationInput === undefined) {
          this.jobLocationInput = "";
        }
      }
      this.jobIsEdited = true
      // this.jobLocationInput = (this.job.location !== null ? this.job.location.name : '')
      console.log('this.jobCustomerInput');
      console.log(this.jobCustomerInput);
      console.log(this.jobLocationOutput);
    },
    formatDateTime,
    async deleteJob() {
      this.jobIsEdited = false;
      await this.$store.dispatch("bin/binAction", { jobId: [this.job.id], operation: "move" });
      await this.fetchJob();
      // await this.$router.replace({name: 'jobList'});
    },
    async saveJob() {
      const jobData = {
        id: this.job.id,
        jobCustomer: this.jobCustomerOutput,
        jobLocation: this.jobLocationOutput,
        jobTitle: this.jobTitle,
        externalJobNumber: this.externalJobNumber,
        cutOffDate: this.cutOffDate,
        isSLA: this.isSLA,
        deadLineOffset: this.deadLineOffset,
        jobStatus: this.jobStatus,
        jobSettlementStatus: this.jobSettlementStatus,
      };
      this.jobIsLoaded = false;
      await this.$store.dispatch("jobs/editJob", {
        jobData: jobData,
      });
      await this.fetchJob();
      this.jobIsEdited = false;
      this.jobIsLoaded = true;
    },
    async fetchNotes() {
      await this.fetchJobFiles();
      try {
        await this.$store.dispatch("jobs/fetchNotes", { jobId: this.job.id });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchProtocols() {
      console.log("fetch protocols");
      await this.fetchJob();
      try {
        await this.$store.dispatch("jobs/fetchProtocols", { jobId: this.job.id });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchJobFiles() {
      console.log("fetch files");
      await this.fetchJob();
      try {
        await this.$store.dispatch("jobs/fetchJobFiles", { jobId: this.job.id });
      } catch (error) {
        console.log(error);
      }
      console.log(this.files);
    },
    async fetchJob() {
      console.log(this.jobId);
      this.$store.commit("layoutHelpers/addLoadingProcess", "fetchJob");
      try {
        await this.$store.dispatch("jobs/fetchJob", {
          jobId: this.jobId,
        });
      } catch (error) {
        console.log(error);
      }
      this.jobIsLoaded = true;
      this.$store.commit("layoutHelpers/removeLoadingProcess", "fetchJob");
    },
    async fetchWorkers() {
      try {
        this.$store.commit("layoutHelpers/addLoadingProcess", "fetchWorkers");
        await this.$store.dispatch("workers/fetchWorkers", {});
      } catch (error) {
        console.log(error);
      }
      this.workersAreLoaded = true;
      this.$store.commit("layoutHelpers/removeLoadingProcess", "fetchWorkers");
    },
    async setJobCustomer(v) {
      this.jobLocationInput = '';
      if (v && typeof v === "object") {
        await this.fetchCustomerLocations({ customerId: v.id});
        if (this.job.customer && v.name === this.job.customer.name) {
          this.jobLocationInput = this.job.location;
        } else {
          this.jobLocationInput = '';
        }
      } else {
        this.resetCustomerLocations();
      }
      this.jobLocationOutput = null;
      this.jobCustomerOutput = v;
    },
    setJobCustomerLocation(v) {
      this.jobLocationOutput = v;
    },
    setListeners() {
      this.updateUnassignedTaskEventCallback = this.updateUnassignedTaskEvent();
      this.updatePlannedTaskEventCallback = this.updatePlannedTaskEvent();

      this.getStream.addEventListener(UPDATE_TYPE.JOB_TASK_UNASSIGNED_UPDATE, this.updateUnassignedTaskEventCallback);
      this.getStream.addEventListener(UPDATE_TYPE.JOB_TASK_PLANNED_UPDATE, this.updatePlannedTaskEventCallback);
    },
    removeListeners() {
      console.log('remove listeners');
      this.getStream.removeEventListener(UPDATE_TYPE.JOB_TASK_UNASSIGNED_UPDATE, this.updateUnassignedTaskEventCallback);
      this.getStream.removeEventListener(UPDATE_TYPE.JOB_TASK_PLANNED_UPDATE, this.updatePlannedTaskEventCallback);
    },
    updatePlannedTaskEvent() {
      return async (event) => {
        console.log("NEW -PLANNED- TASK ADD");
        console.log(JSON.parse(event.data));
        await this.fetchTasks();
      };
    },
    updateUnassignedTaskEvent() {
      return async (event) => {
        console.log("NEW -UNASSIGNED- TASK ADD");
        console.log(JSON.parse(event.data));
        await this.fetchTasks();
      };
    },
  },
};
</script>