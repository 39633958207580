<template>
  <v-list :key="key" dense nav>
    <v-list-item v-if="notes.length === 0">
      <v-list-item-content>
        <p>Brak notatek</p>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-for="(note, noteInd) in notes" v-else :key="note.id">
      <v-list-item-content
        :style="{ 'background-color': noteInd % 2 ? 'rgba(0,0,0,0.03)' : 'transparent', display: 'inline' }">
        <v-row no-gutters>
          <v-list-item-title style="font-size: 1em">
            <v-row no-gutters>
              <v-col>
                <v-chip
                  :key="note.author.id"
                  color="transparent"
                  label
                  style="margin-right: 5px; margin-bottom: 5px"
                  text-color="black">
                  <v-icon :color="note.author.color" left> mdi-circle</v-icon>
                  {{ note.author.name }}
                </v-chip>
                <v-icon v-if="note.isPrivate" color="rgba(0, 0, 0, 1))" small> mdi-lock</v-icon>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="d-flex justify-end align-center pr-1" style="font-size: 0.8em; color: grey">
                {{ formatDateTime(note.timeStamp) }}
              </v-col>
            </v-row>
          </v-list-item-title>
          <!--          <v-col cols="auto">-->
          <!--              <v-icon>{{ noteId===note.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
          <!--          </v-col>-->
        </v-row>
        <v-row style="flex-direction: row-reverse; margin: 2px">
          <download-note-files
            v-if="note.noteFiles.length"
            :job-id="jobId"
            :files="note.noteFiles">Pobierz wybrane</download-note-files>
        </v-row>
        <v-row v-if="note.content !== '' && note.content !== null" no-gutters>
          <v-col>
            <p
              class="mx-4"
              style="color: black"
              v-html="note.content"></p>
          </v-col>
        </v-row>

        <v-row no-gutters style="margin-left: 40px; margin-right: 40px">
          <v-col
            v-for="f in sortByFileType(note.noteFiles)"
            :key="f.filename"
            cols="3"
            class="d-flex"
            style="margin: 5px; flex-direction: column">
            <v-img
              v-if="getFileTypeIcon(f.filename) === 'mdi-image'"
              :aspect-ratio="4 / 3"
              :src="'/uploads/files/' + f.filename"
              class="flex-grow-1"
              contain
              max-height="100px"
              style="cursor: pointer; border: lightgrey 1px solid; border-radius: 3px"
              @click="download(f.filename)"></v-img>
            <v-card
              v-else-if="getFileTypeIcon(f.filename) === 'mdi-movie'"
              class="flex-grow-1"
              max-height="100px"
              max-width="200px">
              <video id="video" controls height="100px" width="200px">
                <source :src="'/uploads/files/' + f.filename" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </v-card>
            <v-chip
              v-else
              color="transparent"
              class="flex-grow-1"
              label
              style="margin-right: 5px; margin-bottom: 5px"
              text-color="black"
              @click="download(f.filename)">
              <v-icon black left>
                {{ getFileTypeIcon(f.filename) }}
              </v-icon>
              {{ normFilename(f.filename) }}
            </v-chip>
            <v-row style="flex: 1" class="justify-start align-center">
              <v-spacer></v-spacer>
              <job-note-delete-toolbar
                v-if="userRole === ROLE.OFFICE || userRole === ROLE.ADMIN"
                :file="f"
                @fetch="fetchNotes(f)">
              </job-note-delete-toolbar>
            </v-row>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { get } from "axios";
import { mapGetters } from "vuex";
import { ROLE } from "../../constants";
import JobNoteDeleteToolbar from "./JobNoteDeleteToolbar.vue";
import { formatDateTime } from "../../helper";
import DownloadNoteFiles from "./DownloadNoteFiles.vue";

export default {
  components: {DownloadNoteFiles, JobNoteDeleteToolbar },
  props: ["jobId"],
  computed: {
    ROLE() {
      return ROLE;
    },
    ...mapGetters({
      userRole: "user/getUserRole",
    }),
    notes() {
      return this.$store.getters["jobs/getNotes"];
    },
  },
  data: () => ({
    key: 0,
    noteId: null,
    fileCanBeDeleted: false,
    fileControl: null,
  }),

  created() {
    this.created();
  },

  methods: {
    async created() {
      try {
        await this.$store.dispatch("jobs/fetchNotes", { jobId: this.jobId });
      } catch (error) {
        console.log(error);
      }
    },
    sortByFileType(arr) {
      console.log(arr);
      const result = [...arr].sort(function (a, b) {
        function f(x) {
          // const t = fn.toString()
          // const filetype = (t.split('.')).at(-1)
          const o = x.toString();
          const r = o.split(".").at(-1);
          switch (r) {
            case "pdf":
              return -1;
            case "mp4":
              return 1;
            default:
              return 0;
          }
        }

        const filetypeA = f(a.filename);
        const filetypeB = f(b.filename);

        return filetypeA - filetypeB;
      });
      console.log(result);
      return result;
    },
    normFilename(fn) {
      const r = fn.toString().split("-");
      r.shift();
      return r.join("-");
    },
    formatDateTime,
    async fetchNotes(f) {
      await this.$emit("refresh");
      document.getElementById("video").load();
      this.key++;
    },
    getFileTypeIcon(fn) {
      const t = fn.toString();
      const filetype = t.split(".").at(-1);
      if (filetype === "pdf") {
        return "mdi-file-pdf-box"
      }
      if (["png", "jpg", "jpeg"].includes(filetype)) {
        return "mdi-image";
      }
      if (filetype.includes("mp4")) {
        return "mdi-movie";
      }
      return "mdi-file-word";
    },
    getPhotosFromFiles(f) {
      const photos = [];
      for (let file in f) {
        if (f[file]["filetype"] === "image") {
          photos.push(f[file]);
        }
      }

      return photos;
    },
    download(u) {
      // console.log('/uploads/files/'+ u)
      // window.open();
      const r = u.split(".").at(-1);
      var mimeType = "";
      switch (r) {
        case "pdf":
          mimeType = "application/pdf";
          break;
        case "mp4":
          mimeType = "video/mp4";
          break;
        default:
          mimeType = "image/" + r;
          break;
      }
      console.log(mimeType);
      get("/job/download?file=" + u, { responseType: "blob" })
        .then((response) => {
          console.log(mimeType);
          const blob = new Blob([response.data], { type: mimeType });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = u;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
};
</script>

<style scoped>
.v-menu__content {
  box-shadow: none;
}
</style>
