<template>
  <v-sheet class="ma-1 pa-2">
    <v-row class="d-flex flex-row flex-nowrap align-content-space-between" no-gutters>
      <v-col class="d-flex flex-row align-start" cols="auto">
        <v-icon color="grey" size="24"> mdi-note</v-icon>
        <span class="pl-2">Zadanie</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-col class="d-flex flex-row justify-end align-center">
            <chat-task-status-chip disabled :outlined="false" :status="task.status" />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pa-1" cols="auto">
        <created-modified-row
          :date="task.createdInfo.createdAt"
          :is-modified="false"
          :user="task.createdInfo.createdBy" />
        <created-modified-row
          v-if="task.modifiedInfo.lastModified"
          :date="task.modifiedInfo.lastModified"
          :is-modified="true"
          :user="task.modifiedInfo.modifiedBy" />
      </v-col>
    </v-row>
    <v-row class="d-flex flex-row flex-nowrap align-end py-3" no-gutters>
      <v-col class="px-2">
        <span style="word-break: break-word">{{ task.title }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <chat-task-user-selector-chip bottom :assigned-user="task.performBy" @changeAssignedUser="(newAssigned) => changeAssignedUser(newAssigned)" />
      </v-col>
      <v-spacer></v-spacer>
      <v-col v-if="task.status !== CHAT_TASK_STATUS.REJECTED && task.status !== CHAT_TASK_STATUS.COMPLETED" cols="auto">
        <chat-filter-chip
          :status="CHAT_TASK_STATUS.REJECTED"
          icon="mdi-minus-circle"
          icon-color="red"
          @changeTaskProperties="changeTaskStatus(CHAT_TASK_STATUS.REJECTED)">
          Odrzuć
        </chat-filter-chip>
      </v-col>
      <v-col cols="auto">
        <chat-filter-chip
          v-if="task.status !== CHAT_TASK_STATUS.OPEN"
          :status="CHAT_TASK_STATUS.OPEN"
          class="mx-1"
          icon="mdi-play-box-outline"
          icon-color="blue"
          @changeTaskProperties="changeTaskStatus(CHAT_TASK_STATUS.OPEN)">
          Otwórz
        </chat-filter-chip>
        <chat-filter-chip
          v-if="task.status === CHAT_TASK_STATUS.OPEN"
          :status="CHAT_TASK_STATUS.COMPLETED"
          class="ml-1"
          icon="mdi-check-circle"
          icon-color="green"
          @changeTaskProperties="changeTaskStatus(CHAT_TASK_STATUS.COMPLETED)">
          Zakończ
        </chat-filter-chip>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import CreatedModifiedRow from "../layout/CreatedModifiedRow.vue";
import ChatTaskStatusChip from "./ChatTaskStatusChip.vue";
import ChatFilterChip from "./ChatFilterChip.vue";
import { CHAT_TASK_STATUS } from "../../constants";
import ChatTaskUserSelectorChip from "./ChatTaskUserSelectorChip.vue";

export default {
  components: {
    ChatTaskUserSelectorChip,
    ChatFilterChip,
    ChatTaskStatusChip,
    CreatedModifiedRow,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    CHAT_TASK_STATUS() {
      return CHAT_TASK_STATUS;
    },
  },
  methods: {
    async changeAssignedUser(newAssigned) {
      console.log(newAssigned);
      await this.$store.dispatch("chat/changeTaskAssignedUser", {
        assignedUserId: newAssigned ? newAssigned.id : null,
        taskId: this.task.id,
      });
    },
    async changeTaskStatus(status) {
      if (this.task.status !== status) {
        await this.$store.dispatch("chat/changeTaskStatus", {
          status: status,
          taskId: this.task.id,
        });
      }
    },
  },
};
</script>
