import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      uploadingFiles: [],
      uploadingFilesProgress: [],
      uploadingFilesControllers: [],
      deletingFile: null,
      settingAsPrimaryImage: null,
    };
  },
  mutations,
  actions,
  getters,
};
