<template>
  <v-sheet :style="getBorderStyle()" rounded>
    <slot></slot>
  </v-sheet>
</template>
<script>
export default {
  name: "BorderActiveVSheet",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isUnread: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    getBorderStyle() {
      if (this.isActive) {
        return "border: 1px solid #2daae2";
      }
      if (this.isUnread) {
        return "border: 1px solid mediumseagreen";
      }
      return "border: 1px solid rgba(0, 0, 0, 0.12)";
    },
  },
};
</script>
<style scoped>
@keyframes selectFiltersAnimation {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}

/*.theme--light.v-calendar-daily .v-calendar-daily__intervals-body .v-calendar-daily__interval-text {*/
/*  color: transparent;*/
/*}*/
</style>
