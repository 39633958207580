import { ROLE } from "../../constants";

export default class CreateUserClass {
  name = null;
  password = null;
  confirmPassword = null;
  email = null;
  color = null;
  role = null;
  planVisibility = null;
  canReplan = null;

  constructor({
    name = null,
    email = null,
    password = null,
    confirmPassword = null,
    role = null,
    color = null,
    planVisibility = null,
    canReplan = null,
  }) {
    // <-------
    this.name = name;
    this.email = email;
    this.password = password;
    this.confirmPassword = confirmPassword;
    this.role = role;
    this.color = color;
    this.planVisibility = planVisibility;
    this.canReplan = canReplan;
  }

  static createDefault() {
    // <-------
    return new CreateUserClass({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      color: "#1654ff88",
      role: ROLE.SERVICE_TECHNICIAN,
      planVisibility: true,
      canReplan: false,
    });
  }

  static create(params) {
    // <-------
    return new CreateUserClass(params);
  }

  display() {
    console.log(this);
  }
}
