<template>
  <v-list dense nav>
    <v-list-item v-if="loadingProtocols">
      <v-list-item-content>
        <v-progress-circular :size="100" :width="7" color="tertiary" indeterminate></v-progress-circular>
      </v-list-item-content>
    </v-list-item>
    <div v-else>
      <div v-for="(jobOrLocationGroup, kJoRLG, ind) in protocols" :key="kJoRLG">
        <p>{{ ind === 0 ? "Protokoły zlecenia" : "Protokoły lokalizacji" }}</p>
        <p v-if="!!!protocols[kJoRLG]">brak</p>
        <div v-for="(applianceType, k) in jobOrLocationGroup" style="margin-bottom: 10px">
          <v-expansion-panels multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ types.applianceGroupType[k].name + " (" + applianceType.length + ")" }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item v-for="(protocol, protocolIndex) in applianceType" :key="protocol.id">
                  <v-list-item-content
                    :style="{
                      'background-color': protocolIndex % 2 ? 'rgba(0,0,0,0.03)' : 'transparent',
                      display: 'inline',
                    }">
                    <v-row no-gutters>
                      <v-list-item-title style="font-size: 1em">
                        <v-row no-gutters>
                          <v-spacer></v-spacer>
                          <v-col cols="auto" style="font-size: 0.8em; color: grey">
                            <created-modified-row
                              :date="protocol.createdInfo.createdAt"
                              :user="protocol.createdInfo.createdBy"
                              :is-modified="false" />
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <v-chip
                          color="transparent"
                          class="flex-grow-1"
                          label
                          style="margin-right: 5px; margin-bottom: 5px"
                          text-color="black"
                          @click="download(protocol.filename)">
                          <v-icon black left> mdi-file </v-icon>
                          {{ protocol.name + " " + formatDate(protocol.date) }}
                        </v-chip>
                      </v-col>
                      <v-spacer></v-spacer>
                      <job-note-delete-toolbar
                        v-if="userRole === ROLE.OFFICE || userRole === ROLE.ADMIN"
                        :file="{ filename: protocol.filename }"
                        is-protocol
                        @fetch="fetchProtocols">
                      </job-note-delete-toolbar>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <v-divider></v-divider>
      </div>
    </div>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
import { ROLE } from "../../constants";
import { formatDate, formatDateTime } from "../../helper";
import CreatedModifiedRow from "../layout/CreatedModifiedRow.vue";
import types from "../../types.json";
import { get } from "axios";
import JobNoteDeleteToolbar from "./JobNoteDeleteToolbar.vue";

export default {
  components: { JobNoteDeleteToolbar, CreatedModifiedRow },
  props: ["jobId"],
  computed: {
    ROLE() {
      return ROLE;
    },
    ...mapGetters({
      userRole: "user/getUserRole",
    }),
    ...mapGetters({ protocols: "jobs/getProtocols" }),
  },
  data: () => ({
    noteId: null,
    types,
    fileCanBeDeleted: false,
    loadingProtocols: true,
  }),
  async created() {
    this.loadingProtocols = true;
    try {
      await this.$store.dispatch("jobs/fetchProtocols", { jobId: this.jobId });
    } catch (error) {
      console.log(error);
    }
    this.loadingProtocols = false;
  },
  methods: {
    formatDate,
    normFilename(fn) {
      const r = fn.toString().split("-");
      r.shift();
      return r.join("-");
    },
    formatDateTime,
    async fetchProtocols() {
      await this.$emit("refresh");
      this.loadingProtocols = true;
      try {
        await this.$store.dispatch("jobs/fetchProtocols", { jobId: this.jobId });
      } catch (error) {
        console.log(error);
      }
      this.loadingProtocols = false;
    },
    getFileTypeIcon(fn) {
      const t = fn.toString();
      const filetype = t.split(".").at(-1);
      if (filetype === "pdf") {
        return "mdi-file";
      }
      if (["png", "jpg", "jpeg"].includes(filetype)) {
        return "mdi-image";
      }
      if (filetype.includes("mp4")) {
        return "mdi-movie";
      }
    },
    download(u) {
      console.log("pobierz");
      // window.open();
      var mimeType = "application/pdf";
      get("/protocol/download?file=" + u, { responseType: "blob" })
        .then((response) => {
          console.log(mimeType);
          const blob = new Blob([response.data], { type: mimeType });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = u;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
};
</script>

<style scoped>
.v-menu__content {
  box-shadow: none;
}

/* CSS talk bubble */
.talk-bubble {
  margin-left: 40px;
  display: inline-block;
  position: relative;
  /*width: 200px;*/
  height: auto;
  background-color: white;
}

.border {
  border: 2px solid deepskyblue;
}

.round {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.tri-right.border.left-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -25px;
  right: auto;
  top: -2px;
  bottom: auto;
  border: 23px solid;
  border-color: deepskyblue transparent transparent transparent;
}

.tri-right.left-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0;
  bottom: auto;
  border: 22px solid;
  border-color: white transparent transparent transparent;
}

/* talk bubble contents */
.talktext {
  padding: 0.8em;
  text-align: left;
  line-height: 1.3em;
  color: dimgrey;
}

.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}
</style>
