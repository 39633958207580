<template>
  <v-row no-gutters>
    <v-col v-if="articleChange.action === AUDIT_LOG_LOG_TYPE.WAREHOUSE_STOCK_CORRECTION" cols="auto">
      <v-row no-gutters>
        <v-col>
          <span>{{
            getFacilityById(articleChange.data.current.warehouseArticleFacilities[0].warehouseFacility.id)?.name ??
            "Magazyn usunięty"
          }}</span>
          <span>
            - nowa ilość:
            {{ articleChange.data.current.warehouseArticleFacilities[0].quantity }}</span
          >
        </v-col>
      </v-row>
      <v-row v-if="showExtraInfo" no-gutters>
        <v-col>
          <span>
            Poprzednia ilość:
            {{ articleChange.data.previous.warehouseArticleFacilities[0].quantity }}</span
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else-if="articleChange.action === AUDIT_LOG_LOG_TYPE.WAREHOUSE_STOCK_MIN_QUANTITY_UPDATE" cols="auto">
      <v-row no-gutters>
        <v-col>
          <span>{{
            getFacilityById(articleChange.data.current.warehouseArticleFacilities[0].warehouseFacility.id)?.name ??
            "Magazyn usunięty"
          }}</span>
          <span>
            - nowa ilość minimalna:
            {{ articleChange.data.current.warehouseArticleFacilities[0].min_quantity ?? 'brak' }}</span
          >
        </v-col>
      </v-row>
      <v-row v-if="showExtraInfo" no-gutters>
        <v-col>
          <span>
            Poprzednia ilość minimalna:
            {{ articleChange.data.previous.warehouseArticleFacilities[0].min_quantity ?? 'brak' }}</span
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else-if="articleChange.action === AUDIT_LOG_LOG_TYPE.WAREHOUSE_STOCK_CONSUMPTION" cols="auto">
      <v-row no-gutters>
        <v-col>
          <span>{{
            getFacilityById(articleChange.data.current.warehouseArticleFacilities[0].warehouseFacility.id)?.name ??
            "Magazyn usunięty"
          }}</span>
          <span>
            - nowa ilość:
            {{ articleChange.data.current.warehouseArticleFacilities[0].quantity }}</span
          >
          - zużyto:
          <span>{{
            articleChange.data.previous.warehouseArticleFacilities[0].quantity -
            articleChange.data.current.warehouseArticleFacilities[0].quantity
          }}</span>
        </v-col>
      </v-row>
      <v-row v-if="showExtraInfo" no-gutters>
        <v-col>
          <span>
            Poprzednia ilość:
            {{ articleChange.data.previous.warehouseArticleFacilities[0].quantity }}</span
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else-if="articleChange.action === AUDIT_LOG_LOG_TYPE.WAREHOUSE_STOCK_REPLENISH" cols="auto">
      <v-row no-gutters>
        <v-col>
          <span>{{
            getFacilityById(articleChange.data.current.warehouseArticleFacilities[0].warehouseFacility.id)?.name ??
            "Magazyn usunięty"
          }}</span>
          <span>
            - nowa ilość:
            {{ articleChange.data.current.warehouseArticleFacilities[0].quantity }}</span
          >
          - uzupełniono:
          <span>{{
            articleChange.data.current.warehouseArticleFacilities[0].quantity -
            articleChange.data.previous.warehouseArticleFacilities[0].quantity
          }}</span>
          <template v-if="articleChange.data.current.supplier.id !== articleChange.data.previous.supplier.id">
            <br />
            <span>Nowy dostawca - {{ getArticleSupplierById(articleChange.data.current.supplier.id).name }}</span>
          </template>
          <template v-if="articleChange.data.current.price !== articleChange.data.previous.price">
            <br />
            <span>Nowa cena zakupu - {{ articleChange.data.current.price }}</span>
          </template>
        </v-col>
      </v-row>
      <v-row v-if="showExtraInfo" no-gutters>
        <v-col>
          <span>
            Poprzednia ilość:
            {{ articleChange.data.previous.warehouseArticleFacilities[0].quantity }}</span
          >
          <br />
          <span
            >Poprzedni dostawca -
            {{ getArticleSupplierById(articleChange.data.previous.supplier.id)?.name ?? "Magazyn usunięty" }}</span
          >
          <br />
          <span>Poprzednia cena zakupu - {{ articleChange.data.previous.price }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else-if="articleChange.action === AUDIT_LOG_LOG_TYPE.WAREHOUSE_STOCK_TRANSFER" cols="auto">
      <v-row no-gutters>
        <v-col>
          <span>{{
            getFacilityById(articleChange.data.current.warehouseArticleFacilities[0].warehouseFacility.id)?.name ??
            "Magazyn usunięty"
          }}</span>
          <span>
            - nowa ilość:
            {{ articleChange.data.current.warehouseArticleFacilities[0].quantity }}
            (<b>{{
              articleChange.data.current.warehouseArticleFacilities[0].quantity -
              articleChange.data.previous.warehouseArticleFacilities[0].quantity
            }}</b
            >) </span
          ><br />
          <span>{{
            getFacilityById(articleChange.data.current.warehouseArticleFacilities[1].warehouseFacility.id)?.name ??
            "Magazyn usunięty"
          }}</span>
          <span>
            - nowa ilość:
            {{ articleChange.data.current.warehouseArticleFacilities[1].quantity }}</span
          >
          (<b
            >+{{
              articleChange.data.current.warehouseArticleFacilities[1].quantity -
              articleChange.data.previous.warehouseArticleFacilities[1].quantity
            }}</b
          >)
        </v-col>
      </v-row>
      <v-row v-if="showExtraInfo" no-gutters>
        <v-col>
          <span>{{
            getFacilityById(articleChange.data.current.warehouseArticleFacilities[0].warehouseFacility.id)?.name ??
            "Magazyn usunięty"
          }}</span>
          <span>
            - poprzednia ilość:
            {{ articleChange.data.previous.warehouseArticleFacilities[0].quantity }} </span
          ><br />
          <span>{{
            getFacilityById(articleChange.data.current.warehouseArticleFacilities[1].warehouseFacility.id)?.name ??
            "Magazyn usunięty"
          }}</span>
          <span>
            - poprzednia ilość:
            {{ articleChange.data.previous.warehouseArticleFacilities[1].quantity }}</span
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else-if="articleChange.action === AUDIT_LOG_LOG_TYPE.WAREHOUSE_ARTICLE_UPDATE" cols="auto">
      <v-row no-gutters>
        <v-col>
          <template v-for="(propertyValue, propertyKey) in articleChange.data.current">
            <span
              >{{ auditLogTypes.warehousePropertiesChange[propertyKey]?.name ?? "Magazyn usunięty" }} - nowa wartość:
              <b>{{ getNestedProperty(propertyKey, propertyValue) }}</b> </span
            ><br />
          </template>
        </v-col>
      </v-row>
      <v-row v-if="showExtraInfo" no-gutters>
        <v-col>
          <br />
          <template v-for="(propertyValue, propertyKey) in articleChange.data.previous">
            <span
              >{{ auditLogTypes.warehousePropertiesChange[propertyKey]?.name ?? "Magazyn usunięty" }} - poprzednia
              wartość: <b>{{ getNestedProperty(propertyKey, propertyValue) }}</b> </span
            ><br />
          </template>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="auto">
      <v-btn text x-small @click="showExtraInfo = !showExtraInfo"> {{ showExtraInfo ? 'Mniej': 'Więcej' }}</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { AUDIT_LOG_LOG_TYPE } from "../../constants";
import auditLogTypes from "../../auditLogTypes.json";

export default {
  name: "WarehouseAuditLogDetails",
  props: {
    articleChange: {},
  },
  data: () => ({
    showExtraInfo: false,
    auditLogTypes,
  }),
  computed: {
    AUDIT_LOG_LOG_TYPE() {
      return AUDIT_LOG_LOG_TYPE;
    },
    ...mapGetters("warehouse/facilities", ["getFacilityById"]),
    ...mapGetters("warehouse/suppliers", ["getArticleSupplierById"]),
    ...mapGetters("warehouse/groups", ["getArticleGroupById"]),
  },
  methods: {
    getNestedProperty(propertyKey, propertyValue) {
      switch (propertyKey) {
        case "articleGroup":
          return this.getArticleGroupById(propertyValue.id)?.name ?? "Grupa usunięta";
        case "supplier":
          return this.getArticleSupplierById(propertyValue.id)?.name ?? "Dostawca Usunięty";
        default:
          return propertyValue;
      }
    },
  },
};
</script>
