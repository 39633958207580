import axios from 'axios';

export default {
  async fetchWeekUserTasks(context, payload) {
    console.log(payload);
    const response = await axios.get(`task/fetchTasksForWeekCalendar`, { params: payload !== null ? payload : {} });
    context.commit('setUserTasks', response.data);
  },
  async fetchServiceTechnicians(context, payload) {
    const response = await axios.get(`user/getServiceTechniciansList`, { params: payload !== null ? payload : {} });
    const serviceTechnicians = response.data;
    const keys = Object.keys(response.data);
    keys.forEach((value) => {
      serviceTechnicians[value].selected = true;
    });
    context.commit('setUsers', serviceTechnicians);
  },
  async fetchUserVacations(context, payload) {
    const response = await axios.get(`vacation/calendarVacations`, { params: payload !== null ? payload : {} });
    const mappedData = response.data !== [] ? response.data.map((v) => v.id) : [];
    // console.log('parsowanie wakacji')
    // console.log(mappedData)
    context.commit('setVacationsByDay', { date: payload.date, data: mappedData });
  },
};
