export default {
  setChatTasks(state, task) {
    state.tasks = task;
  },
  setCurrentOldestTaskDate(state, date) {
    state.currentOldestTaskDate = date;
  },
  setAreAllTaskFetched(state, are) {
    state.areAllTaskFetched = are;
  },
  addChatTask(state, task) {
    state.tasks = [...state.tasks, task];
  },
  setRecalculateHelper(state, newState) {
    state.recalculateHelper = newState;
  },
  setChatStats(state, { unreadTasks, unreadModifications, unreadComments }) {
    state.chatStats.unreadTasks = unreadTasks;
    state.chatStats.unreadModifications = unreadModifications;
    state.chatStats.unreadComments = unreadComments;
  },
};
