<template>
  <v-app id="inspire">
    <nav-mobile v-if="isServiceTechnician" />
    <nav-office v-else />

    <app-notification />

    <v-main>
      <v-container fill-height fluid>
        <v-layout align-start justify-center>
          <v-progress-circular v-show="isLoading" :size="200" :width="7" color="tertiary" indeterminate />
          <router-view v-show="!isLoading"></router-view>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavOffice from "./components/layout/NavOffice";
import NavMobile from "./components/layout/NavMobile";
import { mapActions, mapGetters } from "vuex";
import AppNotification from "./components/ui/AppNotification.vue";

export default {
  name: "App",
  components: {
    AppNotification,
    NavMobile,
    NavOffice,
  },
  data() {
    return {
      value: 0,
    };
  },
  computed: {
    ...mapGetters({
      isBottomNavigation: "mobileLayoutSettings/isBottomNavigation",
      isServiceTechnician: "user/isServiceTechnician",
    }),
    ...mapGetters("layoutHelpers", ["isLoading"]),
    color() {
      switch (this.value) {
        case 0:
          return "blue-grey";
        case 1:
          return "teal";
        case 2:
          return "brown";
        case 3:
          return "indigo";
        default:
          return "blue-grey";
      }
    },
  },
  mounted() {
    this.setWindowHeight(window.innerHeight);
    this.setWindowWidth(window.innerWidth);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapActions("layoutHelpers", ["setWindowHeight", "setWindowWidth"]),
    onResize() {
      this.setWindowHeight(window.innerHeight);
      this.setWindowWidth(window.innerWidth);
    }
  }
};
</script>