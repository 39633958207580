<template>
  <v-card style="margin-top: 10px" :class="{'border_loader': getTasksAreLoading}">
    <v-toolbar flat color="transparent">
      <v-toolbar-title>Zadania</v-toolbar-title>
      <v-spacer></v-spacer>
      <add-task v-if="!job.isDeleted" :job="job"></add-task>
    </v-toolbar>
    <v-card-text v-if="tasks.length > 0">
      <task-item
        v-for="task in tasks.filter((x) => x.isDeleted === job.isDeleted)"
        :key="task.id"
        :can-edit="!job.isDeleted"
        :job="job"
        :task="task"></task-item>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddTask from "../../components/task/AddTask";
import TaskItem from "../../components/task/TaskItem";

export default {
  name: "JobTasksList",
  components: {
    TaskItem,
    AddTask,
  },
  computed: {
    ...mapGetters({
      job: "jobs/getJob",
      tasks: "tasks/getTasks",
    }),
    ...mapGetters('tasks', ['getTasksAreLoading'])
  },
  async created() {
    await this.fetchTasks();
  },
  methods: {
    ...mapActions('tasks', ['fetchTasks'])
  },
};
</script>

<style scoped>
.border_loader {
  position: relative;
  z-index: 0;
  border-radius: 10px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #2daae2;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
</style>