import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      mobileReleases: [],
      loadingMobileReleases: false,
      submittingMobileReleaseReleaseId: null,
      submittingMobileReleaseDeleteId: null,
      submittingMobileReleaseRollbackId: null,
      uploadingApk: null,
      uploadProgress: null,
    }
  },
  mutations,
  actions,
  getters,
};
