<template>
  <div>
    <v-row v-if="editor" class="light-blue lighten-5" no-gutters>
      <v-col cols="auto">
        <v-btn
          :color="editor.isActive('bold') ? 'blue' : 'white'"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :outlined="editor.isActive('bold')"
          elevation="0"
          icon
          tile
          @click="editor.chain().focus().toggleBold().run()"
        >
          <v-icon color="black">mdi-format-bold</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          :color="editor.isActive('italic') ? 'blue' : 'white'"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :outlined="editor.isActive('italic')"
          elevation="0"
          icon
          tile
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <v-icon color="black">mdi-format-italic</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          :color="editor.isActive('strike') ? 'blue' : 'white'"
          :disabled="!editor.can().chain().focus().toggleStrike().run()"
          :outlined="editor.isActive('strike')"
          elevation="0"
          icon
          tile
          @click="editor.chain().focus().toggleStrike().run()"
        >
          <v-icon color="black">mdi-format-underline</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-menu offset-x>
          <template #activator="{ on, attrs }">
            <v-btn elevation="0" icon tile v-bind="attrs" v-on="on">
              <v-icon :color="getColor()">mdi-palette</v-icon>
            </v-btn>
          </template>
          <v-sheet>
            <v-color-picker
              v-model="computedColor"
              :swatches="swatches"
              class="ma-2"
              hide-canvas
              hide-inputs
              hide-sliders
              show-swatches
              @update:color="editor.chain().focus().setColor(getColor()).run()"
            ></v-color-picker>
          </v-sheet>
        </v-menu>
      </v-col>
      <v-col cols="auto">
        <v-btn elevation="0" icon tile>
          <v-icon :color="computedPickedColor" @click="editor.chain().focus().setColor(pickedColor).run()"
            >mdi-water
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn elevation="0" icon tile>
          <v-icon @click="editor.chain().focus().unsetColor().run()">mdi-water-off-outline</v-icon>
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>

      <v-col cols="auto">
        <v-btn
          :color="editor.isActive('heading', { level: 1 }) ? 'blue' : 'white'"
          :outlined="editor.isActive('heading', { level: 1 })"
          elevation="0"
          icon
          tile
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        >
          <v-icon color="black">mdi-format-header-1</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn
          :color="editor.isActive('heading', { level: 2 }) ? 'blue' : 'white'"
          :outlined="editor.isActive('heading', { level: 2 })"
          elevation="0"
          icon
          tile
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        >
          <v-icon color="black">mdi-format-header-2</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn
          :color="editor.isActive('heading', { level: 3 }) ? 'blue' : 'white'"
          :outlined="editor.isActive('heading', { level: 3 })"
          elevation="0"
          icon
          tile
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        >
          <v-icon color="black">mdi-format-header-3</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn
          :color="editor.isActive('paragraph') ? 'blue' : 'white'"
          :outlined="editor.isActive('paragraph')"
          elevation="0"
          icon
          tile
          @click="editor.chain().focus().setParagraph().run()"
        >
          <v-icon color="black">mdi-format-paragraph</v-icon>
        </v-btn>
      </v-col>

      <v-spacer></v-spacer>

      <v-col cols="auto">
        <v-btn
          color="white"
          :disabled="!editor.can().chain().focus().undo().run()"
          elevation="0"
          icon
          tile
          @click="editor.chain().focus().undo().run()"
        >
          <v-icon color="black">mdi-undo</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn
          color="white"
          elevation="0"
          icon
          tile
          :disabled="!editor.can().chain().focus().redo().run()"
          @click="editor.chain().focus().redo().run()"
        >
          <v-icon color="black">mdi-redo</v-icon>
        </v-btn>
      </v-col>

      <!--      <button :disabled="!editor.can().chain().focus().undo().run()" @click="editor.chain().focus().undo().run()">-->
      <!--        undo-->
      <!--      </button>-->
      <!--      <button :disabled="!editor.can().chain().focus().redo().run()" @click="editor.chain().focus().redo().run()">-->
      <!--        redo-->
      <!--      </button>-->
    </v-row>
    <editor-content :editor="editor" />
    <!--    <div v-if="componentText">-->
    <!--      {{ componentText.length }}/{{ textLimit }} znaków-->
    <!--    </div>-->
    <br />
  </div>
</template>

<script>
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-2";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import { Placeholder } from "@tiptap/extension-placeholder";

export default {
  components: {
    EditorContent,
  },
  model: {
    prop: "parentValue",
    event: "newValue",
  },
  props: {
    parentValue: { type: String, default: "" },
    textLimit: { type: Number },
    placeholder: { type: String, default: ""}
  },

  data: () => ({
    editor: null,
    color: { hex: "#000000" },
    pickedColor: "#000000",
    swatches: [
      ["#db5e8a", "#c00909", "#000000"],
      ["#FFFF00", "#AAAA00", "#555500"],
      ["#00FF00", "#00AA00", "#005500"],
      ["#00FFFF", "#00AAAA", "#005555"],
      ["#0000FF", "#0000AA", "#000055"],
    ],
  }),
  computed: {
    computedColor: {
      get() {
        return this.color;
      },
      set(v) {
        this.color = v;
        this.pickedColor = this.getColor();
        console.log(this.pickedColor);
      },
    },
    componentText: {
      get() {
        return this.parentValue;
      },
      set(v) {
        this.$emit("newValue", v);
      },
    },
    computedPickedColor() {
      console.log(this.pickedColor);
      return this.pickedColor;
    },
  },

  watch: {
    editor: {
      handler(v) {
        console.log(this.color);
        const { state } = this.editor;
        const text = state.doc.textContent;
        const textStyle = this.editor.getAttributes("textStyle");
        if (this.editor.getAttributes("textStyle") !== null) {
          this.color.hex = textStyle.color;
        }
        if (text === "") {
          this.componentText = null;
        }
      },
      deep: true,
    },
    parentValue(n, o){
      if (n === null) {
        this.componentText = '';
        this.editor.commands.clearContent(true);
      }
    }
  },

  mounted() {
    this.editor = new Editor({
      extensions: [StarterKit,
        TextStyle,
        Color,
        Placeholder.configure({
        placeholder: this.placeholder,
      }),],
      content: this.componentText,
      onUpdate: ({ editor }) => {
        this.componentText = editor.getHTML();
      },
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    setColor(v) {
      this.color = this.editor.chain().focus().color;
    },
    getColor() {
      console.log(this.color);
      return this.color.hex;
    },
  },
};
</script>

<style lang="scss">
/* Basic editor styles */

.ProseMirror:focus {
  outline: 1px solid grey;
}

.ProseMirror {
  min-height: 100px !important;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}


.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
</style>
