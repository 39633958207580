<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <template #activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon left> mdi-plus</v-icon>
        dostawca
      </v-btn>
    </template>
    <v-card class="d-flex flex-column justify-space-between">
      <v-card-title>
        <span class="text-h5">Nowy dostawca</span>
      </v-card-title>
      <v-card-text>
        <v-card v-if="dialog" class="d-flex flex-column justify-space-between" elevation="0">
          <v-row class="align-center justify-start" no-gutters>
            <v-col>
              <v-text-field
                v-model="newSupplier.name"
                :rules="[rules.isNameEmpty(), rules.checkIfSupplierExists(suppliers)]"
                label="Nazwa dostawcy"></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-btn text @click="dialog = false">
          <v-icon left> mdi-cancel</v-icon>
          Anuluj
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="isButtonDisabled" :loading="supplierSubmitting" color="primary" @click="createNewSupplier">
          <v-icon left> mdi-home-plus-outline</v-icon>
          Utwórz dostawcę
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import warehouseTypes from "../../../warehouseTypes.json";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "WarehouseCreateSupplierDialog",
  computed: {
    ...mapGetters({
      suppliers: "warehouse/suppliersSettings/articleSuppliersDetails",
      supplierSubmitting: "warehouse/suppliersSettings/supplierDetailsSubmitting",
    }),
    isButtonDisabled() {
      const nameErrors = [
        this.rules.isNameEmpty()(this.newSupplier.name),
        this.rules.checkIfSupplierExists(this.suppliers)(this.newSupplier.name),
      ].filter((error) => typeof error === "string");
      return nameErrors.length > 0;
    },
  },
  data: () => ({
    dialog: false,
    newSupplier: {
      name: null,
    },
    rules: {
      isNameEmpty() {
        return (v) => !!v || "Nazwa nie może być pusta";
      },
      checkIfSupplierExists(suppliers) {
        return (v) => !v || !suppliers.map((v) => v.name).includes(v) || "Dostawca już istnieje";
      },
    },
    warehouseTypes,
  }),
  methods: {
    ...mapActions("warehouse/suppliers", ["postNewSupplier"]),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    async createNewSupplier() {
      try {
        await this.postNewSupplier({ supplierData: this.newSupplier });
        await this.notifyInfo("Utworzono nowego dostawcę");
      } catch (e) {
        await this.notifyError(e);
        console.log(e);
      }
      this.dialog = false;
      this.newSupplier = {
        name: null,
      };
    },
  },
};
</script>
