var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"d-flex flex-nowrap",style:({ cursor: _vm.draggingTask !== null ? 'grabbing' : '' }),attrs:{"no-gutters":""}},[(_vm.sameAssigmentHandle)?_c('calendar-same-assignment-dialog',{attrs:{"my-list":_vm.myList,"new-user":_vm.newUser,"old-user":_vm.oldUser,"same-assigment-handle":_vm.sameAssigmentHandle},on:{"cancelAssignment":_vm.cancelAssignment,"resolveAssignment":_vm.resolveAssignment}}):_vm._e(),_vm._l((_vm.myList === undefined ? _vm.users.length : _vm.myList),function(list,k,index){return _c('v-col',{key:k + '' + _vm.date,staticClass:"pa-0",class:{ border_loader: _vm.isColLoading(list.id, _vm.date) },staticStyle:{"display":"flex","flex-flow":"column","height":"100%"},style:({
        border: _vm.$store.getters['calendarTasks/doesUserHasVacations']({ date: _vm.date, id: list.id })
          ? '2px dashed #CCCCCC'
          : '',
        width: _vm.width,
        'min-width': _vm.getCardWidthPX,
        'background-color': index % 2 || (index === undefined && k % 2) ? 'transparent' : 'rgba(0,0,0,0.03)',
      }),attrs:{"cols":"auto"}},[(_vm.serviceTechnicians.find((u) => u.id === list.id) !== undefined)?_c('v-row',{staticStyle:{"height":"64px","flex":"0 1 auto"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-5 pl-3"},[_c('v-icon',{attrs:{"color":_vm.serviceTechnicians.find((u) => u.id === list.id).color,"size":"24"}},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(_vm.serviceTechnicians.find((u) => u.id === list.id).name)+" ")],1)],1):_vm._e(),(
          list.tasks !== undefined &&
          list.tasks.length === 0 &&
          (!(list.id == _vm.newUser && _vm.targetDate === _vm.date) || _vm.draggingTask === null)
        )?_c('v-card',{staticStyle:{"flex":"0 1 auto"},attrs:{"color":"transparent","elevation":"0"}},[_c('v-card-title',{staticStyle:{"padding":"0","margin":"16px","-webkit-user-select":"none","/* Safari */\n            -moz-user-select":"none","/* Firefox */\n            -ms-user-select":"none","/* IE10+/Edge */\n            user-select":"none"}},[_vm._v(" "+_vm._s(_vm.emptyOrVacations(list.id))+" ")])],1):_vm._e(),(_vm.isDragAndDropActive)?_c('draggable',{key:'' + _vm.date + ' ' + list.id,staticClass:"list-group",staticStyle:{"flex":"1 1 auto"},style:({
          'background-color': _vm.columnBackground(list),
        }),attrs:{"id":list.id + '_' + _vm.date,"delay":_vm.isMobile ? 400 : 50,"disabled":_vm.getColLoading.length > 0 || (_vm.hoveredTask && _vm.hoveredTask.task.status === 'completed'),"group":{ name: 'group', pull: !_vm.cancel, put: !_vm.cancel },"move":_vm.checkMove,"force-fallback":"true"},on:{"end":(event) => _vm.endDrag(null, event),"start":(event) => _vm.startDrag(list.id, event)},model:{value:(list.tasks),callback:function ($$v) {_vm.$set(list, "tasks", $$v)},expression:"list.tasks"}},_vm._l((list.tasks),function(element,i){return (!_vm.loading && list.tasks.length > 0)?_c('v-card',{key:i,ref:'' + element.id + '_' + _vm.date,refInFor:true,class:{
            myCard: _vm.draggingTask !== null && _vm.draggingTask.id === element.id && element.task.status !== 'completed',
          },staticStyle:{"margin":"0 10px 5px","-webkit-user-select":"none","/* Safari */\n            -moz-user-select":"none","/* Firefox */\n            -ms-user-select":"none","/* IE10+/Edge */\n            user-select":"none"},style:({ cursor: element.task.status === 'completed' ? 'default' : 'grab' }),attrs:{"color":_vm.taskCardBackground(index, element),"elevation":element.task.status === 'completed' ? 0 : 3,"outlined":""},on:{"click":function($event){return _vm.show($event, '' + element.id + '_' + _vm.date, element)},"mouseleave":function($event){return _vm.setSelectedTask(null)},"mouseover":function($event){return _vm.setSelectedTask(element)}}},[_c('calendar-task-card',{attrs:{"task":element.task}})],1):_vm._e()}),1):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }