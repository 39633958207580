<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <template #activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon left> mdi-plus</v-icon>
        magazyn
      </v-btn>
    </template>
    <v-card class="d-flex flex-column justify-space-between">
      <v-card-title>
        <span class="text-h5">Tworzenie magazynu</span>
      </v-card-title>
      <v-card-text>
        <v-card v-if="dialog" class="d-flex flex-column justify-space-between" elevation="0">
          <v-row class="align-center justify-start" no-gutters>
            <v-col>
              <v-text-field
                v-model="newFacility.name"
                :rules="[rules.isNameEmpty(), rules.checkIfFacilityExists(facilities)]"
                label="Nazwa magazynu"></v-text-field>
            </v-col>
          </v-row>
          <form-row-with-icon text="Typ magazynu">
            <v-chip
              v-for="(tt, k_tt, i) in warehouseTypes.facilityType"
              :key="k_tt"
              :autofocus="true"
              :color="newFacility.type === k_tt ? 'black' : 'white'"
              :outlined="newFacility.type === k_tt"
              :tabindex="0"
              :text-color="newFacility.type === k_tt ? 'black' : 'grey'"
              class="mr-2"
              label
              @click="newFacility.type = k_tt">
              <v-icon :color="newFacility.type === k_tt ? 'black' : 'grey'" left>
                {{ tt.icon }}
              </v-icon>
              {{ tt.name }}
            </v-chip>
          </form-row-with-icon>
        </v-card>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-btn text @click="dialog = false">
          <v-icon left> mdi-cancel</v-icon>
          Anuluj
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="isButtonDisabled" :loading="facilitySubmitting" color="primary" @click="createNewFacility">
          <v-icon left> mdi-home-plus-outline</v-icon>
          Utwórz magazyn
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormRowWithIcon from "../../ui/FormRowWithIcon.vue";
import warehouseTypes from "../../../warehouseTypes.json";
import { WAREHOUSE_FACILITY_TYPE } from "../../../constants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "WarehouseCreateFacilityDialog",
  components: { FormRowWithIcon },
  data: () => ({
    dialog: false,
    newFacility: {
      name: null,
      type: WAREHOUSE_FACILITY_TYPE.STATIONARY,
    },
    rules: {
      isNameEmpty() {
        return (v) => !!v || "Nazwa nie może być pusta";
      },
      checkIfFacilityExists(facilities) {
        return (v) => !v || !facilities.map((v) => v.name).includes(v) || "Magazyn już istnieje";
      },
    },
    warehouseTypes,
  }),
  computed: {
    ...mapGetters({
      facilities: "warehouse/facilitiesSettings/facilitiesDetails",
      facilitySubmitting: "warehouse/facilitiesSettings/facilityDetailsSubmitting",
    }),
    isButtonDisabled() {
      const nameErrors = [
        this.rules.isNameEmpty()(this.newFacility.name),
        this.rules.checkIfFacilityExists(this.facilities)(this.newFacility.name),
      ].filter((error) => typeof error === "string");
      return nameErrors.length > 0;
    },
  },
  methods: {
    ...mapActions("warehouse/facilitiesSettings", ["postNewFacility"]),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    async createNewFacility() {
      try {
        await this.postNewFacility({ facilityData: this.newFacility });
        await this.notifyInfo("Utworzono nowy magazyn");
      } catch (e) {
        await this.notifyError(e);
        console.log(e);
      }
      this.dialog = false;
      this.newFacility = {
        name: null,
        type: WAREHOUSE_FACILITY_TYPE.STATIONARY,
      };
    },
  },
};
</script>
