import axios from 'axios';
import Vue from "vue";

export default {
  async fetchArticleGroupsDetails({ commit }, payload) {
    commit('setArticleGroupsDetailsLoading', true);

    const response = await axios.get(`warehouse/articleGroup/all/article/stats`);
    console.debug(response.data, 'response.data');
    commit('setArticleGroupsDetails', response.data);
    commit('setArticleGroupsDetailsLoading', false);
  },
  async postNewArticleGroup({ commit, getters }, { articleGroupData }) {
    console.debug(articleGroupData, 'payload');
    commit('setSubmittingArticleGroupDetails', true);
    try {
      const response = await axios.post(`warehouse/articleGroup/add`, { ...articleGroupData });
      console.debug(response.data, 'response.data');
      articleGroupData.id = response.data.id;
      commit('setArticleGroupsDetails', [...getters.articleGroupsDetails, articleGroupData]);
    } catch (e) {
      throw e;
    } finally {
      commit('setSubmittingArticleGroupDetails', false);
    }
  },
  async postEditedArticleGroup({ commit, getters }, { groupData }) {
    console.debug(groupData, 'payload');
    commit('setSubmittingArticleGroupDetails', true);
    // await new Promise((resolve) => setTimeout(resolve, 2000)); //TODO: TEST
    try {
      const response = await axios.post(`warehouse/articleGroup/${groupData.id}/edit`, { ...groupData });
      console.debug(response.data, 'response.data');
      const facilityIdx = getters.articleGroupsDetails.findIndex((value) => value.id === groupData.id);
      if (facilityIdx === -1) {
        console.error('could not find article group after submitting edition');
      }
      Vue.set(getters.articleGroupsDetails, facilityIdx, groupData);
    } catch (e) {
      throw e;
    } finally {
      commit('setSubmittingArticleGroupDetails', false);
    }
  },
  async postDeleteArticleGroup({ commit, getters }, { groupData }) {
    console.debug(groupData, 'payload');
    commit('setSubmittingArticleGroupDetails', true);
    // await new Promise((resolve) => setTimeout(resolve, 2000)); //TODO: TEST
    try {
      const response = await axios.delete(`warehouse/articleGroup/${groupData.id}/delete`);
      console.debug(response.data, 'response.data');
      const facilities = getters.articleGroupsDetails.filter((value) => value.id !== groupData.id);
      commit('setArticleGroupsDetails', facilities);
    } catch (e) {
      throw e;
    } finally {
      commit('setSubmittingArticleGroupDetails', false);
    }
  },
};
