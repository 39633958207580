<template>
  <v-container>
    <v-row>
      <v-col lg="6" sm="3" cols="12" class="logo-container">
        <picture>
          <img class="logo" src="/img/radmax-logo.svg" alt="Radmax" />
        </picture>
      </v-col>

      <v-col lg="6" sm="9" cols="12">
        <v-card :loading="isLogging">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Zaloguj</v-toolbar-title>
          </v-toolbar>
          <v-card-text style="padding-bottom: 0">
            <v-form>
              <v-text-field
                v-model="email"
                :error-messages="emailErrors"
                label="E-mail"
                autofocus
                required
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
                @keydown.enter="handleSubmit"
              />
              <v-text-field
                v-model="password"
                :error-messages="passwordErrors"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                label="Hasło"
                required
                @click:append="show = !show"
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
                @keydown.enter="handleSubmit"
              />
              <v-btn style="text-transform: unset; padding-left: 0" text @click="$router.push({ name: 'forgot' })"
                >Zapomniałeś hasła?</v-btn
              >
            </v-form>
          </v-card-text>
          <v-sheet
            v-if="error"
            color="error"
            elevation="4"
            rounded
            style="padding: 20px; margin-right: 30px; margin-left: 30px; margin-bottom: 10px"
            >Sprawdź <strong>e-mail</strong> lub <strong>hasło</strong>.
          </v-sheet>
          <v-card-actions class="d-flex justify-end pa-3">
            <v-btn :disabled="buttonDisabled" type="submit" color="secondary" min-width="50%" @click="handleSubmit">
              Zaloguj
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "LoginPage",

  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required },
    data: null,
  },

  data: () => ({
    show: false,
    password: "",
    email: "",
    error: "",
    isLogging: false,
    data: null,
  }),

  mounted() {
    this.data = this.$route.params.redirect ?? null;
  },

  computed: {
    buttonDisabled() {
      return !!(this.emailErrors.length + this.passwordErrors.length) || !!!this.email.length;
    },
    showLoading() {
      return "loading";
    },
    emailErrors() {
      if (!this.$v.email.$dirty) return [];

      const errors = [];
      if (!this.$v.email.email) errors.push("E-mail niepoprawny");
      if (!this.$v.email.required) errors.push("E-mail wymagany");
      return errors;
    },
    passwordErrors() {
      if (!this.$v.password.$dirty) return [];

      const errors = [];
      if (!this.$v.password.required) errors.push("Hasło wymagane");
      return errors;
    },
    ...mapGetters({ isServiceTechnician: "user/isServiceTechnician" }),
  },
  methods: {
    clear() {
      this.$v.$reset();
      this.email = "";
      this.password = "";
    },
    clearPassword() {
      this.password = "";
    },
    async handleSubmit() {
      this.$v.email.$touch();
      this.$v.password.$touch();

      if (this.buttonDisabled) return;

      this.isLogging = true;
      try {
        await this.$store.dispatch("user/login", {
          username: this.email,
          password: this.password,
        });

        if (this.data && this.data !== "/") {
          return await this.$router.replace({ path: this.data });
        }
        if (this.isServiceTechnician) {
          return await this.$router.replace({ name: "mobileDashboard" });
        }
        if (!this.isServiceTechnician) {
          return await this.$router.replace({ name: "home" });
        }
      } catch (e) {
        console.log(e);
        this.clearPassword();
        this.error = "Niepoprawne hasło lub e-mail";
      }
    },
  },
};
</script>

<style scoped>
.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 100%;
}
</style>
