<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters>
        <v-col>
          <border-active-v-sheet :is-active="!!(filters.taskTypes.length)" class="mt-1 pa-1">
            <v-row class="pb-1 d-flex flex-row justify-space-between" no-gutters>
              <v-col class="d-flex flex-row align-end">
                <v-icon color="grey" size="24"> mdi-file-question-outline</v-icon>
                <span class="pl-2">Typy zadań</span>
              </v-col>
              <v-col cols="auto">
                <single-multi-array-selector
                  v-model="singleOrMultiSelect"
                  :all-members="Object.keys(types.taskTypes)"
                  :target-array="filters.taskTypes"
                  @updateArray="(arr) => handleSelectedTaskType(arr)"></single-multi-array-selector>
              </v-col>
            </v-row>
            <v-row v-if="filters !== {}" no-gutters>
              <v-col class="d-flex flex-column" cols="auto">
                <jobs-list-filter-column-single-select-chip
                  :arr="selectedTypes"
                  :cols="'auto'"
                  :compared-array-key="null"
                  :compared-value="'selected'"
                  :display-key="'name'"
                  :md="'auto'"
                  small
                  @selectElement="(n) => handleSelectedTaskType(n)" />
              </v-col>
            </v-row>
          </border-active-v-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import BorderActiveVSheet from "./BorderActiveVSheet.vue";
import JobsListFilterColumnSingleSelectChip from "../../job/JobsListFilterColumnSingleSelectChip.vue";
import { getKeyByValue } from "../../../helper";
import types from "../../../types.json";
import SingleMultiArraySelector from "./SingleMultiArraySelector.vue";

export default {
  name: "UnassignedFiltersExtended",
  components: {
    SingleMultiArraySelector,
    BorderActiveVSheet,
    JobsListFilterColumnSingleSelectChip,
  },
  data: () => ({
    tab: null,
    types,
    singleOrMultiSelect: false,
  }),
  computed: {
    selectedTypes() {
      return Object.keys(types.taskTypes).map((x) => ({
        ...types.taskTypes[x],
        key: x,
        selected: this.isTaskTypeSelected(x),
      }));
    },
    filters: {
      get() {
        return this.$store.state.calendarTasks.unassignedFilters;
      },
      set(v) {
        this.$store.dispatch("calendarTasks/setUnassignedFilters", v);
      },
    },
  },
  methods: {
    handleSelectedFilter(filterKey, item) {
      if (Array.isArray(item)) {
        return (this.filters = { ...this.filters, ...{ [filterKey]: item } });
      }
      if (this.singleOrMultiSelect) {
        return (this.filters = { ...this.filters, ...{ [filterKey]: [item.key] } });
      }
      const { selected, ...newItem } = item;

      const arr = [...this.filters[filterKey]];
      const i = this.filters[filterKey].findIndex((v) => v === item.key);

      if (i !== -1) {
        arr.splice(i, 1);
      } else {
        arr.push(item.key);
      }

      this.filters = { ...this.filters, [filterKey]: arr };
    },
    isTaskTypeSelected(item) {
      return this.filters.taskTypes.find((v) => v === item) !== undefined;
    },
    handleSelectedTaskType(item) {
      this.handleSelectedFilter("taskTypes", item);
      // getKeyByValue(types.taskTypes, { selected: item.selected, ...item });
    },
  },
};
</script>