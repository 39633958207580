export default {
  getChatTasks(state) {
    return state.tasks;
  },
  getCurrentOldestTaskDate(state) {
    return state.currentOldestTaskDate;
  },
  getAreAllTaskFetched(state) {
    return state.areAllTaskFetched;
  },
  getChatTask: (state) => (taskId) => {
    return state.tasks.find((t) => t.id === taskId);
  },
  getChatStats(state) {
    return state.chatStats;
  },
  countUnreadTasksComments: (state) => (taskId) => {
    const task = state.tasks.find((t) => t.id === taskId);
    if ((!task && !task.comments) || !task.hasUserRead.unreadCommentId) {
      console.log('MINUS ZERO');
      return 0;
    }

    const unreadCommentIndex = task.comments.findIndex((comment) => comment.id === task.hasUserRead.unreadCommentId);
    console.log('MINUS');
    console.log(unreadCommentIndex);

    if (unreadCommentIndex !== -1) {
      return task.comments.length - unreadCommentIndex;
    }

    return 0;
  },
  getRecalculateHelper(state) {
    return state.recalculateHelper;
  },
  isChatTaskIsBeingRead: (state) => (taskId) => {
    return state.recalculateHelper.includes(taskId);
  },
};
