<template>
  <v-row no-gutters>
    <v-col>
      <v-row v-if="filters.planningMode === CALENDAR_PLANNING_MODE.UNPLANNED" class="mb-1" no-gutters>
        <v-col cols="12">
          <v-chip
            :color="filters.showUnplanned ? 'primary' : 'rgba(0, 0, 0, 0.12)'"
            :text-color="filters.showUnplanned ? 'black' : 'grey'"
            label
            outlined
            style="width: 100%"
            @click="filters.showUnplanned = !filters.showUnplanned">
            <v-icon :color="filters.showUnplanned ? 'black' : 'grey'" left> mdi-account-question-outline</v-icon>
            Nieprzypisane
          </v-chip>
        </v-col>
      </v-row>

      <border-active-v-sheet :is-active="!!filters.users.length" class="pa-1 mt-1">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row v-if="filters !== {}" class="pb-2 d-flex flex-row justify-space-between" no-gutters>
              <v-col class="d-flex flex-row align-center">
                <v-icon :color="filters.users.length ? 'black' : 'grey'" size="24"> mdi-calendar-account</v-icon>
                <span class="pl-2">Przypisane</span>
              </v-col>
              <v-col cols="auto">
                <single-multi-array-selector
                  v-model="singleOrMultiSelect"
                  :all-members="workers.map((w) => w.id)"
                  :target-array="filters.users"
                  @updateArray="(arr) => handleSelectedWorkers(arr)"></single-multi-array-selector>
              </v-col>
            </v-row>
            <v-row v-if="filters !== {}" class="d-flex flex-column" no-gutters>
              <v-row no-gutters>
                <v-col class="d-flex flex-column" cols="6" md="auto">
                  <form-row-with-icon
                    :disabled="singleOrMultiSelect"
                    :toggleable-value="isRadmax"
                    text="Radmax"
                    toggleable
                    @toggled="selectTeam(isRadmax, radmaxWorkers)">
                    <jobs-list-filter-column-single-select-chip
                      :arr="radmaxWorkers.map((x) => ({ ...x, icon: 'mdi-circle', selected: findUser(x) }))"
                      :compared-array-key="null"
                      :compared-value="'selected'"
                      :md="'auto'"
                      :prop-class="'me-2'"
                      display-key="name"
                      has-icon
                      small
                      @selectElement="(n) => handleSelectedWorkers(n, singleOrMultiSelect)" />
                  </form-row-with-icon>
                </v-col>
                <v-col class="d-flex flex-column" cols="6" md="auto">
                  <form-row-with-icon
                    :disabled="singleOrMultiSelect"
                    :toggleable-value="isExternal"
                    text="Podwykonawcy"
                    toggleable
                    @toggled="selectTeam(isExternal, externalWorkers)">
                    <jobs-list-filter-column-single-select-chip
                      :arr="externalWorkers.map((x) => ({ ...x, icon: 'mdi-circle', selected: findUser(x) }))"
                      :compared-array-key="null"
                      :compared-value="'selected'"
                      :md="'auto'"
                      :prop-class="'me-2'"
                      display-key="name"
                      has-icon
                      small
                      @selectElement="(n) => handleSelectedWorkers(n, singleOrMultiSelect)" />
                  </form-row-with-icon>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
        </v-row>
      </border-active-v-sheet>

      <border-active-v-sheet
        :is-active="!!filters.realizationStartDate || !!filters.realizationEndDate"
        class="pa-1 mt-1">
        <v-row class="pb-1 d-flex flex-row justify-space-between" no-gutters>
          <v-col class="d-flex flex-row align-center">
            <v-icon color="grey" size="24"> mdi-calendar-clock</v-icon>
            <span class="pl-2">{{
              CALENDAR_PLANNING_MODE.UNPLANNED === unassignedFilters.planningMode
                ? "Z datą (nieprzypisane)"
                : "Data realizacji"
            }}</span>
          </v-col>
        </v-row>
        <text-field-extended-date-picker
          v-model="filters.realizationStartDate"
          empty-text="początek"
          icon="mdi-calendar-arrow-right"
          label-text="Od" />
        <text-field-extended-date-picker
          v-model="filters.realizationEndDate"
          class="pb-4"
          empty-text="koniec"
          icon="mdi-calendar-arrow-left"
          label-text="Do" />
      </border-active-v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import FormRowWithIcon from "../../ui/FormRowWithIcon.vue";
import JobsListFilterColumnSingleSelectChip from "../../job/JobsListFilterColumnSingleSelectChip.vue";
import { mapGetters } from "vuex";
import BorderActiveVSheet from "./BorderActiveVSheet.vue";
import TextFieldExtendedDatePicker from "../../task/TextFieldExtendedDatePicker.vue";
import { CALENDAR_PLANNING_MODE } from "../../../constants";
import SingleMultiArraySelector from "./SingleMultiArraySelector.vue";

export default {
  name: "UnassignedFiltersBasic",
  components: {
    SingleMultiArraySelector,
    TextFieldExtendedDatePicker,
    BorderActiveVSheet,
    JobsListFilterColumnSingleSelectChip,
    FormRowWithIcon,
  },
  data: () => ({
    menu: [false, false],
    tab: null,
    singleOrMultiSelect: false,
  }),
  computed: {
    CALENDAR_PLANNING_MODE() {
      return CALENDAR_PLANNING_MODE;
    },
    filters: {
      get() {
        return this.$store.state.calendarTasks.unassignedFilters;
      },
      set(v) {
        this.$store.dispatch("calendarTasks/setUnassignedFilters", v);
      },
    },
    showUnassignedPlannedColumn: {
      get() {
        return this.$store.state.layoutHelpers.showUnassignedPlannedColumn;
      },
      set(v) {
        this.$store.commit("layoutHelpers/setShowUnassignedPlannedColumn", v);
      },
    },
    showUnassignedUnplannedColumn: {
      get() {
        return this.$store.state.layoutHelpers.showUnassignedUnplannedColumn;
      },
      set(v) {
        this.$store.commit("layoutHelpers/setShowUnassignedUnplannedColumn", v);
      },
    },
    ...mapGetters({
      workers: "calendarTasks/getUsers",
      radmaxWorkers: "calendarTasks/getRadmax",
      externalWorkers: "calendarTasks/getExternal",
    }),
    ...mapGetters("calendarTasks", ["unassignedFilters"]),
    isExternal() {
      return this.externalWorkers.map((x) => x.id).every((r) => this.filters.users.includes(r));
    },
    isRadmax() {
      return this.radmaxWorkers.map((x) => x.id).every((r) => this.filters.users.includes(r));
    },
  },
  methods: {
    handleSelectedWorkers(item) {
      if (Array.isArray(item)) {
        return (this.filters = { ...this.filters, ...{ users: item } });
      }
      if (this.singleOrMultiSelect) {
        return (this.filters = { ...this.filters, ...{ users: [item.id] } });
      }
      const arr = [...this.filters.users];
      const i = this.filters.users.findIndex((v) => v === item.id);
      if (i !== -1) {
        arr.splice(i, 1);
      } else {
        arr.push(item.id);
      }
      this.filters = { ...this.filters, ...{ users: arr } };
    },
    findUser(item) {
      if (this.filters.users === undefined) {
        return false;
      }
      if (this.filters.users.length > 0) {
        return this.filters.users.find((v) => v === item.id) !== undefined;
      }
      return false;
    },
    selectTeam(isTeam, teamToSet) {
      const tempWorkers = new Set(this.filters.users);
      const a = isTeam;
      teamToSet.forEach((x) => {
        if (!a) {
          tempWorkers.add(x.id);
        } else {
          tempWorkers.delete(x.id);
        }
      });
      this.filters = { ...this.filters, ...{ users: Array.from(tempWorkers) } };
    },
  },
};
</script>