export default {
  isLoading(state) {
    return state.loadingProcesses.length !== 0;
  },
  getLoadingProcesses(state) {
    return state.loadingProcesses;
  },
  getMenuPopUpWidth(state) {
    return state.menuPopUpWidth;
  },
  getMenuParams(state) {
    return state.menuParams;
  },
  isCurrentViewRefreshing(state) {
    return state.refreshCurrentView;
  },
  getWindowHeight(state) {
    return state.windowHeight;
  },
  getWindowWidth(state) {
    return state.windowsWidth;
  },
  getUnassignedFiltersWidth(state) {
    if (!state.showUnsignedFilters) {
      return 0;
    }
    return state.unassignedFiltersWidth;
  },
  getUnassignedFiltersWidthPX(state) {
    if (!state.showUnsignedFilters) {
      return 0 + 'px';
    }
    return state.unassignedFiltersWidth + 'px';
  },
  getCardWidth(state) {
    return state.cardWidth;
  },
  getCardWidthPX(state) {
    return state.cardWidth + 'px';
  },
  getShowUnassignedFilters(state) {
    return state.showUnsignedFilters;
  },
  getShowUnassignedPlannedColumn(state) {
    return state.showUnassignedPlannedColumn;
  },
  getShowUnassignedUnplannedColumn(state) {
    return state.showUnassignedUnplannedColumn;
  },
  isDragAndDropActive(state) {
    return state.dragAndDropActive;
  },
};
