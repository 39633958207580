<template>
  <div v-if="vacationsAreLoaded">
    <v-sheet tile height="54" class="d-flex">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :events="vacations"
        :event-overlap-threshold="30"
        @click:event="goToUserInVacationManagement"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import { formatDate } from "../../helper";
export default {
  data: () => ({
    vacationsAreLoaded: false,
    weekday: [1, 2, 3, 4, 5, 6, 0],
    value: "",
  }),
  computed: {
    vacations() {
      const vacations = [];

      console.log("xdxdxddxd");
      // console.log(this.$store.getters['vacation/getVacationsForCalendar'])

      this.$store.getters["vacation/getVacationsForCalendar"].forEach((item) => {
        vacations.push({
          id: item.id,
          userId: item.user.id,
          name: item.user.name + " => " + item.type,
          start: formatDate(item.dateStart),
          end: formatDate(item.dateFinish),
          color: item.user.color,
          timed: false,
        });
      });
      return vacations;
    },
  },

  created() {
    this.fetchVacations();
  },
  methods: {
    async fetchVacations() {
      try {
        await this.$store.dispatch("vacation/fetchVacationsForCalendar", { date: this.value });
      } catch (error) {
        console.log(error);
      }

      this.vacationsAreLoaded = true;
    },
    goToUserInVacationManagement({ nativeEvent, event }) {
      this.$parent.setActiveVacationTab(event.userId);
    },
  },
};
</script>
