<template>
  <v-dialog v-model="sameAssigmentHandle" :max-width="isMobile ? null : '30%'" persistent>
    <v-expand-x-transition>
      <v-card>
        <v-card-title class="text-wrap"> To zadanie jest już przypisane do tego użytkownika.</v-card-title>
        <v-card-text
          >Czy chcesz odpisać
          <v-chip color="white" label text-color="black">
            <v-icon :color="myList[oldUser].color" left> mdi-circle</v-icon>
            <h3>{{ myList[oldUser].name }}</h3>
          </v-chip>
          z zadania?
        </v-card-text>
        <v-card-text>
          <v-chip color="white" label text-color="black">
            <v-icon :color="myList[newUser].color" left> mdi-circle</v-icon>
            <h3>{{ myList[newUser].name }}</h3>
          </v-chip>
          będzie wciąż przypisany
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="$emit('cancelAssignment')"> Anuluj</v-btn>
          <v-btn color="green darken-1" outlined text @click="$emit('resolveAssignment')"> Tak</v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-x-transition>
  </v-dialog>
</template>
<script>
export default {
  name: "CalendarSameAssignmentDialog",
  props: {
    myList: {},
    newUser: {},
    oldUser: {},
    sameAssigmentHandle: {},
  },
  emits: ["cancelAssignment", "resolveAssignment"],
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
<style scoped>
@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}

@keyframes createBox {
  from {
    transform: scale(0.2);
  }
  to {
    transform: scale(1);
  }
}

@keyframes expandBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
</style>
