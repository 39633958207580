import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      files: [],
      jobs: [],
      totalJobs: 0,
      job: '',
      notes: [],
      fileNumber: 0,
      protocols: {
        jobProtocols: null,
        customerLocationProtocols: null,
      },
      uploadProgress: null,
    };
  },
  mutations,
  actions,
  getters,
};
