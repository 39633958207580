<template>
  <v-btn color="secondary" depressed outlined @click="$emit('click')">
    <v-icon left>
      {{ icon }}
    </v-icon>
    {{ label }}
  </v-btn>
</template>
<script>
export default {
  name: "EditUserButton",
  props: {
    label: {},
    icon: {},
  },
  emits: ["click"],
};
</script>
