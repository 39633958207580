import axios from 'axios';
import Vue from "vue";

export default {
  async fetchMobileReleases({ commit, rootGetters, dispatch }) {
    commit('setLoadingMobileReleases', true);
    const response = await axios.get(`mobile-releases/`);
    commit('setMobileReleases', response.data);
    commit('setLoadingMobileReleases', false);
  },
  async uploadApk(context, { file }) {
    let formData = new FormData();
    formData.append("file0", file)

    const controller = new AbortController();
    context.commit("setUploadingApk", { file: file, controller: controller });

    try {
      const response = await axios.post(`mobile-releases/upload`, formData, {
        signal: controller.signal,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          context.commit("setUploadProgress", parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)));
        }.bind(this),
      });
      const newMobileReleases = [response.data, ...context.getters["mobileReleases"]]
      context.commit("setMobileReleases",newMobileReleases )
    } catch (e) {
      console.log(e);
    } finally {
      context.commit("setUploadingApk", null);
      context.commit("setUploadProgress", null);
    }
  },
  async releaseApk(context, apkId) {
    context.commit("submittingMobileReleaseReleaseId", apkId);
    try {
      const response = await axios.post(`mobile-releases/${apkId}/release`);
      const newMobileReleases = response.data
      context.commit("setMobileReleases",newMobileReleases )
    } catch (e) {
      console.log(e);
    } finally {
      context.commit("submittingMobileReleaseReleaseId", null);
    }
  },
  async rollbackApk(context, apkId) {
    context.commit("submittingMobileReleaseRollbackId", apkId);
    try {
      const response = await axios.post(`mobile-releases/${apkId}/rollback`);
      const newMobileReleases = response.data
      context.commit("setMobileReleases",newMobileReleases )
    } catch (e) {
      console.log(e);
    } finally {
      context.commit("submittingMobileReleaseRollbackId", null);
    }
  },
  async deleteApk(context, apkId ) {
    context.commit("submittingMobileReleaseDeleteId", apkId);
    try {
      const response = await axios.delete(`mobile-releases/${apkId}`);
      const newMobileReleases = context.getters.mobileReleases.filter(value => value.id !== response.data.id);
      context.commit("setMobileReleases",newMobileReleases )
    } catch (e) {
      console.log(e);
    } finally {
      context.commit("submittingMobileReleaseDeleteId", null);
    }
  },
};
