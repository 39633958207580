import axios from 'axios';

export default {
  async fetchBinJobs(context, payload) {
    const response = await axios.get(`bin/jobs`, { params: payload !== null ? payload : {} });
    console.log(response);
    context.commit('setJobs', response.data);
  },
  async fetchBinTasks(context, payload) {
    const response = await axios.get(`bin/tasks`, { params: payload !== null ? payload : {} });
    console.log(response);
    context.commit('setJobTasks', response.data);
  },
  async binAction(context, payload) {
    const response = await axios.post(`bin/`, payload);
    console.log(response.data);
  },
  async deleteTask(context, payload) {
    const response = await axios.post(`bin/deleteTask`, {
      taskId: payload.taskId,
      operation: payload.operation,
    });
  },
};
