<template>
  <div>
    <v-app-bar app color="primary" elevation="0">
      <v-btn v-if="$route.path.split('/')[1] === 'job'" color="black" icon @click="$router.back()">
        <v-icon color="rgba(0, 0, 0, 1))"> mdi-chevron-left </v-icon>
      </v-btn>

      <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed right temporary>
      <v-list dense>
        <v-list-item-group v-model="group">
          <v-list-item @click="$router.push({ name: 'mobileDashboard' }).catch((err) => {})">
            <v-list-item-icon>
              <v-icon v-text="'mdi-home'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Panel </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="$router.push({ name: 'user' }).catch((err) => {})">
            <v-list-item-icon>
              <v-icon v-text="'mdi-account'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Konto </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon v-text="'mdi-logout'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Wyloguj </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "NavMobile",
  data: () => ({
    drawer: false,
    group: null,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
    toolbarTitle() {},
  },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
      await this.$router.replace("/login").catch((err) => {});
    },
  },
  computed: {
    ...mapGetters({ toolbarTitle: "mobileLayoutSettings/getToolbarTitle" }),
  },
};
</script>
<style>
a {
  text-decoration: none !important;
}
</style>
