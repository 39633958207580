<template>
  <v-chip :key="item.id" color="white" label text-color="black">
    <v-icon color="black" left>
      {{ getUserRoleProps(item.role).icon }}
    </v-icon>
    {{ getUserRoleProps(item.role).name }}
  </v-chip>
</template>
<script>
import { getUserRoleProps } from "../../helper";
import { ROLE } from "../../constants";

export default {
  name: "UserChip",
  props: {
    item: {},
  },
  computed: {
    ROLE() {
      return ROLE;
    },
  },
  methods: { getUserRoleProps },
};
</script>
