export default {
  //ArticleDetails
  article: (state) => {
    return state.articleDetails;
  },
  articleDetailsLoading: (state) => {
    return state.loadingArticleDetails;
  },
  articleSubmitting: (state) => {
    return state.submittingArticle;
  },
  articleEANSubmitting: (state) => {
    return state.submittingArticleEAN;
  },
  articleNameValidationSubmitting: (state) => {
    return state.submittingArticleNameValidation;
  },
  articleEANValidationSubmitting: (state) => {
    return state.submittingArticleEANValidation;
  },
};
