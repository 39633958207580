import Vue from 'vue';

export default {
  setApiUserTasks(state, userTaskList) {
    state.apiUserTasks = userTaskList;
  },
  setUserTasks(state, payload) {
    state.userTasks = payload;
  },
  unsetUserTask(state, payload) {
    Object.keys(state.userTasks).forEach((x) => Vue.delete(state.userTasks[x], payload));
  },
  setUsers(state, payload) {
    state.users = payload;
  },
  setTargetUser(state, payload) {
    state.targetUser = payload;
  },
  setTargetDate(state, payload) {
    state.targetDate = payload;
  },
  addLoadingCols(state, payload) {
    Vue.set(state.loadingCols, state.loadingCols.length, payload);
  },
  removeLoadingCols(state, payload) {
    // console.log('przed usuwaniem');
    // console.log(state.loadingCols);
    Vue.delete(
      state.loadingCols,
      state.loadingCols.findIndex((value) => value.userId === payload.userId && value.date === payload.date)
    );
    // console.log('usuwanie');
    // console.log(state.loadingCols);
  },
  setDraggingTask(state, payload) {
    console.log('dragging task');
    console.log(payload);
    state.draggingTask = payload;
  },
  setVacationsByDay(state, payload) {
    Vue.set(state.userVacations, payload.date, payload.data);
  },
  setUnplannedTasksOutsideCalendar(state, payload) {
    state.unplannedTasksOutsideCalendar = payload;
  },
  setPlannedTasksOutsideCalendar(state, payload) {
    state.plannedTasksOutsideCalendar = payload;
  },
  setPlannedTaskOutsideCalendar(state, { index, task }) {
    Vue.set(state.plannedTasksOutsideCalendar, index, task);
  },
  setGroupedTasksOutsideCalendar(state, payload) {
    state.tasksGroupedOutsideCalendar = payload;
  },
  setGroupOnTasksOutsideCalendar(state, { group, list }) {
    Vue.set(state.tasksGroupedOutsideCalendar, group, list);
  },
  setUnassignedFilters(state, payload) {
    state.unassignedFilters = payload;
  },
  setCalendarSelectedUserIds(state, userIds) {
    state.calendarSelectedUserIds = userIds;
  },
};
