<template>
  <v-snackbar v-if="isDefaultStyle" :color="snackBarColor()" v-model="message" app timeout="3000" top>
    {{ message.value }}
    <template #action="{ attrs }">
      <v-btn color="black" text v-bind="attrs" @click="message = ''">
        <v-icon> mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    v-else-if="message.type === MESSAGE.CHAT_TASK && message.value.updateType === UPDATE_TYPE.CHAT_TASK_ADD"
    v-model="message"
    color="primary"
    light
    max-width="500"
    timeout="8000"
    top>
    <v-row class="d-flex flex-row align-center" no-gutters>
      <v-col cols="auto">
        <v-chip class="ma-0" color="white" label text-color="black">
          <v-icon :color="message.value.user.color" left> mdi-circle</v-icon>
          {{ message.value.user.name }}
        </v-chip>
      </v-col>
      <v-col cols="auto"><span class="px-4" style="color: white">dodał nowe zadanie</span></v-col>
      <v-spacer></v-spacer>
      <v-btn class="pa-0" color="secondary" elevation="0" small @click="message = false"> Ukryj </v-btn>
    </v-row>
    <chat-task-item :task="message.value.task"></chat-task-item>
  </v-snackbar>
  <!--TODO refactor  -->
  <v-snackbar
    v-else-if="message.type === MESSAGE.CHAT_TASK && message.value.updateType === UPDATE_TYPE.CHAT_TASK_ADD_COMMENT"
    v-model="message"
    color="primary"
    light
    max-width="500"
    timeout="5000"
    top>
    <v-row class="d-flex flex-row align-center" no-gutters>
      <v-col cols="auto">
        <v-chip class="ma-0" color="white" label text-color="black">
          <v-icon :color="message.value.user.color" left> mdi-circle</v-icon>
          {{ message.value.user.name }}
        </v-chip>
      </v-col>
      <v-col cols="auto"><span class="px-4" style="color: white">dodał nowy komentarz</span></v-col>
      <v-spacer></v-spacer>
      <v-btn class="pa-0" color="secondary" elevation="0" small @click="message = false"> Ukryj </v-btn>
    </v-row>
    <chat-task-item :task="message.value.task"></chat-task-item>
    <v-row
      no-gutters>
      <v-spacer></v-spacer>
      <v-col cols="auto">
                    <span style="font-size: 0.8em; color: grey">{{
                        formatDateTime(message.value.task.comments.at(-1).createdInfo.createdAt)
                      }}</span>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row
      no-gutters>
      <v-col cols="auto">
        <v-icon color="transparent" left small> mdi-circle</v-icon>
      </v-col>
      <v-col cols="auto">
        <v-chip
          :key="message.value.task.comments.at(-1).createdInfo.createdBy.id"
          color="transparent"
          disabled
          label
          small
          style="opacity: 1; margin: 0 -4px"
          text-color="black">
          {{ message.value.task.comments.at(-1).createdInfo.createdBy.name }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="auto">
        <v-icon
          :color="message.value.task.comments.at(-1).createdInfo.createdBy.color"
          left
          small>
          mdi-circle
        </v-icon>
      </v-col>
      <v-col cols="auto" style="max-width: 70%">
        <v-sheet
          color="white"
          class="pa-1"
          outlined
          rounded>
          <span style="word-break: break-word">{{ message.value.task.comments.at(-1).text }}</span>
        </v-sheet>
      </v-col>
    </v-row>
  </v-snackbar>
</template>
<script>
import { MESSAGE, UPDATE_TYPE } from "../../constants";
import ChatTaskItem from "../chat/ChatTaskItem.vue";
import { formatDateTime } from "../../helper";

export default {
  name: "AppNotification",
  components: { ChatTaskItem },
  computed: {
    UPDATE_TYPE() {
      return UPDATE_TYPE;
    },
    MESSAGE() {
      return MESSAGE;
    },
    message: {
      get() {
        console.log(this.$store.getters["messages/getMessage"]);
        return this.$store.getters["messages/getMessage"];
      },
      set() {
        this.$store.dispatch("messages/notifyOff");
      },
    },
    isDefaultStyle() {
      return [MESSAGE.INFO, MESSAGE.ERROR].includes(this.message.type);
    },
  },
  methods: {
    formatDateTime,
    snackBarColor() {
      if (this.message.type === MESSAGE.INFO) {
        return "success";
      }
      if (this.message.type === MESSAGE.ERROR) {
        return "error";
      }
      return "success";
    },
  },
};
</script>
