export default {
  supplierSubmitting: (state) => {
    return state.submittingSupplier;
  },
  articleSuppliers: (state) => {
    return state.articleSuppliers;
  },
  getArticleSupplierById: (state) => (supplierId) => {
    const supplierIdx = state.articleSuppliers.findIndex((value) => value.id === supplierId);
    if (supplierIdx === -1) {
      return null;
    }
    return state.articleSuppliers[supplierIdx];
  },
  articleSuppliersLoading: (state) => {
    return state.articleSuppliersLoading;
  },
};
