import Vue from "vue";

export default {
  addUploadingFile(state, { file, controller }) {
    console.log(file.name, state.uploadingFiles.length);
    Vue.set(state.uploadingFiles, state.uploadingFiles.length, file);
    Vue.set(state.uploadingFilesControllers, state.uploadingFilesControllers.length, controller);
    Vue.set(state.uploadingFilesProgress, state.uploadingFilesProgress.length, 0);
  },
  removeUploadingFile(state, file) {
    const indexOf = state.uploadingFiles.indexOf(file);
    if (indexOf === -1) {
      return;
    }
    Vue.delete(state.uploadingFilesProgress, indexOf);
    Vue.delete(state.uploadingFiles, indexOf);
    Vue.delete(state.uploadingFilesControllers, indexOf);
  },
  setUploadProgress(state, { file, progress }) {
    const indexOf = state.uploadingFiles.indexOf(file);
    // console.log(file.name, indexOf);
    if (indexOf === -1) {
      return;
    }
    Vue.set(state.uploadingFilesProgress, indexOf, progress);
  },
  setDeletingFile(state, { fileId }) {
    state.deletingFile = fileId;
  },
  setSubmittingAsPrimaryImage(state, { fileId }) {
    state.settingAsPrimaryImage = fileId;
  },
};
