<template>
  <!--  <v-row justify="center">-->
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template #activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" :disabled="!$store.getters['calendarTasks/getUsers'].length" v-on="on">
        <v-icon left> mdi-plus </v-icon>
        Dodaj zadanie
      </v-btn>
    </template>
    <v-card v-if="dialog || $store.getters['calendarTasks/getUsers'].length">
      <v-card-title>
        <span class="text-h5">Dodaj zadanie </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row no-gutters style="margin-top: 12px; padding-top: 12px">
            <v-col style="margin: 0; padding-left: 30px">
              <p style="margin: 0; padding: 0; font-size: 12px">Typ zadania</p>
            </v-col>
          </v-row>
          <v-row class="align-center justify-start" no-gutters>
            <v-col style="max-width: 30px">
              <v-icon size="20"> mdi-text-short </v-icon>
            </v-col>
            <v-col>
              <v-chip
                v-for="(tt, k_tt) in types.taskTypes"
                :key="k_tt"
                :autofocus="true"
                :color="selectedTaskType  === k_tt? 'black' : 'white'"
                :outlined="selectedTaskType === k_tt"
                :tabindex="0"
                :text-color="selectedTaskType === k_tt ? 'black' : 'grey'"
                class="me-2"
                label
                @click="selectedTaskType = k_tt"
              >
                <v-icon :color="selectedTaskType === k_tt ? 'black' : 'grey'" left>
                  {{ tt.icon }}
                </v-icon>
                {{ tt.name }}
              </v-chip>
            </v-col>
          </v-row>
          <form-row-with-icon v-if="radmaxWorkers" icon="mdi-account-group" text="Przypisane osoby">
            <v-chip
              v-for="item in radmaxWorkers"
              :key="item.id"
              :autofocus="true"
              :color="selectedWorkers.find((v) => v.id === item.id) !== undefined ? 'grey' : 'transparent'"
              :outlined="selectedWorkers.find((v) => v.id === item.id) !== undefined"
              :style="{ opacity: selectedWorkers.find((v) => v.id === item.id) === undefined ? '0.5' : '1' }"
              :tabindex="0"
              class="me-2"
              label
              style="margin-right: 5px; margin-bottom: 5px"
              text-color="black"
              @click="handleSelectedWorkers(item)"
              @keydown.space.prevent="handleSelectedWorkers(item)"
            >
              <v-icon :color="item.color" left> mdi-circle </v-icon>
              {{ item.name }}
            </v-chip>
          </form-row-with-icon>
          <form-row-with-icon v-if="externalWorkers" icon="mdi-account-supervisor-outline">
            <v-chip
              v-for="item in externalWorkers"
              :key="item.id"
              :autofocus="true"
              :color="selectedWorkers.find((v) => v.id === item.id) !== undefined ? 'grey' : 'transparent'"
              :outlined="selectedWorkers.find((v) => v.id === item.id) !== undefined"
              :style="{ opacity: selectedWorkers.find((v) => v.id === item.id) === undefined ? '0.5' : '1' }"
              :tabindex="0"
              class="me-2"
              label
              style="margin-right: 5px; margin-bottom: 5px"
              text-color="black"
              @click="handleSelectedWorkers(item)"
              @keydown.space.prevent="handleSelectedWorkers(item)"
            >
              <v-icon :color="item.color" left> mdi-circle </v-icon>
              {{ item.name }}
            </v-chip>
          </form-row-with-icon>
          <v-row>
            <v-col>
              <v-menu
                v-model="menuStartAtDate"
                :close-on-content-click="false"
                min-width="auto"
                offset-y
                transition="scale-transition"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="startAtDate"
                    clearable
                    label="Data rozpoczęcia"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @keydown.s.space.prevent="menuStartAtDate = true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startAtDate"
                  :first-day-of-week="1"
                  no-title
                  show-adjacent-months
                  @input="menuStartAtDate = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <form-row-with-icon text="Opis zadania" icon="mdi-text-long">
            <text-editor v-model="taskDescription" :text-limit="textAreaMaxLength"></text-editor>
          </form-row-with-icon>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelAddingTask"> Anuluj </v-btn>
        <v-btn :disabled="validateTask()" color="blue darken-1" text @click="addTask"> Dodaj zadanie </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--  </v-row>-->
</template>

<script>
import { mapGetters } from "vuex";
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import TextEditor from "../textEditor/TextEditor.vue";
import types from "../../types.json";

export default {
  components: { TextEditor, FormRowWithIcon },
  data: () => ({
    types,
    dialog: false,
    menu: false,
    startAtDate: null,
    menuStartAtDate: false,
    taskDescription: "",
    selectedWorkers: [],
    selectedTaskType: null,
    textAreaMaxLength: 10000
  }),
  computed: {
    ...mapGetters({
      workers: "workers/getWorkers",
      radmaxWorkers: "calendarTasks/getRadmax",
      externalWorkers: "calendarTasks/getExternal"
    }),
    workers() {
      return this.$store.getters["workers/getWorkers"];
    }
  },
  async created() {
    this.selectedTaskType = Object.keys(this.types.taskTypes).at(0);
  },
  props: {
    job: {}
  },
  methods: {
    handleSelectedWorkers(item) {
      const i = this.selectedWorkers.findIndex((v) => v.id === item.id);
      if (i !== -1) {
        return this.selectedWorkers.splice(i, 1);
      }
      return this.selectedWorkers.push(item);
    },
    async addTask() {
      const taskData = {
        description: this.taskDescription === "" ? null : this.taskDescription,
        dateStart: this.startAtDate,
        type: this.selectedTaskType,
        users: this.selectedWorkers.map((u) => u.id)
      };
      await this.$store.dispatch("tasks/addTask", {
        jobId: this.job.id,
        taskData: taskData
      });

      this.resetValues();
      this.$emit("refresh");
      this.dialog = false;
    },
    cancelAddingTask() {
      this.resetValues();
      this.dialog = false;
    },
    resetValues(selectedWorkers = true, description = true, type = true, dateStart = true) {
      if (selectedWorkers) {
        this.selectedWorkers = [];
      }
      if (description) {
        this.taskDescription = "";
      }
      if (type) {
        this.selectedTaskType = Object.keys(types.taskTypes).at(0);
      }
      if (dateStart) {
        this.startAtDate = null;
      }
    },
    validateTask() {
      if (!this.taskDescription){
        return false;
      }
      return this.taskDescription && this.taskDescription.length > this.textAreaMaxLength;
    }
  }
};
</script>
