<template>
  <v-container>
    <v-card>
      <v-toolbar flat v-if="itemsToDelete.deleteItems !== null">
        <v-toolbar-title>Kosz</v-toolbar-title>
        <template #extension>
          <v-tabs v-model="tab" background-color="white">
            <v-tab v-for="item in items" :key="item.tab">
              {{ item.tab }}
            </v-tab>
          </v-tabs>
        </template>
        <v-spacer></v-spacer>
        <v-chip v-if="itemsToDelete.type !== null" color="transparent">
          {{ (itemsToDelete.type === "job" ? "zlecenia" : "zadania") + " (" + itemsToDelete.deleteItems.length + ")" }}
        </v-chip>
        <v-btn :disabled="itemsToDelete.deleteItems.length === 0" text @click="dispatch('restore')">
          <v-icon left> mdi-restore </v-icon>
          Przywróć
        </v-btn>
        <v-btn :disabled="itemsToDelete.deleteItems.length === 0" color="primary" @click="dispatch('delete')">
          <v-icon left> mdi-delete-forever </v-icon>
          Usuń
        </v-btn>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <job-bin-table v-model="itemsToDelete" :tab="tab"></job-bin-table>
        </v-tab-item>
        <v-tab-item>
          <task-bin-tree v-model="itemsToDelete" :tab="tab"></task-bin-tree>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import JobBinTable from "../../components/bin/JobBinTable.vue";
import TaskBinTree from "../../components/bin/TaskBinTree.vue";
import { mapActions } from "vuex";

export default {
  name: "Bin",
  components: { TaskBinTree, JobBinTable },
  data: () => ({
    tab: null,
    items: [{ tab: "Zlecenia" }, { tab: "Zadania" }],
    itemsToDelete: {
      deleteItems: [],
      type: null,
    },
  }),
  methods: {
    ...mapActions({
      fetchJobs: "bin/fetchBinJobs",
      fetchJobTasks: "bin/fetchBinTasks",
    }),
    clearItemsToDelete() {
      this.itemsToDelete = {
        deleteItems: [],
        type: null,
      };
    },
    async dispatch(operation) {
      if (this.itemsToDelete.type === "task") {
        await this.$store.dispatch("bin/deleteTask", {
          taskId: this.itemsToDelete.deleteItems.map((x) => x.id),
          operation: operation,
        });
      } else {
        await this.$store.dispatch("bin/binAction", {
          jobId: this.itemsToDelete.deleteItems.map((x) => x.id),
          operation: operation,
        });
      }
      await this.fetchJobs();
      await this.fetchJobTasks();
      this.clearItemsToDelete();
    },
  },
  watch: {
    itemsToDelete: {
      deep: true,
      handler(a,b) {
        console.log(a);
      }
    },
    tab(a) {
      this.clearItemsToDelete();
    },
  },
};
</script>

<style scoped></style>
