<template>
  <v-card>
    <v-card-title>Stan magazynowy</v-card-title>
    <v-card-text>
      <v-row v-if="!getArticleStock({ articleId: article.id })" no-gutters>
        <v-spacer />
        <v-col>
          <v-progress-circular />
        </v-col>
        <v-spacer />
      </v-row>
      <v-simple-table v-else>
        <template #default>
          <thead>
            <tr>
              <th class="text-left">Magazyn</th>
              <th>Ilość</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="facility in facilities" :key="facility.id">
              <td>
                <v-icon left>{{ warehouseTypes.facilityType[facility.type].icon }}</v-icon>
                {{ facility.name }}
              </td>
              <td :ref="`article_${article.id}_warehouseFacility-${facility.id}`">
                <v-menu :close-on-content-click="false" bottom left offset-x offset-y>
                  <template #activator="{ on, attrs }">
                    <v-chip
                      :style="{ cursor: 'grab' }"
                      color="transparent"
                      draggable
                      label
                      v-bind="attrs"
                      @click="openEditMenu($event, `article_${article.id}_warehouseFacility-${facility.id}`, article)"
                      @dragstart="
                        startDrag($event, {
                          article: article,
                          facilityId: facility.id,
                        })
                      "
                      @drop="
                        endDrag($event, {
                          article: article,
                          facilityId: facility.id,
                        })
                      "
                      v-on="on"
                      @dragover.prevent>
                      {{
                        getArticleStock({ articleId: article.id }).warehouseArticleFacilities.find(
                          (value) => value.warehouseFacility.id === facility.id
                        )?.quantity ?? 0
                      }}
                    </v-chip>
                  </template>

                  <v-card v-if="editingProperty && editingProperty !== 'transfer'">
                    <!--        <v-card-title>{{ editedArticle }}</v-card-title>-->
                    <v-card-text class="py-0">
                      <template v-if="editingProperty.includes('warehouseFacility')">
                        <v-row no-gutters>
                          <warehouse-stock-operation-chip
                            v-for="type in Object.values(WAREHOUSE_STOCK_OPERATION).filter(
                              (x) => ![WAREHOUSE_STOCK_OPERATION.TRANSFER].includes(x)
                            )"
                            :key="type"
                            :operation-type="type"
                            :stock-operation="stockOperation"
                            @update:stockOperation="stockOperation = $event" />
                        </v-row>
                        <v-text-field
                          v-if="stockOperation === WAREHOUSE_STOCK_OPERATION.CORRECTION"
                          v-model="
                            editedArticle.warehouseArticleFacilities[
                              editedArticle.warehouseArticleFacilities.findIndex(
                                (wAF) =>
                                  wAF.warehouseFacility.id ===
                                  parseInt(editingProperty.split('warehouseFacility-').at(-1))
                              )
                            ].quantity
                          "
                          :label="`Inwentaryzacja -  ${
                            facilities[
                              facilities.findIndex(
                                (wAF) => wAF.id === parseInt(editingProperty.split('warehouseFacility-').at(-1))
                              )
                            ]?.name
                          }`"
                          :rules="[rules.isQuantityNegative()]">
                          <template #append-outer>
                            <v-btn
                              :loading="articleStockSubmitting"
                              :disabled="
                                !!rules.isQuantityNegative()(
                                  editedArticle.warehouseArticleFacilities[
                                    editedArticle.warehouseArticleFacilities.findIndex(
                                      (wAF) =>
                                        wAF.warehouseFacility.id ===
                                        parseInt(editingProperty.split('warehouseFacility-').at(-1))
                                    )
                                  ].quantity
                                ).length
                              "
                              icon
                              @click="submitArticleStockUpdate()">
                              <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                        <v-text-field
                          v-else-if="stockOperation === WAREHOUSE_STOCK_OPERATION.CONSUMPTION"
                          v-model="
                            editedArticle.warehouseArticleFacilities[
                              editedArticle.warehouseArticleFacilities.findIndex(
                                (wAF) =>
                                  wAF.warehouseFacility.id ===
                                  parseInt(editingProperty.split('warehouseFacility-').at(-1))
                              )
                            ].quantity
                          "
                          :label="`Rozchód - ${
                            facilities[
                              facilities.findIndex(
                                (wAF) => wAF.id === parseInt(editingProperty.split('warehouseFacility-').at(-1))
                              )
                            ]?.name
                          }`"
                          :rules="[rules.isQuantityNonPositive()]"
                          prefix="-">
                          <template #append-outer>
                            <v-btn
                              :loading="articleStockSubmitting"
                              :disabled="
                                !!rules.isQuantityNonPositive()(
                                  editedArticle.warehouseArticleFacilities[
                                    editedArticle.warehouseArticleFacilities.findIndex(
                                      (wAF) =>
                                        wAF.warehouseFacility.id ===
                                        parseInt(editingProperty.split('warehouseFacility-').at(-1))
                                    )
                                  ].quantity
                                ).length
                              "
                              icon
                              @click="submitArticleStockUpdate()">
                              <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                        <template v-else-if="stockOperation === WAREHOUSE_STOCK_OPERATION.REPLENISHMENT">
                          <v-text-field
                            v-model="
                              editedArticle.warehouseArticleFacilities[
                                editedArticle.warehouseArticleFacilities.findIndex(
                                  (wAF) =>
                                    wAF.warehouseFacility.id ===
                                    parseInt(editingProperty.split('warehouseFacility-').at(-1))
                                )
                              ].quantity
                            "
                            :label="`Uzupełnienie - ${
                              facilities[
                                facilities.findIndex(
                                  (wAF) => wAF.id === parseInt(editingProperty.split('warehouseFacility-').at(-1))
                                )
                              ]?.name
                            }`"
                            :rules="[rules.isQuantityNonPositive()]"
                            prefix="+">
                            <template #append-outer>
                              <v-btn
                                :loading="articleStockSubmitting"
                                :disabled="
                                !!rules.isQuantityNonPositive()(
                                  editedArticle.warehouseArticleFacilities[
                                    editedArticle.warehouseArticleFacilities.findIndex(
                                      (wAF) =>
                                        wAF.warehouseFacility.id ===
                                        parseInt(editingProperty.split('warehouseFacility-').at(-1))
                                    )
                                  ].quantity
                                ).length || !!rules.isPriceNegative()(editedArticle.price).length
                              "
                                icon
                                @click="submitArticleStockUpdate()">
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                            </template>
                          </v-text-field>
                          <custom-combobox-single
                            v-if="stockOperation === WAREHOUSE_STOCK_OPERATION.REPLENISHMENT"
                            v-model="supplierHelper"
                            :disabled="articleSuppliersLoading"
                            :item-text="'name'"
                            :items="articleSuppliers"
                            :loading="articleSuppliersLoading"
                            dense
                            label="Dostawca"
                            @trueValue="(n) => setSupplierObjOrName(n)" />
                          <v-text-field
                            v-if="stockOperation === WAREHOUSE_STOCK_OPERATION.REPLENISHMENT"
                            v-model="editedArticle.price"
                            :rules="[rules.isPriceNegative()]"
                            label="Cena zakupu"
                            suffix="PLN"
                            type="number">
                          </v-text-field>
                        </template>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <template
                  v-if="
                    (getArticleStock({ articleId: article.id }).warehouseArticleFacilities.find(
                      (value) => value.warehouseFacility.id === facility.id
                    )?.quantity ?? 0) <
                    (getArticleStock({ articleId: article.id }).warehouseArticleFacilities.find(
                      (value) => value.warehouseFacility.id === facility.id
                    )?.min_quantity ?? 0)
                  ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: on1, attrs: attrs1 }">
                      <v-icon color="error" v-bind="attrs1" v-on="on1">mdi-alert-rhombus-outline</v-icon>
                    </template>
                    <span
                      >Brakująca ilość:
                      {{
                        (getArticleStock({ articleId: article.id }).warehouseArticleFacilities.find(
                          (value) => value.warehouseFacility.id === facility.id
                        )?.min_quantity ?? 0) -
                        (getArticleStock({ articleId: article.id }).warehouseArticleFacilities.find(
                          (value) => value.warehouseFacility.id === facility.id
                        )?.quantity ?? 0)
                      }}
                    </span>
                  </v-tooltip>
                </template>
              </td>
              <td class="d-flex flex-row justify-end align-center">
                <v-menu :close-on-content-click="false" bottom left offset-x offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn icon text v-bind="attrs" x-small @click="openMinQuantityMenu(facility.id)" v-on="on">
                      <v-icon> mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <template
                    v-if="
                      editingProperty === 'min_quantity' &&
                      editedArticle.warehouseArticleFacilities.find(
                        (wAF) => wAF.warehouseFacility.id === facility.id
                      ) !== undefined
                    ">
                    <v-card>
                      <v-card-text>Ustaw ilość minimalną na {{ facility.name }}</v-card-text>
                      <v-card-text class="pt-0">
                        <v-text-field
                          v-model="
                            editedArticle.warehouseArticleFacilities.find(
                              (wAF) => wAF.warehouseFacility.id === facility.id
                            ).min_quantity
                          "
                          clearable
                          placeholder="Nie ustawiono"
                          :rules="[rules.isQuantityNegative()]"
                          label="Ilość minimalna">
                          <template #append-outer>
                            <v-btn
                              :loading="articleStockSubmitting"
                              icon
                              @click="submitArticleMinQuantity(facility.id)">
                              <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-menu>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <b
                  ><span class="px-3">{{
                    getArticleStock({ articleId: article.id }).warehouseArticleFacilities.reduce(
                      (accumulator, f) => accumulator + f.quantity,
                      0
                    ) +
                    " " +
                    warehouseTypes.articleUnit[article.unit].abbreviation
                  }}</span></b
                >
              </td>
              <td></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-menu
      v-if="editingProperty"
      v-model="showEditingMenu"
      :close-on-content-click="false"
      :left="getWindowWidth / 2 < x"
      :position-x="x"
      :position-y="y"
      :right="getWindowWidth / 2 >= x"
      transition="slide-x-transition">
      <v-card v-if="editingProperty === 'transfer'">
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="auto">
              <v-text-field
                v-model="
                  draggedData.to.article.warehouseArticleFacilities.find(
                    (wAF) => wAF.warehouseFacility.id === draggedData.from.facilityId
                  ).quantity
                "
                :label="facilities.find((f) => f.id === draggedData.from.facilityId).name"
                dense
                disabled
                type="number">
              </v-text-field>
              <v-text-field
                v-model="draggedData.quantity"
                :rules="[rules.isQuantityNonPositive()]"
                label="Ilość"
                prepend-icon="mdi-arrow-down-bold-circle-outline"
                type="number">
              </v-text-field>
              <v-text-field
                v-model="
                  draggedData.to.article.warehouseArticleFacilities.find(
                    (wAF) => wAF.warehouseFacility.id === draggedData.to.facilityId
                  ).quantity
                "
                :label="facilities.find((f) => f.id === draggedData.to.facilityId).name"
                dense
                disabled
                type="number">
                <template #append-outer>
                  <v-btn
                    :loading="articleStockSubmitting"
                    :disabled="
                      !!rules.isQuantityNonPositive()(draggedData.quantity).length ||
                      draggedData.to.article.warehouseArticleFacilities.find(
                        (wAF) => wAF.warehouseFacility.id === draggedData.from.facilityId
                      ).quantity -
                        draggedData.quantity <
                        0
                    "
                    icon
                    @click="submitArticleTransfer()">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-card>
</template>
<script>
import CustomComboboxSingle from "../../components/layout/CustomComboboxSingle.vue";
import WarehouseStockOperationChip from "../../components/warehouse/WarehouseStockOperationChip.vue";
import { WAREHOUSE_STOCK_OPERATION } from "../../constants";
import { mapActions, mapGetters } from "vuex";
import warehouseTypes from "../../warehouseTypes.json";

export default {
  name: "WarehouseArticleDetailsStock",
  components: { CustomComboboxSingle, WarehouseStockOperationChip },
  computed: {
    WAREHOUSE_STOCK_OPERATION() {
      return WAREHOUSE_STOCK_OPERATION;
    },
    ...mapGetters({ getWindowWidth: "layoutHelpers/getWindowWidth" }),
    ...mapGetters("warehouse/facilities", ["facilities", "facilitiesLoading", "getFacilityById"]),
    ...mapGetters("warehouse/stocks", ["articleStocksLoading", "getArticleStock", "articleStockSubmitting"]),
    ...mapGetters("warehouse/articleDetails", ["article", "articleDetailsLoading"]),
    ...mapGetters("warehouse/suppliers", ["articleSuppliers", "articleSuppliersLoading", "getArticleSupplierById"]),
  },
  data() {
    return {
      warehouseTypes,
      stockOperation: WAREHOUSE_STOCK_OPERATION.REPLENISHMENT,
      editedArticle: { name: null },
      editingProperty: null,
      showEditingMenu: false,
      draggedData: {
        from: {
          article: null,
          facilityId: null,
        },
        to: {
          article: null,
          facilityId: null,
        },
        quantity: 0,
        formErrors: {
          name: [],
        },
      },
      formErrors: {
        name: [],
      },
      supplierHelper: null,
      rules: {
        isPriceNegative() {
          return (v) => !v || (v >= 0 && v && v.toString().match(/^\d+(\.\d{1,2})?$/) !== null) || "Niepoprana cena";
        },
        isQuantityNonPositive() {
          return (v) => v > 0 || "Niepoprana wartość";
        },
        isQuantityNegative() {
          return (v) => v >= 0 || "Niepoprana wartość";
        },
      },
      x: 0,
      y: 0,
    };
  },
  methods: {
    openMinQuantityMenu(warehouseId) {
      this.editedArticle = structuredClone(this.article);
      //create warehouseArticleFacility for editing article if not exists
      const warehouseFacilityId = warehouseId;
      if (this.editedArticle.warehouseArticleFacilities.length) {
        const warehouse =
          this.editedArticle.warehouseArticleFacilities.find(
            (wAF) => wAF.warehouseFacility.id === warehouseFacilityId
          ) ?? null;
        if (!warehouse) {
          const newWarehouseArticleFacility = {
            id: null,
            warehouseFacility: { id: warehouseFacilityId },
            min_quantity: 0,
          };
          this.editedArticle.warehouseArticleFacilities.push(newWarehouseArticleFacility);
        }
      }
      this.editingProperty = "min_quantity";
    },
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    ...mapActions("warehouse/stocks", [
      "fetchArticleStock",
      "updateArticleStock",
      "postArticleStockCorrection",
      "postArticleStockReplenish",
      "postArticleStockConsumption",
      "postArticleStockTransfer",
      "postArticleStockMinQuantity",
    ]),
    async setSupplierObjOrName(v) {
      this.editedArticle.supplier = v;
    },
    submitArticleStockUpdate() {
      const { id, ...facilityUpdateData } =
        this.editedArticle.warehouseArticleFacilities[
          this.editedArticle.warehouseArticleFacilities.findIndex(
            (wAF) => wAF.warehouseFacility.id === parseInt(this.editingProperty.split("warehouseFacility-").at(-1))
          )
        ];

      switch (this.stockOperation) {
        case WAREHOUSE_STOCK_OPERATION.REPLENISHMENT:
          this.postArticleStockReplenish({
            articleId: this.editedArticle.id,
            facilityId: facilityUpdateData.warehouseFacility.id,
            quantity: facilityUpdateData.quantity,
            supplier: this.editedArticle.supplier,
            price: this.editedArticle.price,
          });
          break;
        case WAREHOUSE_STOCK_OPERATION.CONSUMPTION:
          this.postArticleStockConsumption({
            articleId: this.editedArticle.id,
            facilityId: facilityUpdateData.warehouseFacility.id,
            quantity: facilityUpdateData.quantity,
          });
          break;
        case WAREHOUSE_STOCK_OPERATION.CORRECTION:
          this.postArticleStockCorrection({
            articleId: this.editedArticle.id,
            facilityId: facilityUpdateData.warehouseFacility.id,
            quantity: facilityUpdateData.quantity,
          });
          break;
        default:
          break;
      }
    },
    submitArticleTransfer() {
      this.postArticleStockTransfer({
        articleId: this.draggedData.to.article.id,
        sourceFacilityId: this.draggedData.from.facilityId,
        destinationFacilityID: this.draggedData.to.facilityId,
        quantity: this.draggedData.quantity,
      });
    },
    submitArticleMinQuantity(facilityId) {
      const { id, ...facilityUpdateData } =
        this.editedArticle.warehouseArticleFacilities[
          this.editedArticle.warehouseArticleFacilities.findIndex((wAF) => wAF.warehouseFacility.id === facilityId)
        ];
      this.postArticleStockMinQuantity({
        articleId: this.editedArticle.id,
        facilityId: facilityUpdateData.warehouseFacility.id,
        min_quantity: facilityUpdateData.min_quantity,
      });
    },
    openEditMenu(e, i, o) {
      console.log(this.editingProperty);
      this.editedArticle = structuredClone(o);
      this.supplierHelper = this.editedArticle.supplier
        ? this.articleSuppliers.find((sup) => sup.id === this.editedArticle.supplier.id) ?? null
        : null;
      this.editingProperty = i.split("_").at(-1);
      //create warehouseArticleFacility for editing article if not exists
      if (this.editingProperty.includes("warehouseFacility")) {
        const warehouseFacilityId = parseInt(this.editingProperty.split("warehouseFacility-").at(-1));
        const warehouse =
          this.editedArticle.warehouseArticleFacilities.find(
            (wAF) => wAF.warehouseFacility.id === warehouseFacilityId
          ) ?? null;
        if (!warehouse) {
          const newWarehouseArticleFacility = {
            id: null,
            warehouseFacility: { id: warehouseFacilityId },
            quantity: 0,
          };
          this.editedArticle.warehouseArticleFacilities.push(newWarehouseArticleFacility);
        }
      }
      console.log(this.editingProperty);
      console.log(this.editedArticle.warehouseArticleFacilities);
      console.log(
        this.editedArticle.warehouseArticleFacilities.findIndex(
          (wAF) => wAF.warehouseFacility.id === parseInt(this.editingProperty.split("warehouseFacility-").at(-1))
        )
      );
    },
    openTransferMenu() {
      this.showEditingMenu = false;
      let article = this.draggedData.to.article;
      const refId = `article_${article.id}_warehouseFacility-${this.draggedData.to.facilityId}`;
      console.log(refId);
      console.log(article);
      console.log(this.$refs[refId]);
      let pos = this.$refs[refId][0].getBoundingClientRect();
      this.x = pos.left;
      this.y = pos.top;
      this.menuWidth = null;

      this.editingProperty = "transfer";
      //create warehouseArticleFacility for target article if not exists
      const warehouseFacilityId = this.draggedData.to.facilityId;
      if (article.warehouseArticleFacilities.length) {
        const warehouse =
          article.warehouseArticleFacilities.find((wAF) => wAF.warehouseFacility.id === warehouseFacilityId) ?? null;
        if (!warehouse) {
          const newWarehouseArticleFacility = {
            id: null,
            warehouseFacility: { id: warehouseFacilityId },
            quantity: 0,
          };
          article.warehouseArticleFacilities.push(newWarehouseArticleFacility);
        }
      }

      this.$nextTick(() => {
        this.showEditingMenu = true;
      });
    },
    startDrag(e, { article, facilityId }) {
      this.showEditingMenu = false;
      this.draggedData.from = { article: article, facilityId: facilityId };
    },
    async endDrag(e, { article, facilityId }) {
      console.log(e);
      this.draggedData.to = { article: article, facilityId: facilityId };
      console.log(this.draggedData);

      if (
        this.draggedData.from.article.id !== this.draggedData.to.article.id ||
        this.draggedData.from.facilityId === this.draggedData.to.facilityId
      ) {
        console.log("cancel");
        return;
      }
      const foundFacility = this.draggedData.from.article.warehouseArticleFacilities.find(
        (wAF) => wAF.warehouseFacility.id === this.draggedData.from.facilityId
      );
      if (!foundFacility || foundFacility.quantity === 0) {
        await this.notifyError("Transfer niemożliwy. Niewystarczająca ilość.");
        console.log("0 quantity");
        return;
      }
      this.openTransferMenu();

      // console.log(article);
      // console.log(articleGroup);
      e.preventDefault();
    },
  },
};
</script>
