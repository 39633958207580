export default {
  uploadingFiles: (state) => {
    return state.uploadingFiles;
  },
  uploadingFilesProgress: (state) => {
    return state.uploadingFilesProgress;
  },
  uploadingFilesControllers: (state) => {
    return state.uploadingFilesControllers;
  },
  deletingFile: (state) => {
    return state.deletingFile;
  },
  settingAsPrimaryImage: (state) => {
    return state.settingAsPrimaryImage;
  },
};
