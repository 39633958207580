<template>
  <div key="'Zadania'">
    <v-progress-circular
      v-if="jobTasks === null"
      :size="200"
      :width="7"
      color="tertiary"
      indeterminate
    ></v-progress-circular>
    <p v-else-if="jobTasks.length === 0" class="d-flex justify-center">Brak zadań w koszu</p>
    <template v-else>
      <v-treeview
        v-model="computedItemsToDelete"
        selectable
        :items="jobTasks"
        item-children="tasks"
        item-text="title"
        return-object
        item-key="uid"
      ></v-treeview>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TaskBinTree",
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    ...mapGetters({ jobTasks: "bin/getJobTasks" }),
    computedItemsToDelete: {
      get() {
        console.log("task");
        return this.itemsToDelete.deleteItems;
      },
      set(v) {
        console.log("SET TASK");
        if (this.tab !== 1) return;
        this.$emit("newValueA", {
          deleteItems: v,
          type: "task",
        });
      },
    },
  },
  methods: {
    ...mapActions({ fetchJobTasks: "bin/fetchBinTasks" }),
  },
  watch: {
    selected(a) {
      this.computedItemsToDelete = a;
    },
  },
  async created() {
    await this.fetchJobTasks();
  },
  model: {
    prop: "itemsToDelete",
    event: "newValueA",
  },
  props: {
    itemsToDelete: {},
    tab: {},
  },
};
</script>

<style scoped></style>
