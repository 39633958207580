import Vue from 'vue';
import Vuex from 'vuex';

import userModule from './store/modules/user/index';
import messages from './store/modules/messages';
import jobs from './store/modules/jobs';
import tasks from './store/modules/tasks';
import customers from './store/modules/customers';
import customerLocations from './store/modules/customerLocations';
import calendarTasks from './store/modules/calendar';
import workers from './store/modules/workers';
import layoutHelpers from './store/modules/layoutHelpers';
import vacation from './store/modules/vacation';
import users from './store/modules/admin/users';
import weekCalendar from './store/modules/weekCalendar';
import bin from './store/modules/bin';
import chat from './store/modules/chat';
import stream from './store/modules/stream';
import warehouse from './store/modules/warehouse';
import mobileVersion from './store/modules/mobileVersion';

Vue.use(Vuex);

const state = {};

const store = new Vuex.Store({
  modules: {
    user: userModule,
    messages,
    chat,
    jobs,
    tasks,
    customers,
    customerLocations,
    calendarTasks,
    workers,
    layoutHelpers,
    vacation,
    users,
    weekCalendar,
    stream,
    bin,
    warehouse,
    mobileVersion,
  },
  state,
});

export default store;
