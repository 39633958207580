<template>
  <v-btn
    text
    :to="path"
    color="secondary"
    exact
    :style="child ? 'margin-left: 10px' : ''"
    :replace="child"
    @click="$emit('click')"
  >
    <span v-if="isRight" :class="labelClass">{{ label }}</span>
    <v-icon color="secondary" :left="isLeft" :right="isRight">
      {{ icon }}
    </v-icon>
    <span v-if="isLeft" :class="labelClass">{{ label }}</span>
  </v-btn>
</template>

<script>
export default {
  name: "NavButton",
  props: {
    path: { type: String, default: "" },
    child: { type: Boolean, default: false },
    icon: { type: String, default: "" },
    label: { type: String, default: "" },
    left: { type: Boolean, default: true },
    right: { type: Boolean, default: false },
    hideLabelOnMobile: { type: Boolean, default: false },
  },
  computed: {
    isLeft() {
      return !this.isLabelEmpty() && this.left && !this.right;
    },
    isRight() {
      return !this.isLabelEmpty() && this.right;
    },
    labelClass() {
      return this.hideLabelOnMobile ? "d-none d-md-block" : "";
    },
  },
  methods: {
    isLabelEmpty() {
      return this.label.trim() === "";
    },
  },
};
</script>
