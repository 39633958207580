import { MESSAGE } from "../../constants";

export default class SnackbarMessageClass {
  type = null;
  value = null;

  constructor({ type = null, value = null }) {
    // <-------
    this.type = type;
    this.value = value;
  }

  static create(params) {
    // <-------
    return new SnackbarMessageClass(params);
  }

  static info(text) {
    // <-------
    return new SnackbarMessageClass({ type: MESSAGE.INFO, value: text });
  }

  static error(text) {
    // <-------
    return new SnackbarMessageClass({ type: MESSAGE.ERROR, value: text });
  }

  static chatTask({ user, task, updateType }) {
    // <-------
    return new SnackbarMessageClass({
      type: MESSAGE.CHAT_TASK,
      value: { user: user, task: task, updateType: updateType },
    });
  }

  display() {
    console.log(this);
  }
}
