<template>
  <div>
    <!--    {{vacations}}-->
    <v-form>
      <v-container>
        <v-row align="center">
          <v-col cols="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :close-on-click="false"
              :return-value.sync="vacation.dates"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Daty"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="vacation.dates" :first-day-of-week="1" range show-adjacent-months>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="menu = false"> Anuluj </v-btn>
                <v-btn color="primary" text @click="$refs.menu.save(vacation.dates)"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-select v-model="vacation.type" :items="['Urlop', 'Zwolnienie lekarskie']" label="Typ"></v-select>
          </v-col>
          <v-col cols="4">
            <v-btn :disabled="canBeSaved" block @click="addVacation">Dodaj</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card v-if="vacationsAreLoaded" flat>
      <v-card-text>
        <template>
          <v-data-table
            :headers="headers"
            :items="vacations"
            class="elevation-1"
            disable-pagination
            hide-default-footer
          >
            <!--            <template v-slot:item.isNew="{ item }">-->
            <!--              {{ item }}-->
            <!--            </template>-->
            <template #item.actions="{ item }">
              <v-icon class="mr-2" small @click="deleteVacation(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>
    <v-row v-else>
      <v-col align="center" style="margin-top: 10%">
        <v-progress-circular :size="100" :width="7" color="tertiary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  props: ["user"],
  data() {
    return {
      headers: [
        {
          text: "Początek",
          sortable: false,
          value: "dateStart",
        },
        {
          text: "Koniec",
          sortable: false,
          value: "dateFinish",
        },
        {
          text: "Typ",
          sortable: false,
          value: "type",
        },
        {
          text: "Zgłoszono",
          sortable: false,
          value: "requestDate",
        },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ],

      vacationsAreLoaded: false,

      menu: false,

      vacation: {
        type: "",
        dates: [],
      },
    };
  },
  computed: {
    vacations() {
      return this.$store.getters["vacation/getVacations"][this.user.id];
    },

    dateRangeText() {
      return this.vacation.dates.join(" ~ ");
    },

    canBeSaved() {
      return !(this.vacation.type !== "" && this.vacation.dates.length !== 0);
    },
  },
  watch: {
    user(a, b) {
      this.fetchVacationsForUser();
    },
  },
  created() {
    this.fetchVacationsForUser();
  },
  methods: {
    async fetchVacationsForUser() {
      this.vacationsAreLoaded = false;
      try {
        await this.$store.dispatch("vacation/fetchVacationsForUser", { userId: this.user.id });
      } catch (error) {
        console.log(error);
      }

      this.vacationsAreLoaded = true;
    },

    async addVacation() {
      this.vacationsAreLoaded = false;

      try {
        await this.$store.dispatch("vacation/addVacation", { user: this.user, vacation: this.vacation });
      } catch (error) {
        console.log(error);
      }
      this.vacation = {
        type: "",
        dates: [],
      };

      await this.$nextTick(async () => {
        await this.fetchVacationsForUser();
      });
      this.vacationsAreLoaded = true;
    },

    async deleteVacation(item) {
      console.log(item);
      this.vacationsAreLoaded = false;

      try {
        await this.$store.dispatch("vacation/deleteVacation", { user: this.user, vacation: item });
      } catch (error) {
        console.log(error);
      }
      this.vacationsAreLoaded = true;
    },
  },
};
</script>
