<template>
  <v-row no no-gutters>
    <v-col>
      <p>Użyj aplikacji mobilnej.</p>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "Dashboard",
};
</script>
