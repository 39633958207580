<template>
  <div v-if="technicians">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="auto" class="ma-0 pl-3">
            <v-chip
              :color="isRadmax ? 'grey' : 'transparent'"
              :outlined="isRadmax"
              :style="{ opacity: isRadmax ? '1' : '0.5' }"
              :tabindex="0"
              class="me-2"
              label
              small
              style="margin-right: 5px; margin-bottom: 5px"
              text-color="black"
              @click="selectTeam(isRadmax, radmaxWorkers)">
              {{ "Radmax" }}
            </v-chip>
          </v-col>
          <v-col cols="auto">
            <jobs-list-filter-column-single-select-chip
              :arr="
                technicians
                  .filter((x) => getUserRole(x.roles) !== ROLE.EXTERNAL)
                  .map((y) => ({ ...y, icon: 'mdi-circle', selected: selectedIds.includes(y.id) }))
              "
              :compared-array-key="null"
              :compared-value="'selected'"
              :is-horizontal="true"
              :md="'auto'"
              :prop-class="'me-2'"
              display-key="name"
              has-icon
              @selectElement="(n) => $emit('selectUser', n.id)" />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="auto" class="ma-0 pl-3">
            <v-chip
              :color="isExternal ? 'grey' : 'transparent'"
              :outlined="isExternal"
              :style="{ opacity: isExternal ? '1' : '0.5' }"
              :tabindex="0"
              class="me-2"
              label
              small
              style="margin-right: 5px; margin-bottom: 5px"
              text-color="black"
              @click="selectTeam(isExternal, externalWorkers)">
              {{ "Podwykonawcy" }}
            </v-chip>
          </v-col>
          <v-col cols="auto">
            <jobs-list-filter-column-single-select-chip
              :arr="
                technicians
                  .filter((x) => getUserRole(x.roles) === ROLE.EXTERNAL)
                  .map((y) => ({ ...y, icon: 'mdi-circle', selected: selectedIds.includes(y.id) }))
              "
              :compared-array-key="null"
              :compared-value="'selected'"
              :is-horizontal="true"
              :md="'auto'"
              :prop-class="'me-2'"
              display-key="name"
              has-icon
              @selectElement="(n) => $emit('selectUser', n.id)" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { getUserRole } from "../../helper";
import { ROLE } from "../../constants";
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import JobsListFilterColumnSingleSelectChip from "./JobsListFilterColumnSingleSelectChip.vue";
import { mapGetters } from "vuex";

export default {
  name: "CalendarTechnicianFilter",
  components: { JobsListFilterColumnSingleSelectChip, FormRowWithIcon },
  props: {
    selectedIds: {},
    technicians: { type: Array },
    singleSelect: { type: Boolean, default: false },
  },
  emits: ["selectUser"],
  computed: {
    ...mapGetters({
      workers: "calendarTasks/getUsers",
      radmaxWorkers: "calendarTasks/getRadmax",
      externalWorkers: "calendarTasks/getExternal",
    }),
    ROLE() {
      return ROLE;
    },
    isExternal() {
      return this.externalWorkers.map((x) => x.id).every((r) => this.selectedIds.includes(r));
    },
    isRadmax() {
      return this.radmaxWorkers.map((x) => x.id).every((r) => this.selectedIds.includes(r));
    },
  },
  methods: {
    getUserRole,
    selectTeam(isTeam, teamToSet) {
      const tempWorkers = new Set(this.selectedIds);
      const a = isTeam;
      teamToSet.forEach((x) => {
        if (!a) {
          tempWorkers.add(x.id);
        } else {
          tempWorkers.delete(x.id);
        }
      });
      this.$emit("selectUser", Array.from(tempWorkers));
    },
  },
};
</script>
<style scoped>
@keyframes selectFiltersAnimation {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}

/*.theme--light.v-calendar-daily .v-calendar-daily__intervals-body .v-calendar-daily__interval-text {*/
/*  color: transparent;*/
/*}*/
</style>
