<template>
  <v-row :style="{ width: width, 'min-width': getCardWidthPX }" no-gutters>
    <v-col v-if="plannedTasksList.length === 0">
      <v-row class="ml-3" no-gutters>
        <v-col style="max-width: 30px">
          <v-icon color="grey" size="25"> mdi-calendar-alert</v-icon>
        </v-col>
        <v-col>
          <span>Brak</span>
        </v-col>
      </v-row>
    </v-col>
<!--    {{plannedTasksList}}-->
    <v-col cols="12">
      <draggable
        v-model="plannedTasksList"
        :delay="isMobile ? 400 : 50"
        :group="{ name: 'group', pull: 'clone', put: false }"
        :move="checkMove"
        class="list-group"
        force-fallback="true"
        :sort="false"
        :disabled="otherTasksLoading"
        @end="endDrag"
        @start="hideUsers = true">
<!--        TODO-->
        <v-card
          v-for="otherTask in plannedTasksList"
          :key="otherTask.task.id + '_' + date"
          :id="'' + otherTask.task.id + '_' + date + '_'"
          :style="{ cursor: 'grab' }"
          elevation="3"
          outlined
          style="
            margin: 0 10px 5px;
            -webkit-user-select: none; /* Safari */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */
          "
          @click="show($event, '' + otherTask.task.id + '_' + date, otherTask.task)">
          <v-container :ref="otherTask.task.id + '_' + date" class="pa-0">
            <calendar-task-card :task="otherTask.task" show-dates is-unassigned style="padding: 3px">
              calendar-task-card>
            </calendar-task-card>
          </v-container>
        </v-card>
      </draggable>
      <v-row v-if="!plannedTasksList.length" class="ml-2 mt-2" no-gutters>
        <v-col style="max-width: 30px">
          <v-icon color="gray" size="25"> mdi-calendar-search</v-icon>
        </v-col>
        <v-col>
          <span>Brak wyników</span>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
    </v-col>
    <v-divider vertical></v-divider>
  </v-row>
</template>
<script>
import Draggable from "vuedraggable";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { dates, dateWithDayOfWeek, formatDate, groupBy } from "../../helper";
import types from "../../types.json";
import { FiltersBasicGroup, CALENDAR_PLANNING_MODE } from "../../constants";
import CalendarTaskCard from "./CalendarTaskCard.vue";

export default {
  name: "CalendarPlannedTasksDragColumn",
  components: { CalendarTaskCard, Draggable },
  props: {
    users: {},
    date: {},
    filters: {},
    otherTasksLoading: {},
    cancel: {},
    width: {},
  },
  emits: ["loadTasksOutsideCalendar", "loadCalendarTasks", "setCancel"],
  data: () => ({
    PLANNING_MODE: CALENDAR_PLANNING_MODE,
    taskNewPriority: null,
    hideUsers: false,
    oldUser: null,
    delay: 300,
    timer: null,
    clickedTask1: null,
    clickedTask2: null,
    firstFetch: true,
  }),
  computed: {
    plannedTasksList: {
      get() {
        return this.$store.getters["calendarTasks/getPlannedTasksOutsideCalendar"].map(item => ({task: item}))
      },
      set(v) {
        // console.log(v);
        // console.log(v);
        // console.log(v);
        this.$store.dispatch('calendarTasks/setPlannedTasksOutsideCalendar', v.map(item => (item.task)))
      }
    },
    FiltersBasicGroup() {
      return FiltersBasicGroup;
    },
    dates() {
      return dates;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    ...mapGetters({
      draggingTask: "calendarTasks/getDraggingTask",
      menuCardWidth: "layoutHelpers/getMenuPopUpWidth",
      serviceTechnicians: "calendarTasks/getUsers",
      newUser: "calendarTasks/getTargetUser",
      targetDate: "calendarTasks/getTargetDate",
      userTasks: "calendarTasks/getUserTasks",
    }),
    ...mapGetters("calendarTasks", [
      "getUnplannedTasksOutsideCalendar",
      "getAssignedTaskWithoutDate",
      "getUnassignedTaskWithoutDate",
      "getUnassignedTaskWithDate",
      "getGroupedTasksOutsideCalendar"
    ]),
    ...mapGetters("layoutHelpers", ["getCardWidthPX"]),
  },
  methods: {
    formatDate,
    dayDiff(d) {
      return Math.ceil((new Date(d) - new Date()) / (1000 * 60 * 60 * 24));
    },
    keyDate(o) {
      return dateWithDayOfWeek(formatDate(o));
    },
    ...mapMutations({ setTargetDate: "calendarTasks/setTargetDate" }),
    ...mapMutations({ setNewUser: "calendarTasks/setTargetUser" }),
    ...mapActions({ setMenuPopUp: "layoutHelpers/setMenuParams" }),
    ...mapMutations({ setDraggingTask: "calendarTasks/setDraggingTask" }),
    ...mapActions({ addLoadingCols: "calendarTasks/addLoadingCols" }),
    ...mapActions({ removeLoadingCols: "calendarTasks/removeLoadingCols" }),
    ...mapActions("calendarTasks", ["setUnassignedFilters"]),
    ...mapActions('tasks', ['scheduleTask']),
    show(e, i, o) {
      console.log(this.$refs);
      console.log(this.$refs[i][0].getBoundingClientRect());
      let pos = this.$refs[i][0].getBoundingClientRect();
      e.preventDefault();
      this.setMenuPopUp({ showMenu: false });
      this.$nextTick(() => {
        const menuParams = {
          dayDiff: this.dayDiff(o.job.cutOffDate),
          cutOffDate: formatDate(o.job.cutOffDate),
          startAt: formatDate(o.startAt),
          menuDetails: o,
          returnIcon: types.taskTypes[o.type].icon,
          showMenu: true,
          x: pos.right > 1000 ? pos.left - this.menuCardWidth : pos.right,
          y: pos.top,
        };

        if (this.clickedTask1 !== null && this.clickedTask2 === null) {
          this.clickedTask2 = o.id;
        }
        if (this.clickedTask1 === null) {
          this.clickedTask1 = o.id;
        }

        if (this.clickedTask1 !== null && this.clickedTask2 === null) {
          var self = this;
          this.timer = setTimeout(
            function () {
              self.clickedTask1 = null;
            },
            this.delay,
            this.$nextTick(() => {
              this.setMenuPopUp(menuParams);
            })
          );
        } else {
          if (this.clickedTask1 === this.clickedTask2) {
            clearTimeout(this.timer);
            window.open("/job/" + o.job.id, "_blank");
          }
          this.clickedTask1 = null;
          this.clickedTask2 = null;
        }
      });
    },
    endDrag: async function(e) {
      if (this.cancel || e.from === e.to) {
        try {
          this.$delete(this.userTasks[this.targetDate][this.newUser].tasks, this.taskNewPriority);
        } catch (e) {
          console.log(e);
        }

        this.setDraggingTask(null);
        this.taskNewPriority = null;
        this.setTargetDate(null);
        this.hideUsers = false;
        this.$emit("setCancel", false);
        return false;
      }
      if (!this.draggingTask) {
        this.setNewUser(null);
        this.oldUser = null;

        this.setDraggingTask(null);
        this.taskNewPriority = null;
        this.setTargetDate(null);
        this.hideUsers = false;
        return
      }
      const payload = {
        taskId: this.draggingTask.id,
        taskNewDate: this.targetDate,
        taskNewPriority: this.taskNewPriority + 1,
        taskNewUser: this.newUser,
      };

      await this.addLoadingCols({ userId: Number(this.newUser), date: this.targetDate });
      await this.scheduleTask(payload);
      this.$emit("loadCalendarTasks", { date: this.targetDate });
      this.$emit("loadTasksOutsideCalendar");
      await this.removeLoadingCols({ userId: Number(this.newUser), date: this.targetDate });
      this.setNewUser(null);
      this.oldUser = null;

      this.setDraggingTask(null);
      this.taskNewPriority = null;
      this.setTargetDate(null);
      this.hideUsers = false;
    },
    checkMove: function (e) {
      this.showMenu = false;
      let info = e.to.id.split("_");
      this.setTargetDate(info[1]);
      this.setNewUser(info[0]);
      // debug
      // console.log('target');
      // console.log(info);
      // console.log("dragging task info");
      // console.log("element");
      console.log(e.draggedContext.element);
      console.log(e.draggedContext.element.id);
      this.taskNewPriority = e.draggedContext.futureIndex;
      const passParam = { task: e.draggedContext.element.task, id: e.draggedContext.element.task.id, priority: -1 };
      // console.log("setDraggingTask");
      this.setDraggingTask(passParam);
      this.oldUser = null;
      if (this.cancel) {
        return false;
      }
    },
  },
  watch: {
    filters: {
      async handler(a, b) {
        console.log(b);
        console.log(a);
        if (this.firstFetch) {
          this.firstFetch = false;
          await this.setUnassignedFilters({ ...this.filters, ...{ users: this.serviceTechnicians.map((x) => x.id) } });
        }
        await this.$emit("loadTasksOutsideCalendar");
      },
      deep: true,
    },
    getTasksOutsideCalendar(a, b) {
      this.genericSort(this.filters.planningMode);
    },
  },
  created() {
    console.log(this.filters);
    console.log(this.serviceTechnicians);
  },
};
</script>