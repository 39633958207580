<template>
  <chat-filter-chip
    v-if="status === CHAT_TASK_STATUS.OPEN"
    :disabled="disabled"
    :has-text="!noText"
    :outlined="outlined"
    icon="mdi-play-box-outline"
    icon-color="blue">
    {{ statusText() }}
  </chat-filter-chip>
  <chat-filter-chip
    v-else-if="status === CHAT_TASK_STATUS.COMPLETED"
    :disabled="disabled"
    :has-text="!noText"
    :outlined="outlined"
    icon="mdi-check-circle"
    icon-color="green">
    {{ statusText() }}
  </chat-filter-chip>
  <chat-filter-chip
    v-else-if="status === CHAT_TASK_STATUS.UNCONFIRMED"
    :disabled="disabled"
    :has-text="!noText"
    :outlined="outlined"
    icon="mdi-help-rhombus"
    icon-color="orange">
    {{ statusText() }}
  </chat-filter-chip>
  <chat-filter-chip
    v-else-if="status === CHAT_TASK_STATUS.REJECTED"
    :disabled="disabled"
    :has-text="!noText"
    :outlined="outlined"
    icon="mdi-minus-circle"
    icon-color="red">
    {{ statusText() }}
  </chat-filter-chip>
</template>

<script>
import ChatFilterChip from "./ChatFilterChip.vue";
import { CHAT_TASK_STATUS } from "../../constants";

export default {
  components: { ChatFilterChip },
  props: {
    status: {
      type: String,
      required: true,
    },
    noText: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    CHAT_TASK_STATUS() {
      return CHAT_TASK_STATUS;
    },
  },
  methods: {
    statusText() {
      if (this.noText) {
        return null;
      }
      switch (this.status) {
        case CHAT_TASK_STATUS.OPEN:
          return "Otwarte";
        case CHAT_TASK_STATUS.COMPLETED:
          return "Zakończone";
        case CHAT_TASK_STATUS.UNCONFIRMED:
          return "Oczekujące";
        case CHAT_TASK_STATUS.REJECTED:
          return "Odrzucone";
      }
    },
  },
};
</script>
