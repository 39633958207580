<template>
  <v-combobox
    ref="mycombobox"
    :append-icon="appendIcon"
    :background-color="resolveColor()"
    :dense="dense"
    :disabled="disabled"
    :item-text="itemText"
    :items="items"
    :label="label"
    :loading="loading"
    :readonly="readonly"
    :search-input.sync="search"
    :hide-details="hideDetails"
    :value="value"
    :clearable="!readonly"
    color="black"
    filled
    :rules="rules"
    persistent-hint
    @change="addEmit"
    @click:append="$emit('appendEmit')">
    <template v-if="valueIsNew" #prepend-item>
      <v-list-item>
        <v-list-item-title> {{ search }} zostanie <strong>utworzony</strong>.</v-list-item-title>
      </v-list-item>
    </template>
    <template v-if="valueIsNew" #no-data>
      <div style="height: 5px"></div>
    </template>
    <template v-if="valueIsNew" #message>
      <v-list-item>
        <v-list-item-title> {{ search }} zostanie <strong>utworzony</strong>.</v-list-item-title>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: {
    value: {},
    label: {},
    items: {},
    itemText: {},
    disabled: {},
    autofocus: {},
    loading: {},
    appendIcon: { default: null },
    dense: { default: false, type: Boolean },
    readonly: {default: false, type: Boolean },
    hideDetails: {default: false, type: Boolean },
    rules: {default: () => [], type: Array}
  },
  emits: ["trueValue", "appendEmit"],
  data: () => ({
    search: null,
    currentTargetInput: null,
    valueIsNew: false,
  }),
  watch: {
    async search(val) {
      if (val === "" || val === null) {
        this.valueIsNew = null;
        return;
      }
      for (const [key, value] of Object.entries(this.items)) {
        if (value.name === val) {
          this.valueIsNew = false;
          await this.updateTargetInput(value);
          return;
        } else {
          this.valueIsNew = true;
        }
      }
      if (this.items.length === 0) {
        this.valueIsNew = true;
      }
      this.addEmit(val);
    },
  },
  methods: {
    resolveColor() {
      if (this.readonly) {
        return "transparent";
      }
      if (!this.currentTargetInput) {
        return "transparent";
      }
      if (this.valueIsNew === null) {
        return "transparent";
      }
      if (this.valueIsNew) {
        return "rgba(255,245,157,0.6)";
      } else {
        return "rgba(139,195,74,0.6)";
      }
    },
    async updateTargetInput(currentValue) {
      this.currentTargetInput = currentValue;
      await this.$emit("trueValue", currentValue);
    },
    addEmit(val) {
      this.$emit("trueValue", val);
    },
  },
};
</script>
