import Vue from 'vue';

export default {
  setSearchTerm(state, term) {
    state.searchTerm = term;
  },
  setArticles(state, payload) {
    state.articles = payload;
  },
  setArticleInArticles(state, { articleIdx, article }) {
    console.log(article);
    console.log(articleIdx);
    console.log(state.articles[articleIdx]);
    Vue.set(state.articles, articleIdx, article);
  },
  setTotalArticles(state, payload) {
    state.totalArticles = payload;
  },
  setArticlesLoading(state, isLoading) {
    state.loadingProducts = isLoading;
  },

  setTableOptions(state, options) {
    state.tableOptions = options;
  },
};
