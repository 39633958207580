<template>
  <v-container fluid>
    <v-card flat>
      <customer-edit-dialog :edit-type="editType" :edited-customer="editedCustomer" @refresh="refresh()" />
      <template>
        <v-data-table
          :headers="customerHeaders"
          :items="customers"
          class="elevation-1"
          item-key="id"
          show-expand
          single-expand
          :items-per-page="-1"
        >
          <template #item.name="{ item }">
            {{ item.name ?? "Brak przypisanego klienta" }}
          </template>
          <template #item.locations="{ item }">
            {{ item.customerLocations.length }}
          </template>
          <template #item.actions="{ item }">
            <v-icon class="mr-2" small @click="showDialog(DIALOG.EDIT_CUSTOMER, item)"> mdi-pencil </v-icon>
            <v-icon v-if="couldDelete(item)" small @click="showDialog(DIALOG.DELETE_CUSTOMER, item)">
              mdi-delete
            </v-icon>
          </template>
          <template #expanded-item="{ headers, item }">
            <customer-locations-table :headers="headers" :item="item" @showDialog="(d, i) => showDialog(d, item, i)" />
          </template>
        </v-data-table>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CustomerLocationsTable from "../../components/customer/CustomerLocationsTable.vue";
import CustomerEditDialog from "../../components/customer/CustomerEditDialog.vue";
import { DIALOG } from "../../constants";

export default {
  components: { CustomerEditDialog, CustomerLocationsTable },
  data: () => ({
    editType: null,
    customerHeaders: [
      {
        text: "Klient",
        value: "name",
        sortable: false,
      },
      {
        text: "Lokalizacje",
        value: "locations",
        sortable: false,
      },
      {
        text: "",
        value: "actions",
        sortable: false,
      },
    ],
    editedCustomer: {
      customerId: null,
      locationId: null,
      customerName: null,
      locationName: null,
    },
  }),
  computed: {
    DIALOG() {
      return DIALOG;
    },
    ...mapGetters({
      customers: "customers/getCustomerLocationsJobs",
    }),
  },
  methods: {
    async refresh() {
      this.editType = null;
      this.addLoadingProcess("fetchCustomerLocationJobs");
      await this.fetchCustomerLocationJobs();
      this.removeLoadingProcess("fetchCustomerLocationJobs");
    },
    ...mapActions({
      fetchCustomerLocationJobs: "customers/fetchCustomerLocationsJobs",
    }),
    ...mapMutations("layoutHelpers", ["addLoadingProcess", "removeLoadingProcess"]),
    couldDelete(item) {
      return item.customerLocations.find((x) => x.jobs.length > 0) === undefined;
    },
    showDialog(type, customer, location = null) {
      this.editType = type;
      this.editedCustomer = {
        customerId: customer.id,
        locationId: location !== null ? location.id : null,
        customerName: customer.name,
        locationName: location !== null ? location.name : null,
      };
    },
  },
  async created() {
    this.addLoadingProcess("fetchCustomerLocationJobs");
    await this.fetchCustomerLocationJobs();
    this.removeLoadingProcess("fetchCustomerLocationJobs");
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>
