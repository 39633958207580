export default {
  articleSuppliersDetails: (state) => {
    return state.articleSuppliersDetails;
  },
  supplierDetailsSubmitting: (state) => {
    return state.submittingSupplierDetails;
  },
  getArticleSupplierById: (state) => (supplierId) => {
    const supplierIdx = state.articleSuppliersDetails.findIndex((value) => value.id === supplierId);
    if (supplierIdx === -1) {
      return null;
    }
    return state.articleSuppliersDetails[supplierIdx];
  },
  articleSuppliersDetailsLoading: (state) => {
    return state.articleSuppliersDetailsLoading;
  },
};
