<template>
  <v-menu
    v-model="menuActive"
    :bottom="bottom"
    :close-on-content-click="false"
    :disabled="disabled"
    :top="top"
    offset-y>
    <template #activator="{ on, attrs }">
      <v-chip
        :disabled="disabled"
        class="mx-0 pr-0"
        color="transparent"
        label
        text-color="black"
        v-bind="attrs"
        v-on="on">
        <v-icon :color="taskStatuses[currentTaskStatus].color" left> {{ taskStatuses[currentTaskStatus].icon }}</v-icon>
        {{ taskStatuses[currentTaskStatus].name }}
        <v-icon color="grey" right> mdi-menu-down-outline</v-icon>
      </v-chip>
    </template>
    <v-list class="pa-0" dense>
      <v-list-item class="px-1">
        <v-row no-gutters>
          <v-col>
            <span>Zmień status</span>
          </v-col>
        </v-row>
      </v-list-item>
      <v-list-item
        v-for="(availableStatus, taskStatusKey, index) in availableStatuses"
        :key="index"
        class="px-1"
        @click="preSelectedStatus = taskStatusKey">
        <v-row no-gutters>
          <v-col>
            <v-chip
              :color="taskStatusKey === computedPreSelectedStatus ? 'grey' : 'transparent'"
              :outlined="taskStatusKey === computedPreSelectedStatus"
              class="ma-0"
              label
              style="width: 100%"
              text-color="black">
              <v-icon :color="availableStatus.color" left>{{ availableStatus.icon }}</v-icon>
              {{ availableStatus.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-list-item>
      <v-list-item class="pa-2">
        <v-row no-gutters>
          <v-col>
            <v-btn color="primary" block @click="changeStatus()"> Zapisz </v-btn>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import types from "../../types.json";

export default {
  props: {
    currentTaskStatus: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bottom: {
      default: false,
      type: Boolean,
    },
    top: {
      default: false,
      type: Boolean,
    },
  },
  emits: ["changeStatus"],
  data: () => ({
    preSelectedStatus: null,
    menuActive: false,
  }),
  computed: {
    computedPreSelectedStatus: {
      get() {
        return this.preSelectedStatus;
      },
      set(v) {
        this.preSelectedStatus = v;
      },
    },
    taskStatuses() {
      return types.taskStatusesFinal;
    },
    availableStatuses() {
      return Object.fromEntries(
        Object.entries(types.taskStatusesFinal).filter(([key, value]) => key !== this.currentTaskStatus)
      );
    }
  },
  methods: {
    async changeStatus() {
      this.$emit("changeStatus", this.computedPreSelectedStatus);
      this.menuActive = false;
    },
  },
  watch: {
    menuActive(n) {
      if (!n) {
        this.preSelectedStatus = n
      }
    }
  }
};
</script>
