<template>
  <v-card class="elevation-12">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Zmień e-mail</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form v-if="!!!message">
        <v-container>
          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="email"
                label="E-mail"
                autofocus
                required
                :error-messages="emailErrors"
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="repeatEmail"
                label="Powtórz e-mail"
                required
                :error-messages="repeatEmailErrors"
                @input="$v.repeatEmail.$touch()"
                @blur="$v.repeatEmail.$touch()"
              ></v-text-field>
            </v-col>
            <v-col align-self="center" cols="12" md="2">
              <v-btn :disabled="buttonDisabled" color="secondary" block @click="handleSubmit"> Zmień </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-sheet
        v-if="message"
        color="success"
        elevation="4"
        rounded
        style="padding: 20px; margin-right: 30px; margin-left: 30px; margin-bottom: 10px"
        >{{ message }}</v-sheet
      >
      <v-sheet
        v-if="error"
        color="error"
        elevation="4"
        rounded
        style="padding: 20px; margin-right: 30px; margin-left: 30px; margin-bottom: 10px"
        >{{ error }}</v-sheet
      >
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { email, required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "ChangeEmail",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    repeatEmail: { sameAs: sameAs("email") },
  },
  data: () => ({
    email: "",
    repeatEmail: "",
    error: "",
    message: "",
  }),
  computed: {
    ...mapGetters({ userName: "user/userInfo" }),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Wprowadź poprawny e-mail");
      !this.$v.email.required && errors.push("E-mail wymagany");
      return errors;
    },
    repeatEmailErrors() {
      const errors = [];
      if (!this.$v.repeatEmail.$dirty) return errors;
      !this.$v.repeatEmail.sameAs && errors.push("Powtórz e-mail");
      return errors;
    },
    buttonDisabled() {
      const nErrors = this.emailErrors.length + this.repeatEmailErrors.length;
      return !!nErrors || !!!this.repeatEmail.length;
    },
  },
  async created() {
    console.log("abc");
    console.log(this.$store.getters["user/userInfo"].name);
    await this.$store.dispatch("user/loadUserInfo");
    this.email = await this.$store.getters["user/userInfo"].email;
  },
  methods: {
    async handleSubmit() {
      this.isLogging = true;

      const request = {
        newEmail: this.email,
      };

      try {
        const response = await axios.post("user/changeEmail/sendEmail", JSON.stringify(request), {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.message =
          "Sprawdź skrzynkę pocztową dla podanego e-maila. Wejdź w link podany w wiadomości w celu zatwierdzenia nowego e-maila.";
      } catch (e) {
        console.log(e);
        this.error = e;
      }
      this.isLogging = false;
    },
  },
};
</script>
