import axios from 'axios';
import { deepMerge } from '../../../../helper';

export default {
  updateArticle({ commit, getters, rootGetters }, { article }) {
    const currentArticleIdx = rootGetters['warehouse/articles/articles'].findIndex((a) => a.id === article.id);
    console.log(currentArticleIdx);
    console.log(rootGetters['warehouse/articles/articles'][currentArticleIdx]);
    if (currentArticleIdx !== -1) {
      const updatedArticle = deepMerge(rootGetters['warehouse/articles/articles'][currentArticleIdx], article);
      commit(
        'warehouse/articles/setArticleInArticles',
        { articleIdx: currentArticleIdx, article: updatedArticle },
        { root: true }
      );
    }
    // console.log(getters.article);
    // console.log(getters.article.id);
    console.log(article);
    if (getters.article && getters.article.id === article.id) {
      // commit('setArticleDetailsLoading', true);

      const updatedArticle = deepMerge(getters.article, article);
      console.log(updatedArticle);

      commit('setArticleDetails', updatedArticle);
      // commit('setArticleDetailsLoading', false);
    }
  },

  async postNewArticle({ commit, getters, dispatch }, { articleData }) {
    console.debug(articleData, 'payload');
    commit('setSubmittingArticle', true);
    // await new Promise((resolve) => setTimeout(resolve, 2000)); //TODO: TEST
    try {
      const response = await axios.post(`warehouse/article/create`, { ...articleData });
      await dispatch('warehouse/suppliers/fetchArticleSuppliers',null, {root: true});
      console.debug(response.data, 'response.data');
    } catch (e) {
      // dispatch("messages/notifyError", `Serwer error ${e?.response?.status}: ${e?.response?.data?.error}`, {
      //   root: true,
      // });
      throw e;
    } finally {
      commit('setSubmittingArticle', false);
    }
  },
  async postArticleAddEAN({ commit, getters, dispatch }, { articleId, ean }) {
    commit('setSubmittingArticleEAN', true);
    // await new Promise((resolve) => setTimeout(resolve, 2000)); //TODO: TEST
    try {
      const response = await axios.post(`warehouse/article/${articleId}/ean/add`, { ean: ean });

      const currentArticleDetails = getters.article;
      commit('setArticleDetails', {
        ...currentArticleDetails,
        warehouseArticleExternalIdentifiers: [
          ...currentArticleDetails.warehouseArticleExternalIdentifiers,
          { id: response.data.id, EAN: ean },
        ],
      });
      console.debug(response.data, 'response.data');
    } catch (e) {
      throw e;
    } finally {
      commit('setSubmittingArticleEAN', false);
    }
  },
  async postArticleRemoveEAN({ commit, getters, dispatch }, { ean }) {
    commit('setSubmittingArticleEAN', true);
    // await new Promise((resolve) => setTimeout(resolve, 2000)); //TODO: TEST
    try {
      const response = await axios.post(`warehouse/article/ean/${ean.id}/remove`);

      const currentArticleDetails = getters.article;
      const currentEANS = currentArticleDetails.warehouseArticleExternalIdentifiers.filter((value) => value !== ean);
      commit('setArticleDetails', {
        ...currentArticleDetails,
        warehouseArticleExternalIdentifiers: currentEANS,
      });
      console.debug(response.data, 'response.data');
    } catch (e) {
      throw e;
    } finally {
      commit('setSubmittingArticleEAN', false);
    }
  },
  async postArticleNameValidation({ commit, getters }, { name }) {
    console.debug(name, 'payload');
    commit('setSubmittingArticleValidation', true);
    // await new Promise((resolve) => setTimeout(resolve, 2000)); //TODO: TEST
    try {
      const response = await axios.post(`warehouse/article/name/validate`, { name: name });
      console.debug(response.data, 'response.data');
      return !response.data.error;
    } catch (e) {
      if (e.response.data.error) {
        return false;
      }
      throw e;
    } finally {
      commit('setSubmittingArticleValidation', false);
    }
  },
  async postArticleEANValidation({ commit, getters }, { ean }) {
    console.debug(ean, 'payload');
    commit('setSubmittingArticleEANValidation', true);
    // await new Promise((resolve) => setTimeout(resolve, 2000)); //TODO: TEST
    try {
      const response = await axios.post(`warehouse/article/ean/validate`, { ean: ean });
      console.debug(response.data, 'response.data');
      return !response.data.error;
    } catch (e) {
      if (e.response.data.error) {
        return false;
      }
      throw e;
    } finally {
      commit('setSubmittingArticleEANValidation', false);
    }
  },
  async fetchArticleDetails({ commit }, { articleId }) {
    commit('setArticleDetailsLoading', true);

    const response = await axios.get(`warehouse/article/${articleId}`);
    console.debug(response.data, 'response.data');
    commit('setArticleDetails', response.data);
    commit('setArticleDetailsLoading', false);
  },
  async postArticleUpdate({ commit, getters, dispatch }, article) {
    // commit('setArticleDetailsLoading', true);



    try {
      const response = await axios.post(`warehouse/article/${article.id}/update`, { ...article });
      // await dispatch('warehouse/suppliers/fetchArticleSuppliers',null, {root: true});
      console.debug(response.data, 'response.data');
      commit('setArticleDetails', response.data);
    } catch (e) {
      dispatch("messages/notifyError", `Serwer error ${e?.response?.status}: ${e?.response?.data?.error}`, {
        root: true,
      });
    }

    // commit('setArticleDetailsLoading', false);
  }
};
