<template>
  <v-container style="height: 100%">
    <template v-if="!articleDetailsLoading && !articleSuppliersLoading && !articleGroupsLoading">
      <v-row no-gutters>
        <v-col cols="6">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col v-if="article.warehouseArticleImages.length" cols="6">
                  <v-img
                    :src="WAREHOUSE_IMAGE_PATH + article.warehouseArticleImages.find((i) => i.isPrimary)?.filename"
                    aspect-ratio="1"
                    contain>
                    <template #placeholder>
                      <v-row align="center" class="fill-height ma-0" justify="center">
                        <v-icon> mdi-image-plus</v-icon>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col
                      v-for="(ai, aiIdx) in article.warehouseArticleImages.filter((i) => !i.isPrimary).slice(1, 4)"
                      :key="ai.id"
                      class="d-flex child-flex"
                      cols="6">
                      <v-img :src="WAREHOUSE_IMAGE_PATH + ai.filename" aspect-ratio="1" class="grey lighten-2">
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                          </v-row>
                        </template>
                        <v-row
                          class="d-flex flex-row fill-height align-content-center justify-center"
                          no-gutters
                          style="position: absolute; z-index: 10; width: 100%">
                          <v-col class="d-flex flex-column justify-center align-content-center flex-nowrap">
                            <v-row class="d-flex flex-row flex-grow-0 flex-shrink-1" no-gutters> </v-row>
                            <v-row class="d-flex flex-row flex-grow-0 flex-shrink-1 flex-nowrap" no-gutters>
                              <v-spacer />
                              <v-col class="d-flex flex-column" cols="auto">
                                <v-btn
                                  v-if="aiIdx === 2 && article.warehouseArticleImages.length > 4"
                                  color="grey darken-1 "
                                  >+{{ article.warehouseArticleImages.length - 4 }}
                                </v-btn>
                              </v-col>
                              <v-spacer />
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-img>
                    </v-col>
                    <v-col
                      :class="{
                        'justify-start': !article.warehouseArticleImages.length,
                        'justify-center': article.warehouseArticleImages.length,
                      }"
                      class="d-flex child-flex align-center"
                      cols="6">
                      <WarehouseArticleAddImage />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-toolbar-title>
              <v-card-title>
                <span>Szczegóły</span>
                <v-spacer></v-spacer>
                <v-row>
                  <v-col class="d-flex justify-end align-center">
                    <v-btn v-show="!articleIsEdited" depressed small @click="startEditingArticle">
                      <v-icon> mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      v-show="articleIsEdited"
                      depressed
                      small
                      style="margin-right: 5px"
                      @click="cancelEditingArticle">
                      <v-icon left> mdi-cancel</v-icon>
                      Anuluj
                    </v-btn>
                    <v-btn
                      v-if="articleDeletionConfirmed === false"
                      v-show="articleIsEdited"
                      depressed
                      small
                      style="margin-right: 5px"
                      @click="submitArticleSave">
                      <v-icon left> mdi-content-save</v-icon>
                      Zapisz
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-toolbar-title>
            <!--            <v-card-title></v-card-title>-->

            <v-card-text class="pt-0">
              <warehouse-article-form
                :article="article"
                :is-edition-active="articleIsEdited"
                @interface="getChildInterface"
                @newArticleData="(args) => (articleUpdateData = args)">
              </warehouse-article-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-spacer />
        <v-col class="pl-5" cols="6">
          <WarehouseArticleDetailsStock />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="py-3" cols="6">
          <v-card>
            <v-toolbar-title>
              <v-card-title class="pb-1">
                <span>Kody artykułu (GTIN)</span>
                <v-spacer></v-spacer>
                <v-row>
                  <v-col class="d-flex justify-end align-center">
                    <warehouse-create-article-e-a-n-dialog :article-id="article.id" />
                  </v-col>
                </v-row>
              </v-card-title>
            </v-toolbar-title>
            <v-card-text class="pa-0">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ String(article.id).padStart(6, "0") }}</v-list-item-title>
                    <v-list-item-subtitle>(systemowy)</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon class="ma-0">
                    <warehouse-article-ean-print-button :codes="[String(article.id).padStart(6, '0')]" />
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item v-for="ean in article.warehouseArticleExternalIdentifiers" :key="ean.id">
                  <v-list-item-content>
                    <v-list-item-title>{{ ean.EAN }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="ma-0">
                    <warehouse-delete-article-e-a-n-dialog :ean="ean" />
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="py-3" cols="6">
          <v-card>
            <v-card-title>Historia zmian</v-card-title>
            <v-card-text>
              <v-row v-if="articleChangesLoading" no-gutters>
                <v-col>
                  <v-progress-circular></v-progress-circular>
                </v-col>
              </v-row>
              <v-row v-else no-gutters>
                <v-col>
                  <v-row
                    v-for="(articleChange, idx) in articleChanges"
                    :key="articleChange.id"
                    :style="{ 'background-color': idx % 2 === 1 ? '#FAFAFA' : 'white' }"
                    no-gutters>
                    <v-col>
                      <v-row no-gutters>
                        <v-spacer />
                        <v-col cols="auto">
                          <created-modified-row
                            :date="articleChange.createdInfo.createdAt"
                            :is-modified="true"
                            :user="
                              !workersLoading && getWorkers.find((w) => w.id === articleChange.createdInfo.createdBy.id)
                            "></created-modified-row>
                        </v-col>
                      </v-row>
                      <warehouse-form-row-with-icon
                        :icon="auditLogTypes.actionTypes[articleChange.action].icon"
                        :text="auditLogTypes.actionTypes[articleChange.action].name">
                        <warehouse-audit-log-details :article-change="articleChange"></warehouse-audit-log-details>
                      </warehouse-form-row-with-icon>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-spacer />
                    <v-col cols="auto">
                      <created-modified-row
                        :date="article.createdInfo.createdAt"
                        :is-modified="false"
                        :user="
                          !workersLoading &&
                          article.createdInfo.createdBy &&
                          getWorkers.find((w) => w.id === article.createdInfo.createdBy.id)
                        "></created-modified-row>
                    </v-col>
                  </v-row>
                  <warehouse-form-row-with-icon icon="mdi-plus">
                    <span>Utworzono artykuł</span>
                  </warehouse-form-row-with-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-row v-else class="fill-height" no-gutters>
      <v-col class="d-flex justify-center align-center">
        <v-progress-circular />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import warehouseTypes from "../../warehouseTypes.json";
import auditLogTypes from "../../auditLogTypes.json";
import CreatedModifiedRow from "../../components/layout/CreatedModifiedRow.vue";
import WarehouseArticleForm from "../../components/warehouse/article/WarehouseArticleForm.vue";
import WarehouseAuditLogDetails from "../../components/warehouse/WarehouseAuditLogDetails.vue";
import WarehouseFormRowWithIcon from "../../components/warehouse/WarehouseFormRowWithIcon.vue";
import { UPDATE_TYPE, WAREHOUSE_IMAGE_PATH } from "../../constants";
import WarehouseCreateArticleEANDialog from "../../components/warehouse/articleEAN/WarehouseCreateArticleEANDialog.vue";
import WarehouseDeleteArticleEANDialog from "../../components/warehouse/articleEAN/WarehouseDeleteArticleEANDialog.vue";
import WarehouseArticleEanPrintButton from "../../components/warehouse/articleEAN/WarehouseArticleEanPrintButton.vue";
import WarehouseArticleDetailsStock from "../../components/warehouse/WarehouseArticleDetailsStock.vue";
import WarehouseArticleAddImage from "../../components/warehouse/articleImage/WarehouseArticleAddImage.vue";

export default {
  name: "WarehouseArticleDetailsView",
  components: {
    WarehouseArticleAddImage,
    WarehouseArticleDetailsStock,
    WarehouseArticleEanPrintButton,
    WarehouseDeleteArticleEANDialog,
    WarehouseCreateArticleEANDialog,
    WarehouseFormRowWithIcon,
    WarehouseAuditLogDetails,
    WarehouseArticleForm,
    CreatedModifiedRow,
  },
  beforeRouteLeave(to, from, next) {
    this.removeListeners();
    next();
  },
  props: {
    articleId: {
      type: String,
    },
  },
  childInterface: {
    cancelEditing: () => {},
  },
  data() {
    return {
      warehouseTypes,
      auditLogTypes,
      articleDeletionConfirmed: false,
      articleIsEdited: false,
      selectedAuditLogId: null,
      articleUpdateData: null,
      updateWarehouseArticleCallback: null,
      updateWarehouseArticleStockCallback: null,
      addWarehouseArticleAuditLogCallback: null,
      addWarehouseArticleSupplierCallback: null,
      menuWidth: null,
      addWarehouseArticleGroupCallback: null,
      WAREHOUSE_IMAGE_PATH,
    };
  },
  computed: {
    ...mapGetters("warehouse/facilities", ["facilities", "facilitiesLoading", "getFacilityById"]),
    ...mapGetters("warehouse/groups", ["articleGroups", "articleGroupsLoading"]),
    ...mapGetters("warehouse/suppliers", ["articleSuppliers", "articleSuppliersLoading", "getArticleSupplierById"]),
    ...mapGetters("warehouse/articleDetails", ["article", "articleDetailsLoading"]),
    ...mapGetters("warehouse/changes", ["articleChangesLoading", "articleChanges"]),
    ...mapGetters("workers", ["getWorkers", "workersLoading"]),
    ...mapGetters("stream", ["getStream"]),
  },
  mounted() {
    console.log("MOUNTED");
    this.setListeners();
  },
  created() {
    this.fetchWorkers();
    this.fetchFacilities();
    this.fetchArticleGroups();
    this.fetchArticleSuppliers();
    this.fetchArticleDetails({ articleId: this.articleId });
    this.fetchArticleStock({ articleId: this.articleId });
    this.fetchArticleChanges({ articleId: this.articleId });
  },
  methods: {
    ...mapActions("warehouse/facilities", ["fetchFacilities"]),
    ...mapActions("warehouse/groups", ["fetchArticleGroups", "addArticleGroup"]),
    ...mapActions("warehouse/stocks", [
      "fetchArticleStock",
      "updateArticleStock",
      "postArticleStockCorrection",
      "postArticleStockReplenish",
      "postArticleStockConsumption",
      "postArticleStockTransfer",
    ]),
    ...mapActions("warehouse/suppliers", ["fetchArticleSuppliers", "addNewSupplier"]),
    ...mapActions("warehouse/changes", ["fetchArticleChanges", "addArticleChange"]),
    ...mapActions("warehouse/articleDetails", ["fetchArticleDetails", "postArticleUpdate", "updateArticle"]),
    ...mapActions("workers", ["fetchWorkers"]),

    startEditingArticle() {
      this.articleIsEdited = true;
    },
    cancelEditingArticle() {
      this.articleIsEdited = false;
      this.recreateForm();
    },
    async submitArticleSave() {
      await this.postArticleUpdate(this.articleUpdateData);
      this.articleIsEdited = false;
      console.debug("post article here", "submitArticleSave");
    },
    setListeners() {
      this.updateWarehouseArticleCallback = this.updateWarehouseArticleEvent();
      this.getStream.addEventListener(UPDATE_TYPE.WAREHOUSE_ARTICLE_UPDATE, this.updateWarehouseArticleCallback);
      this.updateWarehouseArticleStockCallback = this.updateWarehouseArticleStockEvent();
      this.getStream.addEventListener(
        UPDATE_TYPE.WAREHOUSE_ARTICLE_STOCK_UPDATE,
        this.updateWarehouseArticleStockCallback
      );
      this.addWarehouseArticleAuditLogCallback = this.addWarehouseArticleAuditLogEvent();
      this.getStream.addEventListener(
        UPDATE_TYPE.WAREHOUSE_ARTICLE_AUDIT_LOG_NEW_ENTRY,
        this.addWarehouseArticleAuditLogCallback
      );

      this.addWarehouseArticleSupplierCallback = this.addWarehouseArticleSupplierEvent();
      this.getStream.addEventListener(UPDATE_TYPE.WAREHOUSE_SUPPLIER_CREATE, this.addWarehouseArticleSupplierCallback);
      this.addWarehouseArticleGroupCallback = this.addWarehouseArticleGroupEvent();
      this.getStream.addEventListener(UPDATE_TYPE.WAREHOUSE_ARTICLE_GROUP_CREATE, this.addWarehouseArticleGroupCallback);
    },
    removeListeners() {
      console.log("remove listeners");
      this.getStream.removeEventListener(UPDATE_TYPE.WAREHOUSE_ARTICLE_UPDATE, this.updateWarehouseArticleCallback);
      this.getStream.removeEventListener(
        UPDATE_TYPE.WAREHOUSE_ARTICLE_STOCK_UPDATE,
        this.updateWarehouseArticleStockCallback
      );
      this.getStream.removeEventListener(
        UPDATE_TYPE.WAREHOUSE_ARTICLE_AUDIT_LOG_NEW_ENTRY,
        this.addWarehouseArticleAuditLogCallback
      );
      this.getStream.removeEventListener(
        UPDATE_TYPE.WAREHOUSE_SUPPLIER_CREATE,
        this.addWarehouseArticleSupplierCallback
      );
      this.getStream.removeEventListener(
        UPDATE_TYPE.WAREHOUSE_ARTICLE_GROUP_CREATE,
        this.addWarehouseArticleGroupCallback
      );
    },
    updateWarehouseArticleEvent() {
      return (event) => {
        console.debug(JSON.parse(event.data), "NEW -ARTICLE-  UPDATE");
        this.updateArticle({ article: JSON.parse(event.data) });
      };
    },
    updateWarehouseArticleStockEvent() {
      return (event) => {
        console.debug(JSON.parse(event.data), "NEW -ARTICLE STOCK-  UPDATE");
        this.updateArticleStock({ article: JSON.parse(event.data) });
        this.recreateForm();
      };
    },
    addWarehouseArticleAuditLogEvent() {
      return (event) => {
        console.debug(JSON.parse(event.data), "NEW -ARTICLE AUDIT LOG-  UPDATE");
        this.addArticleChange({ articleAuditLog: JSON.parse(event.data) });
      };
    },
    addWarehouseArticleSupplierEvent() {
      return (event) => {
        console.debug(JSON.parse(event.data), "NEW -SUPPIER-  ADD");
        this.addNewSupplier({ supplierData: JSON.parse(event.data) });
      };
    },
    addWarehouseArticleGroupEvent() {
      return (event) => {
        console.debug(JSON.parse(event.data), "NEW -Group-  ADD");
        this.addArticleGroup({ newGroup: JSON.parse(event.data) });
      };
    },
    recreateForm() {
      this.$options.childInterface.cancelEditing();
    },
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
  },
};
</script>
