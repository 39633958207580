<template>
  <div>
    <v-row v-if="title" no-gutters>
      <v-col>
        <p class="ma-3">{{ title }}</p>
      </v-col>
    </v-row>
    <v-sheet outlined rounded>
      <v-row no-gutters>
        <slot></slot>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "JobsListFilterColumn",
  props: {
    title: {},
  },
};
</script>

<style></style>
