import axios from 'axios';
import { formatDate, formatDateTime } from '../../../helper';

export default {
  async fetchVacationsForUser(context, payload) {
    const response = await axios.get('vacation/fetchVacations', { params: { userId: payload.userId } });
    if (response.status === 200) {
      response.data.forEach((item) => {
        item['dateStart'] = formatDate(item['dateStart']);
        item['dateFinish'] = formatDate(item['dateFinish']);
        item['requestDate'] = formatDateTime(item['requestDate']);
      });

      context.commit('setVacationsForUser', { userId: payload.userId, data: response.data });
    }
  },

  async fetchVacationsForCalendar(context, payload) {
    const response = await axios.get('vacation/fetchVacationsForCalendar', { params: { date: payload.date } });
    if (response.status === 200) {
      context.commit('setVacationsForCalendar', { data: response.data });
    }
  },

  async addVacation(context, payload) {
    const response = await axios.post('vacation/addVacation', { userId: payload.user.id, vacation: payload.vacation });
    if (response.status === 200) {
      context.commit('addVacationForUser', {
        userId: payload.user.id,
        newVacationId: response.data['newVacationId'],
        data: payload.vacation,
      });
      context.commit('addVacationToCalendar', {
        user: payload.user,
        newVacationId: response.data['newVacationId'],
        data: payload.vacation,
      });

      // payload.UserId = payload.user.id;
      // await this.fetchVacationsForUser(context, payload);
    }
  },

  async deleteVacation(context, payload) {
    const response = await axios.post('vacation/deleteVacation', { vacationId: payload.vacation.id });
    if (response.status === 200) {
      context.commit('deleteVacationForUser', { userId: payload.user.id, vacationId: payload.vacation.id });
      context.commit('deleteVacationInCalendar', { vacationId: payload.vacation.id });
    }
  },
};
