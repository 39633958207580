<template>
  <v-chip v-if="performBy" class="ma-0" color="transparent" disabled label style="opacity: 1" text-color="black">
    <v-icon :color="performBy.color" left> mdi-face-agent</v-icon>
    {{ performBy.name }}
  </v-chip>
  <v-chip v-else class="ma-0" color="transparent" disabled label style="opacity: 1" text-color="orange">
    <v-icon color="orange" left> mdi-account-question</v-icon>
    Nieprzypisane
  </v-chip>
</template>

<script>
export default {
  props: {
    performBy: {
      type: Object,
      default: null,
    },
  },
};
</script>
