import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      loadingProcesses: [],
      refreshCurrentView: false,
      menuPopUpWidth: 400,
      dragAndDropActive: true,
      menuParams: {
        dayDiff: {},
        formatDateMethod: {},
        menuDetails: null,
        returnIcon: {},
        showMenu: false,
        x: {},
        y: {},
      },
      windowHeight: null,
      windowsWidth: null,
      unassignedFiltersWidth: 500,
      showUnsignedFilters: false,
      showUnassignedPlannedColumn: true,
      showUnassignedUnplannedColumn: true,
      cardWidth: 200,
    };
  },
  mutations,
  actions,
  getters,
};
