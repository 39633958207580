<template>
  <v-chip
    :color="isOutlined() ? 'grey' : 'transparent'"
    :outlined="isOutlined()"
    :style="{ opacity: activeFilter === filter ? '1' : '0.5' }"
    :text-color="activeFilter === filter ? 'black' : 'grey'"
    :disabled="disabled"
    label
    @click="setActiveFilter(filter)">
    <v-icon :color="iconColor" :left="hasText" :right="!hasText"> {{ icon }}</v-icon>
    <slot></slot>
  </v-chip>
</template>

<script>
export default {
  emits:['activateFilter','changeTaskProperties'],
  props: {
    assignedUser:{
    },
    status:{
    },
    activeFilter: {
    },
    filter: {
    },
    hasText: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      default: 'black'
    }
  },
  model: {
    prop: 'activeFilter',
    event: 'activateFilter',
  },
  methods: {
    isOutlined() {
      if (this.disabled){
        return this.outlined;
      }
      return this.activeFilter === this.filter
    },
    setActiveFilter(filter) {
      // console.log('set filter');
      // console.log(filter);
      if(this.status || this.assignedUser){
        // console.log(this.status);
        this.$emit("changeTaskProperties");
      }
      this.$emit("activateFilter", filter);
    },
  },
};
</script>
