<template>
  <v-text-field
    v-model="computedName"
    :error-messages="nameErrors"
    :label="label"
    :append-icon="appendIcon()"
    :type="getTextFieldType()"
    required
    @blur="$v.inputValue.$touch()"
    @input="$v.inputValue.$touch()"
    @click:append="show = !show"
  ></v-text-field>
</template>
<script>
import { validationMixin } from "vuelidate";
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "CreateNewUserFieldName",
  mixins: [validationMixin],
  model: {
    prop: "inputValue",
    event: "newValue",
  },
  props: {
    inputValue: {},
    type: {},
    label: {},
    repeat: {},
  },
  emits: ["newValue"],
  data: () => ({
    show: false,
  }),
  validations() {
    return {
      inputValue: {
        required,
        email,
        minLength: minLength(8),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function (value) {
          return /[#?!@$%^&*-]/.test(value);
        },
        sameAs: sameAs("repeat"),
      },
    };
  },
  computed: {
    computedName: {
      get() {
        return this.inputValue;
      },
      set(v) {
        this.$emit("newValue", v);
      },
    },
    nameErrors() {
      console.log("type");
      console.log(this.type);
      const errors = [];
      if (!this.$v.inputValue.$dirty) return errors;
      if (this.type === "name") {
        !this.$v.inputValue.required && errors.push("Nazwa wymagana");
      }
      if (this.type === "email") {
        !this.$v.inputValue.required && errors.push("E-mail wymagany");
        !this.$v.inputValue.email && errors.push("E-mail niepoprawny");
      }
      if (this.type === "repeatEmail") {
        !this.$v.inputValue.required && errors.push("Powtórz e-mail!");
        !this.$v.inputValue.sameAs && errors.push("E-mail jest różny!");
      }
      if (this.type === "password") {
        !this.$v.inputValue.required && errors.push("Hasło wymagane");
        const passwordRules = ["Wymagania: "];
        !this.$v.inputValue.containsLowercase && passwordRules.push("mała litera,");
        !this.$v.inputValue.containsNumber && passwordRules.push("cyfrę,");
        !this.$v.inputValue.containsUppercase && passwordRules.push("wielka litera,");
        !this.$v.inputValue.containsSpecial && passwordRules.push("znak specjalny,");
        !this.$v.inputValue.minLength && passwordRules.push("co najmniej 8 znaków");
        passwordRules.length > 1 && errors.push(passwordRules.join(" "));
      }
      if (this.type === "confirmPassword") {
        !this.$v.inputValue.required && errors.push("Powtórz hasło!");
        !this.$v.inputValue.sameAs && errors.push("Hasłą są różne!");
      }
      return errors;
    },
  },
  methods: {
    getNameError() {
      if (!!!this.inputValue) {
        return ["empty"];
      }
      return this.nameErrors;
    },
    appendIcon() {
      if (this.type === "password" || this.type === "confirmPassword") {
        return this.show ? "mdi-eye-off" : "mdi-eye";
      }
      return null;
    },
    getTextFieldType() {
      if (this.type === "password" || this.type === "confirmPassword") {
        return this.show ? "text" : "password";
      }
      return "text";
    },
  },
};
</script>
