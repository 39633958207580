export default {
  setSubmittingSupplierDetails(state, isSubmitting) {
    state.submittingSupplierDetails = isSubmitting;
  },
  setArticleSuppliersDetails(state, payload) {
    state.articleSuppliersDetails = payload;
  },
  setArticleSuppliersDetailsLoading(state, isLoading) {
    state.articleSuppliersDetailsLoading = isLoading;
  },
};
