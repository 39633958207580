import axios from "axios";

export default {
  async uploadFile(context, { articleImage, articleId }) {
    let formData = new FormData();
    formData.append("file0", articleImage)
    formData.append("converted", false);
    const controller = new AbortController();

    context.commit("addUploadingFile", { file: articleImage, controller: controller });
    try {
      const response = await axios.post(`warehouse/article/${articleId}/upload-image`, formData, {
        signal: controller.signal,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          context.commit("setUploadProgress", { progress: parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)), file: articleImage});
        }.bind(this),
      });
      console.log(context.rootGetters["warehouse/articleDetails/article"], response.data);
      const newArticle = {...context.rootGetters["warehouse/articleDetails/article"], ...response.data}
      context.commit("warehouse/articleDetails/setArticleDetails",newArticle , {root: true})
    } catch (e) {
      console.log(e);
    } finally {
      context.commit('removeUploadingFile', articleImage);
    }
  },
  async deleteFile(context, {fileId}) {
    context.commit("setDeletingFile", { fileId });
    try {
      const response = await axios.delete(`warehouse/article-image/${fileId}`);
      const newArticle = {...context.rootGetters["warehouse/articleDetails/article"], ...response.data}
      context.commit("warehouse/articleDetails/setArticleDetails",newArticle , {root: true})
    } catch (e) {
      console.log(e);
    } finally {
      context.commit('setDeletingFile', { fileId: null });
    }
  },
  async setPrimaryImage(context, {fileId}) {
    context.commit("setSubmittingAsPrimaryImage", { fileId });
    try {
      const response = await axios.post(`warehouse/article-image/${fileId}/primary`);
      const newArticle = {...context.rootGetters["warehouse/articleDetails/article"], ...response.data}
      context.commit("warehouse/articleDetails/setArticleDetails",newArticle , {root: true})
    } catch (e) {
      console.log(e);
    } finally {
      context.commit('setSubmittingAsPrimaryImage', { fileId: null });
    }
  }
};
