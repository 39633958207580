<template>
  <v-card-text>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" md="10">
            <form-row-with-icon icon="mdi-account-question" text="Typ konta">
              <user-role-selector v-model="userData.role"></user-role-selector>
            </form-row-with-icon>

            <form-row-with-icon icon="mdi-table-eye" text="Widoczny w planowaniu">
              <chip-switch v-model="userData.planVisibility" />
            </form-row-with-icon>

            <form-row-with-icon icon="mdi-table-key" text="Przeplanowywanie zadań">
              <chip-switch v-model="userData.canReplan" />
            </form-row-with-icon>
          </v-col>
          <v-col align-self="center" cols="12" md="2">
            <v-btn block color="secondary" @click="handleSubmit"> Zapisz </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card-text>
</template>
<script>
import axios from "axios";
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import ChipSwitch from "./ChipSwitch.vue";
import UserRoleSelector from "../layout/UserRoleSelector.vue";
import { mapActions } from "vuex";
import CreateUserClass from "../../store/class/CreateUserClass";

export default {
  name: "AdminChangeRole",
  components: { UserRoleSelector, ChipSwitch, FormRowWithIcon },
  props: ["user"],
  data: () => ({
    userData: null,
    error: "",
    message: "",
  }),
  methods: {
    ...mapActions({ editUser: "users/editUser" }),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    async handleSubmit() {
      const ok = await this.editUser({ userId: this.user.id, userData: this.userData });
      if (ok) {
        this.notifyInfo("Zmieniono uprawnienia.");
        this.$emit("refresh");
      } else {
        this.notifyError("Nie można zmienić uprawnień");
      }
    },
  },
  created() {
    this.userData = CreateUserClass.create({
      role: this.user.role,
      canReplan: this.user.canReplan,
      planVisibility: this.user.planVisibility,
    });
    console.log(this.userData);
  },
};
</script>
