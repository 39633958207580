<template>
  <v-row no-gutters style="padding: 10px">
    <v-col cols="12" sm="6"></v-col>
    <v-col cols="12" sm="6">
      <v-row no-gutters>
        <v-col>
          <v-card>
            <ChangeEmail></ChangeEmail>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters><v-col style="padding-top: 10px"></v-col></v-row>
      <v-row no-gutters>
        <v-col>
          <v-card>
            <ChangePassword></ChangePassword>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ChangeEmail from "../../components/user/ChangeEmail";
import ChangePassword from "../../components/user/ChangePassword";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "UserEdit",
  components: { ChangePassword, ChangeEmail },
  created() {
    this.$store.commit("mobileLayoutSettings/setToolbarTitle", "Konto");
  },
};
</script>
