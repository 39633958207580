export default {
  setCustomers(state, payload) {
    // console.log(payload)
    state.customers = payload;
  },
  setCustomerLocationsJobs(state, payload) {
    console.log(payload);
    state.customerLocationsJobs = payload;
  },
  setCustomersLoading(state, { isLoading }) {
    state.customersLoading = isLoading;
  },
};
