export default {
  addLoadingProcess(state, payload) {
    state.loadingProcesses.push(payload);
  },
  removeLoadingProcess(state, payload) {
    if (state.loadingProcesses !== null && state.loadingProcesses.includes(payload)) {
      state.loadingProcesses = state.loadingProcesses.filter((value) => value !== payload);
    }
  },
  setMenuParams(state, payload) {
    state.menuParams = payload;
  },
  setCurrentViewRefreshing(state, payload) {
    state.refreshCurrentView = payload;
  },
  setWindowHeight(state, payload) {
    state.windowHeight = payload;
  },
  setWindowWidth(state, payload) {
    state.windowsWidth = payload;
  },
  setUnassignedFiltersWidth(state, payload) {
    state.unassignedFiltersWidth = payload;
  },
  setShowUnassignedFilters(state, payload) {
    state.showUnsignedFilters = payload;
  },
  switchShowUnassignedFilters(state) {
    state.showUnsignedFilters = !state.showUnsignedFilters;
  },
  setShowUnassignedPlannedColumn(state, payload) {
    state.showUnassignedPlannedColumn = payload;
  },
  setShowUnassignedUnplannedColumn(state, payload) {
    state.showUnassignedUnplannedColumn = payload;
  },
  setDragAndDropActive(state, boolPayload) {
    state.dragAndDropActive = boolPayload;
  },
};
