export default {
  searchTerm: (state) => {
    return state.searchTerm;
  },
  articles: (state) => {
    return state.articles;
  },
  totalArticles: (state) => {
    return state.totalArticles;
  },
  articlesLoading: (state) => {
    return state.loadingProducts;
  },
  tableOptions: (state) => {
    return state.tableOptions;
  },
};
