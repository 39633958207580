<template>
  <v-row no-gutters style="padding: 10px">
    <v-col sm="6" cols="12"> </v-col>
    <v-col cols="12" sm="6">
      <v-card style="width: 100%">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Resetowanie hasła</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form v-if="!message">
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="email"
                  :error-messages="emailErrors"
                  autofocus
                  label="E-mail"
                  required
                  @blur="$v.email.$touch()"
                  @input="$v.email.$touch()"
                ></v-text-field>
              </v-col>
              <v-col align-self="center" cols="12" md="4">
                <v-btn :disabled="buttonDisabled" block @click="handleSubmit">
                  <v-icon color="rgba(0, 0, 0, 1))" left> mdi-email </v-icon>
                  Wyślij
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-sheet
            v-if="message"
            color="success"
            elevation="4"
            rounded
            style="padding: 20px; margin-right: 30px; margin-left: 30px; margin-bottom: 10px"
            >{{ message }}
          </v-sheet>
          <v-sheet
            v-if="error"
            color="error"
            elevation="4"
            rounded
            style="padding: 20px; margin-right: 30px; margin-left: 30px; margin-bottom: 10px"
            >{{ error }}
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Forgot",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  data() {
    return {
      email: "",
      message: "",
      error: "",
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("E-mail niepoprawny");
      !this.$v.email.required && errors.push("E-mail wymagany");
      return errors;
    },
    buttonDisabled() {
      console.log(!!this.emailErrors.length || !!!this.email.length);
      return !!this.emailErrors.length || !!!this.email.length;
    },
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await axios.post("user/resetPassword/sendEmail", {
          email: this.email,
        });
        this.message = "Jeśli twój e-mail istnieje, otrzymasz e-mail z odnośnikiem do ustawienia nowego hasła.";
      } catch (e) {
        console.log(e);
        this.error = e;
      }
    },
  },
};
</script>
