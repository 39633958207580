<template>
  <v-row no-gutters>
    <v-col>
      <v-row v-if="articleGroupsDetailsLoading" no-gutters>
        <v-spacer />
        <v-col>
          <v-progress-circular></v-progress-circular>
        </v-col>
        <v-spacer />
      </v-row>
      <template v-else>
        <v-row no-gutters>
          <v-col>
            <v-data-table
              :headers="articleGroupHeaders"
              :items="articleGroupsDetails"
              class="elevation-1"
              hide-default-footer
              item-key="id"
              :items-per-page="-1">
              <template #top>
                <v-row no-gutters>
                  <v-spacer></v-spacer>
                  <v-col cols="auto" class="pr-3">
                    <warehouse-create-article-group-dialog />
                  </v-col>
                </v-row>
              </template>
              <template #item.actions="{ item }">
                <warehouse-edit-article-group-dialog :article-group="item" />
                <v-tooltip :disabled="item.articlesInGroupStats === 0" bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      <warehouse-delete-article-group-dialog :article-group="item" />
                    </span>
                  </template>
                  <span>Nie można usunąć z powodu przypisanych artykułów.</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import warehouseTypes from "../../../warehouseTypes.json";
import WarehouseCreateArticleGroupDialog from "./WarehouseCreateArticleGroupDialog.vue";
import WarehouseEditArticleGroupDialog from "./WarehouseEditArticleGroupDialog.vue";
import WarehouseDeleteArticleGroupDialog from "./WarehouseDeleteArticleGroupDialog.vue";

export default {
  name: "WarehouseArticleGroupsManagement",
  components: {
    WarehouseDeleteArticleGroupDialog,
    WarehouseEditArticleGroupDialog,
    WarehouseCreateArticleGroupDialog,
  },
  computed: {
    ...mapGetters({
      articleGroupsDetails: "warehouse/groupsSettings/articleGroupsDetails",
      articleGroupsDetailsLoading: "warehouse/groupsSettings/articleGroupsDetailsLoading",
    }),
  },
  async created() {
    await this.fetchArticleGroupsDetails();
  },
  data: () => ({
    articleGroupHeaders: [
      {
        text: "Grupa",
        value: "name",
        sortable: false,
      },
      {
        text: "Liczba artykułów",
        value: "articlesInGroupStats",
        sortable: false,
      },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    warehouseTypes,
  }),
  methods: {
    ...mapActions("warehouse/groupsSettings", ["fetchArticleGroupsDetails"]),
  },
};
</script>