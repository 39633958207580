<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 368 368"
    enable-background="new 0 0 368 368"
    xml:space="preserve"
  >
    <path
      fill="#2DAAE2"
      opacity="1.000000"
      stroke="none"
      d="
M50.000000,369.000000
	C33.678406,369.000000 17.356813,369.000000 1.017610,369.000000
	C1.017610,246.433243 1.017610,123.866486 1.017610,1.000000
	C64.687248,1.000000 128.374969,1.000000 192.272003,1.382924
	C196.921692,2.507110 201.338959,3.456160 205.806534,3.952487
	C222.271591,5.781678 237.957352,10.096834 252.503311,18.171213
	C263.063995,24.033371 271.315918,32.330936 278.064362,42.277248
	C289.452209,59.061432 291.809052,78.271912 292.897461,97.749069
	C293.226654,103.639877 292.155670,109.595337 292.033508,115.527504
	C291.657410,133.792847 287.668152,151.131165 278.444458,166.980499
	C270.764465,180.177261 260.133453,190.615295 245.674240,195.913345
	C232.220276,200.843048 218.296188,204.489685 204.380112,208.762283
	C208.080414,214.297562 212.279114,220.811462 216.708481,227.164520
	C237.461334,256.930511 258.270966,286.656952 279.074524,316.387604
	C287.847107,328.924591 296.775360,341.354950 305.384216,354.003021
	C308.618744,358.755188 311.148773,363.986908 314.000000,369.000000
	C293.979095,369.000000 273.958221,369.000000 253.462845,368.639160
	C250.646606,364.781158 248.353241,361.250458 245.955002,357.792450
	C232.780273,338.795898 219.541260,319.843842 206.397278,300.826050
	C186.313354,271.766998 166.218063,242.715042 146.353027,213.506744
	C144.444168,210.700073 142.571854,209.946243 139.546844,209.961365
	C123.215027,210.042999 106.882591,210.000000 90.550362,210.000000
	C88.788063,210.000000 87.025772,210.000000 85.371620,210.000000
	C85.371620,195.288498 85.371620,181.230042 85.371620,167.000000
	C110.232132,167.000000 134.727982,167.339310 159.208206,166.867630
	C173.004410,166.601807 186.859863,165.644272 200.536148,163.841553
	C219.068329,161.398743 232.240311,151.283463 237.462067,132.899612
	C242.386887,115.561127 242.938110,97.695572 238.769577,80.206261
	C234.899002,63.967041 224.374512,53.185776 207.795517,48.916683
	C190.918564,44.570866 173.663635,44.115368 156.406342,44.041981
	C122.742561,43.898823 89.077751,44.000004 55.413364,44.000011
	C53.664349,44.000011 51.915333,44.000015 50.000000,44.000015
	C50.000000,152.831223 50.000000,260.915619 50.000000,369.000000
z"
    />
    <path
      fill="none"
      opacity="1.000000"
      stroke="none"
      d="
M50.468658,369.000000
	C50.000000,260.915619 50.000000,152.831223 50.000000,44.000015
	C51.915333,44.000015 53.664349,44.000011 55.413364,44.000011
	C89.077751,44.000004 122.742561,43.898823 156.406342,44.041981
	C173.663635,44.115368 190.918564,44.570866 207.795517,48.916683
	C224.374512,53.185776 234.899002,63.967041 238.769577,80.206261
	C242.938110,97.695572 242.386887,115.561127 237.462067,132.899612
	C232.240311,151.283463 219.068329,161.398743 200.536148,163.841553
	C186.859863,165.644272 173.004410,166.601807 159.208206,166.867630
	C134.727982,167.339310 110.232132,167.000000 85.371620,167.000000
	C85.371620,181.230042 85.371620,195.288498 85.371620,210.000000
	C87.025772,210.000000 88.788063,210.000000 90.550362,210.000000
	C106.882591,210.000000 123.215027,210.042999 139.546844,209.961365
	C142.571854,209.946243 144.444168,210.700073 146.353027,213.506744
	C166.218063,242.715042 186.313354,271.766998 206.397278,300.826050
	C219.541260,319.843842 232.780273,338.795898 245.955002,357.792450
	C248.353241,361.250458 250.646606,364.781158 252.994186,368.639160
	C185.645767,369.000000 118.291542,369.000000 50.468658,369.000000
z"
    />
    <path
      fill="none"
      opacity="1.000000"
      stroke="none"
      d="
M314.468658,369.000000
	C311.148773,363.986908 308.618744,358.755188 305.384216,354.003021
	C296.775360,341.354950 287.847107,328.924591 279.074524,316.387604
	C258.270966,286.656952 237.461334,256.930511 216.708481,227.164520
	C212.279114,220.811462 208.080414,214.297562 204.380112,208.762283
	C218.296188,204.489685 232.220276,200.843048 245.674240,195.913345
	C260.133453,190.615295 270.764465,180.177261 278.444458,166.980499
	C287.668152,151.131165 291.657410,133.792847 292.033508,115.527504
	C292.155670,109.595337 293.226654,103.639877 292.897461,97.749069
	C291.809052,78.271912 289.452209,59.061432 278.064362,42.277248
	C271.315918,32.330936 263.063995,24.033371 252.503311,18.171213
	C237.957352,10.096834 222.271591,5.781678 205.806534,3.952487
	C201.338959,3.456160 196.921692,2.507110 192.740662,1.382924
	C251.623795,1.000000 310.247589,1.000000 368.935669,1.000000
	C368.935669,123.666656 368.935669,246.333328 368.935669,369.000000
	C350.911041,369.000000 332.924194,369.000000 314.468658,369.000000
z"
    />
  </svg>
</template>

<script>
export default {
  name: "CustomIcon",
};
</script>
