import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
import UnassignedFiltersClass from '../../class/UnassignedFiltersClass';

export default {
  namespaced: true,
  state() {
    return {
      calendarSelectedUserIds: [],
      apiUserTasks: [],
      userTasks: {},
      users: {},
      targetDate: null,
      targetUser: null,
      loadingCols: [],
      draggingTask: null,
      userVacations: {},
      unplannedTasksOutsideCalendar: {
        assignedTaskWithoutDate: [],
        unassignedTaskWithDate: [],
        unassignedTaskWithoutDate: [],
        overdueMoreThan7Days: [],
        overdueLessThan7Days: [],
      },
      plannedTasksOutsideCalendar: [],
      tasksGroupedOutsideCalendar: null,
      unassignedFilters: UnassignedFiltersClass.createDefault([]),
    };
  },
  mutations,
  actions,
  getters,
};
