import { TASK_TYPES as TT, CALENDAR_PLANNING_MODE} from "../../constants";
import { formatDate } from "../../helper";

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
export default class UnassignedFiltersClass {
  planningMode = null;
  ascending = null;
  realizationStartDate = null;
  realizationEndDate = null;
  cutOffStartDate = null;
  cutOffEndDate = null;
  showUnplanned = null;
  taskTypes = null;
  taskStatuses = null;
  users = null;

  constructor({
    planningMode = null,
    ascending = null,
    realizationStartDate = null,
    realizationEndDate = null,
    cutOfStartDate = null,
    cutOfEndDate = null,
    showUnplanned = null,
    taskTypes = null,
    taskStatuses = null,
    users = null,
  }) {
    // <-------
    this.planningMode = planningMode;
    this.ascending = ascending;
    this.realizationStartDate = realizationStartDate;
    this.realizationEndDate = realizationEndDate;
    this.cutOffStartDate = cutOfStartDate;
    this.cutOffEndDate = cutOfEndDate;
    this.showUnplanned = showUnplanned;
    this.taskTypes = taskTypes;
    this.taskStatuses = taskStatuses;
    this.users = users;
  }

  static createDefault(users = []) {
    // <-------
    const date = new Date();

    return new UnassignedFiltersClass({
      planningMode: CALENDAR_PLANNING_MODE.UNPLANNED,
      ascending: true,
      realizationStartDate: null,
      realizationEndDate: formatDate(date.addDays(30)),
      cutOfStartDate: null,
      cutOfEndDate: formatDate(date.addDays(30)),
      showUnplanned: true,
      taskTypes: [TT.DIAGNOSIS, TT.OVERVIEW, TT.QUOTATION, TT.REPAIR, TT.DEFECT, TT.INSTALLATION, TT.WORKSHOP, TT.MODERNIZATION, TT.ANOTHER],
      taskStatuses: [],
      users: users,
    });
  }

  static create(params) {
    // <-------
    return new UnassignedFiltersClass(params);
  }

  display() {
    console.log(this);
  }
}
