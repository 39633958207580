export default class DoubleClick {
  delay = 300
  timer = null
  clicks = 0

  constructor({
                delay = 300,
                timer = null,
                clicks = 0
  }) {
    // <-------
    this.delay = delay
    this.timer = timer
    this.clicks = clicks
  }

  static createDefault() {
    // <-------
    console.log(this.timer);
    console.log(this.clicks);
    return new DoubleClick({
      delay: 300,
      timer: null,
      clicks: 0
    });
  }

  static create(params) {
    // <-------
    return new DoubleClick(params);
  }

  doubleClick(value, callbackSingle, callbackDouble) {
    console.log(this.timer);
    console.log(this.clicks);
    this.clicks++;
    console.log(this.timer);
    console.log(this.clicks);
    console.log(this.delay);
    if (this.clicks === 1) {
      this.timer = setTimeout( () => {
        callbackSingle()
        this.clicks = 0
      }, this.delay);
    } else {
      clearTimeout(this.timer);
      callbackDouble()
      this.clicks = 0;
    }
  }

  display() {
    console.log(this);
  }
}
