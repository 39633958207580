<template>
  <div v-if="roles">
    <v-chip
      v-for="role in roles"
      :key="role"
      :color="role !== selectedRole ? 'white' : 'grey'"
      :outlined="role === selectedRole"
      :text-color="role === selectedRole ? 'black' : 'grey'"
      label
      @click="setSelectedUser(role)"
    >
      <v-icon v-if="role !== ROLE.EXTERNAL" left small> $custom </v-icon>
      <v-icon left>
        {{ getUserRoleProps(role).icon }}
      </v-icon>
      {{ getUserRoleProps(role).name }}
    </v-chip>
  </div>
</template>
<script>
import types from "../../types.json";
import { getUserRoleProps } from "../../helper";
import { ROLE } from "../../constants";

export default {
  name: "UserRoleSelector",
  model: {
    prop: "selectedRole",
    event: "newValue",
  },
  props: {
    selectedRole: {},
  },
  emits: ["newValue"],
  data: () => ({
    roles: Object.keys(types.userRole),
  }),
  computed: {
    ROLE() {
      return ROLE;
    },
  },
  methods: {
    getUserRoleProps,
    setSelectedUser(role) {
      this.$emit("newValue", role);
    },
  },
};
</script>

<style scoped></style>
