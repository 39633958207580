<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template #activator="{ on, attrs }">
      <v-btn color="primary" small dark v-bind="attrs" v-on="on">
        <v-icon left> mdi-plus </v-icon>
        Dodaj protokół
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Dodaj protokół</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="8">
              <form-row-with-icon icon="mdi-text">
                <v-text-field
                  v-model="protocolName"
                  :error="!!!protocolName"
                  :rules="[(value) => !!value || 'Nazwa protokołu nie może być pusta.']"
                  clearable
                  label="Nazwa protokołu"></v-text-field>
              </form-row-with-icon>
            </v-col>
            <v-col cols="4">
              <v-menu
                v-model="menuProtocolDate"
                :close-on-content-click="false"
                min-width="auto"
                offset-y
                transition="scale-transition">
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="protocolDate"
                    label="Data protokołu"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @keydown.s.space.prevent="menuProtocolDate = true"></v-text-field>
                </template>
                <v-date-picker
                  v-model="protocolDate"
                  :first-day-of-week="1"
                  no-title
                  show-adjacent-months
                  @input="menuProtocolDate = false">
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <form-row-with-icon icon="mdi-snowflake" text="Rodzaj urządzenia" style="margin-top: 10px">
            <v-chip
              v-for="(v, k, i) in types.applianceGroupType"
              :key="k"
              :autofocus="true"
              :color="applianceGroupType === k ? 'black' : 'white'"
              :outlined="applianceGroupType === k"
              :text-color="applianceGroupType === k ? 'black' : 'grey'"
              class="me-2"
              label
              @click="applianceGroupType = k">
              {{ v.name }}
            </v-chip>
          </form-row-with-icon>
          <!--          <form-row-with-icon icon="mdi-card-account-details-outline" text="Przypnij do lokalizacji">-->
          <!--            <chip-switch v-model="attachToCustomer" />-->
          <!--          </form-row-with-icon>-->
          <v-row>
            <v-col>
              <v-file-input
                v-model="file"
                :rules="[fileInputRules.checkTotalFileSize(filesSize)]"
                accept="application/pdf"
                chips
                clearable
                label="Wybierz plik protokołu (.pdf)"
                show-size
                small-chips
                truncate-length="33"></v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-progress-linear v-if="uploadProgress == -1" color="red" height="25">
        <strong>ZERWANO POŁĄCZENIE</strong>
      </v-progress-linear>
      <v-progress-linear v-else-if="uploadProgress !== null" v-model="uploadProgress" height="25">
        <strong>{{ uploadProgress + "%" }}</strong>
      </v-progress-linear>

      <v-card-actions v-if="uploadProgress == -1">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="refreshAll"> Odśwież </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelAddingProtocol"> Anuluj </v-btn>
        <v-btn :disabled="validateProtocol() || loadingUpload" color="blue darken-1" text @click="addProtocol()">
          Dodaj protokół
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--  </v-row>-->
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Integer from "vuelidate/lib/validators/integer";
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import { formatDate } from "../../helper";
import ChipSwitch from "../admin/ChipSwitch.vue";
import types from "../../types.json";

export default {
  components: { FormRowWithIcon },
  props: {
    jobId: { type: Integer, default: "" },
  },
  data: () => ({
    types,
    dialog: false,
    filesSize: 100000000,
    loadingUpload: false,
    menuProtocolDate: false,
    attachToCustomer: false,
    protocolName: "Protokół z dnia",
    applianceGroupType: "other",
    protocolDate: formatDate(new Date()),
    fileInputRules: {
      checkTotalFileSize(maxSize) {
        return (v) => !v || v.size < maxSize || "PDF poniżej 100MB";
      },
    },
    file: [],
  }),
  computed: {
    ...mapGetters({
      uploadProgress: "jobs/getUploadProgress",
      userRole: "user/getUserRole",
    }),
  },
  methods: {
    ...mapMutations({ resetProgress: "jobs/setUploadProgress" }),
    refreshAll() {
      location.reload();
    },
    validateProtocol() {
      if (!!!this.protocolName) {
        return true;
      }
      if (!!!this.file) {
        return true;
      }
      if (this.file[0] > this.filesSize) {
        return true;
      }
      if (this.file.length === 0) {
        return true;
      }
    },
    async addProtocol() {
      // console.log("Dodaj protokol");
      this.loadingUpload = true;
      try {
        if (this.file !== null) {
          await this.$store.dispatch("jobs/addProtocol", {
            jobId: this.jobId,
            file: this.file,
            protocolDetails: {
              protocolName: this.protocolName,
              applianceGroupType: this.applianceGroupType,
              protocolDate: this.protocolDate,
              attachToCustomer: this.attachToCustomer,
            },
          });
          // console.log("dodany");
          this.file = [];
        }
        this.$emit("refresh");
        // console.log('emit refresh');
        this.resetProgress(null);
        this.dialog = false;
        this.loadingUpload = false;
      } catch (error) {
        if (!window.navigator.onLine) {
          this.resetProgress(-1);
        }
      }
      // console.log("Dodaj protokol - koniec");
      this.resetValues();
    },
    cancelAddingProtocol() {
      this.resetValues();
      this.dialog = false;
    },
    resetValues() {
      this.dialog = false;
      this.protocolName = "Protokół z dnia";
      this.loadingUpload = false;
      this.attachToCustomer = false;
      this.applianceGroupType = "other";
      this.protocolDate = formatDate(new Date());
      this.file = [];
      // console.log("reset");
    },
  },
  watch: {
    applianceGroupType(v) {
      console.log(v);
    },
  },
};
</script>
