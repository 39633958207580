import axios from 'axios';
import Vue from 'vue';

export default {
  async fetchArticleSuppliersDetails({ commit }, payload) {
    commit('setArticleSuppliersDetailsLoading', true);
    const response = await axios.get(`warehouse/supplier/all/article/stats`);
    console.debug(response.data, 'response.data');
    commit('setArticleSuppliersDetails', response.data);
    commit('setArticleSuppliersDetailsLoading', false);
  },
  async postEditedSupplier({ commit, getters }, { supplierData }) {
    console.debug(supplierData, 'payload');
    commit('setSubmittingSupplierDetails', true);
    // await new Promise((resolve) => setTimeout(resolve, 2000)); //TODO: TEST
    try {
      const response = await axios.post(`warehouse/supplier/${supplierData.id}/edit`, { ...supplierData });
      console.debug(response.data, 'response.data');
      const supplierIdx = getters.articleSuppliersDetails.findIndex((value) => value.id === supplierData.id);
      if (supplierIdx === -1) {
        console.error('could not find supplier after submitting edition');
      }
      Vue.set(getters.articleSuppliersDetails, supplierIdx, supplierData);
    } catch (e) {
      throw e;
    } finally {
      commit('setSubmittingSupplierDetails', false);
    }
  },
  async postDeleteSupplier({ commit, getters }, { supplierData }) {
    console.debug(supplierData, 'payload');
    commit('setSubmittingSupplierDetails', true);
    // await new Promise((resolve) => setTimeout(resolve, 2000)); //TODO: TEST
    try {
      const response = await axios.delete(`warehouse/supplier/${supplierData.id}/delete`);
      console.debug(response.data, 'response.data');
      const suppliers = getters.articleSuppliersDetails.filter((value) => value.id !== supplierData.id);
      commit('setArticleSuppliersDetails', suppliers);
    } catch (e) {
      throw e;
    } finally {
      commit('setSubmittingSupplierDetails', false);
    }
  },
};
