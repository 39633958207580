<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <template #activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        <v-icon left> mdi-plus</v-icon>
        artykuł
      </v-btn>
    </template>
    <v-card class="d-flex flex-column justify-space-between">
      <v-card-title>
        <span class="text-h5">Tworzenie artykułu</span>
      </v-card-title>
      <v-card-text>
        <v-card v-if="dialog" elevation="0">
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="newArticle.name"
                autofocus
                :error-messages="formErrors.name"
                :loading="articleNameValidationSubmitting"
                :rules="[rules.isNameEmpty()]"
                label="Nazwa artykułu"
                @blur="cleanName"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="newArticle.originalProductNumber"
                label="Kod producenta"
                @blur="cleanOriginalProductNumber"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols>
              <v-text-field
                v-model="newArticle.quantityPerPackage"
                :rules="[rules.isQuantityPerPackageNotPositive()]"
                label="Ilość w opakowaniu"
                type="number"
                @blur="setDefaultQuantityPerPackage"
                @focus="removeDefaultQuantityPerPackage">
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="newArticle.unit"
                :items="availableUnits"
                item-text="name"
                item-value="key"
                label="Jednostka"
                single-line></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols>
              <v-text-field
                v-model="newArticle.price"
                :rules="[rules.isPriceNegative()]"
                label="Cena zakupu"
                type="number"
                @blur="setDefaultPrice"
                @focus="removeDefaultPrice">
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="newArticle.currency"
                :items="['PLN']"
                label="Waluta"
                return-object
                single-line></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols>
              <v-text-field
                v-model="newArticle.markup"
                :rules="[rules.isMarkupNegative()]"
                label="Narzut"
                suffix="%"
                type="number"
                @blur="setDefaultMarkup"
                @focus="removeDefaultMarkup">
              </v-text-field>
            </v-col>
            <v-col cols>
              <v-text-field
                class="pl-2"
                v-model="finalPrice"
                readonly
                label="Cena finalna"
                messages="CZ * (1 + Narzut)"
                suffix="PLN"
                type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols>
              <v-text-field
                v-model="newArticle.VATRate"
                :rules="[rules.isVATRateValid()]"
                label="Stawka VAT"
                suffix="%"
                type="number"
                @blur="setDefaultVARRate"
                @focus="removeDefaultVARRate">
              </v-text-field>
            </v-col>
          </v-row>
          <custom-combobox-single
            v-model="supplierHelper"
            :disabled="articleSuppliersLoading"
            :item-text="'name'"
            :items="articleSuppliers"
            :loading="articleSuppliersLoading"
            label="Dostawca"
            @trueValue="(n) => setSupplierObjOrName(n)" />
          <custom-combobox-single
            v-model="groupHelper"
            :disabled="articleGroupsLoading"
            :item-text="'name'"
            :rules="[rules.isGroupEmpty()]"
            :items="articleGroups"
            :loading="articleGroupsLoading"
            label="Grupa"
            @trueValue="(n) => setGroupObjOrName(n)" />
        </v-card>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-btn :tabindex="1" text @click="dialog = false">
          <v-icon left> mdi-cancel</v-icon>
          Anuluj
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="isButtonDisabled" :loading="articleSubmitting" color="primary" @click="createNewArticle">
          <v-icon left> mdi-home-plus-outline</v-icon>
          Utwórz artykuł
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import warehouseTypes from "../../../warehouseTypes.json";
import { mapActions, mapGetters } from "vuex";
import { cleanString } from "../../../helper";
import CustomComboboxSingle from "../../layout/CustomComboboxSingle.vue";

export default {
  name: "WarehouseCreateArticleDialog",
  components: { CustomComboboxSingle },
  computed: {
    finalPrice() {
      return Number(this.newArticle?.price ?? 0) * (1 + Number(this.newArticle?.markup ?? 0) * 0.01)
    },
    availableUnits() {
      return Object.entries(this.warehouseTypes.articleUnit).map(([key, value]) => {
        return {
          key: key,
          ...value, // This spreads the properties of the current value object
        };
      });
    },
    ...mapGetters("warehouse/facilities", [
      "facilities",
    ]),
    ...mapGetters("warehouse/articleDetails", [
      "articleSubmitting",
      "articleNameValidationSubmitting",
    ]),
    ...mapGetters("warehouse/suppliers", [
      "articleSuppliers",
      "articleSuppliersLoading",
    ]),
    ...mapGetters("warehouse/groups", [
      "articleGroups",
      "articleGroupsLoading"
    ]),
    isButtonDisabled() {
      if (this.articleNameValidationSubmitting) {
        return true;
      }
      const mergedArray = Object.values(this.formErrors).reduce((acc, currentArray) => {
        return acc.concat(currentArray);
      }, []);
      const nameErrors = [this.rules.isNameEmpty()(this.newArticle.name)].filter((error) => typeof error === "string");
      const groupErrors = [this.rules.isGroupEmpty()(this.newArticle.group)].filter((error) => typeof error === "string");
      return nameErrors.length > 0 || mergedArray.length > 0 || groupErrors.length > 0;
    },
  },
  data: () => ({
    dialog: false,
    newArticle: {
      name: null,
      originalProductNumber: null,
      price: 0,
      currency: "PLN",
      quantityPerPackage: 1.0,
      unit: "piece",
      markup: 0,
      VATRate: 23,
      supplier: null,
      group: null,
      note: null,
    },
    supplierHelper: null,
    groupHelper: null,
    formErrors: {
      name: [],
    },
    rules: {
      isNameEmpty() {
        return (v) => !!cleanString(v) || "Nazwa nie może być pusta";
      },
      isPriceNegative() {
        return (v) => !v || (v >= 0 && v && v.toString().match(/^\d+(\.\d{1,2})?$/) !== null) || "Niepoprana cena";
      },
      isQuantityPerPackageNotPositive() {
        return (v) => (v > 0 && v && v.toString().match(/^\d+(\.\d{1,2})?$/) !== null) || "Niepoprana ilość";
      },
      isMarkupNegative() {
        return (v) => v >= 0 || "Niepoprana wartość";
      },
      isVATRateValid() {
        return (v) => v >= 0 && v<=100 || "Niepoprana wartość";
      },
      isGroupEmpty() {
        return (v) => !!v || "Grupa nie może być pusta";
      }
    },
    warehouseTypes,
  }),
  watch: {
    "newArticle.name"(val) {
      if (val)
        this.postArticleNameValidation({ name: val }).then((valid) => {
          this.$set(this.formErrors, "name", valid ? [] : ["Nazwa artykułu jest już zajęta"]);
        });
      this.$set(this.formErrors, "name", []);
    },
  },
  methods: {
    ...mapActions("warehouse/articleDetails", ["postNewArticle", "postArticleNameValidation"]),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    cleanName() {
      this.$set(this.newArticle, "name", cleanString(this.newArticle.name));
    },
    cleanOriginalProductNumber() {
      this.$set(this.newArticle, "originalProductNumber", cleanString(this.newArticle.originalProductNumber));
    },
    setDefaultPrice() {
      if (!!!this.newArticle.price) {
        this.newArticle.price = 0;
      }
    },
    removeDefaultPrice() {
      if (this.newArticle.price === 0) {
        this.$set(this.newArticle, "price", null);
      }
    },
    setDefaultMarkup() {
      if (!!!this.newArticle.markup) {
        this.newArticle.markup = 0;
      }
    },
    removeDefaultMarkup() {
      if (this.newArticle.markup === 0) {
        this.$set(this.newArticle, "markup", null);
      }
    },
    setDefaultQuantityPerPackage() {
      if (!!!this.newArticle.quantityPerPackage) {
        this.newArticle.quantityPerPackage = 1;
      }
    },
    removeDefaultQuantityPerPackage() {
      if (this.newArticle.quantityPerPackage === 1) {
        this.$set(this.newArticle, "quantityPerPackage", null);
      }
    },
    setDefaultVARRate() {
      if (!!!this.newArticle.VARRate) {
        this.newArticle.VARRate = 23;
      }
    },
    removeDefaultVARRate() {
      if (this.newArticle.VARRate === 23) {
        this.$set(this.newArticle, "VARRate", null);
      }
    },
    async setSupplierObjOrName(v) {
      this.newArticle.supplier = v;
    },
    async setGroupObjOrName(v) {
      this.newArticle.group = v;
    },
    async createNewArticle() {
      console.log(this.newArticle);
      try {
        await this.postNewArticle({ articleData: this.newArticle });
        await this.notifyInfo("Utworzono nowy artykuł");
        this.dialog = false;
        this.newArticle = {
          name: null,
          price: "0",
          quantityPerPackage: 1.0,
          unit: "piece",
          markup: 0,
          originalProductNumber: null,
          VATRate: 23,
          currency: "PLN",
          supplier: null,
          group: {
            id: null,
          },
          note: null,
        };
      } catch (e) {
        await this.notifyError(e);
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
>>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot,
>>> .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0;
}
</style>