import SnackbarMessageClass from '../../class/SnackbarMessageClass';

export default {
  notifyOff(context) {
    context.commit('setMessage', SnackbarMessageClass.create({}));
  },
  notifyChatInfo(context, { user, task, updateType }) {
    context.commit('setMessage', SnackbarMessageClass.chatTask({ user, task, updateType }));
  },
  notifyInfo(context, payload) {
    context.commit('setMessage', SnackbarMessageClass.info(payload));
  },
  notifyError(context, payload) {
    context.commit('setMessage', SnackbarMessageClass.error(payload));
  },
};
