export default {
  articleStocks: (state) => {
    return state.articleStocks;
  },
  articleStocksLoading: (state) => {
    return state.articleStocksLoading;
  },
  getArticleStock:
    (state) =>
    ({ articleId }) => {
      const articleStockIdx = state.articleStocks.findIndex((value) => value.id === articleId);
      if (articleStockIdx === -1) {
        return null;
      }
      return state.articleStocks[articleStockIdx];
    },
  articleStockSubmitting: (state) => {
    return state.submittingArticleStocks
  }
};
