import Vue from 'vue';

export default {
  setArticleStock(state, { stockIdx, stock }) {
    Vue.set(state.articleStocks, stockIdx, stock);
  },
  setArticleStocksLoading(state, isLoading) {
    state.articleStocksLoading = isLoading;
  },
  setArticleStocks(state, payload) {
    state.articleStocks = payload;
  },
  setArticleStockSubmitting(state, payload) {
    state.submittingArticleStocks = payload;
  },
};
