import axios from 'axios';
import { DIALOG } from '../../../constants';

export default {
  async fetchCustomers({ commit }, _) {
    commit('setCustomersLoading', { isLoading: true });
    const response = await axios.get(`customer/fetchCustomers`, { params: {} });
    // console.log(response.data)
    commit('setCustomers', response.data.customers);
    commit('setCustomersLoading', { isLoading: false });
  },
  async fetchCustomerLocationsJobs(context, payload) {
    const response = await axios.get(`customer/all`, { params: {} });
    console.log(response.data);
    context.commit('setCustomerLocationsJobs', response.data);
  },
  async editCustomer(context, payload) {
    try {
      switch (payload.action) {
        case DIALOG.EDIT_CUSTOMER:
          await axios.post('customer/editCustomerName', payload.customerData);
          break;
        case DIALOG.DELETE_CUSTOMER:
          await axios.post('customer/deleteCustomer', payload.customerData);
          break;
        case DIALOG.EDIT_LOCATION:
          await axios.post('customer/editLocationName', payload.customerData);
          break;
        case DIALOG.DELETE_LOCATION:
          await axios.post('customer/deleteLocation', payload.customerData);
          break;
      }
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};
