<template>
  <td :colspan="headers.length">
    <v-data-table
      :headers="customerLocationHeaders"
      :items="item.customerLocations"
      hide-default-footer
      item-key="id"
      show-expand
      single-expand
      :items-per-page="-1"
    >
      <template #item.name="{ item }">
        {{ item.name ?? "Brak przypisanego adresu" }}
      </template>
      <template #item.jobs="{ item }">
        {{ item.jobs.length }}
      </template>
      <template #item.actions="{ item }">
        <v-icon small class="mr-2" @click="$emit('showDialog', DIALOG.EDIT_LOCATION, item)"> mdi-pencil </v-icon>
        <v-icon v-if="couldDelete(item)" small @click="$emit('showDialog', DIALOG.DELETE_LOCATION, item)">
          mdi-delete
        </v-icon>
      </template>
      <template #expanded-item="{ headers, item }" style="width: 100%">
        <customer-locations-jobs-table :headers="headers" :item="item" />
      </template>
    </v-data-table>
  </td>
</template>
<script>
import CustomerLocationsJobsTable from "./CustomerLocationsJobsTable.vue";
import { DIALOG } from "../../constants";

export default {
  name: "CustomerLocationsTable",
  components: { CustomerLocationsJobsTable },
  props: {
    headers: {},
    item: {},
  },
  emits: ["showDialog"],
  data: () => ({
    customerLocationHeaders: [
      {
        text: "Lokalizacja",
        value: "name",
        sortable: false,
      },
      {
        text: "Zlecenia",
        value: "jobs",
        sortable: false,
      },
      {
        text: "",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  computed: {
    DIALOG() {
      return DIALOG;
    },
  },
  methods: {
    couldDelete(item) {
      return item.jobs.length === 0;
    },
  },
};
</script>
