<template>
  <v-row no-gutters>
    <v-col cols="12" ref="formRef">
      <border-active-v-sheet>
        <warehouse-form-row-with-icon
          appendIcon="mdi-menu-down"
          class="pl-3"
          icon="mdi-toolbox-outline"
          text="Grupy artykułów"
          @appendIconClick="expand">
          <v-row no-gutters>
            <v-col :class="{ 'align-start': !showHiddenItems }" cols="12">
              <template v-if="!selectedTypes.length || selectedTypes.length === allTypes.length"
                ><span>wszystkie</span></template
              >
              <jobs-list-filter-column-single-select-chip
                v-else
                :arr="selectedTypes"
                :cols="'auto'"
                :compared-array-key="null"
                :compared-value="'selected'"
                :display-key="'name'"
                :md="'auto'"
                class="d-flex flex-row flex-wrap"
                is-horizontal="true"
                prop-class="mr-1"
                small
                @selectElement="(n) => handleSelectedArticleGroup(n)" />
            </v-col>
          </v-row>
        </warehouse-form-row-with-icon>
      </border-active-v-sheet>
      <v-menu
        v-model="showHiddenItems"
        :position-x="menuX"
        :position-y="menuY"
        absolute
        :close-on-content-click="false">
        <border-active-v-sheet :is-active="!!filters.length" class="pa-1">
          <v-row class="pb-1 d-flex flex-row justify-space-between" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <single-multi-array-selector
                v-model="isSingleSelect"
                :all-members="articleGroups"
                :target-array="filters"
                @updateArray="(arr) => handleSelectedArticleGroup(arr)"></single-multi-array-selector>
            </v-col>
          </v-row>
          <jobs-list-filter-column-single-select-chip
            :arr="allTypes"
            :cols="'auto'"
            :compared-array-key="null"
            :compared-value="'selected'"
            :display-key="'name'"
            :md="'auto'"
            class="d-flex flex-column flex-wrap"
            style="max-height: 400px"
            is-horizontal="true"
            prop-class="mr-1"
            small
            @selectElement="(n) => handleSelectedArticleGroup(n)" />
        </border-active-v-sheet>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
import SingleMultiArraySelector from "../../calendar/filters/SingleMultiArraySelector.vue";
import BorderActiveVSheet from "../../calendar/filters/BorderActiveVSheet.vue";
import JobsListFilterColumnSingleSelectChip from "../../job/JobsListFilterColumnSingleSelectChip.vue";
import { mapGetters } from "vuex";
import FormRowWithIcon from "../../ui/FormRowWithIcon.vue";
import WarehouseFormRowWithIcon from "../WarehouseFormRowWithIcon.vue";

export default {
  name: "WarehouseArticleGroupFilter",
  components: {
    WarehouseFormRowWithIcon,
    FormRowWithIcon,
    SingleMultiArraySelector,
    BorderActiveVSheet,
    JobsListFilterColumnSingleSelectChip,
  },
  data: () => ({
    tab: null,
    isSingleSelect: false,
    showHiddenItems: false,
    menuX: 0,
    menuY: 0,
  }),
  computed: {
    ...mapGetters("warehouse/groups", ["articleGroups"]),
    selectedTypes() {
      const selected = this.articleGroups
        .map((group) => ({
          ...group,
          selected: this.isArticleGroupSelected(group),
        }))
        .sort((a, b) => a.name - b.name); /*.sort((a, b) => b.selected - a.selected)*/
      return selected.filter((x) => x.selected);
      // return selected;
    },
    allTypes() {
      const selected = this.articleGroups
        .map((group) => ({
          ...group,
          selected: this.isArticleGroupSelected(group),
        }))
        .sort((a, b) => a.name - b.name); /*.sort((a, b) => b.selected - a.selected)*/
      return selected;
    },
    filters: {
      get() {
        return this.$store.getters["warehouse/groups/articleGroupFilter"];
      },
      set(v) {
        this.$store.dispatch("warehouse/groups/setArticleGroupFilter", v);
      },
    },
  },
  methods: {
    expand() {
      console.log(this.showHiddenItems);
      const pos = this.$refs.formRef.getBoundingClientRect();
      this.menuX = pos.left;
      this.menuY = pos.bottom;
      this.showHiddenItems = !this.showHiddenItems;
    },
    isArticleGroupSelected(item) {
      return this.filters.find((v) => v.id === item.id) !== undefined;
    },
    handleSelectedArticleGroup(item) {
      if (Array.isArray(item)) {
        return (this.filters = item);
      }
      if (this.isSingleSelect) {
        return (this.filters = [item]);
      }

      const arr = [...this.filters];
      const i = this.filters.findIndex((v) => v.id === item.id);

      if (i !== -1) {
        arr.splice(i, 1);
      } else {
        arr.push(item);
      }

      this.filters = arr;
    },
  },
};
</script>