import axios from 'axios';

export default {
  setSearchTerm({ commit }, term) {
    commit('setSearchTerm', term);
  },

  async fetchArticles({ commit, getters, rootGetters }, _) {
    commit('setArticlesLoading', true);
    let options = {
      ...getters.tableOptions,
    };
    const groups = rootGetters['warehouse/groups/articleGroupFilter'];
    if (groups && groups.length > 0) {
      options = {
        ...options,
        groups: rootGetters['warehouse/groups/articleGroupFilter']?.map((g) => g.id).join(','),
      };
    }
    const facilities = rootGetters['warehouse/facilities/articleFacilitiesFilter'];
    if (facilities && facilities.length > 0) {
      options = {
        ...options,
        facilities: rootGetters['warehouse/facilities/articleFacilitiesFilter']?.map((g) => g.id).join(','),
      };
    }


    const searchTerm = getters.searchTerm;
    if (searchTerm) {
      options = { ...options, searchTerm };
    }
    console.debug(options, 'request.data');
    const response = await axios.get(`warehouse/article/all`, { params: options });
    console.debug(response.data, 'response.data');
    commit('setArticles', response.data.articles);
    commit('setTotalArticles', response.data.total);
    commit('setArticlesLoading', false);
  },

  addArticle({ commit, getters }, { article }) {
    commit('setArticles', [article, ...getters.articles]);
  },

  setTableOptions({ commit }, { options }) {
    commit('setTableOptions', options);
  },
};
