<template>
  <div>
    <v-row v-if="text && toggleable" no-gutters>
      <v-col class="ma-0 pl-5">
        <v-chip
          :color="toggleableValue ? 'grey' : 'transparent'"
          :outlined="toggleableValue"
          :style="{ opacity: toggleableValue ? '1' : '0.5' }"
          :tabindex="0"
          :disabled="disabled"
          class="me-2"
          label
          small
          style="margin-right: 5px; margin-bottom: 5px"
          text-color="black"
          @click="$emit('toggled')">
          {{ text }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row v-else-if="text" no-gutters>
      <v-col class="ma-0" :class="{ 'pl-6': icon }">
        <p class="ma-0 pa-0" style="font-size: 12px">{{ text }}</p>
      </v-col>
    </v-row>
    <v-row class="flex-nowrap" no-gutters>
      <v-col v-if="icon" cols="auto" style="max-width: 30px; padding-right: 10px">
        <v-icon :color="color" size="20">
          {{ icon }}
        </v-icon>
      </v-col>
      <v-col class="d-flex flex-row">
        <slot></slot>
      </v-col>
      <v-col v-if="appendIcon" cols="auto" style="max-width: 30px; padding-right: 10px">
        <v-icon color="grey" size="20" @click="$emit('appendIconClick')">
          {{ appendIcon }}
        </v-icon>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "WarehouseFormRowWithIcon",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    color: { default: "rgba(0,0,0,0.54)" },
    icon: {},
    onButton: {},
    text: {},
    toggleable: { default: false, type: Boolean },
    appendIcon: {
      default: null
    },
    toggleableValue: {},
  },
  emits: ["toggled", "appendIconClick"],
};
</script>