<template>
  <v-row no-gutters class="d-flex flex-row justify-space-between align-center">
    <v-col cols="auto">
      <v-icon
        small
        color="grey">
        {{ isModified ? 'mdi-database-edit' : 'mdi-database-plus' }}
      </v-icon>
    </v-col>
    <v-col cols="auto">
      <v-chip
        color="transparent"
        label
        small
        disabled
        class="mx-1"
        style="opacity: 1"
        text-color="black">
        <v-icon
          small
          :color="user !== null ? user.color : 'grey'"
          left>
          {{ user !== null ? 'mdi-circle' : "mdi-robot" }}
        </v-icon>
        {{ user !== null ? user.name : "System" }}
      </v-chip>
    </v-col>
    <v-col cols="auto" style="font-size: 0.8em; color: grey">
      {{ formatDateTime(date) }}
    </v-col>
  </v-row>
</template>
<script>
import { formatDateTime } from "../../helper";

export default {
  name: "CreatedModifiedRow",
  props: {
    user: {},
    date: {},
    isModified: {default: true}
  },
  methods: {
    formatDateTime
  }
};
</script>