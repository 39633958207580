<template>
  <div>
    <h3>to chyba jakaś pomyłka</h3>
    <p
      style="color: blue"
      @click="$router.push({ name: $store.getters['user/isServiceTechnician'] ? 'mobileDashboard' : 'home' })"
    >
      zacznijmy od nowa (click)
    </p>
  </div>
</template>

<script>
export default {
  name: "NoPageFound",
};
</script>

<style scoped></style>
