import colors from 'vuetify/lib/util/colors';

export default {
  primary: '#2daae2',
  secondary: '#204879',
  // All keys will generate theme styles,
  // Here we add a custom `tertiary` color
  tertiary: '#57b7b5',
  anchor: '#8eb1bc',
  navbar: '#2daae2',
};
