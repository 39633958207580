<template>
  <v-card class="elevation-12">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Zmień hasło</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form v-if="!!!message">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="oldPassword"
                :error-messages="oldPasswordErrors"
                :append-icon="showOld ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showOld ? 'text' : 'password'"
                label="Obecne hasło"
                required
                @click:append="showOld = !showOld"
                @input="$v.oldPassword.$touch()"
                @blur="$v.oldPassword.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="newPassword"
                :error-messages="newPasswordErrors"
                :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNew ? 'text' : 'password'"
                label="Nowe hasło"
                required
                @click:append="showNew = !showNew"
                @input="$v.newPassword.$touch()"
                @blur="$v.newPassword.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="confirmNewPassword"
                :error-messages="confirmNewPasswordErrors"
                :append-icon="showNew1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNew1 ? 'text' : 'password'"
                label="Powtórz nowe hasło"
                required
                @click:append="showNew1 = !showNew1"
                @input="$v.confirmNewPassword.$touch()"
                @blur="$v.confirmNewPassword.$touch()"
              ></v-text-field>
            </v-col>
            <v-col align-self="center" cols="12" md="2">
              <v-btn :disabled="buttonDisabled" color="secondary" block @click="handleSubmit"> Zmień </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-sheet
        v-if="message"
        color="success"
        elevation="4"
        rounded
        style="padding: 20px; margin-right: 30px; margin-left: 30px; margin-bottom: 10px"
        >{{ message }}
      </v-sheet>
      <v-sheet
        v-if="error"
        color="error"
        elevation="4"
        rounded
        style="padding: 20px; margin-right: 30px; margin-left: 30px; margin-bottom: 10px"
        >{{ error }}
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "ChangePassword",
  mixins: [validationMixin],
  validations: {
    oldPassword: { required },
    newPassword: {
      required,
      minLength: minLength(8),
      containsUppercase: function (value) {
        return /[A-Z]/.test(value);
      },
      containsLowercase: function (value) {
        return /[a-z]/.test(value);
      },
      containsNumber: function (value) {
        return /[0-9]/.test(value);
      },
      containsSpecial: function (value) {
        return /[#?!@$%^&*-]/.test(value);
      },
    },
    confirmNewPassword: {
      required,
      sameAs: sameAs("newPassword"),
    },
  },
  data: () => ({
    showOld: false,
    showNew: false,
    showNew1: false,
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    error: "",
    incorrectOldPass: false,
    message: "",
  }),
  computed: {
    ...mapGetters({ userName: "user/userInfo" }),
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.oldPassword.$dirty) return errors;
      if (this.incorrectOldPass) {
        errors.push("Obecne hasło niepoprawne");
      }
      !this.$v.oldPassword.required && errors.push("Wprowadź obecne hasło");
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.newPassword.$dirty) return errors;
      !this.$v.newPassword.required && errors.push("Hasło wymagane");
      const passwordRules = ["Wymagania: "];
      !this.$v.newPassword.containsLowercase && passwordRules.push("mała litera,");
      !this.$v.newPassword.containsNumber && passwordRules.push("cyfrę,");
      !this.$v.newPassword.containsUppercase && passwordRules.push("wielka litera,");
      !this.$v.newPassword.containsSpecial && passwordRules.push("znak specjalny,");
      !this.$v.newPassword.minLength && passwordRules.push("co najmniej 8 znaków");
      passwordRules.length > 1 && errors.push(passwordRules.join(" "));
      return errors;
    },
    confirmNewPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmNewPassword.$dirty) return errors;
      !this.$v.confirmNewPassword.required && errors.push("Powtórz hasło!");
      !this.$v.confirmNewPassword.sameAs && errors.push("Hasła nie są identyczne!");
      return errors;
    },
    buttonDisabled() {
      const nErrors =
        this.oldPasswordErrors.length + this.newPasswordErrors.length + this.confirmNewPasswordErrors.length;
      return !!nErrors || !!!this.oldPassword.length;
    },
  },
  methods: {
    async handleSubmit() {
      this.isLogging = true;
      const request = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      };
      try {
        const response = await axios.post("user/changePassword", JSON.stringify(request), {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.message = "Ustawiono nowe hasło";
      } catch (e) {
        if (e.response.data.message === "badPassword") {
          this.incorrectOldPass = true;
        } else {
          this.error = e;
        }
      }
      console.log(request);
      this.isLogging = false;
    },
  },
};
</script>
