import { EventSourcePolyfill } from 'event-source-polyfill';

export default {
  openStream(context, { token, userId }) {
    const topic = `http://radmax.eu/update/chat/${userId}`;
    const base_url = '/.well-known/mercure?topic=';
    const eventSource = new EventSourcePolyfill(base_url + topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      heartbeatTimeout: 30000,
    });
    context.commit('setStream', eventSource);
  },
};
