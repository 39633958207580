<template>
  <v-row no-gutters>
    <v-col>
      <calendar-technician-filter
        :selected-ids="selectedIds"
        :technicians="serviceTechnicians"
        @selectUser="(id) => selectUser(id)">
      </calendar-technician-filter>
    </v-col>
  </v-row>
</template>
<script>
import CalendarTechnicianFilter from "./CalendarTechnicianFilter.vue";
import { mapGetters } from "vuex";

export default {
  name: "CalendarToolbarDesktop",
  components: { CalendarTechnicianFilter },
  props: {
    cancel: {},
    isMobile: {},
    selectedIds: {},
    serviceTechnicians: {},
  },
  emits: ["refresh", "selectUser"],
  computed: {
    ...mapGetters({
      draggingTask: "calendarTasks/getDraggingTask",
    }),
  },
  methods: {
    reload() {
      this.$emit("refresh");
    },
    async selectUser(id) {
      this.$emit("selectUser", id);
    },
  },
};
</script>
