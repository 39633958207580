var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v("Stan magazynowy")]),_c('v-card-text',[(!_vm.getArticleStock({ articleId: _vm.article.id }))?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-col',[_c('v-progress-circular')],1),_c('v-spacer')],1):_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Magazyn")]),_c('th',[_vm._v("Ilość")]),_c('th',{staticClass:"text-left"})])]),_c('tbody',[_vm._l((_vm.facilities),function(facility){return _c('tr',{key:facility.id},[_c('td',[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.warehouseTypes.facilityType[facility.type].icon))]),_vm._v(" "+_vm._s(facility.name)+" ")],1),_c('td',{ref:`article_${_vm.article.id}_warehouseFacility-${facility.id}`,refInFor:true},[_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","left":"","offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({style:({ cursor: 'grab' }),attrs:{"color":"transparent","draggable":"","label":""},on:{"click":function($event){return _vm.openEditMenu($event, `article_${_vm.article.id}_warehouseFacility-${facility.id}`, _vm.article)},"dragstart":function($event){return _vm.startDrag($event, {
                        article: _vm.article,
                        facilityId: facility.id,
                      })},"drop":function($event){return _vm.endDrag($event, {
                        article: _vm.article,
                        facilityId: facility.id,
                      })},"dragover":function($event){$event.preventDefault();}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getArticleStock({ articleId: _vm.article.id }).warehouseArticleFacilities.find( (value) => value.warehouseFacility.id === facility.id )?.quantity ?? 0)+" ")])]}}],null,true)},[(_vm.editingProperty && _vm.editingProperty !== 'transfer')?_c('v-card',[_c('v-card-text',{staticClass:"py-0"},[(_vm.editingProperty.includes('warehouseFacility'))?[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((Object.values(_vm.WAREHOUSE_STOCK_OPERATION).filter(
                            (x) => ![_vm.WAREHOUSE_STOCK_OPERATION.TRANSFER].includes(x)
                          )),function(type){return _c('warehouse-stock-operation-chip',{key:type,attrs:{"operation-type":type,"stock-operation":_vm.stockOperation},on:{"update:stockOperation":function($event){_vm.stockOperation = $event}}})}),1),(_vm.stockOperation === _vm.WAREHOUSE_STOCK_OPERATION.CORRECTION)?_c('v-text-field',{attrs:{"label":`Inwentaryzacja -  ${
                          _vm.facilities[
                            _vm.facilities.findIndex(
                              (wAF) => wAF.id === parseInt(_vm.editingProperty.split('warehouseFacility-').at(-1))
                            )
                          ]?.name
                        }`,"rules":[_vm.rules.isQuantityNegative()]},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.articleStockSubmitting,"disabled":!!_vm.rules.isQuantityNegative()(
                                _vm.editedArticle.warehouseArticleFacilities[
                                  _vm.editedArticle.warehouseArticleFacilities.findIndex(
                                    (wAF) =>
                                      wAF.warehouseFacility.id ===
                                      parseInt(_vm.editingProperty.split('warehouseFacility-').at(-1))
                                  )
                                ].quantity
                              ).length,"icon":""},on:{"click":function($event){return _vm.submitArticleStockUpdate()}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)]},proxy:true}],null,true),model:{value:(
                          _vm.editedArticle.warehouseArticleFacilities[
                            _vm.editedArticle.warehouseArticleFacilities.findIndex(
                              (wAF) =>
                                wAF.warehouseFacility.id ===
                                parseInt(_vm.editingProperty.split('warehouseFacility-').at(-1))
                            )
                          ].quantity
                        ),callback:function ($$v) {_vm.$set(_vm.editedArticle.warehouseArticleFacilities[
                            _vm.editedArticle.warehouseArticleFacilities.findIndex(
                              (wAF) =>
                                wAF.warehouseFacility.id ===
                                parseInt(_vm.editingProperty.split('warehouseFacility-').at(-1))
                            )
                          ], "quantity", $$v)},expression:"\n                          editedArticle.warehouseArticleFacilities[\n                            editedArticle.warehouseArticleFacilities.findIndex(\n                              (wAF) =>\n                                wAF.warehouseFacility.id ===\n                                parseInt(editingProperty.split('warehouseFacility-').at(-1))\n                            )\n                          ].quantity\n                        "}}):(_vm.stockOperation === _vm.WAREHOUSE_STOCK_OPERATION.CONSUMPTION)?_c('v-text-field',{attrs:{"label":`Rozchód - ${
                          _vm.facilities[
                            _vm.facilities.findIndex(
                              (wAF) => wAF.id === parseInt(_vm.editingProperty.split('warehouseFacility-').at(-1))
                            )
                          ]?.name
                        }`,"rules":[_vm.rules.isQuantityNonPositive()],"prefix":"-"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.articleStockSubmitting,"disabled":!!_vm.rules.isQuantityNonPositive()(
                                _vm.editedArticle.warehouseArticleFacilities[
                                  _vm.editedArticle.warehouseArticleFacilities.findIndex(
                                    (wAF) =>
                                      wAF.warehouseFacility.id ===
                                      parseInt(_vm.editingProperty.split('warehouseFacility-').at(-1))
                                  )
                                ].quantity
                              ).length,"icon":""},on:{"click":function($event){return _vm.submitArticleStockUpdate()}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)]},proxy:true}],null,true),model:{value:(
                          _vm.editedArticle.warehouseArticleFacilities[
                            _vm.editedArticle.warehouseArticleFacilities.findIndex(
                              (wAF) =>
                                wAF.warehouseFacility.id ===
                                parseInt(_vm.editingProperty.split('warehouseFacility-').at(-1))
                            )
                          ].quantity
                        ),callback:function ($$v) {_vm.$set(_vm.editedArticle.warehouseArticleFacilities[
                            _vm.editedArticle.warehouseArticleFacilities.findIndex(
                              (wAF) =>
                                wAF.warehouseFacility.id ===
                                parseInt(_vm.editingProperty.split('warehouseFacility-').at(-1))
                            )
                          ], "quantity", $$v)},expression:"\n                          editedArticle.warehouseArticleFacilities[\n                            editedArticle.warehouseArticleFacilities.findIndex(\n                              (wAF) =>\n                                wAF.warehouseFacility.id ===\n                                parseInt(editingProperty.split('warehouseFacility-').at(-1))\n                            )\n                          ].quantity\n                        "}}):(_vm.stockOperation === _vm.WAREHOUSE_STOCK_OPERATION.REPLENISHMENT)?[_c('v-text-field',{attrs:{"label":`Uzupełnienie - ${
                            _vm.facilities[
                              _vm.facilities.findIndex(
                                (wAF) => wAF.id === parseInt(_vm.editingProperty.split('warehouseFacility-').at(-1))
                              )
                            ]?.name
                          }`,"rules":[_vm.rules.isQuantityNonPositive()],"prefix":"+"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.articleStockSubmitting,"disabled":!!_vm.rules.isQuantityNonPositive()(
                                _vm.editedArticle.warehouseArticleFacilities[
                                  _vm.editedArticle.warehouseArticleFacilities.findIndex(
                                    (wAF) =>
                                      wAF.warehouseFacility.id ===
                                      parseInt(_vm.editingProperty.split('warehouseFacility-').at(-1))
                                  )
                                ].quantity
                              ).length || !!_vm.rules.isPriceNegative()(_vm.editedArticle.price).length,"icon":""},on:{"click":function($event){return _vm.submitArticleStockUpdate()}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)]},proxy:true}],null,true),model:{value:(
                            _vm.editedArticle.warehouseArticleFacilities[
                              _vm.editedArticle.warehouseArticleFacilities.findIndex(
                                (wAF) =>
                                  wAF.warehouseFacility.id ===
                                  parseInt(_vm.editingProperty.split('warehouseFacility-').at(-1))
                              )
                            ].quantity
                          ),callback:function ($$v) {_vm.$set(_vm.editedArticle.warehouseArticleFacilities[
                              _vm.editedArticle.warehouseArticleFacilities.findIndex(
                                (wAF) =>
                                  wAF.warehouseFacility.id ===
                                  parseInt(_vm.editingProperty.split('warehouseFacility-').at(-1))
                              )
                            ], "quantity", $$v)},expression:"\n                            editedArticle.warehouseArticleFacilities[\n                              editedArticle.warehouseArticleFacilities.findIndex(\n                                (wAF) =>\n                                  wAF.warehouseFacility.id ===\n                                  parseInt(editingProperty.split('warehouseFacility-').at(-1))\n                              )\n                            ].quantity\n                          "}}),(_vm.stockOperation === _vm.WAREHOUSE_STOCK_OPERATION.REPLENISHMENT)?_c('custom-combobox-single',{attrs:{"disabled":_vm.articleSuppliersLoading,"item-text":'name',"items":_vm.articleSuppliers,"loading":_vm.articleSuppliersLoading,"dense":"","label":"Dostawca"},on:{"trueValue":(n) => _vm.setSupplierObjOrName(n)},model:{value:(_vm.supplierHelper),callback:function ($$v) {_vm.supplierHelper=$$v},expression:"supplierHelper"}}):_vm._e(),(_vm.stockOperation === _vm.WAREHOUSE_STOCK_OPERATION.REPLENISHMENT)?_c('v-text-field',{attrs:{"rules":[_vm.rules.isPriceNegative()],"label":"Cena zakupu","suffix":"PLN","type":"number"},model:{value:(_vm.editedArticle.price),callback:function ($$v) {_vm.$set(_vm.editedArticle, "price", $$v)},expression:"editedArticle.price"}}):_vm._e()]:_vm._e()]:_vm._e()],2)],1):_vm._e()],1),(
                  (_vm.getArticleStock({ articleId: _vm.article.id }).warehouseArticleFacilities.find(
                    (value) => value.warehouseFacility.id === facility.id
                  )?.quantity ?? 0) <
                  (_vm.getArticleStock({ articleId: _vm.article.id }).warehouseArticleFacilities.find(
                    (value) => value.warehouseFacility.id === facility.id
                  )?.min_quantity ?? 0)
                )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: on1, attrs: attrs1 }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs1,false),on1),[_vm._v("mdi-alert-rhombus-outline")])]}}],null,true)},[_c('span',[_vm._v("Brakująca ilość: "+_vm._s((_vm.getArticleStock({ articleId: _vm.article.id }).warehouseArticleFacilities.find( (value) => value.warehouseFacility.id === facility.id )?.min_quantity ?? 0) - (_vm.getArticleStock({ articleId: _vm.article.id }).warehouseArticleFacilities.find( (value) => value.warehouseFacility.id === facility.id )?.quantity ?? 0))+" ")])])]:_vm._e()],2),_c('td',{staticClass:"d-flex flex-row justify-end align-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","left":"","offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":"","x-small":""},on:{"click":function($event){return _vm.openMinQuantityMenu(facility.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cog")])],1)]}}],null,true)},[(
                    _vm.editingProperty === 'min_quantity' &&
                    _vm.editedArticle.warehouseArticleFacilities.find(
                      (wAF) => wAF.warehouseFacility.id === facility.id
                    ) !== undefined
                  )?[_c('v-card',[_c('v-card-text',[_vm._v("Ustaw ilość minimalną na "+_vm._s(facility.name))]),_c('v-card-text',{staticClass:"pt-0"},[_c('v-text-field',{attrs:{"clearable":"","placeholder":"Nie ustawiono","rules":[_vm.rules.isQuantityNegative()],"label":"Ilość minimalna"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.articleStockSubmitting,"icon":""},on:{"click":function($event){return _vm.submitArticleMinQuantity(facility.id)}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)]},proxy:true}],null,true),model:{value:(
                          _vm.editedArticle.warehouseArticleFacilities.find(
                            (wAF) => wAF.warehouseFacility.id === facility.id
                          ).min_quantity
                        ),callback:function ($$v) {_vm.$set(_vm.editedArticle.warehouseArticleFacilities.find(
                            (wAF) => wAF.warehouseFacility.id === facility.id
                          ), "min_quantity", $$v)},expression:"\n                          editedArticle.warehouseArticleFacilities.find(\n                            (wAF) => wAF.warehouseFacility.id === facility.id\n                          ).min_quantity\n                        "}})],1)],1)]:_vm._e()],2)],1)])}),_c('tr',[_c('td'),_c('td',[_c('b',[_c('span',{staticClass:"px-3"},[_vm._v(_vm._s(_vm.getArticleStock({ articleId: _vm.article.id }).warehouseArticleFacilities.reduce( (accumulator, f) => accumulator + f.quantity, 0 ) + " " + _vm.warehouseTypes.articleUnit[_vm.article.unit].abbreviation))])])]),_c('td')])],2)]},proxy:true}])})],1),(_vm.editingProperty)?_c('v-menu',{attrs:{"close-on-content-click":false,"left":_vm.getWindowWidth / 2 < _vm.x,"position-x":_vm.x,"position-y":_vm.y,"right":_vm.getWindowWidth / 2 >= _vm.x,"transition":"slide-x-transition"},model:{value:(_vm.showEditingMenu),callback:function ($$v) {_vm.showEditingMenu=$$v},expression:"showEditingMenu"}},[(_vm.editingProperty === 'transfer')?_c('v-card',[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"label":_vm.facilities.find((f) => f.id === _vm.draggedData.from.facilityId).name,"dense":"","disabled":"","type":"number"},model:{value:(
                _vm.draggedData.to.article.warehouseArticleFacilities.find(
                  (wAF) => wAF.warehouseFacility.id === _vm.draggedData.from.facilityId
                ).quantity
              ),callback:function ($$v) {_vm.$set(_vm.draggedData.to.article.warehouseArticleFacilities.find(
                  (wAF) => wAF.warehouseFacility.id === _vm.draggedData.from.facilityId
                ), "quantity", $$v)},expression:"\n                draggedData.to.article.warehouseArticleFacilities.find(\n                  (wAF) => wAF.warehouseFacility.id === draggedData.from.facilityId\n                ).quantity\n              "}}),_c('v-text-field',{attrs:{"rules":[_vm.rules.isQuantityNonPositive()],"label":"Ilość","prepend-icon":"mdi-arrow-down-bold-circle-outline","type":"number"},model:{value:(_vm.draggedData.quantity),callback:function ($$v) {_vm.$set(_vm.draggedData, "quantity", $$v)},expression:"draggedData.quantity"}}),_c('v-text-field',{attrs:{"label":_vm.facilities.find((f) => f.id === _vm.draggedData.to.facilityId).name,"dense":"","disabled":"","type":"number"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.articleStockSubmitting,"disabled":!!_vm.rules.isQuantityNonPositive()(_vm.draggedData.quantity).length ||
                    _vm.draggedData.to.article.warehouseArticleFacilities.find(
                      (wAF) => wAF.warehouseFacility.id === _vm.draggedData.from.facilityId
                    ).quantity -
                      _vm.draggedData.quantity <
                      0,"icon":""},on:{"click":function($event){return _vm.submitArticleTransfer()}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)]},proxy:true}],null,false,178251939),model:{value:(
                _vm.draggedData.to.article.warehouseArticleFacilities.find(
                  (wAF) => wAF.warehouseFacility.id === _vm.draggedData.to.facilityId
                ).quantity
              ),callback:function ($$v) {_vm.$set(_vm.draggedData.to.article.warehouseArticleFacilities.find(
                  (wAF) => wAF.warehouseFacility.id === _vm.draggedData.to.facilityId
                ), "quantity", $$v)},expression:"\n                draggedData.to.article.warehouseArticleFacilities.find(\n                  (wAF) => wAF.warehouseFacility.id === draggedData.to.facilityId\n                ).quantity\n              "}})],1)],1)],1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }