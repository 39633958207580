<template>
  <v-container class="pa-1">
    <!--    <v-row-->
    <!--      v-if="isUnassigned"-->
    <!--      :class="{ 'd-flex': isCurrentTaskDragged() }"-->
    <!--      class="d-none flex-row flex-nowrap"-->
    <!--      no-gutters>-->
    <!--      <v-col v-if="isCufOffDateExceeded(task.job.cutOffDate)" class="d-flex flex-column align-end">-->
    <!--        <v-spacer></v-spacer>-->
    <!--        <p class="ma-0" style="color: red; font-size: 0.8em">przedawnione</p>-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <v-row v-if="isCurrentTaskDragged() && showDates"  class="d-flex flex-row flex-nowrap" no-gutters>
      <v-col class="d-flex flex-column align-start" cols="auto">
        <v-icon small>
          {{task.startAt ? 'mdi-clock-outline' : 'mdi-clock-alert-outline'}}
        </v-icon>
      </v-col>
      <v-col class="d-flex flex-column align-start pl-1">
        <p class="ma-0" style="font-size: 0.8em">{{ task.startAt ? formatDate(task.startAt) : 'niezaplanowane' }}</p>
      </v-col>
    </v-row>
    <v-row v-if="isCurrentTaskDragged() && showDates" class="d-flex flex-row flex-nowrap" no-gutters>
      <v-col class="d-flex flex-column align-start" cols="auto">
        <v-icon small>
          mdi-timeline-clock-outline
        </v-icon>
      </v-col>
      <v-col class="d-flex flex-column pl-1">
        <p class="ma-0" style="font-size: 0.8em">{{ formatDate(task.job.cutOffDate) }}</p>
      </v-col>
    </v-row>

    <v-row class="d-flex flex-row flex-nowrap py-1 pb-0" no-gutters>
      <v-col class="text-truncate flex-grow-0 flex-shrink-1" cols="auto">
        <b>{{ !!!task.job.customer ? "Klient bez nazwy" : task.job.customer.name }}</b>
      </v-col>
      <v-col class="d-flex flex-column align-end">
        <v-icon v-if="warningType" :color="task.job.sla ? 'red' : 'orange'">
          {{ warningType === WARNING_TYPE.STRONG ? "mdi-alert" : "mdi-alert-outline" }}
        </v-icon>
      </v-col>
    </v-row>

    <v-row class="d-flex flex-row flex-nowrap py-1" no-gutters>
      <v-col class="text-truncate flex-grow-0 flex-shrink-1" cols="auto">
        <span>{{ task.job.location !== null ? task.job.location.name : "Brak adresu" }}</span>
      </v-col>
    </v-row>

    <v-row v-if="isUnassigned && task.userTasks.length" class="d-flex flex-row flex-nowrap pb-1" no-gutters>
      <v-col class="d-flex flex-column align-start">
        <v-container
          v-for="(assignedUsers, i1) in task.userTasks.map((u) => serviceTechnicians.find((n) => n.id === u.user.id))"
          v-show="isCurrentTaskDragged()"
          :key="i1"
          class="pa-0">
          <v-chip v-if="assignedUsers" :key="assignedUsers.id" color="white" label text-color="black">
            <v-icon :color="assignedUsers.color" left> mdi-circle</v-icon>
            {{ assignedUsers.name }}
          </v-chip>
        </v-container>
      </v-col>
    </v-row>

    <v-row class="d-flex flex-row flex-nowrap" no-gutters>
      <v-col class="d-flex flex-row align-start">
        <v-icon v-if="cardStatusIcon(task)" :color="cardIconColor(task)" small>
          {{ cardStatusIcon(task) }}
        </v-icon>
        <v-icon color="#558B2F" small>
          {{ types.jobSettlementStatus[task.job.settlementStatus].icon }}
        </v-icon>
        <v-icon color="grey" small>
          {{ types.taskTypes[task.type].icon }}
        </v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import types from "../../types.json";
import { SETTLEMENT_STATUS } from "../../constants";
import { dates, formatDate } from "../../helper";
import { mapGetters } from "vuex";

const WARNING_TYPE = Object.freeze({ NONE: null, WEAK: 1, STRONG: 2 });

export default {
  name: "CalendarTaskCard",
  props: {
    task: {},
    isUnassigned: {
      type: Boolean,
      default: false,
    },
    showDates: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    WARNING_TYPE() {
      return WARNING_TYPE;
    },
    ...mapGetters({
      draggingTask: "calendarTasks/getDraggingTask",
      serviceTechnicians: "calendarTasks/getUsers",
    }),
  },
  data() {
    return {
      warningType: this.isCufOffDateExceeded(this.task.startAt, this.task.job.warningOffset, this.task.job.cutOffDate),
      taskStatuses: types.taskStatusesFinal,
      types,
      SETTLEMENT_STATUS,
    };
  },
  methods: {
    isCurrentTaskDragged() {
      return !!!this.draggingTask || this.draggingTask.task.id !== this.task.id;
    },
    formatDate,
    isCufOffDateExceeded(startAtDate, warningOffset, cutOffDate) {
      // console.log(new Date().addDays(-2))
      let start = startAtDate ? new Date(startAtDate) : new Date();
      start.setUTCHours(0, 0, 0, 0);
      start.toUTCString();

      const realizationDate = new Date(formatDate(start));
      const warningDate = new Date(formatDate(new Date(cutOffDate).addDays(-warningOffset)));
      const deadlineDate = new Date(formatDate(new Date(cutOffDate)));

      const inWarningRange = dates.inRange(realizationDate, warningDate, deadlineDate);
      const exceededCutOffDate = dates.compare(realizationDate, deadlineDate) > -1;
      if (exceededCutOffDate) {
        return WARNING_TYPE.STRONG;
      } else {
        if (inWarningRange) {
          return WARNING_TYPE.WEAK;
        }
      }
      return WARNING_TYPE.NONE;
    },
    cardIconColor(task) {
      if (this.taskStatuses[task.status]) {
        return this.taskStatuses[task.status].color;
      }
    },
    cardStatusIcon(task) {
      if (this.taskStatuses[task.status]) {
        return this.taskStatuses[task.status].icon;
      }
    },
  },
};
</script>
