import axios from "axios";
import { deepMerge } from "../../../../helper";

export default {
  async postArticleStockMinQuantity({ commit, getters, dispatch }, { articleId, facilityId, min_quantity }) {
    // console.debug(name, 'payload');
    commit("setArticleStockSubmitting", true);
    try {
      const response = await axios.post(`warehouse/article/${articleId}/stock/min-quantity`, {
        facilityId: facilityId,
        min_quantity: min_quantity,
      });
    } catch (e) {
      dispatch("messages/notifyError", `Serwer error ${e?.response?.status}: ${e?.response?.data?.error}`, {
        root: true,
      });
    }

    commit("setArticleStockSubmitting", false);
  },
  async postArticleStockCorrection({ commit, getters, dispatch }, { articleId, facilityId, quantity }) {
    commit("setArticleStockSubmitting", true);
    try {
      const response = await axios.post(`warehouse/article/${articleId}/stock/correction`, {
        facilityId: facilityId,
        quantity: quantity,
      });
    } catch (e) {
      dispatch("messages/notifyError", `Serwer error ${e?.response?.status}: ${e?.response?.data?.error}`, {
        root: true,
      });
    }
    commit("setArticleStockSubmitting", false);
  },
  async postArticleStockConsumption({ commit, getters, dispatch }, { articleId, facilityId, quantity }) {
    commit("setArticleStockSubmitting", true);
    try {
      const response = await axios.post(`warehouse/article/${articleId}/stock/consumption`, {
        facilityId: facilityId,
        quantity: quantity,
      });
    } catch (e) {
      dispatch("messages/notifyError", `Serwer error ${e?.response?.status}: ${e?.response?.data?.error}`, {
        root: true,
      });
    }
    commit("setArticleStockSubmitting", false);
  },
  async postArticleStockReplenish({ commit, getters, dispatch }, { articleId, facilityId, quantity, supplier, price }) {
    commit("setArticleStockSubmitting", true);

    try {
      const response = await axios.post(`warehouse/article/${articleId}/stock/replenish`, {
        facilityId: facilityId,
        quantity: quantity,
        supplier: supplier,
        price: price,
      });
    } catch (e) {
      dispatch("messages/notifyError", `Serwer error ${e?.response?.status}: ${e?.response?.data?.error}`, {
        root: true,
      });
    }

    commit("setArticleStockSubmitting", false);
  },
  async postArticleStockTransfer(
    { commit, getters, dispatch },
    { articleId, sourceFacilityId, destinationFacilityID, quantity }
  ) {
    console.debug({ articleId, sourceFacilityId, destinationFacilityID, quantity }, "payload");
    commit("setArticleStockSubmitting", true);
    try {
      const response = await axios.post(`warehouse/article/${articleId}/stock/transfer`, {
        sourceFacilityId: sourceFacilityId,
        destinationFacilityID: destinationFacilityID,
        quantity: quantity,
      });
    } catch (e) {
      dispatch("messages/notifyError", `Serwer error ${e?.response?.status}: ${e?.response?.data?.error}`, {
        root: true,
      });
    }

    commit("setArticleStockSubmitting", false);
  },
  updateArticleStock({ commit, getters, rootGetters }, { article }) {
    const currentArticleIdx = rootGetters["warehouse/articles/articles"].findIndex((a) => a.id === article.id);
    console.log(currentArticleIdx);
    console.log(rootGetters["warehouse/articles/articles"][currentArticleIdx]);

    if (currentArticleIdx !== -1) {
      const updatedArticle = deepMerge(rootGetters["warehouse/articles/articles"][currentArticleIdx], article, false);
      console.debug(updatedArticle);
      commit(
        "warehouse/articles/setArticleInArticles",
        {
          articleIdx: currentArticleIdx,
          article: updatedArticle,
        },
        { root: true }
      );
    }
    if (
      rootGetters["warehouse/articleDetails/article"] &&
      rootGetters["warehouse/articleDetails/article"].id === article.id
    ) {
      const articleStockIdx = getters.articleStocks.findIndex((value) => value.id === article.id);
      const updatedArticle = deepMerge(rootGetters["warehouse/articleDetails/article"], article, false);
      commit("warehouse/articleDetails/setArticleDetails", updatedArticle, { root: true });
      commit("setArticleStock", { stockIdx: articleStockIdx, stock: article });
    }
  },
  async fetchArticleStock({ commit, getters }, { articleId }) {
    commit("setArticleStocksLoading", true);

    const response = await axios.get(`warehouse/article/${articleId}/stock`);
    console.debug(response.data, "response.data");

    const articleStockIdx = getters.articleStocks.findIndex((value) => value.id === articleId);
    const articleStocks = getters.articleStocks;
    if (articleStockIdx !== -1) {
      articleStocks.splice(articleStockIdx, 1);
    } else {
      articleStocks.push(response.data);
    }

    commit("setArticleStocks", articleStocks);

    commit("setArticleStocksLoading", false);
  },

  async getArticleStock({ commit, getters, dispatch }, { articleId }) {
    const articleStockIdx = getters.articleStocks.findIndex((value) => value.id === articleId);
    console.log(articleStockIdx);
    if (articleStockIdx === -1) {
      await dispatch("fetchArticleStock", { articleId: articleId });
      return getters.articleStocks.at(-1);
    }
    console.log(getters.articleStocks.at(articleStockIdx));
    return getters.articleStocks.at(articleStockIdx);
  },
};
