export default {
  setFacilitiesDetails(state, payload) {
    state.facilitiesDetails = payload;
  },
  setFacilitiesDetailsLoading(state, isLoading) {
    state.loadingFacilitiesDetails = isLoading;
  },
  setSubmittingFacilityDetails(state, isSubmitting) {
    state.submittingFacilityDetails = isSubmitting;
  },
};
