<template>
  <v-row no-gutters style="height: 100%">
    <v-col
      v-for="(dayWeek, k, index) in weekDays"
      :key="k + '' + date"
      :style="{
        width: (1 / 8) * 100 + '%',
        'background-color': index % 2 || (index === undefined && k % 2) ? 'white' : 'rgba(0,0,0,0.03)',
      }"
      class="column"
      cols="auto"
      style="height: 100%">
      <v-card v-if="myList[dayWeek].length === 0" elevation="0">
        <v-card-title
          style="
            padding: 0;
            margin: 16px;
            position: absolute;
            z-index: 0;
            -webkit-user-select: none; /* Safari */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */
          ">
          Brak
        </v-card-title>
      </v-card>
      <v-card
        v-for="(element, i) in myList[dayWeek]"
        v-else
        :key="i"
        :ref="'' + element.id + '_' + date"
        :color="taskStatuses[element.task.status].backgroundColor"
        class="myCard"
        elevation="3"
        outlined
        style="
          margin: 0px 10px 5px;
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */
        "
        @click="show($event, '' + element.id + '_' + date, element)">
        <calendar-task-card
          :task="element.task"></calendar-task-card>
      </v-card>
    </v-col>
  </v-row>
  <!--  $router.push({path: '/job/' + element.task.job.id})-->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "../../helper";
import CalendarTaskCard from "./CalendarTaskCard.vue";
import types from "../../types.json";

export default {
  components: {
    CalendarTaskCard,
  },
  props: ["date", "users", "weekDays"],
  data() {
    return {
      taskStatuses: types.taskStatusesFinal,
      clickedTask1: null,
      clickedTask2: null,
      timer: null,
      delay: 300,
    };
  },
  computed: {
    myList: {
      get() {
        return this.dayTasks;
      },
      set(value) {
        // console.log(value)
      },
    },
    ...mapGetters({
      dayTasks: "weekCalendar/getUserTasks",
      menuCardWidth: "layoutHelpers/getMenuPopUpWidth",
    }),
  },
  methods: {
    dayDiff(d) {
      const date1 = new Date();
      const date2 = new Date(d);
      const diffTime = date2 - date1;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    emptyOrVacations(date) {
      console.log(date);
      console.log(this.users.id);
      return this.$store.getters["weekCalendar/doesUserHasVacations"]({
        date: date,
        id: this.users.id,
      })
        ? "Urlop"
        : "Brak";
    },
    show(e, i, o) {
      let pos = this.$refs[i][0].$el.getBoundingClientRect();
      e.preventDefault();
      this.setMenuPopUp({ showMenu: false });

      const menuParams = {
        dayDiff: this.dayDiff(o.task.job.cutOffDate),
        cutOffDate: formatDate(o.task.job.cutOffDate),
        warningOffset: o.task.job.warningOffset,
        startAt: formatDate(o.task.startAt),
        menuDetails: o.task,
        returnIcon: types.taskTypes[o.task.type].icon,
        showMenu: true,
        x: pos.right > 1000 ? pos.left - this.menuCardWidth : pos.right,
        y: pos.top,
      };
      console.log(menuParams);

      if (this.clickedTask1 !== null && this.clickedTask2 === null) {
        this.clickedTask2 = o.task.id;
      }
      if (this.clickedTask1 === null) {
        this.clickedTask1 = o.task.id;
      }

      if (this.clickedTask1 !== null && this.clickedTask2 === null) {
        let self = this;
        this.timer = setTimeout(
          function () {
            self.clickedTask1 = null;
          },
          this.delay,
          this.$nextTick(() => {
            this.setMenuPopUp(menuParams);
          })
        );
      } else {
        if (this.clickedTask1 === this.clickedTask2) {
          clearTimeout(this.timer);
          window.open("/job/" + o.task.job.id, "_blank");
        }
        this.clickedTask1 = null;
        this.clickedTask2 = null;
      }
    },
    ...mapActions({ setMenuPopUp: "layoutHelpers/setMenuParams" }),
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}

.myCard.ghost {
  opacity: 0.9;
  color: rgba(59, 59, 39, 0.98);
  background-image: linear-gradient(90deg, silver 50%, transparent 50%),
    linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%),
    linear-gradient(0deg, silver 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1s infinite linear, expandBox 0.25s;
}

.chosen {
  /*padding: 0 !important;*/
  /*margin: 0 !important;*/
  /*background-color: transparent !important;*/
  /*opacity: 0.3;*/
  border: black 3px solid !important;
}

.v-card__text {
  padding-top: 0 !important;
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}

.list-group {
  overflow: hidden;
}

.column {
  transition: height 0.25s ease-in-out, width 0.15s linear, background-color 0.15s linear;
}

.myCard {
  animation: expandBox 0.25s;
}

@keyframes createBox {
  from {
    transform: scale(0.2);
  }
  to {
    transform: scale(1);
  }
}

@keyframes expandBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.list-group:hover {
  /*background-color: lightgrey;*/
}
</style>
