import axios from 'axios';
import Vue from "vue";

export default {
  async fetchFacilitiesStats({ commit }, payload) {
    commit('setFacilitiesDetailsLoading', true);

    const response = await axios.get(`warehouse/facility/all/article/stats`);
    console.debug(response.data, 'response.data');
    commit('setFacilitiesDetails', response.data);
    commit('setFacilitiesDetailsLoading', false);
  },
  async postNewFacility({ commit, getters }, { facilityData }) {
    console.debug(facilityData, 'payload');
    commit('setSubmittingFacilityDetails', true);
    // await new Promise((resolve) => setTimeout(resolve, 2000)); //TODO: TEST
    try {
      const response = await axios.post(`warehouse/facility/add`, { ...facilityData });
      console.debug(response.data, 'response.data');
      facilityData.id = response.data.id;
      commit('setFacilitiesDetails', [...getters.facilitiesDetails, facilityData]);
    } catch (e) {
      throw e;
    } finally {
      commit('setSubmittingFacilityDetails', false);
    }
  },
  async postEditedFacility({ commit, getters }, { facilityData }) {
    console.debug(facilityData, 'payload');
    commit('setSubmittingFacilityDetails', true);
    // await new Promise((resolve) => setTimeout(resolve, 2000)); //TODO: TEST
    try {
      const response = await axios.post(`warehouse/facility/${facilityData.id}/edit`, { ...facilityData });
      console.debug(response.data, 'response.data');
      const facilityIdx = getters.facilitiesDetails.findIndex((value) => value.id === facilityData.id);
      if (facilityIdx === -1) {
        console.error('could not find articleGroup after submitting edition');
      }
      Vue.set(getters.facilitiesDetails, facilityIdx, facilityData);
    } catch (e) {
      throw e;
    } finally {
      commit('setSubmittingFacilityDetails', false);
    }
  },
  async postDeleteFacility({ commit, getters }, { facilityData }) {
    console.debug(facilityData, 'payload');
    commit('setSubmittingFacilityDetails', true);
    // await new Promise((resolve) => setTimeout(resolve, 2000)); //TODO: TEST
    try {
      const response = await axios.delete(`warehouse/facility/${facilityData.id}/delete`);
      console.debug(response.data, 'response.data');
      const facilities = getters.facilitiesDetails.filter((value) => value.id !== facilityData.id);
      commit('setFacilitiesDetails', facilities);
    } catch (e) {
      throw e;
    } finally {
      commit('setSubmittingFacilityDetails', false);
    }
  },
};
