<template>
  <v-card-text>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" md="5">
            <form-row-with-icon icon="mdi-at">
              <create-new-user-field-name ref="emailRef" v-model="userData.email" label="Email" type="email" />
            </form-row-with-icon>
          </v-col>
          <v-col cols="12" md="5">
            <form-row-with-icon icon="mdi-at">
              <create-new-user-field-name
                ref="repeatEmailRef"
                v-model="repeatEmail"
                label="Powtórz email"
                type="repeatEmail"
                :repeat="userData.email"
              />
            </form-row-with-icon>
          </v-col>
          <v-col align-self="center" cols="12" md="2">
            <v-btn block :disabled="buttonDisabled()" color="secondary" @click="handleSubmit"> Zmień </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card-text>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CreateUserClass from "../../store/class/CreateUserClass";
import FormRowWithIcon from "../ui/FormRowWithIcon.vue";
import CreateNewUserFieldName from "./ValidatedTextField.vue";

export default {
  name: "AdminChangeEmail",
  components: { CreateNewUserFieldName, FormRowWithIcon },
  props: ["user"],
  data: () => ({
    userData: null,
    repeatEmail: "",
  }),
  methods: {
    ...mapActions({ editUser: "users/editUser" }),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    async handleSubmit() {
      const ok = await this.editUser({ userId: this.user.id, userData: this.userData });
      if (ok) {
        await this.notifyInfo("Zaktualizowano mail");
        this.$emit("refresh");
      } else {
        await this.notifyError("Nie można zmienić maila");
      }
    },
    buttonDisabled() {
      let nErrors = [];
      if (this.$refs.emailRef && this.$refs.repeatEmailRef) {
        nErrors = [...this.$refs.emailRef.getNameError(), ...this.$refs.repeatEmailRef.getNameError()];
      }
      console.log(nErrors);
      return nErrors.length > 0;
    },
  },
  created() {
    this.userData = CreateUserClass.create({
      email: this.user.email,
    });
  },
};
</script>
