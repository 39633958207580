<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <template #activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <v-card class="d-flex flex-column justify-space-between">
      <v-card-title>
        <span class="text-h5">Usuwanie kodu</span>
      </v-card-title>
      <v-card-text>
        <v-card v-if="dialog" class="d-flex flex-column justify-space-between" elevation="0">
          <v-row class="align-center justify-start" no-gutters>
            <v-col>
              <span>Usunięcie tego kodu GTIN/EAN spowoduje, że artykuł nie będzie już dostępny do wyszukiwania za pomocą skanowania.</span>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-btn text @click="dialog = false">
          <v-icon left> mdi-cancel</v-icon>
          Anuluj
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="articleEANSubmitting" color="error" @click="deleteArticleEAN">
          <v-icon left> mdi-barcode-off</v-icon>
          Usuń kod
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import warehouseTypes from "../../../warehouseTypes.json";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "WarehouseDeleteArticleEANDialog",
  props: {
    ean: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    warehouseTypes,
  }),
  computed: {
    ...mapGetters("warehouse/articleDetails", ["articleEANSubmitting", "articleEANValidationSubmitting"]),
  },
  methods: {
    ...mapActions("warehouse/articleDetails", ["postArticleRemoveEAN", "postArticleEANValidation"]),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    async deleteArticleEAN() {
      try {
        await this.postArticleRemoveEAN({ ean: this.ean });
        await this.notifyInfo("Usunięto kod");
      } catch (e) {
        await this.notifyError(e);
        console.log(e);
      }
      this.dialog = false;
    },
  },
};
</script>
