import axios from 'axios';
import { CALENDAR_PLANNING_MODE, FiltersBasicGroup } from '../../../constants';
import Vue from 'vue';
import {
  adjustPrioritiesAfterInsertion,
  adjustPrioritiesAfterRemoval,
  classifyTask,
  flattenUserTasks,
  formatDate,
  isTaskMeetingFiltersCriteria,
} from '../../../helper';
import { compareAsc } from 'date-fns';

export default {
  setCalendarSelectedUserIds({ commit }, { userIds }) {
    commit('setCalendarSelectedUserIds', userIds.sort());
  },

  async fetchCalendarUserTasks({ commit, getters, dispatch }, { date }) {
    // const userIds = getters.getCalendarSelectedUserIds.join();
    // const response = await axios.get(`calendar/${date}`, { params: { userIds: userIds } });
    const response = await axios.get(`calendar/${date}`);
    commit('setApiUserTasks', response.data);
    await dispatch('normalizeUserTasks', { date });
  },

  async addPlannedTask({ commit, getters, dispatch }, { task }) {
    if (getters.unassignedFilters.planningMode !== CALENDAR_PLANNING_MODE.PLANNED) {
      return;
    }
    if (!isTaskMeetingFiltersCriteria({ task, filters: getters.unassignedFilters })) {
      await dispatch('removePlannedTask', { task });
      return;
    }
    const existingTaskOnPlanningColumnIndex = getters.getPlannedTasksOutsideCalendar.findIndex((t) => t.id === task.id);
    if (existingTaskOnPlanningColumnIndex === -1) {
      const newPlanningColumnPlanedTasks = [...getters.getPlannedTasksOutsideCalendar, task];
      newPlanningColumnPlanedTasks.sort((a, b) => compareAsc(new Date(a.startAt), new Date(b.startAt)));
      commit('setPlannedTasksOutsideCalendar', newPlanningColumnPlanedTasks);
    } else {
      commit('setPlannedTaskOutsideCalendar', { index: existingTaskOnPlanningColumnIndex, task });
      commit(
        'setPlannedTasksOutsideCalendar',
        [...getters.getPlannedTasksOutsideCalendar].sort((a, b) => compareAsc(new Date(a.startAt), new Date(b.startAt)))
      );
    }
    console.log('task');
    console.log(task);
    console.log('update planned outside');
    console.log(getters.getPlannedTasksOutsideCalendar);
  },

  async removePlannedTask({ commit, getters }, { task }) {
    if (getters.unassignedFilters.planningMode !== CALENDAR_PLANNING_MODE.PLANNED) {
      return;
    }
    const newPlanningColumnPlanedTasks = getters.getPlannedTasksOutsideCalendar.filter((t) => t.id !== task.id);
    newPlanningColumnPlanedTasks.sort((a, b) => compareAsc(new Date(a.startAt), new Date(b.startAt)));
    commit('setPlannedTasksOutsideCalendar', newPlanningColumnPlanedTasks);

    console.log('task');
    console.log(task);
    console.log('update planned outside');
    console.log(getters.getPlannedTasksOutsideCalendar);
  },

  async addUnassignedTask({ commit, getters }, { task }) {
    const taskGroup = classifyTask(task);
    console.log(taskGroup);
    const mode = getters.unassignedFilters.planningMode;

    if (
      mode !== CALENDAR_PLANNING_MODE.UNPLANNED ||
      !isTaskMeetingFiltersCriteria({ task, filters: getters.unassignedFilters, group: taskGroup })
    ) {
      return;
    }
    const normalizedTask = { task: task };

    const newList = [...getters.getGroupedTasksOutsideCalendar[taskGroup], normalizedTask];
    console.log('new list');
    console.log(newList);
    if (taskGroup === FiltersBasicGroup.NU_D) {
      newList.sort((a, b) => compareAsc(new Date(a.task.startAt), new Date(b.task.startAt)));
    } else {
      newList.sort((a, b) => compareAsc(new Date(a.task.job.cutOffDate), new Date(b.task.job.cutOffDate)));
    }

    commit('setGroupOnTasksOutsideCalendar', { group: taskGroup, list: newList });
  },

  async removeUnassignedTask({ commit, getters }, { task }) {
    const taskGroup = classifyTask(task);
    console.log(taskGroup);
    const mode = getters.unassignedFilters.planningMode;

    if (mode === CALENDAR_PLANNING_MODE.UNPLANNED) {
      if (!taskGroup) {
        return;
      }
      const newList = getters.getGroupedTasksOutsideCalendar[taskGroup].filter((t) => t.task.id !== task.id);

      console.log('new list');
      console.log(newList);
      commit('setGroupOnTasksOutsideCalendar', { group: taskGroup, list: newList });
    }
  },

  async addApiUserTask({ commit, getters }, { userTask }) {
    let userTasks = { ...getters.getUserTasks };
    console.log('vuex userTasks');
    console.log(userTasks);

    // Flatten tasks for easy search
    const flatTasks = flattenUserTasks(userTasks);
    console.log('flatten tasks');
    console.log(flatTasks);
    const existingTaskIndex = flatTasks.findIndex((ut) => ut.task.id === userTask.task.id);
    console.log('found task');
    console.log(flatTasks[existingTaskIndex]);

    // Remove existing task if it exists
    if (existingTaskIndex !== -1) {
      const day = formatDate(userTask.task.startAt);
      userTasks[day][userTask.user.id].tasks = userTasks[day][userTask.user.id].tasks.filter(
        (ut) => ut.task.id !== userTask.task.id
      );
    }

    // Add new task and adjust priorities
    const day = formatDate(userTask.task.startAt);
    if (!userTasks[day]) {
      commit('setUserTasks', userTasks);
      return;
    }
    if (!userTasks[day][userTask.user.id]) {
      userTasks[day][userTask.user.id] = {};
    }
    if (userTasks[day][userTask.user.id].tasks) {
      userTasks[day][userTask.user.id].tasks = userTasks[day][userTask.user.id].tasks.filter(
        (ut) => ut.id !== userTask.id
      );
      userTasks[day][userTask.user.id].tasks = adjustPrioritiesAfterInsertion(
        userTasks[day][userTask.user.id].tasks,
        userTask.priority
      );
      userTasks[day][userTask.user.id].tasks.push(userTask);
      userTasks[day][userTask.user.id].tasks
        .sort((a, b) => a.priority - b.priority)
        .sort(function (a, b) {
          if (a.task.status === 'completed') {
            return 1;
          }
          if (b.task.status === 'completed') {
            return -1;
          }
          return 0;
        });
    }

    commit('setUserTasks', userTasks);
  },

  async updateApiUserTask({ commit, getters }, { userTask }) {
    let userTasks = { ...getters.getUserTasks };
    console.log('vuex userTasks');
    console.log(userTasks);

    // Flatten tasks for easy search
    const flatTasks = flattenUserTasks(userTasks);
    console.log('flatten tasks');
    console.log(flatTasks);
    const existingTaskIndex = flatTasks.findIndex((ut) => ut.id === userTask.id);
    console.log('found task');
    console.log(flatTasks[existingTaskIndex]);

    // Remove existing task if it exists
    if (existingTaskIndex !== -1) {
      const existingTask = flatTasks[existingTaskIndex];
      const oldDay = formatDate(existingTask.task.startAt);
      const removedPriority = existingTask.priority;
      userTasks[oldDay][existingTask.user.id].tasks = adjustPrioritiesAfterRemoval(
        userTasks[oldDay][existingTask.user.id].tasks,
        removedPriority
      );
      userTasks[oldDay][existingTask.user.id].tasks = userTasks[oldDay][existingTask.user.id].tasks.filter(
        (ut) => ut.id !== userTask.id
      );
    }

    // Add new task and adjust priorities
    const day = formatDate(userTask.task.startAt);
    if (!userTasks[day]) {
      commit('setUserTasks', userTasks);
      return;
    }
    if (!userTasks[day][userTask.user.id]) userTasks[day][userTask.user.id] = [];
    userTasks[day][userTask.user.id].tasks = userTasks[day][userTask.user.id].tasks.filter(
      (ut) => ut.id !== userTask.id
    );
    userTasks[day][userTask.user.id].tasks = adjustPrioritiesAfterInsertion(
      userTasks[day][userTask.user.id].tasks,
      userTask.priority
    );
    userTasks[day][userTask.user.id].tasks.push(userTask);
    userTasks[day][userTask.user.id].tasks
      .sort((a, b) => a.priority - b.priority)
      .sort(function (a, b) {
        if (a.task.status === 'completed') {
          return 1;
        }
        if (b.task.status === 'completed') {
          return -1;
        }
        return 0;
      });

    commit('setUserTasks', userTasks);
  },

  async removeApiUserTask({ commit, getters }, { userTask }) {
    let userTasks = { ...getters.getUserTasks };

    // Flatten tasks for easy search
    const flatTasks = flattenUserTasks(userTasks);
    console.log('flatten tasks');
    console.log(flatTasks);
    const existingTaskIndex = flatTasks.findIndex((ut) => ut.id === userTask.id);
    console.log('found task');
    console.log(flatTasks[existingTaskIndex]);

    if (existingTaskIndex !== -1) {
      const existingTask = flatTasks[existingTaskIndex];
      const day = formatDate(existingTask.task.startAt);
      const removedPriority = existingTask.priority;
      userTasks[day][existingTask.user.id].tasks = adjustPrioritiesAfterRemoval(
        userTasks[day][existingTask.user.id].tasks,
        removedPriority
      );
      userTasks[day][existingTask.user.id].tasks = userTasks[day][existingTask.user.id].tasks.filter(
        (ut) => ut.id !== userTask.id
      );

      commit('setUserTasks', userTasks);
    }
  },

  async normalizeUserTasks({ commit, getters }, { date }) {
    const userIds = getters.getCalendarSelectedUserIds.join();
    const userTasks = getters.getApiUserTasks;
    console.log('USER TASKS');
    console.log(userTasks);

    const userTasksGroupedByDay = { [date]: {} };
    userTasks.forEach((ut) => {
      console.log(ut);
      const day = formatDate(ut.task.startAt);
      console.log(day);
      if (!day) {
        return;
      }
      if (!userTasksGroupedByDay[day]) {
        userTasksGroupedByDay[day] = {};
      }
      if (!userTasksGroupedByDay[day][ut.user.id]) {
        userTasksGroupedByDay[day][ut.user.id] = [ut];
      } else {
        userTasksGroupedByDay[day][ut.user.id] = [...userTasksGroupedByDay[day][ut.user.id], ut];
      }
    });

    console.log('DAY USER TASKS');
    console.log(userTasksGroupedByDay);

    let filteredUsers = getters.getUsers;
    // console.log(userIds);
    // const usersToFetch = getters.getCalendarSelectedUserIds;
    // if (userIds) {
    //   filteredUsers = getters.getUsers.filter((x) => usersToFetch.includes(x.id));
    // } else {
    //   filteredUsers = getters.getUsers;
    // }

    const days = Object.keys(userTasksGroupedByDay);
    days.forEach((d) => {
      filteredUsers.forEach((item) => {
        console.log(userTasksGroupedByDay[d][item.id]);
        userTasksGroupedByDay[d][item.id] = {
          ...item,
          tasks:
            userTasksGroupedByDay[d][item.id] === undefined
              ? []
              : userTasksGroupedByDay[d][item.id]
                  .sort((a, b) => a.priority - b.priority)
                  .sort(function (a, b) {
                    if (a.task.status === 'completed') {
                      return 1;
                    }
                    if (b.task.status === 'completed') {
                      return -1;
                    }
                    return 0;
                  }),
        };
      });
    });

    console.log('DAY USER TASKS');
    console.log(userTasksGroupedByDay);

    commit('setUserTasks', userTasksGroupedByDay);
  },

  async fetchServiceTechnicians(context, payload) {
    const response = await axios.get(`user/getServiceTechniciansList`, { params: payload !== null ? payload : {} });
    const serviceTechnicians = response.data;
    const keys = Object.keys(response.data);
    keys.forEach((value) => {
      serviceTechnicians[value].selected = true;
    });
    context.commit('setUsers', serviceTechnicians);
  },

  async fetchUserVacations(context, payload) {
    const response = await axios.get(`vacation/calendarVacations`, { params: payload !== null ? payload : {} });
    const mappedData = response.data !== [] ? response.data.map((v) => v.id) : [];
    // console.log('parsowanie wakacji')
    // console.log(mappedData)
    context.commit('setVacationsByDay', { date: payload.date, data: mappedData });
  },

  async removeLoadingCols(context, payload) {
    await context.commit('removeLoadingCols', payload);
  },

  async addLoadingCols(context, payload) {
    // console.log('loading col');
    // console.log(payload);
    await context.commit('addLoadingCols', payload);
  },

  async fetchTasksOutsideCalendar({ commit, getters, dispatch }, payload) {
    const mode = getters.unassignedFilters.planningMode;
    switch (mode) {
      case CALENDAR_PLANNING_MODE.UNPLANNED:
        const response = await axios.get('task/fetchTasksOutsideCalendar', { params: payload !== null ? payload : {} });
        commit('setUnplannedTasksOutsideCalendar', response.data);
        dispatch('groupTasksOutsideCalendar');
        break;

      case CALENDAR_PLANNING_MODE.PLANNED:
        const response1 = await axios.get('task/calendarPlannedFilteredTasks', {
          params: payload !== null ? payload : {},
        });
        commit('setPlannedTasksOutsideCalendar', response1.data);
        break;
    }
  },

  async groupTasksOutsideCalendar({ commit, getters, dispatch }, _) {
    const mode = getters.unassignedFilters.planningMode;
    let groupedOthers = {};
    // groupBy(getters.getUnassignedTaskWithDate, 'startAt');
    if (mode === CALENDAR_PLANNING_MODE.UNPLANNED) {
      Vue.set(
        groupedOthers,
        FiltersBasicGroup.OVERDUE_MORE_7,
        getters.getOverdueMoreThan7Days.map((t) => ({ task: t }))
      );
      Vue.set(
        groupedOthers,
        FiltersBasicGroup.OVERDUE_LESS_7,
        getters.getOverdueLessThan7Days.map((t) => ({ task: t }))
      );
      Vue.set(
        groupedOthers,
        FiltersBasicGroup.U_ND,
        getters.getAssignedTaskWithoutDate.map((t) => ({ task: t }))
      );
      Vue.set(
        groupedOthers,
        FiltersBasicGroup.NU_ND,
        getters.getUnassignedTaskWithoutDate.map((t) => ({ task: t }))
      );
      Vue.set(
        groupedOthers,
        FiltersBasicGroup.NU_D,
        getters.getUnassignedTaskWithDate.map((t) => ({ task: t }))
      );
      // if (!Object.keys(groupedOthers).length) {
      //   Vue.set(groupedOthers, 'unassignedTaskWithDate', []);
      // }
    }
    commit('setGroupedTasksOutsideCalendar', groupedOthers);
  },

  setGroupedTasksOutsideCalendar(context, payload) {
    context.commit('setGroupedTasksOutsideCalendar', payload);
  },

  setPlannedTasksOutsideCalendar(context, payload) {
    context.commit('setPlannedTasksOutsideCalendar', payload);
  },

  setUnassignedFilters(context, payload) {
    context.commit('setUnassignedFilters', payload);
  },
};
