export default {
  setArticleDetails(state, payload) {
    state.articleDetails = payload;
  },
  setArticleDetailsLoading(state, isLoading) {
    state.loadingArticleDetails = isLoading;
  },
  setSubmittingArticle(state, isLoading) {
    state.submittingArticle = isLoading;
  },
  setSubmittingArticleEAN(state, isLoading) {
    state.submittingArticleEAN = isLoading;
  },
  setSubmittingArticleValidation(state, isLoading) {
    state.submittingArticleNameValidation = isLoading;
  },
  setSubmittingArticleEANValidation(state, isLoading) {
    state.submittingArticleEANValidation = isLoading;
  },
};
