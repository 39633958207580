import articles from './articles';
import articleDetails from './articleDetails';
import facilities from './facilities';
import suppliers from './suppliers';
import groups from './groups';
import stocks from './stocks';
import changes from './auditLog';
import facilitiesSettings from './facilitiesSettings';
import suppliersSettings from './suppliersSettings';
import groupsSettings from './groupsSettings';
import articleImages from "./articleImages";

export default {
  namespaced: true,
  modules: {
    articles,
    articleDetails,
    facilities,
    suppliers,
    groups,
    stocks,
    changes,
    facilitiesSettings,
    suppliersSettings,
    groupsSettings,
    articleImages
  },
};
