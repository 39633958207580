<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <template #activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon left> mdi-plus</v-icon>
        kod
      </v-btn>
    </template>
    <v-card class="d-flex flex-column justify-space-between">
      <v-card-title>
        <span class="text-h5">Nowy kod</span>
      </v-card-title>
      <v-card-text>
        <v-card v-if="dialog" class="d-flex flex-column justify-space-between" elevation="0">
          <v-row class="align-center justify-start" no-gutters>
            <v-col>
              <v-text-field
                v-model="newEAN"
                :error-messages="formErrors.name"
                :loading="articleEANValidationSubmitting"
                :rules="[rules.isEANEmpty(), rules.checkIfEANValid()]"
                label="Kod GTIN"
                type="number"></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-btn text @click="dialog = false">
          <v-icon left> mdi-cancel</v-icon>
          Anuluj
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="isButtonDisabled" :loading="articleEANSubmitting" color="primary" @click="createNewArticleEAN">
          <v-icon left> mdi-home-plus-outline</v-icon>
          Dodaj kod
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import warehouseTypes from "../../../warehouseTypes.json";
import { mapActions, mapGetters } from "vuex";
import { isValidBarcode } from "../../../helper";

export default {
  name: "WarehouseCreateArticleEANDialog",
  props: {
    articleId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    newEAN: null,
    formErrors: {
      name: [],
    },
    rules: {
      isEANEmpty() {
        return (v) => !!v || "Kod nie może być pusty";
      },
      checkIfEANValid() {
        return (v) => !v || isValidBarcode(v) || "Niepoprawny GTIN.";
      },
    },
    warehouseTypes,
  }),
  computed: {
    ...mapGetters("warehouse/articleDetails", ["articleEANSubmitting", "articleEANValidationSubmitting"]),
    isButtonDisabled() {
      const nameErrors = [
        this.rules.isEANEmpty()(this.newEAN),
        this.rules.checkIfEANValid()(this.newEAN),
        ...this.formErrors.name,
      ].filter((error) => typeof error === "string");
      return this.articleEANValidationSubmitting || nameErrors.length > 0;
    },
  },
  watch: {
    newEAN(val) {
      if (val && isValidBarcode(val))
        this.postArticleEANValidation({ ean: val }).then((valid) => {
          this.$set(this.formErrors, "name", valid ? [] : ["Kod artykułu jest już zajęty"]);
        });
      this.$set(this.formErrors, "name", []);
    },
  },
  methods: {
    ...mapActions("warehouse/articleDetails", ["postArticleAddEAN", "postArticleEANValidation"]),
    ...mapActions("messages", ["notifyInfo", "notifyError"]),
    async createNewArticleEAN() {
      try {
        await this.postArticleAddEAN({ ean: this.newEAN, articleId: this.articleId });
        await this.notifyInfo("Dodano nowy kod");
      } catch (e) {
        await this.notifyError(e);
        console.log(e);
      }
      this.dialog = false;
      this.newEAN = null;
    },
  },
};
</script>
