const ROLE = Object.freeze({
  SERVICE_TECHNICIAN: 'ROLE_SERVICE_TECHNICIAN',
  EXTERNAL: 'ROLE_EXTERNAL',
  OFFICE: 'ROLE_OFFICE',
  ADMIN: 'ROLE_ADMIN',
});

const MESSAGE = Object.freeze({
  CHAT_TASK: 'chat_task',
  ERROR: 'info',
  INFO: 'error',
});

const WAREHOUSE_FACILITY_TYPE = Object.freeze({
  STATIONARY: 'stationary',
  MOBILE: 'mobile',
});

const WAREHOUSE_IMAGE_PATH = '/uploads/files/warehouse/article/';

const WAREHOUSE_STOCK_OPERATION = Object.freeze({
  REPLENISHMENT: 'replenishment',
  CONSUMPTION: 'consumption',
  CORRECTION: 'correction',
  TRANSFER: 'transfer',
});
const AUDIT_LOG_LOG_TYPE = Object.freeze({
  WAREHOUSE_ARTICLE_UPDATE: 'warehouse_article_update',
  WAREHOUSE_ARTICLE_DELETE: 'warehouse_article_delete',
  WAREHOUSE_STOCK_CORRECTION: 'warehouse_stock_correction',
  WAREHOUSE_STOCK_CONSUMPTION: 'warehouse_stock_consumption',
  WAREHOUSE_STOCK_REPLENISH: 'warehouse_stock_replenish',
  WAREHOUSE_STOCK_TRANSFER: 'warehouse_stock_transfer',
  WAREHOUSE_STOCK_MIN_QUANTITY_UPDATE: 'warehouse_stock_min_quantity_update'
});

const DIALOG = Object.freeze({
  EDIT_CUSTOMER: 'editCustomer',
  EDIT_LOCATION: 'editLocation',
  DELETE_CUSTOMER: 'deleteCustomer',
  DELETE_LOCATION: 'deleteLocation',
});

const SETTLEMENT_STATUS = Object.freeze({
  ATTACHED_PROTOCOL: 'attached_protocol',
  NO_PROTOCOL: 'no_protocol',
  SETTLED: 'settled',
});

const UPDATE_TYPE = Object.freeze({
  CHAT_TASK_ADD: 'chat_task_add',
  CHAT_TASK_UPDATE: 'chat_task_update',
  CHAT_TASK_ADD_COMMENT: 'chat_task_add_comment',

  JOB_USER_TASK_UPDATE: 'job_user_task_update',
  JOB_USER_TASK_REMOVE: 'job_user_task_remove',
  JOB_USER_TASK_ADD: 'job_user_task_add',

  JOB_TASK_UNASSIGNED_UPDATE: 'job_task_unassigned_update',
  JOB_TASK_UNASSIGNED_REMOVE: 'job_task_unassigned_remove',

  JOB_TASK_PLANNED_UPDATE: 'job_task_planned_update',
  JOB_TASK_PLANNED_REMOVE: 'job_task_planned_remove',

  WAREHOUSE_ARTICLE_UPDATE: 'warehouse_article_update',
  WAREHOUSE_ARTICLE_CREATE: 'warehouse_article_create',
  WAREHOUSE_ARTICLE_DELETE: 'warehouse_article_delete',

  WAREHOUSE_ARTICLE_GROUP_UPDATE: 'warehouse_article_group_update',
  WAREHOUSE_ARTICLE_GROUP_CREATE: 'warehouse_article_group_create',
  WAREHOUSE_ARTICLE_GROUP_DELETE: 'warehouse_article_group_delete',

  WAREHOUSE_FACILITY_UPDATE: 'warehouse_facility_update',
  WAREHOUSE_FACILITY_CREATE: 'warehouse_facility_create',
  WAREHOUSE_FACILITY_DELETE: 'warehouse_facility_delete',

  WAREHOUSE_SUPPLIER_UPDATE: 'warehouse_supplier_update',
  WAREHOUSE_SUPPLIER_CREATE: 'warehouse_supplier_create',
  WAREHOUSE_SUPPLIER_DELETE: 'warehouse_supplier_delete',

  WAREHOUSE_ARTICLE_STOCK_UPDATE: 'warehouse_article_stock_update',

  WAREHOUSE_ARTICLE_AUDIT_LOG_NEW_ENTRY: 'warehouse_article_audit_log_new_entry',
});

const CHAT_TASK_STATUS = Object.freeze({
  REJECTED: 'rejected',
  UNCONFIRMED: 'unconfirmed',
  OPEN: 'open',
  COMPLETED: 'completed',
});

const CHAT_TASK_FILTERS = Object.freeze({
  STATUS: CHAT_TASK_STATUS,
  ALL: 'all',
  MINE: 'mine',
});

const CALENDAR_PLANNING_MODE = Object.freeze({ PLANNED: 1, UNPLANNED: 2 });

const TASK_TYPES = Object.freeze({
  OVERVIEW: 'overview',
  DIAGNOSIS: 'diagnosis',
  INSPECTION: 'inspection',
  QUOTATION: 'quotation',
  PARTS_ORDER: 'parts_order',
  DEVICE_ORDER: 'device_order',
  REPAIR: 'repair',
  CLEANING: 'cleaning',
  INSTALLATION: 'installation',
  WORKSHOP: 'workshop',
  MODERNIZATION: 'modernization',
  DEFECT: 'defect',
  ANOTHER: 'another',
});

const FiltersBasicGroup = Object.freeze({
  U_ND: 'assignedTaskWithoutDate', //user_no-date
  NU_ND: 'unassignedTaskWithoutDate', //no-user_no-date
  NU_D: 'unassignedTaskWithDate', //user_no-date
  OVERDUE_MORE_7: 'overdueMoreThan7Days',
  OVERDUE_LESS_7: 'overdueLessThan7Days'});


const MOBILE_VERSION_TYPE = Object.freeze({
  TECHNICIAN: 'technician',
  SCANNER: 'scanner',
});

const MOBILE_VERSION_STATUS = Object.freeze({
  UNRELEASED: 'unreleased',
  CURRENT: 'current',
  BACKUP: 'backup',
  ARCHIVE: 'archive',
  TEST: 'test',
});

const binJobDataTableHeadersConst = [
  {
    text: 'Tytuł',
    align: 'start',
    sortable: false,
  },
  {
    text: 'Klient',
    sortable: false,
  },
  {
    text: 'Lokalizacja',
    sortable: false,
  },
  {
    text: 'Data graniczna',
    sortable: false,
  },
  {
    text: 'Ostatnio modyfikowano',
    sortable: false,
  },
  {
    text: 'Utworzono',
    sortable: false,
  },
];

const jobsListDataTableConst = [
  {
    text: 'Tytuł',
    align: 'start',
    value: 'title',
    sortable: true,
  },
  {
    text: 'Klient',
    value: 'customer.name',
    sortable: true,
  },
  {
    text: 'Lokalizacja',
    value: 'location.name',
    sortable: true,
  },
  {
    text: 'Data graniczna',
    value: 'cutOffDate',
  },
  {
    text: 'Zmodyfikowano',
    value: 'modifiedInfo.lastModified',
  },
  {
    text: 'Utworzono',
    value: 'createdInfo.createdAt',
  },
];

export {
  ROLE,
  MESSAGE,
  DIALOG,
  CALENDAR_PLANNING_MODE,
  SETTLEMENT_STATUS,
  FiltersBasicGroup,
  TASK_TYPES,
  UPDATE_TYPE,
  CHAT_TASK_STATUS,
  CHAT_TASK_FILTERS,
  WAREHOUSE_FACILITY_TYPE,
  WAREHOUSE_STOCK_OPERATION,
  AUDIT_LOG_LOG_TYPE,
  WAREHOUSE_IMAGE_PATH,
  MOBILE_VERSION_TYPE,
  MOBILE_VERSION_STATUS,
  binJobDataTableHeadersConst,
  jobsListDataTableConst
};

